import React, { useEffect, useState, useRef } from 'react';
import { Chart } from 'chart.js';
import 'chartjs-plugin-annotation';
import { useSelector } from 'react-redux';
import PopupDetailviewmetrics from './PopupDetailviewmetrics';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Modal from 'react-modal';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import PropTypes from "prop-types";

const Detailsviewmetrics = ({ initialPodIds ,selectedSprint}) => {
  const [metrics, setMetrics] = useState([]);
  const [open_stories, setOpenStories] = useState([]);
  const [closed_stories, setClosedStories] = useState([]);
  const { user } = useSelector(state => state.auth);
  const chartRefs = useRef({});
  const [popupData, setPopupData] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedReporter, setSelectedReporter] = useState("");
  const [issues, setIssues] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isTableOpen, setIsTableOpen] = useState(false);
  const [totalUserStoryCount, setTotalUserStoryCount] = useState({ open_user_stories: 0, completed_user_stories: 0 });

  const token = useAuthToken();
  const intl = useIntl();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!token) {
          console.log('Token is missing. API requests will not be made.');
          return;
        }
  
        if (user.data[0].type === "stakeholder") {
          const payload = {
            pod_id: initialPodIds || [],
            org_code: user.data[0].client_code,
            sprint_name: selectedSprint || ''
          };
  
          const response = await fetch(process.env.REACT_APP_STAKEHOLDER_JIRASTORIESMETRICSSTAKEHOLDER, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token, // Include token in the headers
            },
            body: JSON.stringify(payload),
          });
  
          const data = await response.json();
          const parsedData = JSON.parse(data.body);
          setTableData([...parsedData.open_stories, ...parsedData.closed_stories]);
  
          if (Array.isArray(parsedData.reporters)) {
            setMetrics(parsedData.reporters);
            setOpenStories(parsedData.open_stories);
            setClosedStories(parsedData.closed_stories);
            setTotalUserStoryCount(parsedData.total_user_story_count);
          } else {
            console.log('Metrics data is empty or not in the expected format');
            setMetrics([]);
            setOpenStories([]);
            setClosedStories([]);
            setIssues([]);
          }
        } else {
          if (initialPodIds && initialPodIds.length > 0) {
            console.log('Calling fetchMetrics');
            await fetchAdditionalApiForPodId();
          } else {
            console.log('Calling fetchAdditionalApiForPodId');
            await fetchMetrics();
          }
        }
      } catch (error) {
        console.log('Error fetching metrics:', error);
        setMetrics([]);
        setOpenStories([]);
        setClosedStories([]);
        setIssues([]);
      }
    };
  
    fetchData();
  }, [token, user.data[0].type, initialPodIds, selectedSprint]);
  
  const fetchMetrics = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_CLIENT_GITJIRAUSERSTORIES, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Ensure the token is correctly formatted
        },
        body: JSON.stringify({
          manager_id: user.data[0].emp_id,
          sprint_name: selectedSprint,
        }),
      });

      const data = await response.json();
      const parsedData = JSON.parse(data.body);
      setTableData([...parsedData.open_stories, ...parsedData.closed_stories]);

      if (Array.isArray(parsedData.reporters)) {
        setMetrics(parsedData.reporters);
        setOpenStories(parsedData.open_stories);
        setClosedStories(parsedData.closed_stories);
        setTotalUserStoryCount(parsedData.total_user_story_count);
      } else {
        console.log('Metrics data is empty or not in the expected format');
        setMetrics([]);
        setOpenStories([]);
        setClosedStories([]);
        setIssues([]);
      }
    } catch (error) {
      console.log('Error fetching metrics:', error);
      setMetrics([]);
      setOpenStories([]);
      setClosedStories([]);
      setIssues([]);
    }
  };
  const fetchAdditionalApiForPodId = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_CLIENT_JIRASTORIESMETRICSMANAGERPODID, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Ensure the token is correctly formatted
        },
        body: JSON.stringify({
          pod_id: initialPodIds,
          sprint_name: selectedSprint,
        }),
      });

      const data = await response.json();
      const parsedData = JSON.parse(data.body);
      setTableData([...parsedData.open_stories, ...parsedData.closed_stories]);

      if (Array.isArray(parsedData.reporters)) {
        setMetrics(parsedData.reporters);
        setOpenStories(parsedData.open_stories);
        setClosedStories(parsedData.closed_stories);
        setTotalUserStoryCount(parsedData.total_user_story_count);
      } else {
        console.log('Metrics data is empty or not in the expected format');
        setMetrics([]);
        setOpenStories([]);
        setClosedStories([]);
        setIssues([]);
      }
    } catch (error) {
      console.log('Error fetching metrics:', error);
      setMetrics([]);
      setOpenStories([]);
      setClosedStories([]);
      setIssues([]);
    }
  };
  // Update chart data when metrics or selected reporter changes
  useEffect(() => {
    if (metrics.length > 0) {
      
      const chartData = generatePieData();
      displayChart('completedChart', 'Stories', chartData);
    } else {
      displayChart('completedChart', 'Stories', [0, 0]);
    }
  }, [metrics, selectedReporter, open_stories, closed_stories,totalUserStoryCount]);

  // Adjust generatePieData function to handle default case
  const generatePieData = () => {
    if (!selectedReporter) {
      // Return total counts if no reporter is selected
      return [totalUserStoryCount.open_user_stories, totalUserStoryCount.completed_user_stories];
    } else {
      // Render data for the selected reporter
      const reporter = metrics.find((item) => item.Reporter === selectedReporter);
      if (reporter) {
        return [reporter.open_user_stories, reporter.completed_user_stories];
      }
      // Return [0, 0] if the selected reporter is not found (optional)
      return [0, 0]; // No matching reporter found
    }
  };
  
  
  
  

  const displayChart = (chartId, chartLabel, chartData) => {
    const ctx = document.getElementById(chartId);

    if (!ctx) {
      return;
    }

    if (chartRefs.current[chartId]) {
      chartRefs.current[chartId].destroy();
    }

    chartRefs.current[chartId] = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['Open Stories', 'Completed Stories'],
        datasets: [
          {
            label: chartLabel,
            data: chartData,
            backgroundColor: ['lightgreen', 'red'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          annotation: {},
          legend: {
            labels: {
              generateLabels: () => [],
            },
          },
        },
        layout: {
          padding: {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10,
          },
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: function (tooltipItem, data) {
              const dataIndex = tooltipItem.dataIndex;
              const datasetIndex = tooltipItem.datasetIndex;
              const value = data.datasets[datasetIndex].data[dataIndex];
              return value;
            },
          },
        },
        onClick: () => {
          openModal();
        },
      },
    });
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="chart-container">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 className="chart-heading">{intl.formatMessage({ id: 'stories' })}</h2>
        <br />
        <select
          value={selectedReporter}
          onChange={(e) => setSelectedReporter(e.target.value)}
          style={{ width: '12rem' }}
        >
          <option value="">{intl.formatMessage({ id: 'allReporters' })}</option>
          {metrics.map(item => (
            <option key={item.Reporter} value={item.Reporter}>
              {item.Reporter}
            </option>
          ))}
        </select>
     <br/>
      <div className="chart-wrapper" style={{ width: "160px", height: "160px", margin: "auto"}}>
        {metrics.length > 0 ? (
          <canvas
            key="completedChart"
            id="completedChart"
        
          ></canvas>
        ) : (
          <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
        )}
      </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="PR Summary Modal"
        style={{
          content: {
            width: '50%',
            maxWidth: '800px',
            margin: 'auto',
            marginTop: '5rem',
          },
        }}
      >
        <TableContainer component={Paper}>
          <Table component={Paper} sx={{ maxWidth: 900, fontFamily: 'Poppins,Medium', borderRadius: '10px' }} aria-label="simple table">
            <TableHead
              sx={{
                backgroundColor: '#00B4D8',
                '& td, & th': {
                  fontFamily: 'Poppins,Medium',
                  fontSize: '15px',
                  color: 'white',
                  fontWeight: '600',
                },
              }}
            >
              <TableCell align="right">{intl.formatMessage({ id: 'reporter' })}</TableCell>
              <TableCell align="right">{intl.formatMessage({ id: 'issueKey' })}</TableCell>
              <TableCell align="right">{intl.formatMessage({ id: 'issueSummary' })}</TableCell>
            </TableHead>
            <TableBody>
              {tableData.map((reporter, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0, fontFamily: 'Poppins,Medium', fontSize: '15px' },
                    '&:nth-child(even)': {
                      backgroundColor: '#90E0EF',
                    },
                    '&:nth-child(odd)': {
                      backgroundColor: '#caf0f8',
                    },
                    '& td, & th': {
                      fontFamily: 'Poppins,Medium',
                      fontSize: '15px',
                      color: 'black',
                    },
                  }}
                >
                  <TableCell align="right">{reporter.Reporter}</TableCell>
                  <TableCell align="right">{reporter.issue_key}</TableCell>
                  <TableCell align="right">{reporter.issue_summary}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <button onClick={closeModal}>Close</button>
      </Modal>
    </div>
  );
};

Detailsviewmetrics.propTypes = {
  initialPodIds: PropTypes.array.isRequired,
};

export default Detailsviewmetrics;
