import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Avatar, 
  IconButton, 
  CircularProgress,
  styled 
} from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import S3FileUpload from 'react-s3';

const ImageUploadWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 120,
  height: 120,
  margin: '0 auto',
  marginBottom: theme.spacing(3),
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: '100%',
  height: '100%',
  border: `4px solid ${theme.palette.background.paper}`,
  boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
  transition: 'transform 0.2s ease',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const UploadButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: -8,
  bottom: -8,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.grey[400],
  },
}));

const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  borderRadius: '50%',
}));

const ImageUploadComponent = ({ 
  initialImage, 
  onImageChange, 
  config, 
  defaultImage 
}) => {
  const [imageState, setImageState] = useState({
    previewUrl: '',
    imageKey: '',
    isUploading: false,
    error: null
  });

  useEffect(() => {
    if (initialImage) {
      const s3Url = `https://${config.bucketName}.s3.${config.region}.amazonaws.com/${initialImage}`;
      setImageState(prev => ({
        ...prev,
        previewUrl: s3Url,
        imageKey: initialImage
      }));
    }
  }, [initialImage, config.bucketName, config.region]);

  const handleImageUpload = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    // Show immediate preview
    const localPreviewUrl = URL.createObjectURL(file);
    setImageState(prev => ({
      ...prev,
      previewUrl: localPreviewUrl,
      isUploading: true,
      error: null
    }));

    try {
      const uploadResponse = await S3FileUpload.uploadFile(file, config);
      const s3Url = `https://${config.bucketName}.s3.${config.region}.amazonaws.com/${uploadResponse.key}`;
      
      setImageState(prev => ({
        ...prev,
        previewUrl: s3Url,
        imageKey: uploadResponse.key,
        isUploading: false
      }));

      onImageChange(uploadResponse.key);
      URL.revokeObjectURL(localPreviewUrl);
    } catch (error) {
      console.error('Upload error:', error);
      setImageState(prev => ({
        ...prev,
        error: 'Failed to upload image',
        isUploading: false
      }));
    }
  };

  return (
    <ImageUploadWrapper>
      <StyledAvatar
        src={imageState.previewUrl || defaultImage}
        alt="Profile"
        onError={(e) => {
          console.log('Image load error, falling back to default');
          e.target.src = defaultImage;
        }}
      />
      
      {imageState.isUploading && (
        <LoadingOverlay>
          <CircularProgress size={32} sx={{ color: 'common.white' }} />
        </LoadingOverlay>
      )}

      <UploadButton
        component="label"
        disabled={imageState.isUploading}
        size="medium"
      >
        <input
          type="file"
          hidden
          accept="image/*"
          onChange={handleImageUpload}
        />
        <CloudUploadIcon />
      </UploadButton>
    </ImageUploadWrapper>
  );
};

export default ImageUploadComponent;