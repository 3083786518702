import styled from 'styled-components';
import React  from 'react'
import Badge from './Badge';
import Avatar from "./assets/MaskGroup.png";
import {cardShadow,hoverEffect,themeColor} from '../utils';

import PostRequirementCand from './PostRequirementCand';
import { NavHashLink } from 'react-router-hash-link';


const PostReqPodCreatedSuccessfully = () => {





  return (

   
    <Div >

      


<RecommendProject>


<CardContent>
    


<div className='posted'><p>Your Pod requirements have been</p><p> posted successfully!</p></div>




                     


    
  






    
<NavHashLink to='./PostRequirementCand'>
<Button type='submit'>Go Back</Button>

</NavHashLink>


</CardContent>

<SideCard>
<H1>Recommended Pods</H1>
  <SideCardContent>
    
<Row>
 
            <Detail>
              <InfoContainer>
                <img
                  src={Avatar}
                  alt=""
                  style={{ width: 50, height: 50 }}
                ></img>
                <Info>
                  <InfoName>Thomas Martin</InfoName>
                  <InfoUpdate>10 days ago</InfoUpdate>
                </Info>
              </InfoContainer>
            </Detail>
          </Row>
          {/* <Badge content="Design/" /> */}

          <Title>
            Need a designer to form branding essentials for my bussiness

            
          </Title>
          <ProjectInfo>
          Required Position: UX/UI Designer
          </ProjectInfo>

          </SideCardContent>
         <ViewAll>View All</ViewAll>

          
  <SideCardContent1>


          <Title>
          Your Posted Pod Requirements
            
          </Title>
          <View>
            
       <NavHashLink to = '/ViewPostedProReq'  smooth>
       
            View</NavHashLink> 
            
             </View>

          </SideCardContent1>
</SideCard>


</RecommendProject>

     </Div>
  )
}


const Div =styled.div`

// display:flex;
display:inline;
jutify-content:center;
flex-direction:column;
margin-top:6rem;
align-items:center;

margin-left:6.5rem;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  overflow:hidden;
justify-content:center;
align-items:center;
margin:auto;
width:25rem;
  }
`;

const RecommendProject=styled.div`

 display:flex;

jutify-content:center;
flex-direction:row;
@media screen and (min-width:320px) and (max-width:1080px){
    width:80%;
    height:max-content;
    margin:0.4rem;
    
   }
`;


const TitleText=styled.h4`
font-weight:600;
`;
const CardContent=styled.div`
margin:0.4rem;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;


border-radius:20px;
font-family:Poppins,Medium;
width:40rem;

padding: 1rem;
background-color: #F2F4F5;
border-bottom:1px solid pink;
box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};
}
`;



const InputField=styled.input`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
padding-left:20px;
font-weight:600;
font-family:Poppins,Medium;

`;


const SelectField=styled.select`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
font-weight:600;
color:grey;
font-family:Poppins,Medium;
`;

const SelectField1=styled.div`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
font-weight:600;
color:grey;
font-family:Poppins,Medium;
`;


const Button=styled.button`
margin-top:1rem;
border:none;
border-radius: 10px;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
width:20rem;
height:3rem;
font-size:18px;
font-weight:600;
color:white;
margin-top:4rem;
`;




const SideCard=styled.div`
margin-left:5rem;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
border-radius:20px;
font-family:Poppins,Medium;
width:20rem;
height:max-height;

padding: 1rem;
background-color: #F2F4F5;
border-bottom:1px solid pink;
box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};
}
`;


const SideCardContent=styled.div`
background-color:#90E0EF;
width:17rem;
display:flex;
flex-direction:column;
padding:3px;


border-radius:20px;
`;


const SideCardContent1=styled.div`
background-color:#90E0EF;
width:17rem;
display:flex;
margin-top:1rem;
flex-direction:column;
height:10rem;
justify-content:center;
align-items:center;
border-radius:20px;
`;





const Detail = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const Row = styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
`;
const InfoName = styled.h6`
  font-weight: 600;
`;
const InfoUpdate = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;

const Title = styled.h6`
  font-weight: 600;
`;
const ProjectInfo = styled.p`
padding:5px;
margin:3px;
background-color:white;
border-radius:5px  ;
font-size:13px;
color:black;
font-weight:600;
margin-bottom:1rem
`;

const ViewAll =styled.button`
width:5rem;
font-weight:600;
height:2rem;
margin-left:auto;
margin-top:1rem;
border: 2px solid #1DBF73;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;

const View =styled.button`
width:8rem;
font-weight:600;
height:2rem;
color:black;
background-color:white;
border-radius:5px  ;
margin-bottom:1rem;
margin-top:1rem;
border: none;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;

const H1 = styled.h1`
font-size:22px;
font-weight:600;
margin-bottom:1.2rem;
`;

export default PostReqPodCreatedSuccessfully