import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import { useEffect ,useState} from 'react';
import axios from 'axios';
import Badge from "./Badge";
import { cardShadow, hoverEffect, themeColor } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { NavHashLink } from 'react-router-hash-link';
import { CheckBox } from '@mui/icons-material';
import { useToastContainer } from 'react-toastify';
import AddPodactivityLead from './AddPodactivityLead';
import Pods from './Pods';
import { Calendar, DateRangePicker } from 'react-date-range';
import {GrFormEdit} from 'react-icons/gr';
import {RiDeleteBin6Line} from 'react-icons/ri';
import { FaSort } from 'react-icons/fa';
import { AiOutlineSortAscending } from 'react-icons/ai';

import { IoAdd } from 'react-icons/io5';

import './stylereact.css';
import { useAuthToken } from "../TokenContext";
const PodActivityLead = (props) => {

  const token = useAuthToken();

  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(1);
  const [paginationdate, setPaginationdate] = useState([]);
 
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [id, setId] = useState('');
  const { isLoggedIn, user } = useSelector(state => state.auth);
const[getpodactivitydata,setGetpodactivitydata]=useState([]);
const[status,setStatus]=useState('');
const [sorting, setSorting] = useState({
  column: '', // Default sorting column
  order: 'asc', // Default sorting order
});

let IDS = []
const poddata=[{ id :IDS,lead_id:user.data[0].c_id} ]

const getparticulardata = (idarray) => {


  idarray.map( (x,j)=>{

    IDS.push(x.id);
    
   })

const bodyupdate={poddata,lead_id:user.data[0].c_id}

   fetch(
    process.env.REACT_APP_TALENTLEAD_PODACTIVITYPOSTLEAD,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token, 
      },
      body: JSON.stringify(bodyupdate),
    }
  ).then(() => {
    
  alert("Posted successfully.")
  window.location.reload();
  //  window.location.href = "/Editsavedraft"
  });
      

};

const deletedata = (iddata) => {
  
const deletedetails = {id: iddata };
    
fetch(
  process.env.REACT_APP_TALENTLEAD_DELETEPODACTIVITYLEAD
  ,
  {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token, 
    },
    body: JSON.stringify(deletedetails),
  }
).then(() => {

alert("Deleted successfully.")
window.location.reload();
//  window.location.href = "/Editsavedraft"
});
    
 


};
  
useEffect((token) => {


  const headers = {
    'Authorization' : token, // Replace with your actual token
    'Content-Type': 'application/json', // You can add more headers as needed
  };

  const body = {
  lead_id: user.data[0].c_id,
  };


//https://7rjkn88350.execute-api.us-east-1.amazonaws.com/dev/get_pod_activity_lead
  axios.post(process.env.REACT_APP_TALENTLEAD_GETPODACTIVITYLEAD, body,{headers:headers})

    .then((res) => {
     
   setStatus(res.data.status);
     
      setGetpodactivitydata(res.data.data)

      
    
    }


    )
    .catch((err) => {
      console.log("catch", err);
    })




    const body1 = {
      "candidateID": user.data[0].c_id
    };
  
    axios.post(process.env.REACT_APP_CLIENT_GETCANDIDATEPODS, body1, {headers:headers})

      .then((res) => {
        setId(res.data.data.podDetails.id)
     


    }

    ).catch((err) => {
      console.log("catch", err);
    })

}, [token]);



const handleClick = (event) => {
  setcurrentPage(Number(event.target.id));
}


const pages = [];
for (let i = 1; i <= Math.ceil(getpodactivitydata.length / itemsPerPage); i++) {
  pages.push(i);
}
const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;

const currentItems = getpodactivitydata.slice(indexOfFirstItem, indexOfLastItem);
const renderPageNumbers = pages.map(number => {
  if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
   
    return (
      <div>
      <li key={number} id={number} onClick={handleClick} 
      className={currentPage == number ? 'active' : null}>{number}</li>
   
      </div>
      );
  } else {
    return null;
  }
});



const handleNextbtn = () => {
  setcurrentPage(currentPage + 1);
  if (currentPage + 1 > maxPageNumberLimit) {
    setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
    setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
  }
};
const handlePrevbtn = () => {
  setcurrentPage(currentPage - 1);
  if ((currentPage - 1) % pageNumberLimit == 0) {
    setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
    setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
  }
  
};
var todaysDate = new Date();



const handleSort = (column) => {
  setSorting((prevSorting) => {
    if (prevSorting.column === column) {
      // Toggle the sort order if the same column is clicked again
      return { ...prevSorting, order: prevSorting.order === 'asc' ? 'desc' : 'asc' };
    }
    // Set the new column and default sort order
    return { column, order: 'asc' };
  });
};


const sortedData = getpodactivitydata.sort((a, b) => {
  if (sorting.order === 'asc') {
    return a[sorting.column] > b[sorting.column] ? 1 : -1;
  } else {
    return a[sorting.column] < b[sorting.column] ? 1 : -1;
  }
});

const inlineStyles = {
  asc: {
    transform: 'rotate(180deg)'
  },
  desc: {
    transform: 'rotate(0deg)'
  }
};



const renderdata = (sortedData) => {
  return (
    
  <Div >
    
    <TitleText> Pod Activity</TitleText>
<ol className='pageNumbers' >
          <Num2>
            <li>
              <button onClick={handlePrevbtn}
                disabled={currentPage == pages[0] ? true : false} >
                Prev
              </button>
            </li>
            <Numbers>{renderPageNumbers} </Numbers>
            <li>
              <button onClick={handleNextbtn}
                disabled={currentPage == pages[pages.length - 1] ? true : false}
              > Next</button>
            </li>
          </Num2>
        </ol>

  { status == 404 ? (<CardContent  style={{display:'flex',justifyContent:'center',alignItems:'center',margin:'auto'}}> You Don't have any Pod Podactivity...
  <NavHashLink style={{marginLeft:'10px'}} to =  {`/AddPodactivityLead/${id}`}   smooth>
       Create Podactivity
       </NavHashLink> 
  </CardContent>) : (<>
      
       <TableContainer  >
       {
      sortedData.map( (x1,i)=>(
    <div>

      
       { x1.date != todaysDate ? (  
   <NavHashLink style={{marginLeft:'10px'}} to = {`/AddPodactivityLead/${id}`}  smooth>
   Create Podactivity for Today
   </NavHashLink> 
    
    )
    :  (
      <> </ >  
      ) }
      <Table 
      
      component={Paper} sx={{ maxWidth: 900 ,fontFamily:'Poppins,Medium', borderRadius: '10px'}} aria-label="simple table">
    
      
    
        <TableHead  sx={{ backgroundColor:'#00B4D8','& td, & th': {
                fontFamily: 'Poppins,Medium',
                fontSize: '15px',
                color: 'white',
                fontWeight:'600'
              },}} >
        

          <TableRow >
          <TableCell  align="right" onClick={() => handleSort('date')}>
          <Span >Sort </Span>  
          {sorting.column === 'date' && (
         <AiOutlineSortAscending className={sorting.order === 'asc' ? 'asc' : 'desc'} />
       )}
      
     </TableCell>
          <TableCell align="right" sx={{fontWeight:'bold'}}>Posted Date</TableCell>
            <TableCell align="right" sx={{fontWeight:'bold'}}>Pod Member</TableCell>
            <TableCell align="right"sx={{fontWeight:'bold'}}>Category</TableCell>
            <TableCell align="right"sx={{fontWeight:'bold'}}>Today's Update</TableCell>
            <TableCell align="right"sx={{fontWeight:'bold'}}>Blockers</TableCell>
            <TableCell align="right"sx={{fontWeight:'bold'}}> Edit</TableCell>
            <TableCell align="right"sx={{fontWeight:'bold'}}>Delete</TableCell>
            
          </TableRow>
        </TableHead>
       
        <TableBody     >
        {
      x1.items.map( (x,j)=>(
       
            <TableRow
            
              key={""}
              sx={{ '&:last-child td, &:last-child th': { border: 0 , fontFamily:'Poppins,Medium',fontSize: '15px'},
               '&:nth-child(even)': {
                backgroundColor: '#90E0EF'
              },
              '&:nth-child(odd)': {
                backgroundColor: '#caf0f8'
              },
              '& td, & th': {
                fontFamily: 'Poppins,Medium',
                fontSize: '15px',
                color: 'black',
              },
             }}
            >
              <TableCell align="right" ></TableCell>
 <TableCell align="right" >{x1.date}</TableCell>
              
              <TableCell   align="right" >{x.pod_candidates}</TableCell>
             
              <TableCell align="right">{x.categories}</TableCell>
              <TableCell align="right">{x.today_update}</TableCell>
              <TableCell align="right">{x.blockers}</TableCell>
          <TableCell align="right" > <NavHashLink to = {`/Edittalentpodactivitybyid/${x.id}`}  smooth> <ButtonEdit><GrFormEdit style={{color: 'white'}}/> </ButtonEdit >  </NavHashLink>  </TableCell>
              <TableCell align="right"><Buttondelete onClick={()=>{deletedata(x.id)}}> <RiDeleteBin6Line />  </Buttondelete></TableCell>
             
         
           
            </TableRow>
            
             ))}
        </TableBody>

        <NavHashLink style={{display:'flex',justifyContent:'center',alignItems:'center'}} to = {`/AddPodactivityLead/${id}`}  smooth>  <ButtonAdd>Add <span><  IoAdd style={{color:'#00B4D8'}} /></span> </ButtonAdd >  </NavHashLink>
       
 
      </Table>
      <Button  onClick={()=>{getparticulardata(x1.items)}}>Post</Button>
      
      </div> 
      
      ))}
    </TableContainer>
    </>)}
    </Div>
    
  )
}
return (<div>

  {renderdata(currentItems)}
  </div>
 )

}



export default PodActivityLead


const Div =styled.div`
margin-left: 5rem;
margin-top: 10rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
  width: 20rem;
  }

`;
const CardContent = styled.div`
  margin: 0.4rem;
  width: 40rem;
  padding:1rem;
  
  height:max-height;

  border-radius:15px;
  font-family:Poppins,Medium;
  background-color:#F2F4F5;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 20rem;
    margin:auto;
  }
`;
const ButtonEdit =styled.button`

  text-decoration: none  !important;

  background:none;
 
 
  border: none  !important;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top:2rem;
  }

`;
const ButtonAdd =styled.button`
display: flex;
    flex-direction: row;
justify-content: center;
align-items: center;

    margin: auto;
  text-decoration: none  !important;
  border-radius: 10px;
 font-weight:600;
 background:none;
  color: #00B4D8;
  border: none  !important;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top:2rem;
  }

`;

const Para =styled.p`
width: max-width;
float: right;
padding: 5px;
color: white;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
   margin: auto;
  }

`;
const Button=styled.button`
display: flex;
    flex-direction: column;
justify-content: center;
align-items: center;
    margin: auto;
margin-top:1rem;

border:none;
border-radius: 10px;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
width:5rem;
height:2rem;
font-size:18px;
font-weight:600;
color:white;

@media screen and (min-width:320px) and (max-width:1080px){
  width:5rem;
 
  
 }
`;
const Buttondelete =styled.button`

  text-decoration: none  !important;
 
  background:none  !important;

  color: black;
  border: none  !important;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top:2rem;
  }

`;
const Buttonpost =styled.button`

  text-decoration: none  !important;
  border-radius: 10px;
  background-color:seagreen;
  width: 4rem;
  height: 2rem;
  color: white;
  border: none  !important;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top:2rem;
  }

`;

const Num2 = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        margin:auto;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        width:100%;
}
        `;
        const Numbers = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        align-items:center;
        justify-content:center;
        width:7rem;
}
        `;
        const TitleText = styled.h4`
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
   margin-top:2rem;
  }

`;
const Span=styled.span`
cursor:pointer;
`;

