import React from 'react'
import {AiOutlineStar,AiFillStar} from 'react-icons/ai';
import { useState } from 'react';
import styled from 'styled-components';
import { useEffect } from 'react';
import axios from 'axios';
import userEvent from '@testing-library/user-event';
import { useDispatch, useSelector } from "react-redux";
import { hoverEffect } from '../utils';
import './BrowseTalent.css';
import { useAuthToken } from "../TokenContext";
const RatingandReview = ({id}) => {

  const { isLoggedIn, user } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const token = useAuthToken();

  const [number,SetNumber]=useState(0);
  const[writereview,setWritereview]=useState("");
  const[rate,SetRate]=useState('');
const tul=(v)=>{
  
  SetRate(v);
}
const onInputChange =(e)=>{
  setWritereview(e.target.value);
 
}

const [loading, setLoading] = useState(false);

  useEffect(()=>{ })

    const handleSubmit = () => {
      setLoading(true);
      
    const body={
      "reviews": writereview,
      "rating": rate,
      "candidateID": id,
      "clientID":user.data[0].emp_id,
    }
  
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    // https://iwhamz1yrc.execute-api.us-east-1.amazonaws.com/dev/candidatereview
    axios.post(process.env.REACT_APP_CLIENT_CANDIDATEREVIEW,body,   {headers:headers}).then((res)=>{
  
    alert("Review is Submitted!");

    }).catch(()=>{

    })
  }
 


  
  return (
    <div>
    <span style={{fontWeight:"bold"}}> Give Rating</span> 
       
        {Array(5).fill().map((_,index)=>(
        number>=index + 1?(
          <AiFillStar    style={{color:"orange",width:"40px",height:'50px'}}
          onClick={()=>{SetNumber(index + 1);tul(index + 1);}}
          />
    
        ) : (
          <AiOutlineStar   style={{color:"orange",width:"40px",height:'50px'}} 
           onClick={()=>{SetNumber(index + 1);tul(index + 1);}}/>
          
        )
      
      ))}
      
  
<Box>
<textarea  style={{border:'none',borderRadius:"5px",marginTop:'1rem',padding:"20px",width:'20rem',fontSize:"16px",fontWeight:"600"}} placeholder='comment here..'
onChange={onInputChange}
></textarea>
                     <Button type='submit'onClick={()=>handleSubmit()}>Submit</Button>
                     
    </Box>
  
    </div>
  )


}

export default RatingandReview



const Box = styled.div`
margin-top: -0.2rem;
display: flex;
flex-direction: column;
 align-items: center;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  margin:auto;
  align-items:center;
  justify-content:center;
  flex-direction:column;
}
`;
const Button=styled.button`

border:none;

border-radius: 10px;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
width:5rem;
height:2.5rem;
font-size:16px;
font-weight:600;
color:white;
margin-top:2rem;
@media screen and (min-width:320px) and (max-width:1080px){

  width:15rem;
}
`;