import React , { useState } from 'react'
import styled from 'styled-components';
import Avatar from "./assets/profile_img.png";
import {cardShadow,hoverEffect,themeColor} from '../utils';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from 'react';
import axios from 'axios';

import { useParams } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import Podactivitydashboardviewall from './Podactivitydashboardviewall';
import { useAuthToken } from "../TokenContext";
function Leaddashboardpodactivity() {

    const { isLoggedIn, user } = useSelector(state => state.auth);

    const [getactivitydata, setGetactivitydata] = useState([]);
    const token = useAuthToken();

    useEffect(() => {





        const body = {
           
            lead_id: user.data[0].c_id
        }
        const headers = {
            'Authorization' : token, // Replace with your actual token
            'Content-Type': 'application/json', // You can add more headers as needed
          };

        // https://qoe6ubwhig.execute-api.us-east-1.amazonaws.com/dev/pod_activity_dashboard
        axios.post(process.env.REACT_APP_TALENTLEAD_PODACTIVITYDASHBOARD, body,{headers:headers}).then((res) => {
                
                setGetactivitydata(res.data.data.data)
               
            }



          

            )
            .catch((err) => {
                console.log("catch", err);
            })


    }, [])
   

    return (
     
    
        <YourProjects>
      {getactivitydata.length == 0 ? (<> You Don't have any Pod Podactivity</>) : (<>
            <TitleText>                     
            {/* <br></br> */}
        Pod Activity  </TitleText>

           <Border>
           {getactivitydata.map((data) => 
          <div>
            <Project>
                
        
                
                <Detail>
                    <Title>{data.today_update}</Title>
                    <SubTitle> {data.pod_candidates}<span> -  {data.categories} </span></SubTitle>
                   
                </Detail>
            </Project>

            
            
            
           </div> 
        )
    }
    <NavHashLink to = '/Podactivitydashboardviewall'  smooth>
                
                <AllProjects >
                    View All
                    </AllProjects>
                    </NavHashLink> 
    
            </Border> 
            </>)}
        </YourProjects>
       
      )
    }
    const Border=styled.div`
   
    background-color:white;
    border-radius: 1rem ;
    height:10rem;
    overflow-y:scroll;
     padding:20px;
    `;
    const YourProjects=styled.div`
    // border: 25px solid #90E0EF;
    
    height:110%;
    background-color:#90E0EF;
    border-radius: 1rem ;
    padding:0.8rem;
    
 
    width:22rem;
    box-shadow: 0px 4px 6px #00000029;
    transition:0.45 ease-in-out;
    &:hover:{
    box-shadow:${hoverEffect};
    
    
     
    }
    
    @media screen and (min-width:320px) and (max-width:1080px){
        width:61%;
        height:max-content:
        margin-top:1rem;
        margin-right: 16rem
       }

    `;
    
    
    const TitleText = styled.h4`
    height:18%;
    color:white;
    font-weight:600;
    font-size:18px;
    font-family:Poppins,Medium;
    
    `;
    
    const Project=styled.div`
     display :flex;
     align-items: center;
    
     margin-bottom:0.3rem;
    `;
    
    const Detail=styled.div`
    margin-left:1rem;
    
    `;
    const Title=styled.h6`
    font-weight:500;
    
    
    
    @media screen and (min-width:320px) and (max-width:1080px){
        font-size:1rem;
       }
    `;
    const SubTitle=styled.h6`
    font-weight:500;
    font-size:13.5px;
    `;
    const AllProjects=styled.button`
    
     text-align:center;
    color: black;
    float:right;
    cursor:pointer;
    border-radius:0.3rem;
    border:2px solid green;
    background-color:white;
    width:80px;
   
    margin-top:-1rem;
    font-size:15px;
    align-self:center;
    
    @media screen and (min-width:320px) and (max-width:1080px){
        margin-top:-1.5rem;
        font-size:15px;
        width:60px;
       }

    `;
    
export default Leaddashboardpodactivity