import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Avatar from './assets/profile_img.png';
import {
  cardShadow,
  hoverEffect,
  themeColor
} from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import defaultimg from './assets/defaultimge.png';
import { useParams } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import Podactivityviewalltalent from './Podactivityviewalltalent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { useAuthToken } from '../TokenContext';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { useIntl } from 'react-intl';

function Talentdashboardpodactivity({ expanded }) {
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [getactivitydata, setGetactivitydata] = useState([]);
  const [sortingOrder, setSortingOrder] = useState('asc');
  const [selectedCreatedBy, setSelectedCreatedBy] = useState([]);
  const token = useAuthToken();
  const [anchorEl, setAnchorEl] = useState(null);
  const intl = useIntl();


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (value) => {
    const isSelected = selectedCreatedBy.includes(value);
  
    setSelectedCreatedBy((prevSelected) =>
      isSelected
        ? prevSelected.filter((item) => item !== value)
        : [...prevSelected, value]
    );
  };
  
  

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDoneClick = () => {
    handleClose();
    // Additional logic if needed
  };
  const toggleSortingOrder = () => {
    const sortedData = getactivitydata
      .slice()
      .sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return sortingOrder === 'asc' ? dateA - dateB : dateB - dateA;
      });

    setSortingOrder(sortingOrder === 'asc' ? 'desc' : 'asc');
    setGetactivitydata(sortedData);
  };

  const handleCreatedByChange = (event) => {
    setSelectedCreatedBy(event.target.value);
  };
  const handleSelectClose = () => {
    // Ensure the selected items are correctly stored when the dropdown is closed
    if (!selectedCreatedBy.length) {
      setSelectedCreatedBy([]);
    }
  };
  useEffect(() => {
    if (!token) {
      return; // Exit early if there is no token
    }
    const body = {

      talent_id: user.data[0].c_id,
    };
    const headers = {
      Authorization: token,
      'Content-Type': 'application/json',
    };

    axios
      .post(process.env.REACT_APP_TALENT_PODACTIVITYDASHBORDTALENT, body, {
        headers: headers,
      })
      .then((res) => {
        const sortedData = res.data.data.data.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB - dateA;
        });
        setGetactivitydata(sortedData);
      })
      .catch((err) => {
        console.log('catch', err);
      });
  }, [token]);

  const filteredData =
  selectedCreatedBy.length === 0
    ? getactivitydata.filter((data) => data.created_by === user.data[0].c_id)
    : getactivitydata.filter((data) =>
        selectedCreatedBy.includes(data.pod_candidates)
      );


  return (
    <div expanded={expanded}>
     

      {getactivitydata.length === 0 ? (
        <p>{intl.formatMessage({ id: 'noPodActivity' })}</p>
      ) : (
        <Border>
           <NavHashLink to="/Podactivityviewalltalent" smooth>
        <TitleText>{intl.formatMessage({ id: 'podActivity' })}</TitleText>
      </NavHashLink>
       <Button style={{marginBottom:'1rem'}}onClick={handleClick} variant="outlined">
            {selectedCreatedBy.length === 0
              ? intl.formatMessage({ id: 'selectPodTeamMember' })
              : selectedCreatedBy.map((item) => (
                  <Chip
                    key={item}
                    label={item}
                    color="primary"
                    style={{ marginRight: 5 }}
                  />
                ))}
          </Button>
          <Menu
  id="createdByMenu"
  anchorEl={anchorEl}
  open={Boolean(anchorEl)}
  onClose={handleClose}
>
  {[...new Set(getactivitydata.map((data) => data.pod_candidates))].map(
    (name) => (
      <MenuItem
        key={name}
        onClick={() => handleMenuItemClick(name)}
        selected={selectedCreatedBy.includes(name)}
      >
        {selectedCreatedBy.includes(name) && (
          <Chip
            label={name}
            color="primary"
            style={{ marginRight: 5 }}
          />
        )}
        {name}
      </MenuItem>
    )
  )}
  <MenuItem onClick={handleDoneClick}>{intl.formatMessage({ id: 'done' })}</MenuItem>
</Menu>





          <TableContainer component={Paper}>
            <Table
              component={Paper}
              sx={{
                backgroundColor: 'white',
                borderRadius: '10px',
                fontFamily: 'Poppins, Medium',
                borderCollapse: 'separate', // Ensure borders do not collapse
              }}
              aria-label="simple table"
            >
              <TableHead
  sx={{
   // Background color of the header
    '& td, & th': {
      fontFamily: 'Poppins, Medium',
      fontSize: '15px',
      color: '#17a2b8', // Darker color for the text
      fontWeight: '600', // Increase font weight for bolder text
    },
  }}
>

                <TableRow>
                  <TableCell  onClick={toggleSortingOrder}>
                  {intl.formatMessage({ id: 'submittedDate' })}{' '}
                    {sortingOrder === 'asc' ? (
                      <FontAwesomeIcon icon={faSortUp} />
                    ) : (
                      <FontAwesomeIcon icon={faSortDown} />
                    )}
                  </TableCell>
                  <TableCell>{intl.formatMessage({ id: 'teamMember' })}</TableCell>
                 
                  <TableCell>{intl.formatMessage({ id: 'todaysFocus' })}</TableCell>
                  <TableCell>{intl.formatMessage({ id: 'accomplishments' })}</TableCell>
                  <TableCell>{intl.formatMessage({ id: 'blockers' })}</TableCell>
                  
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((data, index) => (
                  <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                      fontFamily: 'Poppins, Medium',
                      fontSize: '15px',
                    },
                    
                    '& td, & th': {
                      fontFamily: 'Poppins, Medium',
                      fontSize: '15px',
                      color: 'black',
                      fontWeight: '600',
                      borderBottom: '1px solid #ccc', // Only bottom border for row lines
                      borderRight: 'none', // No right border for columns
                      borderLeft: 'none', // No left border for columns
                    },
                  }}
                    key={index}
                  >
                    <TableCell align="right">
                      {' '}
                      {moment(data.createdAt).format('MM-DD-YYYY')}
                    </TableCell>
                    <TableCell>
                      {user.data[0].profilePicture == null ||
                      user.data[0].profilePicture == '' ? (
                        <img
                          src={defaultimg}
                          alt=""
                          style={{
                            width: 50,
                            height: 50,
                            borderRadius: '50px',
                          }}
                        />
                      ) : (
                        <img
                          src={
                            'https://prismpoddata.s3.amazonaws.com/' +
                            user.data[0].profilePicture
                          }
                          alt=""
                          style={{
                            width: 50,
                            height: 50,
                            borderRadius: '50px',
                          }}
                        />
                      )}
                      <span>{data.pod_candidates}</span>
                    </TableCell>
                     
                    <TableCell>{data.today_focus}</TableCell>
                    <TableCell>{data.accomplishments}</TableCell>
                    <TableCell>{data.blockers}</TableCell> 
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Border>
      )}
    </div>
  );
}



    const Border=styled.div`
    overflow:hidden;
    background-color:white;
    border-radius: 1rem ;
   
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
     padding:20px;
     @media screen and (min-width:320px) and (max-width:1080px){
      overflow-y:scroll;
      width:20rem;
     }
    `;
    const YourProjects=styled.div`
    // border: 25px solid #90E0EF;
    height:20rem;
    background-color:#0077b6;
    border-radius: 1rem ;
    padding:0.8rem;
  margin-top:6rem;
  width: ${(props) => (props.expanded ? '47rem' : '51rem')}; 
      box-shadow: 0px 4px 6px #00000029;
    transition:0.45 ease-in-out;
    &:hover:{
    box-shadow:${hoverEffect};
    
    
     
    }
    
    @media screen and (min-width:320px) and (max-width:1080px){
      display:flex;
      flex-direction:column;
      justify-content:center;
      align-items:center;
      margin:auto; 
      width:20rem;
        height:max-content:
    
       
       }

    `;
    
    
    const TitleText = styled.h4`
    height:8%;
    color:black;
    font-weight:600;
    font-size:18px;
    font-family:Poppins,Medium;
    &:hover {
    
      color: #00B4D8; /* Change this to your desired text color on hover */
    }
    `;
    
    const Project=styled.div`
     display :flex;
     align-items: center;
    
     margin-bottom:0.3rem;
    `;
    
    const Detail=styled.div`
    margin-left:1rem;
    
    `;
    const Title=styled.h6`
    font-weight:500;
    
    
    
    @media screen and (min-width:320px) and (max-width:1080px){
        font-size:1rem;
       }
    `;
    const SubTitle=styled.h6`
    font-weight:500;
    font-size:13.5px;
    `;
    const AllProjects=styled.button`
    
     text-align:center;
    color: black;
    float:right;
    cursor:pointer;
    border-radius:0.3rem;
    border:2px solid green;
    background-color:white;
    width:80px;
   
    // margin-top:-3rem;
    margin-right:1rem;
    font-size:15px;
    align-self:center;
    
    @media screen and (min-width:320px) and (max-width:1080px){
        margin-top:-1.5rem;
        font-size:15px;
        width:60px;
       }

    `;
    
export default Talentdashboardpodactivity