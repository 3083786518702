import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

const CicdPiechartmanagerdetailvivewtalent = ({initialPodIds}) => {
  const [chartData, setChartData] = useState(null);
  const [pipelineExecutions, setPipelineExecutions] = useState([]);
  const [selectedExecution, setSelectedExecution] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const intl = useIntl();

  const user = useSelector((state) => state.auth.user);
  const token = useAuthToken();

  useEffect(() => {
    fetchData();
  }, [initialPodIds,token]);

  const fetchData = async () => {
    try{
      if (!token) {
        return; // Exit early if there is no token
      }
      const response = await fetch(
        process.env.REACT_APP_TALENT_CICDDEPLOYEMENTSTALENT,
        {
          method: 'POST',
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            c_id: user.data[0].c_id,
            pod_id:initialPodIds || [],
          }),
        }
      );
  
      const responseData = await response.json();
      const executions = JSON.parse(responseData.body).created_pipeline_execution_info;
  
      setPipelineExecutions(executions);
  
      const pipelineNames = Array.from(new Set(executions.map((execution) => execution.Pipeline_Name)));
      const successData = pipelineNames.map((name) =>
        executions.some((execution) => execution.Pipeline_Name === name && execution.Pipeline_execution_status === 'Succeeded') ? 1 : 0
      );

      const pipelineData = {
        labels: pipelineNames,
        datasets: [
          {
            data: successData,
            backgroundColor: [
              'rgba(75, 192, 192, 0.6)',
              'rgba(255, 99, 132, 0.6)',
              'rgba(255, 205, 86, 0.6)',
              // Add more colors as needed
            ],
          },
        ],
      };

      setChartData(pipelineData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleChartClick = (event, elements) => {
    if (elements.length > 0) {
      const clickedIndex = elements[0].index;
      const clickedPipelineName = chartData.labels[clickedIndex];
      const executionsForPipeline = pipelineExecutions.filter(
        (execution) => execution.Pipeline_Name === clickedPipelineName
      );
      setSelectedExecution(executionsForPipeline);
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setSelectedExecution(null);
    setIsModalOpen(false);
  };

  return (
    <div className="chart-container">
    <div style={{ display: 'flex', flexDirection: 'column' }}>
    <h2 className="chart-heading"> {intl.formatMessage({ id: 'ciCdPipeline' })}</h2>
    <br />
    <br/>
    <br/>
    <br/>
      
       <div className="chart-wrapper"style={{width:"150px",height:"150px",margin:"auto",textAlign:"center"}}>
    
       {chartData ? (
       
<Pie
  data={chartData}
  options={{
    onClick: handleChartClick,
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context) => {
            const label = context.chart.data.labels[context.dataIndex];
            const value = context.chart.data.datasets[0].data[context.dataIndex];
            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        display: false, // Hide the legend
      },
    },
  }}
  style={{ width: '50px', height: '50px' }}
/>

) : (
  <p> {intl.formatMessage({ id: 'noDataAvailable' })}</p>
)}

          {/* <button onClick={fetchData}>Refresh Data</button> */}

          {selectedExecution && (
            <Modal isOpen={isModalOpen} 
            style={{
              content: {
                width: '50%',
                maxWidth: '800px',
                margin: 'auto',
                marginTop: '5rem',
              },
            }}
            onRequestClose={closeModal}>
              <h3> {intl.formatMessage({ id: 'pipelineExecutionDetails' })}</h3>

              <TableContainer component={Paper}>
              <Table component={Paper}
                sx={{ maxWidth: 900, fontFamily: 'Poppins,Medium', borderRadius: '10px' }}
                aria-label="simple table">
                <TableHead
                  sx={{
                    backgroundColor: '#00B4D8',
                    '& td, & th': {
                      fontFamily: 'Poppins,Medium',
                      fontSize: '15px',
                      color: 'white',
                      fontWeight: '600'
                    },
                  }}
                >
                  <TableCell align="right"> {intl.formatMessage({ id: 'pipelineName' })}</TableCell>
                  <TableCell align="right"> {intl.formatMessage({ id: 'pipelineVersion' })}</TableCell>
                  <TableCell align="right"> {intl.formatMessage({ id: 'executionID' })}</TableCell>
                  <TableCell align="right"> {intl.formatMessage({ id: 'status' })}</TableCell>
                  <TableCell align="right"> {intl.formatMessage({ id: 'revisionSummary' })}</TableCell>
                  <TableCell align="right"> {intl.formatMessage({ id: 'revisionURL' })}</TableCell>
                  <TableCell align="right"> {intl.formatMessage({ id: 'executionDate' })}</TableCell>
                 
                </TableHead>
                <TableBody>
              
               
                  {selectedExecution.map((execution) => (
                  <TableRow
                          key={execution.PipelineExecutionId}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0, fontFamily: 'Poppins,Medium', fontSize: '15px' },
                            '&:nth-child(even)': {
                              backgroundColor: '#90E0EF'
                            },
                            '&:nth-child(odd)': {
                              backgroundColor: '#caf0f8'
                            },
                            '& td, & th': {
                              fontFamily: 'Poppins,Medium',
                              fontSize: '15px',
                              color: 'black',
                            },
                          }}
                        >
                          <TableCell align="right">{execution.Pipeline_Name}</TableCell>
                          <TableCell align="right">{execution.Pipeline_Version}</TableCell>
                          <TableCell align="right">{execution.PipelineExecutionId}</TableCell>
                          <TableCell align="right">{execution.Pipeline_execution_status}</TableCell>
                          <TableCell align="right">{execution.Revision_Summary}</TableCell>
                          <TableCell align="right"> {execution.Revision_Url}</TableCell>
                         
                          <TableCell align="right"> {execution.Execution_date}</TableCell>
                         
                        </TableRow>
                      )
                    )
                        }
                </TableBody>
              </Table>
            </TableContainer>
              <button onClick={closeModal}>{intl.formatMessage({ id: 'done' })}</button>
            </Modal>
          )}
        </div>
     
    </div>
    </div>
  );
};
CicdPiechartmanagerdetailvivewtalent.propTypes = {
  initialPodIds: PropTypes.array.isRequired,
};
export default CicdPiechartmanagerdetailvivewtalent;