import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import Badge from './Badge';

import {cardShadow,hoverEffect,themeColor} from '../../utils';
import AWS from 'aws-sdk';
import Papa from 'papaparse';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {VscDebugBreakpointConditional} from 'react-icons/vsc'
import {IoIosNotificationsOutline} from 'react-icons/io'
import { useDispatch, useSelector } from "react-redux";
import { icons } from 'react-icons';

import { useAuthToken } from "../../TokenContext";
import { Warning as WarningIcon } from '@mui/icons-material';

import { useIntl } from 'react-intl';

import { Assignment, HourglassEmpty, CheckCircle, Cancel } from '@mui/icons-material'; // Import relevant icons
import BlockIcon from '@mui/icons-material/Block';

function ClientProjectInfo({expanded}) {
const[details,setDetails]=useState([])
const[blockers,setBlockers]=useState([]);
const [isLoading, setIsLoading] = useState(true);
const [errorMessage, setErrorMessage] = useState('');
const { isLoggedIn, user } = useSelector(state => state.auth);
const intl = useIntl();



const token = useAuthToken();
const Blockers = async () => {
  if (!token) {
    console.log('Token is missing. API requests will not be made.');
    return;
  }
  const  headers ={
    'Authorization': token,
    'Content-Type': 'application/json',
  }


  if (user.data[0].type === "stakeholder") {
    try {
      //https://6ooukwlqwk.execute-api.us-east-1.amazonaws.com/dev/fetchLatestBlockers
      const response = await axios.post(process.env.REACT_APP_STAKEHOLDER_FTCHLATESTBLOCKERSSTAKEHOLDER,{"client_code": user.data[0].client_code },{headers:headers});
      
      setBlockers(response.data.data); // Assuming that data.body contains an array of gig options
      setIsLoading(false);
    } catch (error) {
      console.error(error);
           setErrorMessage("Failed to fetch data. Please try again later.");
          setIsLoading(false);
    }
  }else{

  try {
    //https://6ooukwlqwk.execute-api.us-east-1.amazonaws.com/dev/fetchLatestBlockers
    const response = await axios.post(process.env.REACT_APP_CLIENT_FETCHLATESTBLOCKERS,{manager_id:user.data[0].emp_id},{headers:headers});
   
   
    setBlockers(response.data.data); // Assuming that data.body contains an array of gig options
    setIsLoading(false);
  } catch (error) {
    console.error(error);
         setErrorMessage("Failed to fetch data. Please try again later.");
        setIsLoading(false);
  }
}
};


  const notificationdetails = async () => {
    if (!token) {
      return; // Exit early if there is no token
    }
    const  headers ={
      'Authorization': token,
      'Content-Type': 'application/json',
    }
    try {
      const response = await axios.post(process.env.REACT_APP_NOTIFICATIONLIMIT,{user_id:user.data[0].emp_id},{headers:headers});
      
      setDetails(response.data.data); // Assuming that data.body contains an array of gig options
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
 
    notificationdetails();
  Blockers();
  }, [token])

  const colors = ['#f94144', '#f3722c', '#f8961e', '#f9844a', '#f9c74f', '#90be6d', '#43aa8b', '#577590'];

  const iconMap = {
   
    'Canceled': <Cancel />,
    default: <Cancel /> // Default icon if status doesn't match any predefined statuses
  };


  return <InfoCard  >


    <RecommendProject>
 
  <TitleText1>{intl.formatMessage({ id: 'blocker' })}</TitleText1>


    { !Array.isArray(blockers) || blockers.length === 0 ? (
    <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>):(
     <>
       
       {blockers.map((data, index) => (
          <CardContent1 key={data.key}>
            <div style={{ display: 'flex',height:"100%", flexDirection: 'column', alignItems: 'center' }}>
              <BlockerIcon>
                <span style={{ fontWeight: '600', fontSize: '16px', color: 'white' }}>
                  <BlockIcon/>
                </span>
              </BlockerIcon>
              {index < blockers.length - 1 && <VerticalLine />}
            </div>
            <div style={{ flex: 1, marginLeft: '16px' }}>
              <ProjectInfo1>
                <span style={{  fontSize: '16px', paddingRight: '0.3rem', color: '#17a2b8' }}>
                  Team Member :
                </span>
                {data.Candidate_Name}
              </ProjectInfo1>
              <PriceContainer1>
                <span style={{ fontWeight: '600',width:'30%',  fontSize: '16px', color: '#17a2b8' }}>
                  Blocker :
                </span>
                <Price1>{data.description} </Price1>
              </PriceContainer1>
            </div>
          </CardContent1>
        ))}
      </>
    )}
  
</RecommendProject>

    
  </InfoCard>
}

const TitleText1=styled.h6`
 font-family: 'Poppins', sans-serif;
  font-size: 20px;
  color: black;
  font-weight:600;
  margin-bottom: 20px;
`;


const InfoCard = styled.div`
// height:360%!important;

// width: ${(props) => (props.expanded ? '19%' : '21%')}; 

// margin-left:35px;
// border-radius:1rem;
// background-color:#e5f8fc;
// flex-direction: column;
// align-items: center;
// gap: 1rem;
// box-shadow: 0px 4px 6px #00000029;
// transition:0.4s ease-in-out;
// &:hover{
//   box-shadow:${hoverEffect};
// }

// @media screen and (min-width:320px) and (max-width:1080px){
//   width:20rem;
//  margin-right: 300px
//  display: flex;

// margin:auto;
// align-item:center;
// justify-content:center;
//  }
`;
const BlockerIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;  // Adjust size as needed
  height: 40px; // Adjust size as needed
  border-radius: 50%;
  background-color: red; // Customize color as needed
  margin-right: 8px;
`;

const VerticalLine = styled.div`
  width: 1px;
  height:100px;
   // Adjust height based on content
  background-color: #ccc; // Customize color as needed
  margin: 8px 0; // Adjust spacing between icons
`;

const CardContent1 = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const ProjectInfo1 = styled.div`
  display: flex;
  align-items: center;
  font-weight :600;
`;

const PriceContainer1 = styled.div`
  display: flex;
  align-items: center;
  width:100%;
  height:auto;
  @media screen and (min-width:320px) and (max-width:1080px){
   display: flex;
   flex-direction:column;
   }
`;

const Price1 = styled.span`
  font-weight: 600;
  font-size: 16px;
  width:80%;
  
`;

const RecommendProject=styled.div`

  overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  background-color: white;
  padding: 20px;
  border-radius: 10px;
   font-family: 'Poppins', sans-serif;
 height:100%;
@media screen and (min-width:320px) and (max-width:1080px){
    width:100%;
    height:40%;
   }
`;

const Row1 = styled.div`
display: flex;

align-item:center;
margin-bottom:0.2rem;
${({justify})=>
justify &&

`justify-content:space-around;
width:90%;

margin-left:6rem;
margin-bottom:0rem;
`}
`;



export default ClientProjectInfo