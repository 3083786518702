import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';
import { useAuthToken } from '../TokenContext';
import styled from 'styled-components';

const CreateprofilePortfolio = ({ responseData }) => {
  const { isLoggedIn, user } = useSelector(state => state.auth);
  const token = useAuthToken();
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [newItem, setNewItem] = useState({
    projectObjective: '',
    projectDescription: '',
    industry: '',
    images: [],
  });

  // Prefill the form with response data when the component mounts
  useEffect(() => {
    if (responseData?.body?.projects?.length > 0) {
      const prefilledItems = responseData.body.projects.map(project => ({
        projectObjective: project.project_objective || '',
        projectDescription: project.project_description || '',
        industry: project.industry || '',
        images: [], // Images won't be prefilled, as they are typically uploaded
      }));
      setPortfolioItems(prefilledItems);
    }
  }, [responseData]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setPortfolioItems((prevItems) =>
      prevItems.map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      )
    );
  };

  const handleImageUpload = (e, index) => {
    const files = Array.from(e.target.files);

    if (files.length > 3) {
      alert('You can upload up to 3 images only.');
      return;
    }

    setPortfolioItems((prevItems) =>
      prevItems.map((item, i) =>
        i === index ? { ...item, images: files } : item
      )
    );
  };

  const handleAddItem = async () => {
    for (let item of portfolioItems) {
        if (!item.projectObjective || !item.projectDescription || !item.industry) {
          alert('Please fill in all required fields.');
          return;
        }
      }

    try {
      const imageUrls = await Promise.all(newItem.images.map(uploadImageToS3));
      const portfolioUserId = user.data[0].type === "Provider"
        ? user.data[0].id
        : user.data[0].c_id;

      const payload = portfolioItems.map(item => ({
        portfolio_user_id: portfolioUserId,
        project_objective: item.projectObjective,
        project_description: item.projectDescription,
        industry: item.industry,
        project_files: imageUrls,
      }));

      const response = await fetch(process.env.REACT_APP_TALENT_CREATEPORTFOLIO, {
        method: 'POST',
        headers: {
          "Authorization": token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.statusCode === 400 && responseData.body === "Already 5 row values exist for this portfolio_user_id.") {
          alert("You can only create a maximum of 5 portfolio items. You have already created the maximum allowed.");
        } else {
          setPortfolioItems((prevItems) => [...prevItems, newItem]);
          setNewItem({
            projectObjective: '',
            projectDescription: '',
            industry: '',
            images: [],
          });

          // Optionally, fetchPortfolioData(); to refresh the portfolio items
        }
      } else {
        console.error('Error adding portfolio item:', response.statusText);
        alert('Error adding portfolio item. Please try again.');
      }
    } catch (error) {
      console.error('Error adding portfolio item:', error);
      alert('Error adding portfolio item. Please try again.');
    }
  };

  AWS.config.update({
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
  });

  const s3 = new AWS.S3();
  const uploadImageToS3 = async (imageFile) => {
    const imageKey = `portfolio-images/${uuidv4()}.png`;
    const uploadParams = {
      Bucket: process.env.REACT_APP_BUCKETNAME,
      Key: imageKey,
      Body: imageFile,
      ContentType: 'image/png',
    };

    try {
      const uploadResult = await s3.upload(uploadParams).promise();
      return uploadResult.Location;
    } catch (error) {
      console.error('Error uploading image to S3:', error);
      throw error;
    }
  };

  return (
    <div style={{border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px",}}>
        <h5>Add Portfolio Item</h5>
        {portfolioItems.map((item, index) => (
          <div key={index}>
            <Input
              type="text"
              name="projectObjective"
              placeholder="Project Objective"
              maxLength={100}
              value={item.projectObjective}
              onChange={(e) => handleInputChange(e, index)}
            />
            <Textarea
              name="projectDescription"
              maxLength={300}
              placeholder="Project Description"
              value={item.projectDescription}
              onChange={(e) => handleInputChange(e, index)}
            />
            <Selectfield
              name="industry"
              value={item.industry}
              onChange={(e) => handleInputChange(e, index)}
            >
              <option value="">Select Industry</option>
              <option value="IT & Software">IT & Software</option>
              <option value="Healthcare">Healthcare</option>
              <option value="Banking and Financial">Banking and Financial</option>
              <option value="Services">Services</option>
            </Selectfield>
            <ImageInput
              type="file"
              name="images"
              accept="image/*"
              multiple
              onChange={(e) => handleImageUpload(e, index)}
            />
          </div>
        ))}
        <Button1 onClick={handleAddItem}>Add Item</Button1>
      </div>
  
  );
};

export default CreateprofilePortfolio;

const Input = styled.input`
  width: 28rem;
  height: 3rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 18rem;
  }
`;

const Selectfield = styled.select`
  width: 28rem;
  height: 3rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 18rem;
  }
`;

const Textarea = styled.textarea`
  width: 28rem;
  height: 5rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 18rem;
  }
`;

const ImageInput = styled.input`
  margin-bottom: 1rem;
`;

const AddPortfolioItem = styled.div`
  background-color: #f2f2f2;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  width: 30rem;

  h2 {
    margin-bottom: 1rem;
  }
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3), 0 1px 3px rgba(0, 0, 0, 0.01);
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 20rem;
  }
`;

const Button1 = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 10rem;
  width: 80%;
`;
