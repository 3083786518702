// fr/messages.js
const messagesFr = {
  welcome: 'Bienvenue sur Globl.ai!',
  globalWorkPlatform: 'Plateforme de travail mondiale',
  manageProfile: 'Gérer le profil',
  getStarted: 'Commencer',
  createGig: 'Créer un projet',
  createArt: 'Créer de l\'ART',
  createPod: 'Créer un POD',
  findYourBestTalentPod: 'Trouvez votre meilleur POD de talent',
  signUpToGetStarted: '"Inscrivez-vous" ci-dessous pour commencer!',
  Register: 'S\'inscrire',
  privacyPolicy: 'Politique de confidentialité',
  termsOfService: 'Conditions d\'utilisation',
  copyright: 'Copyright © 2023-présent Brilli Global, Inc. Tous droits réservés.',
  home: 'Accueil',
  dashboard: 'Tableau de bord',
  podrate:"Taux de Reproduction",
  resetPassword: 'Réinitialiser le mot de passe',
  logOut: 'Se déconnecter',
  podAnalytics: 'Analytiques de POD',
  latestSprintVelocity: 'Dernière vélocité du sprint',
  stories: 'Histoires',
  noDataAvailable: 'Aucune donnée disponible',
  defects: 'Défauts',
  blockers: 'Obstacles',
 
  code: 'Lignes de Code',
  jiraTimeDetails: 'Journal du Temps',
  selectPods: 'Sélectionner des PODs',
  notifications: 'Notifications',
  noNotifications: 'Il n\'y a pas de notifications',
  assignedItems: 'Éléments attribués',
  taskName: 'Nom de la tâche',
  noPodActivity: 'Vous n\'avez aucune activité de POD',
  done: 'Terminé',
  selectPodTeamMember: 'Sélectionnez un membre de l\'équipe du POD',
  PodTeamMember:'un membre de l\'équipe du POD',
  submittedDate: 'Date de soumission',
  teamMember: 'Membre de l\'équipe',
  todaysFocus: 'Objectif du jour',
  accomplishments: 'Réalisations',
  podAssist: 'Assistance POD',
  enterStoryDescription: 'Entrer une description de l\'histoire',
  pleaseEnterStoryDescription: 'Veuillez saisir une description d\'histoire et cliquer sur soumettre',
  submit: 'Soumettre',
  storyDescription: 'Description de l\'histoire',
  acceptanceCriteria: 'Critères d\'acceptation',
  testCases: 'Cas de test',
  loadingStoryDescription: 'Chargement de la description de l\'histoire',
  cancel: 'Annuler',
  loadingAcceptanceCriteria: 'Chargement des critères d\'acceptation',
  loadingTestCases: 'Chargement des cas de test',
  loading: 'Chargement', 
  noSubscription: "Vous n'avez aucun plan d'abonnement",
  prev: 'Précédent',
  next: 'Suivant',
  podName: 'Nom du Pod',
  email: 'E-mail',
  portfolio: 'Portfolio',
  podSize: 'Taille du Pod',
  spotsLeft: 'Places disponibles',
  joinPod: 'Rejoindre le Pod',
  noMatchingPods: 'Aucun pod correspondant disponible',
  yourPods: 'Vos Pods', // French
  noPods: 'Vous n\'avez aucun Pod',
  podActivity: 'Activité du Pod',
  podRetro: 'Rétro du Pod',
  podTask: 'Tâche du Pod',
  timeSubmit: 'Soumettre l\'heure',
  podBoard: 'Tableau du Pod', // French
  toDo: 'À faire',
  inProgress: 'En cours',
  Done: 'Terminé',
  noData: 'Aucune donnée',
  selectPod: 'Sélectionnez un Pod',
  taskManager: 'Gestionnaire de tâches',
  description: 'Description', // French
  dueDate: 'Date limite', 
  all: 'Tous', 
  markComplete: 'Marquer comme complet', 
  noPodTasks: 'Vous n\'avez aucune tâche de Pod',
  doraMetrics: 'Métriques Dora', 
  allReporters: 'Tous les reporters', // French
  reporter: 'Reporter', // French
  issueSummary: 'Résumé du problème', // French
  issueKey: 'Clé du problème', // French
  issueStatus: 'État du problème', // French
  githubSummary: 'Résumé GitHub', // French
  githubUser: 'Utilisateur GitHub', // French
  totalLinesChangedLastWeek: 'Total des lignes modifiées la semaine dernière', // French
  totalLinesChangedThisWeek: 'Total des lignes modifiées cette semaine', // French
  allRepoCheckins: 'Toutes les validations de dépôt', // French
  currentWeek: 'Semaine en cours', // French
  previousWeek: 'Semaine précédente', // French
  prCount: 'Nombre de PR', // French
  prSummary: 'Résumé des PR', // French
  submittedPRCount: 'Nombre de PR soumis', // French
  approvedPRCount: 'Nombre de PR approuvés', // French
  totalPRCount: 'Nombre total de PR', // French
  ciCdPipeline: 'Pipeline CI/CD', // French
  pipelineExecutionDetails: 'Détails de l\'exécution du pipeline', // French
  pipelineName: 'Nom du pipeline', // French
  pipelineVersion: 'Version du pipeline', // French
  executionID: 'ID d\'exécution', // French
  status: 'Statut', // French
  revisionSummary: 'Résumé de la révision', // French
  revisionURL: 'URL de la révision', // French
  executionDate: 'Date d\'exécution', // French
  sonarqubeStats: "Statistiques Sonarqube",
  sonarqubeprojectkey:'Clé du Projet Sonarqube',
  detailsForGitHubRepo: "Détails du dépôt GitHub",
  projectKey: "Clé du projet",
  ncloc: "Ncloc",
  bugs: "Bugs",
  vulnerabilities: "Vulnérabilités",
  codeSmells: "Code Smells",
  duplicatedLinesDensity: "Densité des lignes dupliquées",
  cloudCost: "Coût Cloud",
  services: "Services",
  cost: "Coût",
  costDetails: "Détails du coût",
  timeLog: "Journal du temps",
  selectFilter: "Sélectionner un filtre",
  candidates: "Candidats",
  linesOfCodeByDay: "Lignes de code par jour",
  selectAUser: "Sélectionner un utilisateur",
  sprintVelocity: "Vélocité de sprint",
  reviewAndRating: "Évaluation & Note",
  pod: "Pod",
  client: "Client",
  candidate: "Candidat",
  noCandidateReviews: "Il n'y a pas d'avis de candidat.",
  individual: "Individuel",
    manageProfileHere: "Gérez votre profil ici",
    updateProfileHere: "Mettez à jour votre profil ici",
    addPortfolio: "Ajouter un portfolio",
    basicInformation: "Informations de base",
    skillsAndExpertise: "Compétences et Expertise",
    skills: "Compétences",
    industry: "Industrie",
    techStack: "TechStack",
    educationAndCertifications: "Éducation et Certifications",
    education: "Éducation",
    certifications: "Certifications",
    workExperience: "Expérience professionnelle",
    podExperience: "Expérience de Pod",
    noExperience: "Aucune expérience",
    otherInformation: "Autres informations",
    github: "Github",
    linkedIn: "LinkedIn",
    preferredWorkHours: "Heures de travail préférées",
    ratePerHour: "Taux /heure",
    calendlyUrl: "URL Calendly",
    talentCode: "Code Talent",
    currency: "Devise",
    resume: "Curriculum vitae",
    resumeLink: "Voir le CV",
    firstName: "Prénom",
    middleName: "Deuxième prénom",
    lastName: "Nom de famille",
    country: "Pays",
    about: "À propos",
    primarySkill: "Compétence principale",
    secondarySkill: "Compétence secondaire",
    qualification: "Qualification",
    specialization: "Spécialisation",
    university: "Université",
    startDate: "Date de début",
    endDate: "Date de fin",
    add: "Ajouter",
    institute: "Institut",
    certificationUrl: "URL de certification",
    validFrom: "Valide à partir de",
    validTill: "Valide jusqu'à",
    companyName: "Nom de l'entreprise",
    positionName: "Nom du poste",
    linkedInUrl: "URL LinkedIn",
    githubUrl: "URL Github",
    preferredWorkHoursMonthly: "Heures de travail préférées /mois",
    ratePerMonth: "Taux /mois",
    availability: "Disponibilité",
    hoursPerWeek: "Heures par semaine",
    timeZone: "Fuseau horaire",
    currentlyAvailable: "Actuellement disponible",
    availableDate: "Date de disponibilité",
    update: "Mettre à jour",
     postActivity: "Publier une activité",
      viewActivity: "Voir l'activité",
      postPodActivity: "Publier une activité de Pod",
      languageSpoken: "Langue parlée",
      categories: "Catégories",
      fileUpload: "Téléchargement de fichier",
      fileNameNoSpaces: "Le nom du fichier ne doit pas inclure d'espaces entre tous les éléments ou caractères",
      upload: "Télécharger",
      userStory: "Récit utilisateur",
      clickHereUploadVideoClip: "Cliquez ici pour télécharger un extrait vidéo !",
      post: "Publier",
      yourPostedPodActivity: "Votre activité de Pod publiée",
      postedDate: "Date de publication",
      jiraIssue: "Problème Jira",
      videoUrl: "URL de la vidéo",
      edit: "Modifier",
      findPods: "Trouver des Pods",
      board: "Tableau",
      myPods: "Mes Pods",
      myTasks: "Mes tâches",
      podMetrics: "Métriques de Pod",
      podTasks: "Tâches de Pod",
      podFeedback: "Feedback de Pod",
      noBlockers: "Il n'y a pas de blocage",
      internalPod: "Pod interne",
  externalPod: "Pod externe",
  podType: "Type de Pod",
  viewPodDetails: "Voir les détails du Pod",
  findBestPod: "Trouvez des groupes correspondant à votre expérience/ensemble de compétences",
  createYourOwnPod: "Créez votre propre Pod et parcourez les projets",
  enterPodName: "Entrer le nom du Pod",
  selectArtName: "Sélectionnez le nom de l'ART",
  noArtOptionsAvailable: "Aucune option ART disponible",
  enterPodMission: "Entrer la mission du Pod",
  podTechStack: "Tech Stack du Pod",
  create: "Créer",
  privatePods: "Pods privés",
  activePods: "Pods actifs",
  previousPods: "Pods précédents",
  pendingPods: "Pods en attente",
  podDescription: "Description du Pod",
  date: "Date",
  viewDetails: "Voir les détails",
  noCandidatesAddedToPod: "Aucun candidat ajouté au Pod !",
  viewPodActivities: "Voir les activités du Pod",
  viewTimeEntries: "Voir les entrées de temps",
  select: "Sélectionner",
  viewVideo: "Voir la vidéo",
  approved: "Approuvé",
  pending: "En attente",
  rowsPerPage: "Lignes par page",
  selectItemsPerPage: "Sélectionner les éléments par page",
  noReviews: "Il n'y a pas de critiques",
  approve: "Approuver",
  noVideoAvailable: "Aucune vidéo disponible",
  dateRangeFilter: "Filtre de plage de dates",
  createTask: "Créer une tâche",
  viewTasks: "Voir les tâches",
  podRequests: "DEMANDES DE POD",
  sprint: "Sprint",
  startDoing: "Commencer à faire",
  stopDoing: "Arrêter de faire",
  keepDoing: "Continuer à faire",
  save: "Enregistrer",
  phoneNumber: "Numéro de téléphone",
  createProfile: "Créer un profil",
  createProfileOpportunity: "Créez votre profil dans notre base de talents pour être inclus dans diverses opportunités de travail à distance.",
  region: "Région",
  creatingProfile: "Création de profil",
  addStakeholder: "Ajouter un intervenant",
  manage: "Gérer",
  deactivatePod: "Désactiver le Pod",
  delete: "Supprimer",
  invite: "Inviter",
  setting: "Paramètre",
  noStakeholders: "Il n'y a pas d'intervenants pour ce Pod",
  editPod: "Modifiez votre pod et parcourez les projets",
  deactivateToUpdate: "Désactivez le pod pour le mettre à jour !",
  managePod: "Gérer le Pod",
  taskForm: "Formulaire de tâche",
  podTaskName: "Nom de la tâche de Pod",
  podTaskDescription: "Description de la tâche de Pod",
  podTaskDueDate: "Date d'échéance de la tâche de Pod",
  podTaskOwner: "Propriétaire de la tâche de Pod",
  podTaskStatus: "Statut de la tâche de Pod",
  podCategories: "Catégories de Pod",
  jiraItem: "Élément Jira",
  notificationRecipient: "Destinataire de la notification",
  checkBoxBlocker: "Cochez cette case s'il s'agit d'un bloqueur",
  visitFindTalent: "Veuillez visiter la page 'Trouver des talents' et ajouter des individus talentueux à votre pod.",
  name: "Nom",
  actions: "Actions",
  gitHubRepo: "Dépôt GitHub",
  gitHubUsername: "Nom d'utilisateur GitHub",
  jiraUsername: "Nom d'utilisateur Jira",
  giveRating: "Donner une note",
  commentHere: "commentaire ici...",
  browseTalent: "Parcourir les talents",
  findBestFreelancers: "Trouvez les meilleurs freelancers ou pods de talents pour obtenir la livraison de vos projets plus rapidement !",
  addingCandidateWait: "Ajout du candidat en cours...",
  clear: "Effacer",
  search: "Rechercher",
  keyword: "Mot-clé",
  applyFilters: "Appliquer les filtres",
  enterEmailToInvite: "Entrez une adresse e-mail pour inviter",
  talentApprovalRequests: "Demandes d'approbation de talent",
  approvalRequests: "Demandes d'approbation",
  listOfTalents: "Liste des talents",
  viewProfile: "Voir le profil",
  findTalent: 'Trouver du talent',
  subscription: 'Abonnement',
  invoices: 'Factures',
  login: 'Connexion'

};

export default messagesFr;
