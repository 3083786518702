import React, { useState, useRef } from 'react';
import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Alert,
  Divider,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import SendIcon from '@mui/icons-material/Send';
import BugReportIcon from '@mui/icons-material/BugReport';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import { useAuthToken } from "../TokenContext";
import { useSelector } from "react-redux";

// Previous styled components remain the same...
const MessageContainer = styled(Box)(({ theme }) => ({
  margin:'auto',
  flexGrow: 1,
  overflow: 'auto',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  height: 'calc(100vh - 250px)',
  '&::-webkit-scrollbar': {
    width: '0.4em'
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent'
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.grey[300],
    borderRadius: '4px'
  }
}));

const UserMessage = styled(Card)(({ theme }) => ({
  maxWidth: '80%',
  marginLeft: 'auto',
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText
}));

const BotMessage = styled(Card)(({ theme }) => ({
  maxWidth: '80%',
  marginRight: 'auto',
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.paper
}));

const InputContainer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`
}));

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const PodAutomation = () => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [question, setQuestion] = useState('');
  const [podInsights, setPodInsights] = useState(null);
  const [jiraResponse, setJiraResponse] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);
  const theme = useTheme();

  const { user } = useSelector(state => state.auth);
  const token = useAuthToken();

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      await handleFileUpload(file);
      // Reset the file input value after upload
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleFileUpload = async (file) => {
    setIsUploading(true);
    setErrorMessage(null);

    const formData = new FormData();
    formData.append('file', file);

    const payload = {
      manager_id: user?.data[0]?.emp_id,
      file_name: file.name,
      org_code: "CLIENT#c4a7530851b09f06caef227637a2fbcd"
    };

    try {
      const response = await axios.post('https://q0aippwzde.execute-api.us-east-1.amazonaws.com/prod/org_file_upload', payload, { headers: { Authorization: token } });
      setMessages(prev => [
        ...prev,
        { type: 'user', text: `Uploaded file: ${file.name}` },
        { type: 'bot', text: 'File uploaded successfully!' }
      ]);
    } catch (error) {
      console.error('Error uploading file:', error);
      setErrorMessage('Error uploading file: ' + error.message);
    } finally {
      setIsUploading(false);
    }
  };

  // Rest of the handlers remain the same...
  const handleSendClick = async () => {
    if (!question.trim()) return;
    
    setIsLoading(true);
    setErrorMessage(null);
    setPodInsights(null);
    setJiraResponse(null);

    const payload = {
      "manager_id": user?.data[0]?.emp_id,
      "query": question,
    };

    try {
      const response = await axios.post('https://agents.globl.ai/prod/pod_automation', payload);
      const responseData = response?.data?.pod_insights;
      setPodInsights(responseData);

      if (responseData) {
        const insights = responseData.split('\n • ').slice(1);
        setMessages(prev => [
          ...prev,
          { type: 'user', text: question },
          { type: 'bot', insights: insights }
        ]);
        setQuestion('');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setErrorMessage('Error fetching data: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateJiraStories = async () => {
    if (!podInsights) return;

    setIsSubmitting(true);
    setErrorMessage(null);

    const payload = {
      "manager_id": user?.data[0]?.emp_id,
      "pod_insights": podInsights
    };

    try {
      const response = await axios.post('https://agents.globl.ai/prod/creating_jira_story', payload);
      setJiraResponse(response.data.pod_automation);
      
      setMessages(prev => [
        ...prev,
        { 
          type: 'bot',
          jiraStories: response.data.pod_automation 
        }
      ]);
    } catch (error) {
      console.error('Error creating Jira stories:', error);
      setErrorMessage('Error creating Jira stories: ' + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendClick();
    }
  };

  return (
    <Container maxWidth="lg" sx={{ height: '90vh', py: 3, marginTop: '5rem' }}>
      <Paper elevation={3} sx={{ height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h5" fontWeight="bold">
            Pod Automation
          </Typography>
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
            disabled={isUploading}
            sx={{ ml: 2 }}
          >
            {isUploading ? 'Uploading...' : 'Upload File'}
            <VisuallyHiddenInput 
              type="file" 
              onChange={handleFileChange}
              ref={fileInputRef}
              accept=".csv,.xlsx,.xls"
            />
          </Button>
        </Box>

        <MessageContainer>
          {messages.map((message, index) => (
            message.type === 'user' ? (
              <UserMessage key={index} elevation={1}>
                <CardContent>
                  <Typography>{message.text}</Typography>
                </CardContent>
              </UserMessage>
            ) : (
              <BotMessage key={index} elevation={1}>
                <CardContent>
                  {message.text && (
                    <Typography>{message.text}</Typography>
                  )}
                  {message.insights && message.insights.map((insight, i) => (
                    <Box key={i} sx={{ mb: 2 }}>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {insight.includes("blocker:") ? (
                          <>
                            <BugReportIcon color="error" sx={{ mr: 1, verticalAlign: 'middle' }} />
                            {insight.split("blocker:")[1].split("reported_date:")[0]}
                          </>
                        ) : insight}
                      </Typography>
                      {insight.includes("blocker:") && (
                        <>
                          <Typography variant="body2" color="text.secondary" sx={{ ml: 4, mt: 1 }}>
                            Reported: {insight.split("reported_date:")[1].split("talent_name:")[0]}
                          </Typography>
                          <Typography variant="body2" color="text.secondary" sx={{ ml: 4 }}>
                            Talent: {insight.split("talent_name:")[1]}
                          </Typography>
                        </>
                      )}
                    </Box>
                  ))}
                  {message.jiraStories && (
                    <>
                      <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                        Results submitted Successfully:
                      </Typography>
                      {message.jiraStories.map((story, i) => (
                        <Typography key={i} variant="body2" sx={{ ml: 2 }}>
                          • {story}
                        </Typography>
                      ))}
                    </>
                  )}
                </CardContent>
              </BotMessage>
            )
          ))}
          {(isLoading || isUploading) && (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <CircularProgress />
            </Box>
          )}
          {errorMessage && (
            <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>
          )}
        </MessageContainer>

        <InputContainer>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              fullWidth
              multiline
              maxRows={4}
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Enter your question here"
              variant="outlined"
              disabled={isLoading || isSubmitting}
            />
            <Button
              variant="contained"
              onClick={handleSendClick}
              disabled={isLoading || isSubmitting || !question.trim()}
              endIcon={<SendIcon />}
            >
              Send
            </Button>
          </Box>
          
          {podInsights && !jiraResponse && (
            <Button
              variant="contained"
              color="success"
              fullWidth
              onClick={handleCreateJiraStories}
              disabled={isSubmitting}
              sx={{ mt: 2 }}
            >
              {isSubmitting ? (
                <>
                  <CircularProgress size={20} sx={{ mr: 1 }} color="inherit" />
                  Submitting...
                </>
              ) : 'Submit the results'}
            </Button>
          )}
        </InputContainer>
      </Paper>
    </Container>
  );
};

export default PodAutomation;