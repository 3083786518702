import React, { useEffect, useState, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-plugin-annotation';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { 
  Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Paper 
} from '@mui/material';
import { useAuthToken } from '../TokenContext';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import './BarGraph.css';

// Utility functions
const createChartConfig = (chartData, chartLabels, openModal) => ({
  type: 'pie',
  data: {
    labels: chartLabels,
    datasets: [{
      label: 'Code',
      data: chartData,
      backgroundColor: ['seagreen', 'red'],
      borderWidth: 1,
    }],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      annotation: {},
      legend: { display: false },
    },
    tooltips: { enabled: false },
    onClick: openModal,
    hover: {
      onHover: (event) => {
        const chart = event.target;
        chart.options.plugins.annotation.labels = {
          render: 'label',
          position: 'outside',
          fontSize: 12,
          fontStyle: 'bold',
        };
        chart.update();
      },
    },
  },
});

const TableComponent = ({ metrics, intl }) => (
  <TableContainer component={Paper}>
    <Table sx={{ maxWidth: 900, fontFamily: 'Poppins,Medium', borderRadius: '10px' }}>
      <TableHead sx={{
        backgroundColor: '#00B4D8',
        '& td, & th': {
          fontFamily: 'Poppins,Medium',
          fontSize: '15px',
          color: 'white',
          fontWeight: '600'
        },
      }}>
        <TableRow>
          <TableCell align="right">{intl.formatMessage({ id: 'githubUser' })}</TableCell>
          <TableCell align="right">{intl.formatMessage({ id: 'totalLinesChangedLastWeek' })}</TableCell>
          <TableCell align="right">{intl.formatMessage({ id: 'totalLinesChangedThisWeek' })}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {metrics?.talent_users?.map((project, index) => (
          <TableRow key={index} sx={{
            '&:last-child td, &:last-child th': { border: 0 },
            '&:nth-child(even)': { backgroundColor: '#90E0EF' },
            '&:nth-child(odd)': { backgroundColor: '#caf0f8' },
            '& td, & th': {
              fontFamily: 'Poppins,Medium',
              fontSize: '15px',
              color: 'black',
            },
          }}>
            <TableCell align="right">{project.github_user}</TableCell>
            <TableCell align="right">{project.total_lines_changed_last_week}</TableCell>
            <TableCell align="right">{project.total_lines_changed_this_week}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const DetailviewGithublinesofcode = ({ initialPodIds, selectedSprint, sprintStartDate, sprintEndDate }) => {
  const [metrics, setMetrics] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const chartId = useRef(crypto.randomUUID());
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const chartRef = useRef(null);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState('');
  const token = useAuthToken();
  const intl = useIntl();

  const fetchMetricsFromAPI = async (endpoint, payload) => {
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      return processAPIResponse(data);
    } catch (error) {
      console.error('Error fetching metrics:', error);
      return null;
    }
  };

  const processAPIResponse = (data) => {
    if (!data?.body) return null;

    if (typeof data.body === 'object' && !Array.isArray(data.body)) {
      return data.body;
    }

    try {
      const responseJSON = JSON.parse(data.body);
      if (responseJSON?.talent_users) return responseJSON;
      
      if (responseJSON?.all_repo_checkins) {
        return {
          talent_users: responseJSON.talent_users.map(user => ({
            github_user: user.github_user,
            total_lines_changed_last_week: user.sprint_commit_lines,
            total_lines_changed_this_week: 0,
          })),
          all_repo_checkins: {
            current_week: responseJSON.all_repo_checkins.sprint_commit_lines,
            previous_week: 0,
          }
        };
      }
    } catch (error) {
      console.error('Error processing API response:', error);
    }
    return null;
  };

  const fetchData = async () => {
    if (!token) return;

    const commonPayload = {
      sprint_name: selectedSprint || '',
      sprint_start_date: sprintStartDate || '',
      sprint_end_date: sprintEndDate || '',
    };

    let newData;
    if (user.data[0].type === 'stakeholder') {
      const payload = {
        ...commonPayload,
        pod_id: initialPodIds || [],
        org_code: user.data[0].client_code,
      };
      newData = await fetchMetricsFromAPI(
        process.env.REACT_APP_STAKEHOLDER_GITHUBCODEMETRICSSTAKEHOLDER,
        payload
      );
    } else {
      const endpoint = initialPodIds?.length > 0
        ? process.env.REACT_APP_CLIENT_GITHUBCODEMETRICSMANAGEPODID
        : process.env.REACT_APP_CLIENT_GITHUBCODEMETRICSMANAGER;
      
      const payload = {
        ...commonPayload,
        ...(initialPodIds?.length > 0 
          ? { pod_id: initialPodIds }
          : { manager_id: user.data[0].emp_id }
        ),
      };
      newData = await fetchMetricsFromAPI(endpoint, payload);
    }

    setMetrics(newData);
  };

  const displayChart = () => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const canvasElement = document.getElementById(chartId.current);
    if (!canvasElement) return;

    const ctx = canvasElement.getContext('2d');
    if (!ctx) return;

    let chartData, chartLabels;

    if (!selectedDropdownValue && metrics?.all_repo_checkins) {
      if ('previous_week' in metrics.all_repo_checkins) {
        chartData = [metrics.all_repo_checkins.previous_week, metrics.all_repo_checkins.current_week];
        chartLabels = ['Previous Week', 'Current Week'];
      } else {
        chartData = [metrics.all_repo_checkins.sprint_commit_lines, 0];
        chartLabels = ['Sprint Commit Lines', ''];
      }
    } else {
      const talentUser = metrics?.talent_users?.find(
        (user) => user.github_user === selectedDropdownValue
      );

      if (talentUser) {
        if ('total_lines_changed_this_week' in talentUser) {
          chartData = [talentUser.total_lines_changed_last_week, talentUser.total_lines_changed_this_week];
          chartLabels = ['Lines Changed Last Week', 'Lines Changed This Week'];
        } else {
          chartData = [talentUser.sprint_commit_lines, 0];
          chartLabels = ['Sprint Commit Lines', ''];
        }
      } else {
        chartData = [0, 0];
        chartLabels = ['Lines Changed Last Week', 'Lines Changed This Week'];
      }
    }

    chartRef.current = new Chart(ctx, createChartConfig(chartData, chartLabels, () => setModalIsOpen(true)));
  };

  useEffect(() => {
    fetchData();
  }, [token, user.data[0].type, initialPodIds, selectedSprint, sprintStartDate, sprintEndDate]);

  useEffect(() => {
    if (metrics) {
      displayChart();
    }
  }, [metrics, token, selectedDropdownValue, modalIsOpen]);

  const hasData = metrics && (
    (selectedDropdownValue && metrics.talent_users?.some(user => 
      user.github_user === selectedDropdownValue &&
      (user.total_lines_changed_last_week !== 0 || user.total_lines_changed_this_week !== 0)
    )) ||
    (!selectedDropdownValue && metrics.all_repo_checkins && 
      (metrics.all_repo_checkins.previous_week !== 0 || metrics.all_repo_checkins.current_week !== 0))
  );

  return (
    <div className="chart-container">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 className="chart-heading">{intl.formatMessage({ id: 'code' })}</h2>
        <br />
        <select
          style={{ width: '12rem' }}
          value={selectedDropdownValue}
          onChange={(e) => setSelectedDropdownValue(e.target.value)}
        >
          <option value="">{intl.formatMessage({id: 'allReporters'})}</option>
          {metrics?.talent_users?.map((user) => (
            <option key={user.github_user} value={user.github_user}>
              {user.github_user}
            </option>
          ))}
        </select>
        
        <div className="chart-wrapper" style={{ 
          width: "140px", 
          height: "150px", 
          margin: "auto", 
          textAlign: "center", 
          marginLeft: "2rem",
          marginTop: "30px" 
        }}>
          {hasData ? (
            <canvas id={chartId.current} width="150" height="150"></canvas>
          ) : (
            <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
          )}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="GitHub Summary Modal"
        style={{
          content: {
            width: '50%',
            maxWidth: '800px',
            margin: 'auto',
            marginTop: '5rem',
          },
        }}
      >
        <h6>{intl.formatMessage({ id: 'githubSummary' })}</h6>
        <TableComponent metrics={metrics} intl={intl} />
        {metrics?.all_repo_checkins && (
          <div>
            <h6>{intl.formatMessage({ id: 'allRepoCheckins' })}</h6>
            <p>{intl.formatMessage({ id: 'currentWeek' })}: {metrics.all_repo_checkins.current_week}</p>
            <p>{intl.formatMessage({ id: 'previousWeek' })}: {metrics.all_repo_checkins.previous_week}</p>
          </div>
        )}
        <button onClick={() => setModalIsOpen(false)}>
          {intl.formatMessage({ id: 'done' })}
        </button>
      </Modal>
    </div>
  );
};



export default DetailviewGithublinesofcode;