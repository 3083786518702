
import React from "react";
import { useState } from "react";
import styled from 'styled-components'


const Clientcreatepodinvite = () => {

    const [email,setEmail]=useState('');
    const[messege,setMessege]=useState(false);

    const emailhandleChange=(e) =>
    {
     var pattern =/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
     var emailValue =e.target.value
     setEmail(emailValue)
     if(email.match(pattern)){
      console.log(true);
      
        setMessege('')
    }
    else{
      console.log(false);
    setMessege('enter valid email address')
    }}
 

  return (
    <Div>
      <TitleText>Enter  a E-Mail to Invite</TitleText>



      <CardContent>

      <InputField value={email} type="email" placeholder="Enter a E-Mail" onChange={emailhandleChange}/>
      <p >{messege}</p>
      <Button
            type="submit"
          >
            Invite
          </Button>
         
        </CardContent>

        
    
 
        
        
       

  
    </Div>
  );
};

const Div = styled.div`
   display:flex;

  jutify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 25rem;
  }
`;



const CardContent = styled.div`
display:flex;

  jutify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0.4rem;
  width: 40rem;
  padding:1rem;
  
  height:max-content;
  border-radius:10px;
  font-family:Poppins,Medium;
  background-color:#F2F4F5;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 22rem;
    
  margin: auto;
  }
`;

const Button=styled.button`

font-family:Poppins,Medium;
    background-color: #1DBF73;
    color: white;
   font-size: 15px;
     border-radius: 10px;
     height: 54px;
        width:18rem;
border:none;
margin-top:2rem;
    font-weight: 600;
    font-family: Poppins,Medium;
    box-shadow: 1px 3px 6px #00000029;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        }
`;
const InputField=styled.input`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
padding-left:1.5rem;
font-weight:600;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;

  
 }
`;
const TitleText = styled.h4`
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
   margin-top:2rem;
  }

`;
export default Clientcreatepodinvite;

