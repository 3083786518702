import React, { useEffect, useState, useRef } from "react";
import Detailsviewmetrics from "./Detailsviewmetrics";

import TicketsDetailviewmetrics from "./TicketsDetailviewmetrics";

import Defectsdetailviewchart from "./Defectsdetailviewchart";
import styled from "styled-components";
import Githublinesofcodebydaywise from "./Githublinesofcodebydaywise";
import DetailviewGithublinesofcode from "./DetailviewGithublinesofcode";
import DetailPRcountmanager from "./DetailPRcountmanager";
import PRcountgraph from "./PRcountgraph";
import SprintVelocitiesChart from "./SprintVelocityChart ";
import CloudCost from "./CloudCost";
import CicdPiechartmanagerdetailvivew from "./CicdPiechartmanagerdetailvivew";
import ProjectPieChart from "./ProjectPieChart";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useSelector } from "react-redux";
import { useAuthToken } from "../TokenContext";
import TimeDetailView from "./TimeDetailView";
import Select from "react-select"; // Import react-select
import DoraMetricsModal from "./DoraMetricsModal";
import Dorametricslinegraph from "./Dorametricslinegraph";
import { useIntl } from "react-intl";
import DetailsTaskmetricsManager from "./DetailsTaskmetricsManager";
import DetailbitbucketPRcountmanager from "./DetailbitbucketPRcountmanager";
import Bitbucketcodebydaywisemanager from "./Bitbucketcodebydaywisemanager";
import DetailBitbucketcodemanager from "./DetailBitbucketcodemanager";

const PodMetricsmanager = () => {
  const [podData, setPodData] = useState([]);
  const [selectedPod, setSelectedPod] = useState("");
  const [fetchMetrics, setFetchMetrics] = useState("");
  const [selectedPods, setSelectedPods] = useState([]);
  const [sprintData, setSprintData] = useState([]);
  const [selectedSprint, setSelectedSprint] = useState({
    name: "", // Selected sprint name
    startDate: "", // Selected sprint start date
    endDate: "", // Selected sprint end date
  });
  const intl = useIntl();

  const { user } = useSelector((state) => state.auth);
  const token = useAuthToken();

  useEffect(() => {
    if (user.data[0].type === "stakeholder") {
      if (!token) {
        console.log("Token is missing. API requests will not be made.");
        return;
      }
      fetch(process.env.REACT_APP_STAKEHOLDER_SPRINTFILTERSTAKEHOLDER, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          manager_id: user.data[0].emp_id,
          org_code: user.data[0].client_code,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200 && data.body) {
            const parsedBody = JSON.parse(data.body);
            if (Array.isArray(parsedBody.sprint_filter)) {
              setSprintData(parsedBody.sprint_filter);
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching data from stakeholder API:", error);
        });
    } else {
      if (!token) {
        console.log("Token is missing. API requests will not be made.");
        return;
      }
      fetch(process.env.REACT_APP_CLIENT_SPRINTFILTERMANAGER, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          manager_id: user.data[0].emp_id,
          org_code: user.data[0].client_code,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200 && data.body) {
            const parsedBody = JSON.parse(data.body);
            if (Array.isArray(parsedBody.sprint_filter)) {
              setSprintData(parsedBody.sprint_filter);
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [token]);

  useEffect(() => {
    if (user.data[0].type === "stakeholder") {
      if (!token) {
        console.log("Token is missing. API requests will not be made.");
        return;
      }
      // If user type is stakeholder, make a request to the specified API_URL
      fetch(process.env.REACT_APP_STAKEHOLDER_GETPODSFORSTAKEHOLDER, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ org_code: user.data[0].client_code }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200 && Array.isArray(data.body)) {
            setPodData(data.body);
          }
        })
        .catch((error) => {
          console.error("Error fetching data from stakeholder API:", error);
        });
    } else {
      if (!token) {
        console.log("Token is missing. API requests will not be made.");
        return;
      }
      fetch(process.env.REACT_APP_CLIENT_GETPODSFORMANAGER, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ emp_id: user.data[0].emp_id }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200 && Array.isArray(data.body)) {
            setPodData(data.body);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [token]);

  const podOptions = podData.map((pod) => ({
    value: pod.pod_id,
    label: pod.pod_name,
  }));

  const handlePodSelect = (selectedOptions) => {
    setSelectedPods(selectedOptions.map((option) => option.value));
  };

  const handleSprintSelect = (e) => {
    const selectedSprintName = e.target.value;
    const selectedSprintData = sprintData.find(
      (sprint) => sprint.sprint_name === selectedSprintName
    );
    setSelectedSprint({
      name: selectedSprintName,
      startDate: selectedSprintData ? selectedSprintData.sprint_start_date : "",
      endDate: selectedSprintData ? selectedSprintData.sprint_end_date : "",
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "inline",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
          flexDirection: "column",
          marginTop: "10rem",
        }}
      >
        <TitleText>{intl.formatMessage({ id: "podAnalytics" })} </TitleText>
      </div>
      <YourProjects>
        <Dropdowns>
          <Seletfield
            options={podOptions}
            isMulti
            value={podOptions.filter((option) =>
              selectedPods.includes(option.value)
            )}
            onChange={handlePodSelect}
            placeholder={intl.formatMessage({ id: "selectPods" })}
            styles={{
              control: (provided) => ({
                ...provided,

                height: "3rem",
                overflow: "auto",
              }),
            }}
          />
          <Sprintselect
            value={selectedSprint.name}
            onChange={handleSprintSelect}
          >
            <option value="">Select a Sprint</option>
            {sprintData.map((sprint, index) => (
              <option key={index} value={sprint.sprint_name}>
                {sprint.sprint_name}
              </option>
            ))}
          </Sprintselect>
        </Dropdowns>
        <Border>
          <Project>
            <div>
              <Detailsviewmetrics
                initialPodIds={selectedPods}
                selectedSprint={selectedSprint.name}
              />
            </div>
            <div style={{ marginLeft: "5px" }}>
              <Defectsdetailviewchart
                initialPodIds={selectedPods}
                selectedSprint={selectedSprint.name}
              />
            </div>
            <div>
              {" "}
              <DetailBitbucketcodemanager
                initialPodIds={selectedPods}
                selectedSprint={selectedSprint.name}
                sprintStartDate={selectedSprint.startDate}
                sprintEndDate={selectedSprint.endDate}
              />{" "}
            </div>
            {/* <div style={{marginLeft:'5px'}}>
      <DetailviewGithublinesofcode 
     initialPodIds={selectedPods}
    
     selectedSprint={selectedSprint.name}
     sprintStartDate={selectedSprint.startDate}
     sprintEndDate={selectedSprint.endDate} />
      </div> */}
            <div style={{ marginLeft: "5px" }}>
              <TimeDetailView
                initialPodIds={selectedPods}
                selectedSprint={selectedSprint.name}
                sprintStartDate={selectedSprint.startDate}
                sprintEndDate={selectedSprint.endDate}
              />
            </div>

            <div>
              <DetailsTaskmetricsManager
                initialPodIds={selectedPods}
                selectedSprint={selectedSprint.name}
                sprintStartDate={selectedSprint.startDate}
                sprintEndDate={selectedSprint.endDate}
              />
            </div>
          </Project>
        </Border>
      </YourProjects>

      <YourProjects2>
        <Border1>
          <Project>
            
            <div>
              <CicdPiechartmanagerdetailvivew initialPodIds={selectedPods} />
            </div>
            <div>
              <ProjectPieChart initialPodIds={selectedPods} />
            </div>
            <div>
              <CloudCost initialPodIds={selectedPods} />
            </div>
            
            {/* <div style={{marginLeft:'5px'}}> <DetailPRcountmanager initialPodIds={selectedPods}/></div> */}
            {/* <div> <DetailBitbucketcodemanager    initialPodIds={selectedPods}  
     selectedSprint={selectedSprint.name}
     sprintStartDate={selectedSprint.startDate}
     sprintEndDate={selectedSprint.endDate} /> </div> */}

            <div style={{ marginLeft: "5px" }}>
              {" "}
              <DetailbitbucketPRcountmanager initialPodIds={selectedPods} />
            </div>

            <div style={{ marginLeft: "5px" }}>
              {" "}
              <TicketsDetailviewmetrics
                initialPodIds={selectedPods}
                
              />
            </div>
          </Project>
        </Border1>
      </YourProjects2>

      <YourProjects2>
        <Border1>
          <Project>
            {/* <div  >
      <Githublinesofcodebydaywise initialPodIds={selectedPods}/>
    </div> */}
            <div>
              {" "}
              <Bitbucketcodebydaywisemanager
                initialPodIds={selectedPods}
              />{" "}
            </div>
            <div>
              <SprintVelocitiesChart initialPodIds={selectedPods} />
            </div>
          </Project>
        </Border1>
      </YourProjects2>

      <YourProjects2>
        <Border1>
          <h6
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {intl.formatMessage({ id: "doraMetrics" })}
          </h6>
          <Project>
            <div>
              {/* <DoraMetricsModal/>
    </div>

    <div style={{marginLeft:'2rem'}}> */}

              <Dorametricslinegraph />
            </div>
          </Project>
        </Border1>
      </YourProjects2>
    </div>
  );
};
const Seletfield = styled(Select)`
  width: 40vw;

  margin: 0.5rem;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 40vw;
  }
`;
const Border = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  background-color: white;
  border-radius: 1rem;
  height: 20rem;
  padding: 20px;
  //  overflow-y:scroll;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70vw;
  }
`;

const Border1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  background-color: white;
  border-radius: 1rem;
  height: 90%;
  padding: 20px;
  //  overflow-y:scroll;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70vw;
  }
`;

const YourProjects = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 80vw;
  margin-top: 2rem;
  height: max-height;
  background-color: #0077b6;
  border-radius: 1rem;
  padding: 0.8rem;
  box-shadow: 0px 4px 6px #00000029;
  transition: 0.45 ease-in-out;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 80vw;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
margin-left:4rem;
    margin-top: 1rem;
  }
`;
const Div = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
  }
`;
const YourProjects2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 80vw;
  margin-top: 2rem;
  height: max-height;
  background-color: #0077b6;
  border-radius: 1rem;
  padding: 0.8rem;
  box-shadow: 0px 4px 6px #00000029;
  transition: 0.45 ease-in-out;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 80vw;
    height: max-content;
    display: flex;
    flex-direction: column;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
margin-left:4rem;
    margin-top: 1rem;
  }
`;
const Project = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 0.3rem;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const TitleText = styled.h4`
  height: 10%;
  color: black;
  font-weight: 600;
  cursor: pointer;
  font-size: 18px;
  font-family: Poppins, Medium;
`;

const Dropdowns = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    margin: auto;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

const Sprintselect = styled.select`
  textdecoration: none;
  border: none;
  borderradius: none;
  width: 15em;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 10em;
  }
`;

export default PodMetricsmanager;
