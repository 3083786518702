import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service here
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI here
      return (
        <div style={{ textAlign: 'center', marginTop: '20px', fontSize: '18px', color: 'red' }}>
          <p>Oops! Something went wrong.</p>
          <p>Please contact your administrator.</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
