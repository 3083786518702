class ActivityTracker {
    constructor(timeoutInMilliseconds, onInactive) {
      this.timeout = timeoutInMilliseconds;
      this.onInactive = onInactive;
      this.resetTimer();
      this.setupEventListeners();
    }
  
    resetTimer() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => this.onInactive(), this.timeout);
    }
  
    setupEventListeners() {
      window.addEventListener("mousemove", () => this.resetTimer());
      window.addEventListener("keydown", () => this.resetTimer());
      // Add more events as needed (e.g., 'click', 'scroll', etc.)
    }
  }
  
  export default ActivityTracker;
  