import { green } from '@mui/material/colors';
import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import './PostRequirement.css';
import { useEffect, useState } from 'react'
import {VscChevronLeft} from 'react-icons/vsc';
import axios from 'axios'
import { postpodproject, postproject } from './action/auth';
import { useDispatch, useSelector } from "react-redux";
// import { styled } from '@material-ui/core';

import styled ,{ css }from 'styled-components'
import { style } from '@mui/system';
import { useAuthToken } from "../TokenContext";
const PostRequirement = (props) => {
  const token = useAuthToken();

  const { isLoggedIn, user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [loading, setloading] = useState(true);
  const[Subscription,setSubscription]=useState({})
  const[subscrictionfetch,setSubscriptionfetch]=useState([]);
  const [gigs, setGigs] = useState([]); // State to store the list of gigs
  const [selectedGigId, setSelectedGigId] = useState(''); // State to store the selected gig ID

  const [Postdata, setPostdata] = useState({
    project_name: "",
    project_description: "",
    budget: "",
    industry: "",
    frontendSkills: "",
    backendSkills: "",
    mobileSkills: "",
    workHours: "",
    type: "",
    timezone: "",
    clientid: user.data[0].emp_id,
    Organizational_Code: user.data[0].client_code,
    errors: {
      cognito: null,
      blankfield: false
    }
  });

  const industry = [
    { value: 'Healthcare', label: 'Healthcare' },
    { value: 'Banking and Financial', label: 'Banking and Financial' },
    { value: 'Services', label: 'Services' },
    // Add more industry options if needed
  ];

  
  const postARTData = async (event) => {
    event.preventDefault();

    const ARTPayload = {
      Industry: null,
      Primary_Skills:null,
      Secondary_Skills: null,
      Time_Zone: null,
      Preferred_Workhours:null,
      Availability:null,
      Budget_Range: null,
      Number_Of_Pods: null,
      Manager_Id: user.data[0].emp_id ||null,
      ART_Name: Postdata.project_name, // Changed from "project_name" to "ART_name"
      ART_Description: Postdata.project_description,
      Organizational_Code: user.data[0].client_code,
      Gigs_id:selectedGigId
    };
    const headers = {
      'Authorization' : token, 
      'Content-Type': 'application/json', // You can add more headers as needed
    };

    try {
      //https://utfkldc84i.execute-api.us-east-1.amazonaws.com/dev/Post-a-gig
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_POSTAGIG,
        ARTPayload,{headers:headers}
      );
    
      alert('ART has been submitted Succesfully.');
      window.location.reload();
      window.history.push('/client-dashboard');
    } catch (error) {
      console.error(error);
    }
  };

  const onInputChange = (e) => {
    setPostdata((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };
  useEffect(() => {
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    // Fetch the list of gigs from the API
    axios.post(process.env.REACT_APP_CLIENT_FETCHGIG, {
     
    Organization_Code: user.data[0].client_code,
      
    },{headers:headers})
    .then((response) => {
  
      if (response.data.body.statuscode === 200) {
        setGigs(response.data.body.body);
       
      }
    })
    .catch((error) => {
      console.error('Error fetching gigs:', error);
    });
  }, [user.data,token]);

  // Handle the gig selection
  const handleGigChange = (e) => {
    setSelectedGigId(e.target.value);
  };

  useEffect(() => {

 
  
 

    const body = {
      client_code: user.data[0].client_code
    
    }
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
  
    if (user.data[0].client_code.includes("PARTNER")) {
      // Second API call
      axios.post(process.env.REACT_APP_PARTNER_FTECHSUBSCCRIPTIONPARTNER, body,{headers:headers})
        .then((res) => {
          // Process the response as needed for the second API
          
          // Set state with the response data
          setSubscription(res.data.data);
          setSubscriptionfetch(res.data.data[0].find_pods);
          setloading(false);
        })
        .catch((err) => {
          console.log("Error in the second API call:", err);
          // Handle errors if needed
          setloading(false);
        });
    } else {
      // First API call
      axios.post(process.env.REACT_APP_FETCHSUBSCRIPTIONDATA, body,{headers:headers})
        .then((res) => {
          // Process the response as needed for the first API
       
          setSubscription(res.data.data);
          setSubscriptionfetch(res.data.data[0].find_pods);
          setloading(false);
        })
        .catch((err) => {
          console.log("Error in the first API call:", err);
          setloading(false);
        });
    }
  }, [token]);

  // const industry = [...new Set(industrydata.map(item=> item.industry))]
  return (

    <div className="container" style={{ "margin-top": "140px" }}>
{loading ? (
        <div>Loading...</div> // Show a loading indicator while waiting for the API call to complete.
      ) : Subscription === "There Are No subscriction data." || subscrictionfetch === "False" ? (
        <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", margin: 'auto', marginTop: "8rem" }}>
          You don't have any subscription plan
        </div>
      ) : (

    <div className="row" >
<br></br>
      <div className="col-lg-2" >      </div>

    
     
   
     <div className="col-lg-8" style={{ boxShadow: "1px 3px 6px #00000029", border: "1px solid #D8D8D8", borderRadius: "16px", opacity: 1 }}>

          

     <Headingtext2 style={{ textAlign: "center" }} >
Create ART</Headingtext2>

{/* 
<center>
              <Navdiv >

                <Nav to='/PostRequirementPod'  >   Pod </Nav>
                <span> <Nav2 to='/project-requirment'  >Individual</Nav2></span>

              </Navdiv>
              </center> */}
              <form onSubmit={postARTData}>
              <Postfield>
          <Dropdown onChange={handleGigChange} value={selectedGigId}>
            <option value="">Select a Gig</option>
            {gigs.map((gig) => (
              <option key={gig.Gigs_id} value={gig.Gigs_id}>
                {gig.Gig_Name}
              </option>
            ))}
          </Dropdown>
        </Postfield>
              <Postfield>
              <Textarea   type="text" maxLength="50"name="project_name" placeholder="ART Name" onChange={onInputChange} required />
            </Postfield>
<Postfield>
              <Textarea type="text"   maxLength="200" name="project_description" placeholder="ART Description" onChange={onInputChange} required />
            </Postfield>
            {/* <Postfield>
              <Textarea type="number" name="number_of_pods" placeholder="Number of Pods" onChange={onInputChange} value={Postdata.number_of_pods} />
            </Postfield> */}
            {/* <Postfield>
              <Dropdown
                onChange={onInputChange}
                value={Postdata.industry}
                name="industry"
                required
              >
                <option value="" defaultValue>
                  Industry
                </option>
                {industry.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Dropdown>
            </Postfield> */}
          
            {/* <Postfield>
              <Dropdown
                onChange={onInputChange}
                value={Postdata.frontendSkills}
                name="frontendSkills"
              >
               <option  value='' defaultValue style={{color:'black'}} >Primaryskill</option>
 
        
 <option value='html'  style={{color:'black'}} > html</option>
 <option value='css'  style={{color:'black'}} > CSS</option>
<option  value='JavaScript' style={{color:'black'}} >JavaScript</option>
<option  value='Adobe Illustrator' style={{color:'black'}} > Adobe Illustrator</option>
<option  value='ReactJS' style={{color:'black'}} > ReactJS</option>
<option  value='AngularJS' style={{color:'black'}} > AngularJS</option>
<option  value='Java' style={{color:'black'}} > Java</option>
<option  value='Node.js' style={{color:'black'}} >Node.js</option>
<option  value='Vue' style={{color:'black'}} > Vue</option>
<option  value='Next' style={{color:'black'}} > Next</option>
<option  value='Typescript' style={{color:'black'}} > Typescript</option>
<option  value='Gatsby' style={{color:'black'}} > Gatsby</option>
<option  value='Three.js' style={{color:'black'}} >Threejs</option>



              </Dropdown>
            </Postfield> */}

            {/* <Postfield>
              <Dropdown
                onChange={onInputChange}
                value={Postdata.backendSkills}
                name="backendSkills"
              >
                 <option value='' defaultValue   style={{color:'black'}} >Secondaryskill</option>
                  
                  <option  style={{color:'black'}} >Python</option>
        <option   value='Java'   style={{color:'black'}} >Java</option>
        <option   value='PHP'  style={{color:'black'}} >PHP</option>
        <option   value='SQL'  style={{color:'black'}} >SQL</option>
        <option   value='Git'  style={{color:'black'}} >Git</option>
        <option   value='c++'  style={{color:'black'}} >c++</option>
        <option   value='Perl'  style={{color:'black'}} >Perl</option>
        <option  value='AngularJS' style={{color:'black'}} > AngularJS</option>
      
        <option   value='Kotlin' style={{color:'black'}} >Kotlin</option>
        <option   value='AWS' style={{color:'black'}} >AWS</option>
        <option   value='NodeJs' style={{color:'black'}} >NodeJs</option>
        
        <option   value='Azure' style={{color:'black'}} >Azure</option>
              </Dropdown>
            </Postfield> */}

            {/* <Postfield>
              <Dropdown
                onChange={onInputChange}
                value={Postdata.timezone}
                name="timezone"
                required
              >
                 <option value='Indian Standard time' >Indian Standard time</option>
                    <option  value='Eastern standard time' >Eastern standard time</option>
                    <option  value='Central standard time'>Central standard time</option>
                    <option  value='Mountain standard time '>Mountain standard time </option>
                    <option value='Pacific standard time'>Pacific standard time</option>

           
              </Dropdown>
            </Postfield> */}

            {/* <Postfield>
              <Dropdown
                onChange={onInputChange}
                value={Postdata.workHours}
                name="workHours"
                required
              >
                <option value="" defaultValue>
                  Preferred Work Hours
                </option>
                <option  value='Morning Shift, 9am - 6pm'>Morning Shift, 9am - 6pm </option>
                <option value='Night Shift, 10pm - 7am '>Night Shift, 10pm - 7am </option>
              </Dropdown>
            </Postfield> */}

            {/* <Postfield>
              <Dropdown
                onChange={onInputChange}
                value={Postdata.type}
                name="type"
              >
                 <option  style={{ color: 'black' }} value="">Availability</option>
                <option style={{ color: 'black' }} value='full time'>Full time</option>
                <option style={{ color: 'black' }} value='part time'>Part time</option>
              </Dropdown>
            </Postfield> */}

            {/* <Postfield>
              <Dropdown
                onChange={onInputChange}
                value={Postdata.budget}
                name="budget"
                required
              >
                     <option style={{ color: "black" }} value="" defaultValue>
              Budget Range
            </option>
            <option  value= '100k - 115k' style={{ color: "black" }}>100k - 115k</option>
  <option value='115k - 150k' style={{ color: "black" }}>115k - 150k</option>
               
              </Dropdown>
            </Postfield> */}

            {/* <Postfield>
              <Textarea1 type="text" onChange={onInputChange} placeholder="About" />
            </Postfield> */}
           
              <center>
                <Button type='submit' >Post</Button>
              </center>
              </form>
              <br></br>
      <br></br>
      <br></br>
      <br></br>

            
          </div>
          <div className="col-lg-2" >      </div>

    
     </div>)}
    </div>
  )
}

const PostPodDiv=styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;

    @media screen and (min-width:320px) and (max-width:1080px){
      display:flex;
      flex-direction:column;
      justify-content:center;
      align-items:center;
    }
`;

const Postreqcontainer=styled.div`

  font-family: Poppins,Medium;
  font-weight: 600;
    width: 60rem;
    height: 1100px;
    box-shadow: 1px 3px 6px #00000029;
border-radius: 16px;
margin-right: -50%;
background-color: #F8F8F8;
margin-top: 5rem;
    border: 1px solid #D8D8D8;
    @media screen and (min-width:320px) and (max-width:1080px){
     
      display:flex;
      flex-direction:column;
      justify-content:center;
      align-items:center;
      width: 24rem;
      margin:auto;
      overflow-x : hidden;
      height: 1100px;   
    }
`;

const Button =styled.button`
height: 50px;
 width: 200px;
 box-shadow: 1px 3px 6px #00000029;
border:none;
font-size:16px;
font-weight: 600;

background-color: #1DBF73;
color: white;

border-radius: 10px;
height: 44px;
  
   font-family: Poppins,Medium;
   @media screen and (min-width:320px) and (max-width:1080px){

     width:20rem;
     margin:auto;
     margin-top:2rem
   }
`;


const Postfield=styled.div`
font-family: Poppins,Medium;
font-weight: 600;
  padding: 10px;

  @media screen and (min-width:320px) and (max-width:1080px){
    display:flex;
    justify-content:center;
    align-items:center;

    margin:auto; 
    }
 
`;
 


const Textarea=styled.textarea`
height: 60px; 
padding: 10px;
 border: 1px solid #B7B7B7;
width: 407px;
 box-sizing: border-box;padding-left: 1.5rem;


resize: none;
 border-radius: 10px;
// margin-top: 5rem;
font-family: Poppins,Medium;
color:grey;
  font-weight: 600;
  @media screen and (min-width:320px) and (max-width:1080px){
    display:flex;
    justify-content:center;
    align-items:center;

    width: 20rem;
    height: 60px; 
    margin:auto;
    

  }
`;

const Textarea1=styled.textarea`
height: 60px; 
padding: 10px;
padding-left: 1.5rem;
 border: 1px solid #B7B7B7;
width: 407px;
 box-sizing: border-box;

resize: none;
 border-radius: 10px;

font-family: Poppins,Medium;
color:grey;
  font-weight: 600;
  @media screen and (min-width:320px) and (max-width:1080px){
    display:flex;
    justify-content:center;
    align-items:center;

    width: 20rem;
    height: 60px; 
    margin:auto;
     
  }
`;
 
const StyleIndustry=styled.select`

width: 407px;
margin-left: 19.6rem;
margin-bottom: -1rem;
padding-left: 1.5rem;
 border: 1px solid #B7B7B7;
height: 54px;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  justify-content:center;
  align-items:center;

  width: 20rem;
  height: 60px; 
  margin:auto;
   
}
`;


 
const Dropdown = styled.select`

width: 25rem;
font-family: Poppins,Medium;
font-weight: 600;
height:50px;
border-radius: 10px;
border: 2px solid #B7B7B7;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  justify-content:center;
  align-items:center;

  width: 20rem;
  height: 60px; 
  margin:auto;
   
}
`;


 const Navdiv=styled.div`


@media screen and (min-width:320px) and (max-width:1080px){
 display:flex;
 flex-direction:row;
justify-content:center;
align-items:center;
}
 `;




 const navcss=css`
 color: black;
  background-color: #E4E4E4; 
 shadow-color:  15px 15px white;
 text-decoration: none;
  border-radius: 2rem ;
  &:hover 
  {
    background-color:#E4E4E4;;
  }
  padding:0.6rem;
 
 padding-left: 6rem; 
 padding-right: 3rem;
 @media screen and (min-width:320px) and (max-width:1080px){
  margin-left: 4rem;

 }
 `;


 const navcss1=css`

 color: white;
  box-shadow: 1px 3px  6px  #00000029; 
 background-color: #00B4D8;
 text-decoration: none;
 border-radius: 2rem;
padding:0.6rem;
pointer-events: none;
 margin-left: -16rem; 
 padding-left: 2rem;
 padding-right: 2rem;
 

  @media screen and (min-width:320px) and (max-width:1080px){
    margin-left: -20rem; 
   
   
   }
   `;



   const Nav2=styled(NavLink)`
   ${navcss1}
   `;
  

 const Nav=styled(NavLink)`
 ${navcss}
 `;

 const Headingtext1=styled.div`
 font-family: Poppins,Medium;
  font-weight: 600;
    text-align: center;
    margin-top:40rem ;
    margin-left: 23rem;
  
font-size: 1.5rem;

@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  width:20rem;
  font-size: 1.5rem;
 margin:auto;
 margin-top:10rem ;
 }
 `;

 const Headingtext2=styled.p`

 color: #8EAEBC;
  margin-top: 2rem;
 margin-bottom: 5rem;
  text-align: center;
 font-weight: 600;
 
 font-size: 40px;
 @media screen and (min-width:320px) and (max-width:1080px){
  font-size: 25px;

 }
 `;
export default PostRequirement;
