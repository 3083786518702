import { green } from "@mui/material/colors";
import React from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { postpodproject, postproject } from "./action/auth";
import { useDispatch, useSelector } from "react-redux";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Select from "react-select";                                                                     
import profile from './assets/profile_img.png';
import { event } from 'jquery';
import styled from 'styled-components';
import { style } from '@mui/system';
import star from './assets/star.png';
import { Podcasts } from "@mui/icons-material";
// import './BrowseTalent.css';
import CloseIcon from '@mui/icons-material/Close';
import allergens from './allergens';
import moment from 'moment';
import CreatePodSkillspage from "./CreatePodSkillspage";
import PodCreatedSuccessFullyCan from './PodCreatedSuccessFullyCan';
import './BrowseTalent.css';
import Popup from 'reactjs-popup';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';

const Clientcreatepod = (props) => {

  const intl = useIntl();

  const token = useAuthToken();





const [artOptions, setARTOptions] = useState([]);

const [podName,setPodName]=useState('');
const [ART_Description,setART_Description]=useState('');
const[podDescription,setPodDescription]=useState('');
const [podtype,setPodtype]=useState('');
const [podsize,setPodsize]=useState('');
const [techStack,setTechStack]=useState('');
const [skills,setSkills]=useState([]);
const[stakeholdersid,Setstakeholdersid]=([]);
const [budget1,setBudget1]=useState('');
const [countrypod,setCountrypod]=useState('');
const [timezonepod,setTimezonepod]=useState('');
const [multiskill,setMultiskill]=useState([]);
const { isLoggedIn, user } = useSelector(state => state.auth);

const [selected, setSelected] = useState([]);
const handleSelectChange = (values) => {
  setSelected(values);
};
const [Poddata,setPoddata]=useState({
podName: '' ,
pod_rate:'',
ART_id: '', // Default value '0'
ART_Name: '',
skills: skills,
budget: '',
country:'',
ART_Description:'',
podDescription:'',
timezone:'',
clientID : user.data[0].emp_id,
candidatesid : [],
podsize :'',
podtype : '',
techStack: "",
primary_language :"",
primary_timezone :"",
Client_Code:user.data[0].client_code
});


const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white",height:'50px',border:"2px solid #B7B7B7", borderRadius: '10px',

  paddingLeft:'20px',
  color:'grey',
  fontWeight:'600'

 
})

};



const [selectedStakeholders, setSelectedStakeholders] = useState([]);
const handleStakeholderChange = (selectedOptions) => {
  setSelectedStakeholders(selectedOptions);

  // Update the state with the selected stakeholder ids
  const selectedStakeholderIds = selectedOptions.map(option => option.id);
  
  // Identify removed stakeholder ids by comparing previous and current selected options
  const removedStakeholderIds = selectedStakeholders
    .filter(stakeholder => !selectedStakeholderIds.includes(stakeholder.id))
    .map(stakeholder => stakeholder.id);

  // Call delete API for each removed stakeholder
  removedStakeholderIds.forEach(stakeholderId => {
    axios.post(process.env.REACT_APP_CLIENT_DELETESTAKEHOLDER, {
      "stakeholder_id": stakeholderId
    })
      .then(response => {
        // Handle success or update UI if needed
        console.log(`Stakeholder with ID ${stakeholderId} deleted successfully.`);
      })
      .catch(error => {
        console.error(`Error deleting stakeholder with ID ${stakeholderId}:`, error);
      });
  });
};
  
  const handleSelectChangeskills = (values) => {


    setMultiskill(values);
    var pushskill= [];
    
values.map(function(ext) {
  var c= {
    'skill':ext.label
  };
pushskill.push(c)
})

setPoddata((prevState) => {
  return { ...prevState, skills: pushskill };
});

    // console.log(values,multiskill,'@@@@@@@@@@@@@@@@@@@');
  };

  const onInputChangetechstack =(e)=>{
    setTechStack(e.target.value);
  }
  const onInputChangebudget1 =(e)=>{
    setBudget1(e.target.value);
  }




  useEffect(() => {
  
   fetchARTOptions();
  
  }, [token])


  
  const fetchARTOptions = async () => {
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    try {
      // Initially, set artOptions to null to indicate loading
      setARTOptions(null);
  
      const response = await axios.post(process.env.REACT_APP_CLIENT_FETCHGIGSLIST, {
        Organization_Code: user.data[0].client_code,
      },{headers:headers});
    
  
      if (response.data && response.data.data) {
        const responseBody = response.data.data; // Access the 'data' property of the response
        
        if (Array.isArray(responseBody)) {
          setARTOptions(responseBody);
         
        } else {
          setARTOptions([]);
          console.log('ART Options Set to Empty Array');
        }
      } else {
        setARTOptions([]);
        console.log('ART Options Set to Empty Array');
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  
  
  
  
  
  const handleARTOptionChange = (e) => {
    const selectedART = artOptions.find((option) => option.ART_id === parseInt(e.target.value, 10));
    if (selectedART) {
      setPoddata((prevState) => ({
        ...prevState,
        ART_id: selectedART.ART_id.toString(),
        ART_Name: selectedART.ART_Name,
      }));
    }
  };
  
  
  
  

const handleSubmitOptions = (e) => {
  e.preventDefault();

  // Include selected stakeholders in Poddata
  const updatedPoddata = {
    ...Poddata,
    stakeholdersid: selectedStakeholders.map(stakeholder => ({
      id: stakeholder.id,
      label: stakeholder.label
    }))
  };

  fetch(process.env.REACT_APP_CLIENT_CLIENTCREATEPOD, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' , Authorization : token,},
    body: JSON.stringify(updatedPoddata)
  })
  .then((response) => response.json())
  .then((data) => {
  
    if (data.status == 400) {
      alert(data.data)
    } else if (data.status == 200) {
      alert("Pod is Created Successfully.")
      window.location.href = '/client-dashboard'
    }
  })
  .catch((error) => {
    console.error('Error creating pod:', error);
  });
};



const onInputChange = e => {
 
    setPoddata((prevState) => {
      return { ...prevState, [e.target.id]: e.target.value };
    });
  }

  

  const onInputChangebudget = e => {
    console.log(e.target)
    setPoddata((prevState) => {
      return { ...prevState, budget: e.target.value };
    });
  }



  // const onInputChangeselect = e => {
  //   console.log(e.target.value)
  //   setState((prevState) => {
  //     return { ...prevState, [e.target.name]: e.target.value };
  //   });
  // }
 


return (

<div>

       <CreatePodScreen>

       {/* { user.subscriptiondata.length >  0 ? user.subscriptiondata[0].type !== "Individual" ? (<> */}

    
    <Headingtext1>{intl.formatMessage({ id: 'createYourOwnPod' })}</Headingtext1>

    <Container1 >
   
      <p
        style={{
          color: "#8EAEBC",
          marginTop: "2rem",
          marginBottom: "5rem",
          textAlign: "center",
          fontWeight: "600",
          fontSize: "40px",
        }}
      >
        {intl.formatMessage({ id: 'createPod' })}
      </p>
   
      <form  onSubmit={handleSubmitOptions}>
        <Postfield>
          <Input
            type="text"
            id="podName"
            onChange={onInputChange}
            maxLength={50}
            placeholder={intl.formatMessage({ id: 'enterPodName' })}
            value={Poddata.podName}
          
        //  onChange={}
            required
          />
        </Postfield>
         

        <Postfield>
              {/* Dropdown field for ART_Name */}
              <Select2
  onChange={handleARTOptionChange}
  id="ART_id"
  value={Poddata.ART_id}
   required
>
  <option style={{ color: 'black' }} value="" defaultValue>
  {intl.formatMessage({ id: 'selectArtName' })}
  </option>
  {artOptions !== null && artOptions.length > 0 ? (
  artOptions.map((option) => (
    <option key={option.ART_id} value={option.ART_id.toString()}>
      {option.ART_Name}
    </option>
  ))
) : (
  <option value="">{intl.formatMessage({ id: 'noArtOptionsAvailable' })}</option>
)}

</Select2>





            </Postfield>


        <Postfield>
          <Input
            type="text"
            id="podDescription"
            onChange={onInputChange}
            placeholder={intl.formatMessage({ id: 'enterPodMission' })}
            value={Poddata.podDescription}
            maxLength={100}
          
        //  onChange={}
            required
          />
        </Postfield>

        <Postfield>
          <Select2
            onChange={onInputChange}
            id="podsize"
              placeholder={intl.formatMessage({ id: 'podSize' })}
              value={Poddata.podsize}
required
              >
            <option style={{ color: "black" }} value="" defaultValue>
         Pod Size
            </option>
                        <option value='3'>3</option>
                        <option value='5'>5</option>
                        <option value='7'>7</option>
                        <option value='9'>9</option>

          </Select2>
        </Postfield>

        <Postfield>
          <Select2
            onChange={onInputChange}
            id="primary_language"
              placeholder="Primary Language"
              value={Poddata.primary_language}

              >
            <option style={{ color: "black" }} value="" defaultValue>
            Primary Language
            </option><option value='English'>English</option>
<option value='Spanish'>Spanish</option>
<option value='French'>French</option>
<option value='Portuguese'>Portuguese</option>
<option value='German'>German</option>
<option value='Italian'>Italian</option>
<option value='Chinese'>Chinese</option>
<option value='Japanese'>Japanese</option>
<option value='Russian'>Russian</option>
<option value='Arabic'>Arabic</option>
<option value='Hindi'>Hindi</option>
<option value='Korean'>Korean</option>
<option value='Turkish'>Turkish</option>
<option value='Dutch'>Dutch</option>
<option value='Greek'>Greek</option>

          </Select2>
        </Postfield>

        <Postfield>
          <Select2
            onChange={onInputChange}
            id="primary_timezone"
              placeholder="Pod Timezone"
              value={Poddata.primary_timezone}

              >
            <option style={{ color: "black" }} value="" defaultValue>
         Pod Timezone
            </option>
            <option   value='Indian standard time'>Indian standard time</option>
        <option    value='Eastern standard time'>Eastern standard time</option>
        <option   value='Central standard time'>Central standard time</option>
        <option    value='Mountain standard tim'>Mountain standard time</option>
        <option    value='Pacific standard tim'>Pacific standard time</option>
                        
          </Select2>
        </Postfield>
    
 
        <Postfield>
          <Select2
            onChange={onInputChange}
            id="podtype"
              placeholder={intl.formatMessage({ id: 'podType' })}
              value={Poddata.podtype}
required
              >
            <option style={{ color: "black" }} value="" defaultValue>
         Pod Type
            </option>
                        <option value='SaaS'>SaaS</option>
                        <option value='Web + Mobile App'>Web + Mobile App</option>
                        <option value='Web App'>Web App</option>
                        <option value='Mobile App'>Mobile App</option>
                        <option value='Data & AI'>Data & AI</option>
                        
          </Select2>
        </Postfield>
        <Postfield>
                    <Select2
                      onChange={onInputChange}
                      id="techStack"
                      placeholder={intl.formatMessage({ id: 'podTechStack' })}
                      value={Poddata.techStack}
                      required
                    >
                      <option style={{ color: "black" }} value="" defaultValue>
                        Pod Tech Stack
                      </option>
                      <option value='MEAN'>MEAN</option>
                      <option value='MERN'>MERN</option>
                      <option value='LAMP'>LAMP</option>
                      <option value='MEVN'>MEVN</option>

                    </Select2>
                  </Postfield>
                  {user.data[0].manager_type === "PartnerClient" && (
                  <Postfield>
          <Input
            type="number"
            id="pod_rate"
            onChange={onInputChange}
          
            placeholder={intl.formatMessage({ id: 'podrate' })}
            value={Poddata.pod_rate}
          
        //  onChange={}
            required
          />
        </Postfield>)}
         
        {/* <Postfield>
          <Select2
         
            onChange={onInputChange}
            id="country"
              placeholder=" Country"
              value={Poddata.country}
          >
            <option style={{ color: "black" }} value="" defaultValue>
         Country
            </option>
            <option value='usa'>USA</option>
                        <option value='india'>India</option>
                        <option value='canada'>Canada</option>
          </Select2>
        </Postfield>
        <Postfield>
        <Select2
         
         onChange={onInputChange}
         id="timezone"
         value={Poddata.timezone}
           placeholder=" Time Zone"
       >
         <option style={{ color: "black" }} value="" defaultValue>
      Time Zone
         </option>
         <option value='Indian Standard time' >Indian Standard time</option>
                    <option  value='Eastern standard time' >Eastern standard time</option>
                    <option  value='Central standard time'>Central standard time</option>
                    <option  value='Mountain standard time '>Mountain standard time </option>
                    <option value='Pacific standard time'>Pacific standard time</option>

       </Select2>
     </Postfield>
    
       

        <Postfield>
        <CreatePodSkillspage
                     onChange={handleSelectChangeskills}
                     isMulti
                     options={allergens}
                   placeholder='Skills'
                     value={multiskill}
                     styles={colourStyles}
                
                />
        </Postfield>
         */}
       


        <br></br>

        {/* <Postfield1>
       <Span >
          Talents
          </Span>
          <Link to={`/Clientcreatepodinvite`} >
          <button style={{display: 'flex',marginLeft:'5rem',textDecoration:'none',border:'none',background:'none',color:'#00B4D8'}}> invite</button>
          </Link>
         
          
{Poddata.candidatesid.length >= Poddata.podsize ? (
<Button2
            type="submit"  onClick={() => { setAddcandidatescreen(true) }} 
            disabled>
            Add + 
          </Button2>       
) : (<Button2
    type="submit"  onClick={() => { setAddcandidatescreen(true) }} 
  >
    Add + 
  </Button2>   )}
 
  <br></br>
 
        
        </Postfield1>
     */}
        { Poddata.candidatesid.map((data, i) => {

return (

        <Postfield>
              <Select3>
            
            <p style={{ margin : "20px" }}>{data.name}</p>

            <p  style={{marginLeft:'auto' ,marginTop:'1rem',backgroundColor:"red",borderRadius:"50%",color:'white',cursor:'pointer'  }} onClick={() => {  Poddata.candidatesid.splice(i, i + 1); setPoddata({ candidatesid : Poddata.candidatesid }) } }><CloseIcon/></p>
            </Select3>

                                </Postfield>
   )})}


       
<center>
{/* <NavLink to='/PodCreatedSuccessFullyCan'> */}
<br></br>
  <br></br>
          <Button
            type="submit"
          >
            {intl.formatMessage({ id: 'create' })}
          </Button>
          <br></br>
  <br></br>
          {/* </NavLink> */}
          </center>
      </form>
    </Container1>

    {/* </>) : (<Para>You Don't have the Pod subscription to add the pod. </Para>) : ( <Para>You Don't have the Pod subscription to add the pod. </Para>)} */}

  </CreatePodScreen>
  <br></br>
  <br></br>



</div>

)
}

export default Clientcreatepod;


const CreatePodScreen = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family:Poppins,Medium;

  margin-left: 175px;
 
  margin-top:5rem;
  width:100%;

  @media screen and (min-width:320px) and (max-width:1080px){
    display:flex;
    margin:auto;
    width:100%;
    
    padding-bottom:2rem;
    flex-direction:column;
    justify-content:center;
    align-items:center;
      margin-top: 7rem;
      }
  

`;





const Selectmulti = styled(Select)`

  font-family: Poppins, Medium;
  width: 100%; /* Expand the width to fit the available space */

  .select__control {
    
    border-radius: 10px;
    // Remove the border and background color
    border: 0;

    box-shadow: none; /* Remove any box shadow if present */
    color: grey;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .select__value-container {
    padding:0;
  }

  .select__single-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
  }

  .select__option {
    height: auto;
    padding: 6px;
   
  }

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 15rem;
  }
  
`;





const Span = styled.span`
margin-left:-3.5rem;
@media screen and (min-width:320px) and (max-width:1080px){
  margin-left:-4rem;
}
`;

const Para1 =styled.p`
margin-left:5rem;

color: #A2A2A2;
font-size:  1.125rem;
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
margin:auto;
width:20rem;

padding-bottom:2rem;
flex-direction:column;
justify-content:center;
align-items:center;
}
`;
const Para=styled.p`

font-family:Poppins,Medium;
display:flex;
justify-content:center;
align-items:center;
margin:auto;
margin-top: 5rem;
font-weight: 600;
font-size: 1.5rem;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  margin:auto;
  width:20rem;
  
  padding-bottom:2rem;
  flex-direction:column;
  justify-content:center;
  align-items:center;
    margin-top: 7rem;
    }
`;
const Container1=styled.div`
display:flex;
margin:auto;
flex-direction: column;
justify-content:center;
align-items:center;
margin:auto;
font-family:Poppins,Medium;
    width: 60rem;
    height: max-height;
    box-shadow: 1px 3px 6px #00000029;
border-radius: 16px;
background-color: #F8F8F8;
margin-top: 5rem;
    border: 1px solid #D8D8D8;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:25rem;
        height:max-height;
        }
`;
const Select2=styled.select`
font-family:Poppins,Medium;
width: 22rem;
height:50px;
border-radius: 10px;
border: 2px solid #B7B7B7;
color:grey;
font-weight:600;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        }
`;

const Select3=styled.p`
font-family:Poppins,Medium;
width: 22rem;
height:50px;
display:flex;
// flex-direction:row;
justify-content:center;
align-items:center;

border-radius: 10px;
border: 2px solid #B7B7B7;
color:grey;
font-weight:600;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        }
`;
const Multi=styled.div`
font-family:Poppins,Medium;
// width: 22rem;
// height:50px;
// border-radius: 10px;
// border: 2px solid #B7B7B7;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        }
`;
const Button=styled.button`
font-family:Poppins,Medium;
    background-color: #1DBF73;
    color: white;
   font-size: 15px;
     border-radius: 10px;
     height: 54px;
        width:22rem;
border:none;
    font-weight: 600;
    font-family: Poppins,Medium;
    box-shadow: 1px 3px 6px #00000029;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        }
`;


const Button2=styled.button`
font-family:Poppins,Medium;
    background-color: #00B4D8;
    color: white;
   font-size: 15px;
     border-radius: 50px;
     height: 30px;
        width:5rem;
        margin-left:5rem;
border:none;
    font-weight: 600;
    font-family: Poppins,Medium;
    box-shadow: 1px 3px 6px #00000029;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        }
`;
const Postfield=styled.div`
font-family:Poppins,Medium;
display:flex;
justify-content:center;
align-items:center;
margin:auto;
padding:10px
`;
const Postfield1=styled.span`
font-family:Poppins,Medium;
color: #8EAEBC;
font-weight: bold;
 font-size:18px;
display:flex;
justify-content:center;
align-items:center;
margin-left:5rem;
padding:10px
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        }
`;

const Input=styled.input`
font-family:Poppins,Medium;
width: 22rem;
height:50px;
border-radius: 10px;
border: 2px solid #B7B7B7;
padding-left:25px;
font-weight:600;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        }
`;
const Headingtext1=styled.h3`
font-family:Poppins,Medium;
display:flex;
justify-content:center;
align-items:center;
margin:auto;
margin-top: 5rem;
font-weight: 600;
font-size: 1.5rem;
@media screen and (min-width:320px) and (max-width:1080px){
    display:flex;
    justify-content:center;
    align-items:center;
    margin:auto;
    }
`;

const Browsecontainer = styled.div`
@media screen and (min-width:320px) and (max-width:1080px){
margin:auto;
width:100%;
display:flex;
align-items:center;
 justify-content:center;
flex-direction:column;
}
`;
const Main = styled.tr`
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
justify-content:center;
flex-direction:column;
margin:auto;
width:100%;
}
`;
const Table=styled.table`
margin-left:5rem;
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
 justify-content:center;
flex-direction:column;
margin:auto;
width:20rem;
height:max-content;
}
`;
const Heading = styled.div`
margin-top: 9rem;
font-size: 1.625rem;
margin-left:5rem;
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
margin:auto;
width:70%;
margin-top:10rem;
padding-bottom:2rem;
flex-direction:column;
justify-content:center;
align-items:center;
}
`;

const Singlecontainer = styled.tr`
display:flex;
flex-direction:row;
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
justify-content:center;
flex-direction:column;
align-items:center;
margin:auto;
width:20rem;

}
`;
const Row =styled.div`
background-color:white;
height:max-content;
padding-bottom:1rem;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  justify-content:center;
 flex-direction:column;
 align-items:center;
width:100%;
}
`;

const SearchTag = styled.td`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
width:20rem;
margin:auto;
border-bottom:1px solid #A2A2A2;
}
`;
// const Select = styled.select`
// @media screen and (min-width:320px) and (max-width:1080px){
//   width:20rem;
//  }
//  `;
const Selecttag = styled.td`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  flex-direction:column;
  margin:auto;
 width:20rem;
  border-bottom:1px solid #A2A2A2;
}
`;
const Clear = styled.p`
margin-top:20px;
width:6rem;
margin-left:31rem;
cursor: pointer;
font-weight:600;
font-size: 18px;
font-family:Poppins,Regular;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  align-items:center;
 flex-direction:column;
 margin-left:auto;
}
`;
const Search = styled.td`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
margin:auto;
width:100%;
align-items:center;
 flex-direction:column;
 margin-bottom:20px;
}
`;
const Row1 = styled.tr`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
// margin:auto;
 flex-direction:column;
align-items:center;
width:100%;
}
`;
const Row2 = styled.tr`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
// margin:auto;
 flex-direction:column;
align-items:center;
width:100%;
}
`;
// const Button = styled.button`
// @media screen and (min-width:320px) and (max-width:1080px){
//   display:flex;
// margin:auto;
//  flex-direction:column;
// }
// `;
const Pagination2 = styled.div`

height: max-content;
margin-left: 5rem;
  width:83.5rem;
 
  background-color: white;

margin-top: 3rem;
 border:1px solid #EFEFEF;
@media screen and (min-width:320px) and (max-width:1080px){
margin:auto;
width:20rem;
flex-direction:column;
display:flex;
align-items:center;
justify-content:center;
margin-top:3rem;

}
`;
const Pega = styled.div`
// display:flex;
// justify-content:center;
// align-items:center;
// align-self:center;
@media screen and (min-width:320px) and (max-width:1080px){
width:100%;
flex-direction:column;
display:flex;
align-items:center;
justify-content:center;
}
`;
const Numbers = styled.li`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  align-items:center;
justify-content:center;
width:7rem;
}
`;
const Num = styled.ol`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
//   align-items:center;
// justify-content:center;
flex-direction:column;
marginLeft:-3rem;
width:70%;
}
`;
const Num1 = styled.p`
@media screen and (min-width:320px) and (max-width:1080px){
  // display:flex;
  margin:auto;
width:100%;
}
`;
const Num2 = styled.li`
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  margin:auto;
  flex-direction:column;
  align-items:center;
justify-content:center;
width:100%;
}
`;
const Col = styled.div`
margin-top: -0.2rem;
display: flex;
flex-direction: row;
 align-items: center;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  margin:auto;
  align-items:center;
  justify-content:center;
  flex-direction:column;
}
`;

