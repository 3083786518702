import React, { useState, useEffect, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import Select from "react-select";
import { useAuthToken } from "../TokenContext";
import { useIntl } from "react-intl";
import PersonIcon from "@mui/icons-material/Person";
import moment from "moment";
import { Drawer, TextField, Button, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import "./kanband.css";
import { FaBug, FaJira } from 'react-icons/fa';

// Separate components for better organization
const TaskCard = ({ task, provided, onClick }) => (
  <div
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
    className="task"
    onClick={onClick}
    style={{
      ...provided.draggableProps.style,
      cursor: "pointer",
    }}
  >
    <div className="task-content">
      <div className="task-header">
        <div className="task-type-icon">
          {task.key ? (
            <FaJira size={16} className="jira-icon" title="JIRA Issue" />
          ) : (
            <FaBug size={16} className="pod-icon" title="POD Task" />
          )}
        </div>
        <div className="task-title">{task.summary || task.pod_task_name}</div>
      </div>
      <div className="task-sprint">{task.key}</div>
      <div className="task-assignee">
        <PersonIcon style={{ color: "green" }} />
        {task.Candidate_Name}
      </div>
      <div className="task-due-date">
        Due Date: {moment(task.due_date).format("MM-DD-YYYY") || "Not Set"}
      </div>
    </div>
  </div>
);

const Comment = ({ comment, renderJiraComment }) => (
  <div
    className="comment"
    style={{
      marginBottom: "20px",
      padding: "12px",
      backgroundColor: "#f8f9fa",
      borderRadius: "8px",
      boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
    }}
  >
    <div style={{
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "12px",
      alignItems: "center",
    }}>
      <span style={{
        backgroundColor: "#0073e6",
        color: "white",
        padding: "4px 8px",
        borderRadius: "4px",
        fontSize: "14px",
        fontWeight: "500",
      }}>
        {comment.author}
      </span>
      <small style={{ color: "#666" }}>
        {moment(comment.created || comment.timestamp).format("MMM DD, YYYY HH:mm")}
      </small>
    </div>
    <div style={{
      whiteSpace: "pre-wrap",
      wordBreak: "break-word",
      lineHeight: "1.5",
    }}>
      {comment.body ? renderJiraComment(comment.body?.content) : comment.text}
    </div>
  </div>
);

// API service
const api = {
  headers: (token) => ({
    Authorization: token,
    "Content-Type": "application/json",
  }),

  async fetchPodNames(token, userId) {
    const response = await axios.post(
      process.env.REACT_APP_TALENT_GETPODSFORTALENT,
      { c_id: userId },
      { headers: this.headers(token) }
    );
    return response.data.body.map(pod => ({
      label: pod.pod_name,
      value: pod.pod_id,
    }));
  },

  async updateTaskStatus(task, destination, authDetails, token) {
    if (task.pod_task_id) {
      return this.updatePodTaskStatus(task, destination, token);
    }
    return this.updateJiraTaskStatus(task, destination, authDetails, token);
  },

  async updatePodTaskStatus(task, destination, token) {
    return axios.put(
      process.env.REACT_APP_CLIENT_UPDATEKANBANBOARDPODTASK,
      {
        pod_task_id: task.pod_task_id,
        status: destination.droppableId,
      },
      { headers: this.headers(token) }
    );
  },

  async updateJiraTaskStatus(task, destination, authDetails, token) {
    const transitionId = await this.fetchTransitionId(destination.droppableId, task.key, authDetails, token);
    if (!transitionId) throw new Error("Invalid transition");

    return axios.put(
      process.env.REACT_APP_CLIENT_KANBANBOARDUPDATE,
      {
        Jira_URL: authDetails.Jira_URL,
        Jira_admin_login_email_address: authDetails.Jira_admin_login_email_address,
        Jira_admin_API_Token: authDetails.Jira_admin_API_Token,
        key: task.key,
        transitionId,
        status: destination.droppableId,
      },
      { headers: this.headers(token), timeout: 10000 }
    );
  },

  async fetchTransitionId(status, key, authDetails, token) {
    if (!status || !key) return null;
    const response = await axios.post(
      process.env.REACT_APP_CLIENT_FETCHSTATUSFORSPECIFICJIRAISSUE,
      {
        Jira_admin_login_email_address: authDetails.Jira_admin_login_email_address,
        Jira_admin_API_Token: authDetails.Jira_admin_API_Token,
        key,
        Jira_URL: authDetails.Jira_URL,
        status,
      },
      { headers: this.headers(token), timeout: 5000 }
    );
    const statusData = response.data.body;
    const matchingStatus = statusData.find(s => s.name === status);
    return matchingStatus?.id || null;
  }
};

// Utility functions
const utils = {
  parseStatusString: (statusString) => {
    if (!statusString) return [];
    return statusString.split(",").map(status => status.trim());
  },

  initializeColumns: (authData) => {
    if (!authData) return {};
    const openStatuses = utils.parseStatusString(authData.jira_open_story_bug_status?.[0]);
    const closedStatuses = utils.parseStatusString(authData.jira_closed_story_bug_status?.[0]);

    const columns = {};
    openStatuses.forEach(status => {
      columns[status] = { title: status, items: [], type: "open" };
    });
    closedStatuses.forEach(status => {
      columns[status] = { title: status, items: [], type: "closed" };
    });
    return columns;
  },

  groupTasksByStatus: (tasks, columns) => {
    const groupedColumns = { ...columns };
    Object.keys(groupedColumns).forEach(key => {
      groupedColumns[key].items = [];
    });

    tasks.forEach(task => {
      const status = task.status || "To Do";
      if (groupedColumns[status]) {
        groupedColumns[status].items.push(task);
      }
    });

    return groupedColumns;
  }
};

const KanbanboardTalent = () => {
  // State management
  const [tasks, setTasks] = useState([]);
  const [columns, setColumns] = useState({
    "To Do": { title: "To Do", items: [], type: "open" },
    "In Progress": { title: "In Progress", items: [], type: "open" },
    Done: { title: "Done", items: [], type: "closed" },
  });
  const [selectedPodId, setSelectedPodId] = useState([]);
  const [authorizationDetails, setAuthorizationDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState([]);
  const [podNames, setPodNames] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [newComment, setNewComment] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  const token = useAuthToken();
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);
  const scrollContainerRef = useRef(null);

  // Effects
  useEffect(() => {
    const loadPodNames = async () => {
      try {
        const names = await api.fetchPodNames(token, user.data[0].c_id);
        setPodNames(names);
      } catch (error) {
        console.error("Error fetching pod names:", error);
      }
    };
    loadPodNames();
  }, [token, user.data]);

  useEffect(() => {
    if (selectedPodId && selectedPodId.length > 0) {
      fetchAuthorizationTokensAndList();
    } else {
      resetStates();
    }
  }, [selectedPodId, selectedCandidate]);
// Inside the KanbanboardTalent component, add these methods:


  const fetchAuthorizationTokensAndList = async () => {
    setLoading(true);
    
    if (!selectedPodId || selectedPodId.length === 0) {
      setLoading(false);
      return;
    }

    try {
      // Fetch authorization tokens
      const authorizationResponse = await axios.post(
        process.env.REACT_APP_CUSTOMERADMIN_FETCHAUTHORIZATIONTOKENS,
        { Organizational_Code: user.data[0].talent_code },
        { headers: api.headers(token) }
      );

      const authorizationData = authorizationResponse.data.body.data[0];
      setAuthorizationDetails(authorizationData);

      // Initialize columns
      const initialColumns = utils.initializeColumns(authorizationData);
      setColumns(initialColumns);
      

      // Fetch candidates
      const emailFetchingResponse = await axios.post(
        process.env.REACT_APP_CLIENT_FETCHPODCANDIDATES,
        { podID: selectedPodId },
        { headers: api.headers(token) }
      );

      const allCandidates = emailFetchingResponse?.data?.data || [];
      setCandidates(allCandidates);
      const candidateNames = selectedCandidate.map(option => option.value);

      if (allCandidates.length > 0) {
        // Fetch tasks
        const listPayload = {
          pod_Id: selectedPodId,
          username: authorizationData.Jira_admin_login_email_address,
          password: authorizationData.Jira_admin_API_Token,
          projectKey: authorizationData.Jira_Kanben_Project_name,
          jira_url: authorizationData.Jira_URL,
          assignees: allCandidates.map(candidate => candidate.email),
          candidateName: candidateNames || [],
        };

        const response = await axios.post(
          "https://irl3zjh84e.execute-api.us-east-1.amazonaws.com/prod/testSingleviewtask",
          listPayload,
          { headers: api.headers(token) }
        );

        // Combine tasks
        const combinedTasks = [
          ...(response.data.data.podMembers || []).map(task => ({
            ...task,
            comments: task.comments || [],
          })),
          ...(response.data.data.jiraIssues || []).map(task => ({
            ...task,
            comments: task.comments || [],
          })),
        ];

        setTasks(combinedTasks);
        setColumns(utils.groupTasksByStatus(combinedTasks, initialColumns));
      }
    } catch (error) {
      console.error("Error in fetchAuthorizationTokensAndList:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTaskClick = async (task) => {
    const fullTask = tasks.find(
      (t) => (t.key || t.pod_task_id) === (task.key || task.pod_task_id)
    );
    
    if (fullTask) {
      setSelectedTask(fullTask);
      if (fullTask.pod_task_id) {
        try {
          const response = await axios.post(
            "https://6w88fzkr0j.execute-api.us-east-1.amazonaws.com/prod/fetchpodtaskComment",
            { pod_task_id: parseInt(fullTask.pod_task_id) },
            { headers: api.headers(token) }
          );
        } catch (error) {
          console.error("Error fetching pod task comments:", error);
        }
      }
    } else {
      setSelectedTask({
        ...task,
        comments: [],
      });
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination || isUpdating) return;

    const { source, destination, draggableId } = result;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const task = Object.values(columns).reduce((found, column) => {
      if (found) return found;
      return column.items.find(
        (task) => (task.key || task.pod_task_id).toString() === draggableId
      );
    }, null);

    if (!task) return;

    const oldColumns = columns;
    const newColumns = { ...columns };
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];

    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];

    const [removed] = sourceItems.splice(source.index, 1);
    const movedTask = { ...removed, status: destination.droppableId };
    destItems.splice(destination.index, 0, movedTask);

    newColumns[source.droppableId] = {
      ...sourceColumn,
      items: sourceItems,
    };
    newColumns[destination.droppableId] = {
      ...destColumn,
      items: destItems,
    };

    setColumns(newColumns);
    setIsUpdating(true);

    try {
      const updateResponse = await api.updateTaskStatus(
        task,
        destination,
        authorizationDetails,
        token
      );

      if (updateResponse.status !== 200) {
        throw new Error("Failed to update task status");
      }
    } catch (error) {
      console.error("Error updating task status:", error);
      setColumns(oldColumns);
      alert("Failed to update task status. Please try again.");
    } finally {
      setIsUpdating(false);
    }
  };

  
  return (
    <div className="kanban-board">
      <h1>{intl.formatMessage({ id: "podBoard" })}</h1>
      
      {/* Selection controls */}
      <div className="board-controls-wrapper">
        <Select
          isMulti
          options={podNames}
          value={podNames.filter(option => selectedPodId.includes(option.value))}
          onChange={selectedOptions => setSelectedPodId(selectedOptions.map(opt => opt.value))}
          placeholder={intl.formatMessage({ id: 'selectPod' })}
          className="select-field"
        />
        
        <Select
          isMulti
          options={candidates.map(candidate => ({
            value: candidate.Candidate_Name,
            label: candidate.Candidate_Name
          }))}
          value={selectedCandidate}
          onChange={selectedOptions => setSelectedCandidate(selectedOptions || [])}
          placeholder="Select Candidates"
          className="select-field"
          styles={{
            control: base => ({
              ...base,
              marginTop: '10px'
            })
          }}
        />
      </div>

      {/* Kanban board */}
      <div style={{ position: "relative", width: "100%" }}>
        <button
          className="scroll-button scroll-left"
          onClick={() => handleScroll("left")}
        >
          ←
        </button>

        <DragDropContext onDragEnd={onDragEnd}>
          <div className="scrollable-board" ref={scrollContainerRef}>
            {Object.entries(columns).map(([columnId, column]) => (
              <Droppable key={columnId} droppableId={columnId}>
                {(provided, snapshot) => (
                  <div
                    className="column"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <div className="task-list">
                      <h6
                        style={{
                          color: "grey",
                          fontWeight: "600",
                          borderBottomColor: column.type === "closed" ? "green" : "yellow",
                        }}
                      >
                        {column.title} ({column.items.length})
                      </h6>

                      <div className="tasks-container">
                        {loading ? (
                          <div className="loading-container">
                            <CircularProgress size={24} />
                          </div>
                        ) : (
                          column.items.map((task, index) => (
                            <Draggable
                              key={task.key || task.pod_task_id}
                              draggableId={(task.key || task.pod_task_id).toString()}
                              index={index}
                            >
                              {(provided) => (
                                <TaskCard
                                  task={task}
                                  provided={provided}
                                  onClick={() => handleTaskClick(task)}
                                />
                              )}
                            </Draggable>
                          ))
                        )}
                        {provided.placeholder}
                      </div>
                    </div>
                  </div>
                )}
              </Droppable>
            ))}
          </div>
        </DragDropContext>

        <button
          className="scroll-button scroll-right"
          onClick={() => handleScroll("right")}
        >
          →
        </button>
      </div>

      {/* Comment drawer */}
      <Drawer 
        anchor="right" 
        open={!!selectedTask} 
        onClose={() => {
          setSelectedTask(null);
          setNewComment("");
        }}
      >
        <div style={{ width: "400px", padding: "20px" }}>
          <div className="drawer-header">
            <h5>
              {selectedTask?.key || selectedTask?.pod_task_id} - {selectedTask?.summary || selectedTask?.pod_task_name}
            </h5>
            <div className="status">Status: {selectedTask?.status}</div>
          </div>

          <div className="comments-section">
            {selectedTask?.comments?.map((comment, index) => (
              <Comment 
                key={index} 
                comment={comment}
                renderJiraComment={renderJiraComment}
              />
            ))}
          </div>

          <div className="add-comment-section">
            <TextField
              label="Add a comment"
              multiline
              rows={3}
              fullWidth
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              variant="outlined"
              size="small"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddComment}
              disabled={!newComment.trim()}
              style={{ marginTop: "10px", float: "right" }}
            >
              Add Comment
            </Button>
          </div>
        </div>
      </Drawer>

      <style jsx>{`
        .drawer-header {
          border-bottom: 1px solid #eee;
          padding-bottom: 10px;
          margin-bottom: 20px;
        }
        
        .drawer-header h5 {
          margin: 0;
        }
        
        .drawer-header .status {
          font-size: 0.9em;
          color: #666;
          margin-top: 4px;
        }
        
        .comments-section {
          max-height: calc(100vh - 300px);
          overflow-y: auto;
          padding: 0 4px;
        }
        
        .add-comment-section {
          border-top: 1px solid #eee;
          padding-top: 20px;
          margin-top: 20px;
          background: white;
          position: sticky;
          bottom: 0;
        }
        
        .comments-section::-webkit-scrollbar {
          width: 6px;
        }

        .comments-section::-webkit-scrollbar-track {
          background: #f1f1f1;border-radius: 3px;
        }

        .comments-section::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 3px;
        }

        .comments-section::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        .comment:hover {
          background-color: #f1f5f9 !important;
        }
      `}</style>
    </div>
  );
};

// Comment rendering helper
const renderJiraComment = (content) => {
  if (!content) return null;

  return content.map((item, index) => {
    if (item.type === 'paragraph') {
      return (
        <div key={index} style={{ marginBottom: '8px' }}>
          {item.content?.map((contentItem, cIndex) => {
            switch (contentItem.type) {
              case 'text':
                return <span key={cIndex}>{contentItem.text}</span>;
              case 'mention':
                return (
                  <span 
                    key={cIndex} 
                    style={{ 
                      color: '#0052CC', 
                      fontWeight: '500',
                      backgroundColor: '#EAE6FF',
                      padding: '0 4px',
                      borderRadius: '3px',
                      margin: '0 2px'
                    }}
                  >
                    {contentItem.attrs.text}
                  </span>
                );
              case 'hardBreak':
                return <br key={cIndex} />;
              default:
                return null;
            }
          })}
        </div>
      );
    }
    return null;
  });
};

// Handle scroll functionality
const handleScroll = (direction, scrollContainerRef) => {
  if (scrollContainerRef.current) {
    const scrollAmount = 300;
    const currentScroll = scrollContainerRef.current.scrollLeft;
    scrollContainerRef.current.scrollTo({
      left: currentScroll + (direction === "left" ? -scrollAmount : scrollAmount),
      behavior: "smooth",
    });
  }
};

// Reset state helper
const resetStates = () => ({
  candidates: [],
  tasks: [],
  columns: {
    "To Do": { title: "To Do", items: [], type: "open" },
    "In Progress": { title: "In Progress", items: [], type: "open" },
    Done: { title: "Done", items: [], type: "closed" },
  }
});

// Comment handling
const handleAddComment = async (newComment, selectedTask, token, user, authorizationDetails, setSelectedTask, setNewComment) => {
  if (!newComment.trim() || !selectedTask) return;

  const headers = {
    Authorization: token,
    "Content-Type": "application/json",
  };

  try {
    if (selectedTask.pod_task_id) {
      await handlePodTaskComment(selectedTask, newComment, user, headers, setSelectedTask);
    } else if (selectedTask.key) {
      await handleJiraComment(selectedTask, newComment, user, headers, authorizationDetails, setSelectedTask);
    }
    setNewComment("");
  } catch (error) {
    console.error("Error adding comment:", error);
    alert("Failed to add comment. Please try again.");
  }
};

const handlePodTaskComment = async (selectedTask, newComment, user, headers, setSelectedTask) => {
  let existingComments = [];
  if (selectedTask.comment) {
    try {
      existingComments = JSON.parse(selectedTask.comment);
    } catch (error) {
      console.error("Error parsing existing comments:", error);
    }
  }

  const newCommentObj = {
    pod_task_id: parseInt(selectedTask.pod_task_id),
    user_id: parseInt(user.data[0].c_id),
    new_text: newComment,
    is_reply: false,
    author: user.data[0].firstname
  };

  const response = await axios.post(
    "https://6w88fzkr0j.execute-api.us-east-1.amazonaws.com/prod/addCommentpodtask",
    [newCommentObj],
    { headers }
  );

  if (response.status === 200) {
    setSelectedTask(prevTask => ({
      ...prevTask,
      comment: JSON.stringify([
        ...existingComments,
        {
          text: newComment,
          user_id: parseInt(user.data[0].c_id),
          timestamp: new Date().toISOString(),
          replies: []
        }
      ])
    }));
  }
};

const handleJiraComment = async (selectedTask, newComment, user, headers, authorizationDetails, setSelectedTask) => {
  const response = await axios.post(
    "https://o0quulyyma.execute-api.us-east-1.amazonaws.com/prod/addCommentjirapodboard",
    {
      jiraUrl: authorizationDetails.Jira_URL,
      issueKey: selectedTask.key,
      commentText: newComment,
      username: authorizationDetails.Jira_admin_login_email_address,
      password: authorizationDetails.Jira_admin_API_Token,
    },
    { headers }
  );

  if (response.status === 200) {
    setSelectedTask(prevTask => ({
      ...prevTask,
      comments: [
        ...(prevTask.comments || []),
        {
          author: user.data[0].firstname || "current_user",
          body: {
            type: "doc",
            version: 1,
            content: [
              {
                type: "paragraph",
                content: [{ type: "text", text: newComment }],
              },
            ],
          },
          created: new Date().toISOString(),
        },
      ],
    }));
  }
};

export default KanbanboardTalent;