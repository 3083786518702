import React, { Component } from 'react';
import FormErrors from "./FormErrors";
import Validate from "./utility/FormValidation";
import axios from 'axios';
import './css/register.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import { Link } from "react-router-dom";
import { useAuthToken } from "../TokenContext";

import MultiSelect from "react-multi-select-component";

const remoteEngineerPreference = [
  { value: 'USA', label: 'US-Based' },
  { value: 'Canada', label: 'Canada-Based' },
  { value: 'LatinAmerica', label: 'Nearshore- Latin America' },
  { value: 'India', label: 'Offshore- India' }
];

const budget = [
  { value: '10000', label: '< $10,000' },
  { value: '25000', label: '$10000 - $25000' },
  { value: '50000', label: '$25000 - $50000' },
  { value: '50000+', label: '> $50000' }
];

const frontEndSkills = [
  { value: 'React', label: 'React' },
  { value: 'Angular', label: 'Angular' },
  { value: 'Vue', label: 'Vue' },
  { value: 'HTML5', label: 'HTML5' },
  { value: 'Bootstrap', label: 'Bootstrap' },
  { value: 'CSS', label: 'CSS' }
];

const backEndSkills = [
  { value: 'Node', label: 'Node' },
  { value: 'Java', label: 'Java' },
  { value: 'Python', label: 'Python' },
  { value: 'C++', label: 'C++' },
  { value: 'C', label: 'C' },
  { value: 'AWS', label: 'AWS' }
];

const mobileSkills = [
  { value: 'Kotlin', label: 'Kotlin' },
  { value: 'Java', label: 'Java' },
  { value: 'Python', label: 'Python' },
  { value: 'C#', label: 'C#' }
];

const industryOptions = [
  { value: 'Healthcare', label: 'Healthcare' },
  { value: 'Banking', label: 'Banking and Financial Services' },
  { value: 'Retail', label: 'Retail' },
  { value: 'Travel', label: 'Travel' }
]


class Register extends Component {
  
  state = {
    projectObjective: "",
    frontendTechSkills: "",
    backendTechSkills: "",
    mobileTechSkills: "",
    remoteEngPref: "",
    budgetRange: "",
    industry: "",
    errors: {
      blankfield: false
    } 
  }

  //for select option
  handleChangeremotepref = event => {
    this.setState({ remoteEngPref : event.value });
  };

  handleChangebudget = event => {
    this.setState({ budgetRange : event.value });
  };

  handleChangefrontskill = event => {
    this.setState({ frontendTechSkills : event.value });
  };

  handleChangebkndskill = event => {
    this.setState({ backendTechSkills : event.value });
  };

  handleChangemobiletechskill = event => {
    this.setState({ mobileTechSkills :  event.value});
  };

  handleChangeindustry = event => {
    this.setState({ industryOptions :  event.value});
  };


  

  

  //for clear error
  clearErrorState = () => {
    this.setState({
      errors: {
        blankfield: false
      }
    });
  }

  handleSubmit = async event => {
    const token = useAuthToken();

    event.preventDefault();
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    const { projectObjective, frontendTechSkills, backendTechSkills, mobileTechSkills, remoteEngPref, budgetRange  } = this.state;

    // Database insert here
    if (projectObjective  && remoteEngPref) {
      // https://lt8n4qcae3.execute-api.us-east-1.amazonaws.com/dev/employerProjectRequirements
      axios.post(process.env.REACT_APP_CLIENT_EMPLOYERPROJECTREQUIREMENTS, 
        {ProjectObjective: projectObjective, BackendSkills: backendTechSkills, FrontendSkills:frontendTechSkills, MobileTechSkills: mobileTechSkills, RemoteEngPrefernce: remoteEngPref, ProjectBudgetRange: budgetRange },{headers:headers}).then(res => {
         
          window.alert("New Requirement added successfully");
          this.props.history.push("/employerhome");
      }, (error) => {
          console.log(error);
      });
  }
  };

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  }

  render() {

    const { remoteEngPref } = this.state;
    const { budgetRange } = this.state;
    const { frontendTechSkills } = this.state;
    const { backendTechSkills } = this.state;
    const { mobileTechSkills } = this.state;
    const { industry } = this.state;

    return (
      <section className="section">
        <div className="container centeritem">
          <h1>Add a Requirement</h1>
          <FormErrors formerrors={this.state.errors} />
          <form onSubmit={this.handleSubmit}>

              <div className="field">
                <p className="control">
                  <textarea
                  className="input" 
                  id="projectObjective"
                  placeholder="Project Objective"
                  value={this.state.projectObjective}
                  onChange={this.onInputChange}
                  />
                </p>
              </div>

              {/* Multiselect Dropdown Starts Here */}


              {/* For Front end Skills */}
              <div className="field">
                <p className="control">
                <Select
                  value={frontendTechSkills}
                  onChange={this.handleChangefrontskill}
                  options={frontEndSkills}
                  id="frontEndSkills"
                  placeholder="Front-end Skills"
                  isMulti="true"
                  />
                </p>
              </div>
              {/* For Back end Skills */}
              <div className="field">
                <p className="control">
                <Select
                  value={backendTechSkills}
                  onChange={this.handleChangebkndskill}
                  options={backEndSkills}
                  id="backEndSkills"
                  placeholder="Back-end Skills"
                  isMulti="true"
                  />
                </p>
              </div>
              {/* For Mobile tech end Skills */}
              <div className="field">
                <p className="control">
                <Select
                  value={mobileTechSkills}
                  onChange={this.handleChangemobiletechskill}
                  options={mobileSkills}
                  id="mobileSkills"
                  placeholder="Mobile Skills"
                  isMulti="true"
                  />
                </p>
              </div>

              {/* Select option starts here */}

              {/* For Remote Eng Pref */}
              <div className="field">
                <p className="control">
                <Select
                  value={remoteEngPref}
                  onChange={this.handleChangeremotepref}
                  options={remoteEngineerPreference}
                  id="remoteEngModel"
                  placeholder="Remote Eng Pref"
                  />
                </p>
              </div>

              {/* For Budget Range */}
              <div className="field">
                <p className="control">
                <Select
                  value={budgetRange}
                  onChange={this.handleChangebudget}
                  options={budget}
                  id="budgetRange"
                  placeholder="Budget Range"
                  />
                </p>
              </div>

              {/* For Industry */}
              <div className="field">
                <p className="control">
                <Select
                  value={industry}
                  onChange={this.handleChangeindustry}
                  options={industryOptions}
                  id="industry"
                  placeholder="Industry"
                  />
                </p>
              </div>

              <div className="field">
                <p className="control">
                  <Link to="/employerhome">Back to Employer Home</Link>
                </p>
              </div>
              
              <div className="field">
                <p className="control">
                  <button className="button is-success">
                    Post Requirement
                  </button>
                </p>
              </div>
            
          </form>
        </div>
      </section>
    );
  }
}

export default Register;