import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { NotificationsNone } from '@mui/icons-material';
import './notification.css';
import { useAuthToken } from "../TokenContext";
import moment from 'moment';
const NotificationDropdown = () => {
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [removedIds, setRemovedIds] = useState([]);
  const dropdownRef = useRef(null);
  const token = useAuthToken();

  const fetchNotifications = async (token, user) => {
    try {
      if (!token || !user.data) {
        console.log('Token or user data is missing. API requests will not be made.');
        return;
      }

      const response = await fetch(
        process.env.REACT_APP_TALENT_CANDIDATENOTIFICATIONS,
        {
          method: 'POST',
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ candidate_id: user.data[0]?.c_id }),
        }
      );
      const data = await response.json();
      const sortedNotifications = data.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
  
      // Process notifications and calculate relative time for each
      const notificationsWithTimeAgo = sortedNotifications.map(notification => ({
        ...notification,
        timeAgo: moment(notification.createdAt).fromNow(), // Calculate "time ago"
      }));
  
      setNotifications(notificationsWithTimeAgo); 
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };
  
  useEffect(() => {
    fetchNotifications(token, user);
  }, [token, user.data]);
  
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const markNotificationAsRead = async (notificationId) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_TALENT_NOTIFICATIONREAD,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ notification_ids: [notificationId] }),
        }
      );

      if (response.ok) {
        // Call the list API again to fetch updated notifications after marking as read
        fetchNotifications(token, user);
        // Add the ID of the removed notification to the state
        setRemovedIds([...removedIds, notificationId]);
      } else {
        console.error('Failed to mark notification as read.');
      }
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  return (
    <div className="notification-dropdown-container">
      <div className="notification-icon" onClick={toggleDropdown}>
        <NotificationsNone style={{ color: "black", marginLeft: '1rem' }} />
        {notifications?.length > 0 && (
          <span className="notification-count">{notifications.length}</span>
        )}
      </div>

      {isOpen && (
        <div className="notification-dropdown" ref={dropdownRef}>
          {notifications?.length > 0 ? (
            notifications.map((notification) => (
              <div
                key={notification.id}
                className={`notification-item ${
                  removedIds.includes(notification.id) ? 'removed' : ''
                }`}
                style={{
                  transition: 'all 0.3s ease-in-out',
                  transform: removedIds.includes(notification.id) ? 'translateX(100%)' : 'translateX(0)',
                  opacity: removedIds.includes(notification.id) ? 0 : 1,
                  maxHeight: removedIds.includes(notification.id) ? 0 : 'max-height',
                  overflow: 'hidden',
                }}
              >
                <p style={{ color: 'black', overflowWrap: 'break-word', wordWrap: 'break-word' }}>{notification.message}</p><span style={{ color: 'grey' }}>{notification.timeAgo}</span>
                
                <button style={{
    backgroundColor: "#90E0EF",
    border: "none",
    borderRadius: "5px",
    color: "white",
    transition: "background-color 0.3s ease",
  }}
  onMouseEnter={(e) => { e.target.style.backgroundColor = '#4a90e2'; }}
  onMouseLeave={(e) => { e.target.style.backgroundColor = '#90E0EF'; }} onClick={(e) => {
  markNotificationAsRead(notification.id);
  e.target.classList.add('clicked');
  setTimeout(() => {
    e.target.classList.remove('clicked');
  }, 300);
}}>
  Read
</button>
              </div>
            ))
          ) : (
            <p className="no-notifications">No new notifications</p>
          )}
        </div>
      )}
    </div>
  );
};

export default NotificationDropdown;