export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const PARTNER_SUCCESS = "PARTNER_SUCCESS";
export const PARTNER_FAIL = "PARTNER_FAIL";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SUCCESS = "SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const FAIL = "FAIL";
export const LOGOUT = "LOGOUT";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const UPDATE_PROFILE = "UPDATE_PROFILE"