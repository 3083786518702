import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useIntl } from 'react-intl';
import moment from 'moment';
import axios from 'axios';
import { logout } from "./components/action/auth";
import {
  Container,
  Paper,
  Box,
  Typography,
  Grid,
  Avatar,
  Chip,
  Divider,
  Link,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  Stack,
  IconButton,
  Button,
  useTheme
} from '@mui/material';
import {
  LinkedIn as LinkedInIcon,
  Language as WebIcon,
  LocationOn as LocationIcon,
  Email as EmailIcon,
  Work as WorkIcon,
  School as SchoolIcon,
  AccessTime as TimeIcon,
  EmojiEvents as AwardIcon,
  Description as DescriptionIcon,
  GitHub as GitHubIcon,
  Today as CalendarIcon,
  CheckCircle as CheckCircleIcon,
  Business as BusinessIcon,
  Code as CodeIcon,
  Timer as TimerIcon,
  AttachMoney as MoneyIcon
} from '@mui/icons-material';

const ProfilePage = () => {
  const { firstName, c_id } = useParams();
  const history = useHistory();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const intl = useIntl();
  const theme = useTheme();

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.post(process.env.REACT_APP_TALENT_GETVISIBILITY, {
          id: c_id
        });

        if (response.data?.data?.[0]) {
          const profile = response.data.data[0];
          const { visibility, ...userData } = profile;

          if (visibility === 'public') {
            setProfileData(userData);
          } else if (visibility === 'link') {
            if (window.location.search.includes('token')) {
              const token = new URLSearchParams(window.location.search).get('token');
              if (token === btoa(c_id)) {
                setProfileData(userData);
              } else {
                dispatch(logout());
                history.push('/login');
              }
            } else {
              dispatch(logout());
              history.push('/login');
            }
          } else {
            dispatch(logout());
            history.push('/login');
          }
        } else {
          dispatch(logout());
        }
      } catch (error) {
        console.error('Error:', error);
        dispatch(logout());
      } finally {
        setLoading(false);
      }
    };

    const fetchAdditionalData = async () => {
      try {
        const response = await axios.post(process.env.REACT_APP_TALENT_GETCANDPROF, {
          id: c_id
        });

        if (response.data?.data) {
          const { education, certification, exp, podExperience } = response.data.data;
          setProfileData(prev => ({
            ...prev,
            education,
            certification,
            experience: exp,
            podExperience,
          }));
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchProfileData();
    fetchAdditionalData();
  }, [c_id, dispatch, history]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!profileData) {
    return (
      <Container maxWidth="lg" sx={{ mt: 15 }}>
        <Alert severity="error">
          No profile found or you do not have access.
        </Alert>
      </Container>
    );
  }

  const InfoChip = ({ icon: Icon, label }) => (
    <Chip
      icon={<Icon sx={{ fontSize: 18 }} />}
      label={label}
      sx={{
        borderRadius: 1,
        bgcolor: 'background.paper',
        '& .MuiChip-label': {
          px: 1,
        }
      }}
      variant="outlined"
    />
  );

  const SectionTitle = ({ icon: Icon, title }) => (
    <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 2 }}>
      <Icon color="primary" />
      <Typography variant="h6">{title}</Typography>
    </Stack>
  );

  const ExperienceItem = ({ role, company, period, present }) => (
    <Box sx={{ mb: 3 }}>
      <Stack direction="row" spacing={2}>
        <Avatar sx={{ bgcolor: 'primary.light', width: 48, height: 48 }}>
          <BusinessIcon />
        </Avatar>
        <Box>
          <Typography variant="subtitle1" fontWeight="medium">
            {role}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {company}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <CalendarIcon sx={{ fontSize: 16 }} />
            {period}
            {present && (
              <Chip 
                label="Present" 
                size="small" 
                color="primary" 
                variant="outlined"
                sx={{ ml: 1 }}
              />
            )}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );

  const EducationItem = ({ degree, institution, startDate, endDate }) => (
    <Box sx={{ mb: 3 }}>
      <Stack direction="row" spacing={2}>
        <Avatar sx={{ bgcolor: 'secondary.light', width: 48, height: 48 }}>
          <SchoolIcon />
        </Avatar>
        <Box>
          <Typography variant="subtitle1" fontWeight="medium">
            {degree}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {institution}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <CalendarIcon sx={{ fontSize: 16 }} />
            {moment(startDate).format('YYYY')} - {moment(endDate).format('YYYY')}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 15, mb: 4 }}>
      {/* Profile Header */}
      <Paper 
        elevation={2}
        sx={{
          borderRadius: 2,
          overflow: 'hidden',
          mb: 3,
          background: '#fff'
        }}
      >
        {/* Cover Background */}
        <Box
          sx={{
            height: 200,
            background: `linear-gradient(120deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`,
          }}
        />

        {/* Profile Info */}
        <Box sx={{ p: 3, mt: -8 }}>
          <Avatar
            src={profileData.profilePicture || undefined}
            sx={{
              width: 150,
              height: 150,
              border: '4px solid white',
              boxShadow: theme.shadows[3],
              mb: 2,
              bgcolor: theme.palette.primary.main
            }}
          >
            {profileData.firstName?.[0]}
          </Avatar>

          <Box sx={{ maxWidth: 'lg' }}>
            <Typography variant="h4" gutterBottom fontWeight="medium">
              {profileData.firstName} {profileData.lastName}
            </Typography>
            
            <Typography variant="h6" color="text.secondary" gutterBottom>
              {profileData.job_title}
            </Typography>

            <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ my: 2 }}>
              <InfoChip icon={LocationIcon} label={profileData.country} />
              {profileData.timezone && (
                <InfoChip icon={TimeIcon} label={profileData.timezone} />
              )}
              {profileData.availability && (
                <InfoChip icon={TimerIcon} label={profileData.availability} />
              )}
              {profileData.work_authorization && profileData.country === 'usa' && (
                <InfoChip icon={CheckCircleIcon} label={profileData.work_authorization} />
              )}
            </Stack>

            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
              {profileData.linkedin && (
                <Button
                  startIcon={<LinkedInIcon />}
                  variant="outlined"
                  size="small"
                  href={profileData.linkedin}
                  target="_blank"
                >
                  LinkedIn
                </Button>
              )}
              {profileData.portfolio && (
                <Button
                  startIcon={<WebIcon />}
                  variant="outlined"
                  size="small"
                  href={profileData.portfolio}
                  target="_blank"
                >
                  Portfolio
                </Button>
              )}
            </Stack>

            <Box sx={{ mt: 3 }}>
              <Typography variant="body1" color="text.secondary">
                {profileData.about}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          {/* Experience Section */}
          <Card sx={{ mb: 3 }}>
            <CardContent>
              <SectionTitle icon={WorkIcon} title={intl.formatMessage({ id: 'workExperience' })} />
              {profileData.experience?.length > 0 ? (
                profileData.experience.map((exp, index) => (
                  <ExperienceItem
                    key={index}
                    role={exp.work_position}
                    company={exp.work_organization}
                    period={`${moment(exp.workStartdate).format('MMM YYYY')} - ${exp.present ? 'Present' : moment(exp.workEnddate).format('MMM YYYY')}`}
                    present={exp.present === 1}
                  />
                ))
              ) : (
                <Typography color="text.secondary">No experience listed</Typography>
              )}
            </CardContent>
          </Card>

          {/* Education Section */}
          <Card sx={{ mb: 3 }}>
            <CardContent>
              <SectionTitle icon={SchoolIcon} title={intl.formatMessage({ id: 'education' })} />
              {profileData.education?.length > 0 ? (
                profileData.education.map((edu, index) => (
                  <EducationItem
                    key={index}
                    degree={edu.degree}
                    institution={edu.institution}
                    startDate={edu.startDate}
                    endDate={edu.endDate}
                  />
                ))
              ) : (
                <Typography color="text.secondary">No education listed</Typography>
              )}
            </CardContent>
          </Card>

          {/* Certifications Section */}
          {profileData.certification?.length > 0 && (
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <SectionTitle icon={AwardIcon} title={intl.formatMessage({ id: 'certifications' })} />
                {profileData.certification.map((cert, index) => (
                  <Box key={index} sx={{ mb: 3 }}>
                    <Stack direction="row" spacing={2}>
                      <Avatar sx={{ bgcolor: 'success.light', width: 48, height: 48 }}>
                        <CheckCircleIcon />
                      </Avatar>
                      <Box>
                        <Typography variant="subtitle1" fontWeight="medium">
                          {cert.cert_name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {cert.certificationInstitution}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {moment(cert.startDate).format('MMM YYYY')} - {moment(cert.endDate).format('MMM YYYY')}
                        </Typography>
                        {cert.certUrl && (
                          <Link href={cert.certUrl} target="_blank" rel="noopener">
                            View Certificate
                          </Link>
                        )}
                      </Box>
                    </Stack>
                  </Box>
                ))}
              </CardContent>
            </Card>
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          {/* Skills Section */}
          <Card sx={{ mb: 3 }}>
            <CardContent>
              <SectionTitle icon={CodeIcon} title={intl.formatMessage({ id: 'skillsAndExpertise' })} />
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {[profileData.primaryskill, profileData.secondaryskill].filter(Boolean).map((skill, index) => (
                  <Chip
                    key={index}
                    label={skill}
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                ))}
                {profileData.techStack && (
                  <Chip
                    label={profileData.techStack}
                    color="secondary"
                    variant="outlined"
                    size="small"
                  />
                )}
              </Stack>
            </CardContent>
          </Card>

          {/* Pod Experience */}
          {profileData.podExperience?.length > 0 && (
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <SectionTitle icon={BusinessIcon} title={intl.formatMessage({ id: 'podExperience' })} />
                <Stack direction="row" flexWrap="wrap" gap={1}>
                  {profileData.podExperience.map((pod, index) => (
                    <Chip
                      key={index}
                      label={pod.podName}
                      variant="outlined"
                      size="small"
                    />
                  ))}
                </Stack>
              </CardContent>
            </Card>
          )}

          {/* Additional Information */}
          <Card>
            <CardContent>
              <SectionTitle icon={DescriptionIcon} title={intl.formatMessage({ id: 'otherInformation' })} />
              <Stack spacing={2}>
                {profileData.workhours && (
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      {intl.formatMessage({ id: 'preferredWorkHours' })}
                    </Typography>
                    <Typography>{profileData.workhours}</Typography>
                  </Box>
                )}
                {profileData.currency && (
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      {intl.formatMessage({ id: 'currency' })}
                    </Typography>
                    <Typography>{profileData.currency}</Typography>
                  </Box>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProfilePage;