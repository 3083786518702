import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  MenuItem,
  Select,
  Pagination,
  IconButton,
  Checkbox,
  FormControl,
  InputLabel,
  OutlinedInput,
  Stack,
  Chip,
  Grid,
  Drawer,
  Dialog,
  DialogTitle,
  DialogContent,
  Menu,
  ListItemIcon,
  ListItemText,
  TextField,
  Snackbar,
  CircularProgress,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import FilterListIcon from "@mui/icons-material/FilterList";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TableRowsIcon from "@mui/icons-material/TableRows";
import DescriptionIcon from "@mui/icons-material/Description";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
import { useAuthToken } from "../TokenContext";
import { useIntl } from "react-intl";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

// Styled components
const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

const ContentWrapper = styled(Box)(({ theme, collapsed }) => ({
  marginLeft: collapsed ? "60px" : "240px",
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    marginLeft: "1rem",
    padding: theme.spacing(1),
  },
}));
const LoaderWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '400px',
  gap: '16px'
});


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: "bold",
  fontSize: "0.875rem",
}));

// Editable Row Component
const EditableTableRow = ({ 
  activity, 
  userStories,
  onSave,
  selectedIds,
  handleCheckboxChange,
  handleViewAndTranscribe,
  token,
  user,
  intl 
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({
    jira_issue: activity.jira_issue || "",
    today_focus: activity.today_focus || "",
    accomplishments: activity.accomplishments || "",
    blockers: activity.blockers || "",
    loom_url: activity.loom_url || "",
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success"
  });

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      const poddata = [{
        pod_candidates: activity.firstName,
        today_focus: editedData.today_focus,
        accomplishments: editedData.accomplishments,
        loom_url: editedData.loom_url,
        blockers: editedData.blockers,
        id: activity.id,
        jira_issue: editedData.jira_issue
      }];

      const response = await fetch(
        process.env.REACT_APP_TALENTLEAD_PODACTIVITYUPDATELEAD,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            poddata,
            status: 'draft'
          }),
        }
      );

      if (!response.ok) {
        throw new Error('Update failed');
      }

      setSnackbar({
        open: true,
        message: "Successfully updated activity",
        severity: "success"
      });
      setIsEditing(false);
      if (onSave) onSave();
    } catch (error) {
      console.error("Error updating activity:", error);
      setSnackbar({
        open: true,
        message: "Failed to update activity",
        severity: "error"
      });
    }
  };

  const handleCancel = () => {
    setEditedData({
      jira_issue: activity.jira_issue || "",
      today_focus: activity.today_focus || "",
      accomplishments: activity.accomplishments || "",
      blockers: activity.blockers || "",
      loom_url: activity.loom_url || "",
    });
    setIsEditing(false);
  };

  const handleChange = (field) => (event) => {
    setEditedData({
      ...editedData,
      [field]: event.target.value
    });
  };
  const handleVideoUrlChange = (event) => {
    setEditedData({
      ...editedData,
      loom_url: event.target.value
    });
  };
  return (
    <>
      <TableRow
        sx={{
          '&:hover': { backgroundColor: 'action.hover' },
          backgroundColor: isEditing ? 'action.selected' : 
            activity.posted_to_client === 1 ? 'action.selected' : 'inherit'
        }}
      >
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={selectedIds.includes(activity.id)}
            onChange={(e) => handleCheckboxChange(e, activity.id)}
            disabled={activity.posted_to_client === 1 || isEditing}
          />
        </TableCell>
        <TableCell>{activity.firstName}</TableCell>
        <TableCell>
          {isEditing ? (
            <Select
              fullWidth
              value={editedData.jira_issue}
              onChange={handleChange('jira_issue')}
              size="small"
            >
              {userStories.map((story) => (
                <MenuItem key={story.issue_key} value={story.issue_key}>
                  {story.issue_key} - {story.issue_summary}
                </MenuItem>
              ))}
            </Select>
          ) : (
            activity.jira_issue
          )}
        </TableCell>
        <TableCell>
          {isEditing ? (
            <TextField
              fullWidth
              multiline
              rows={2}
              value={editedData.today_focus}
              onChange={handleChange('today_focus')}
              size="small"
            />
          ) : (
            activity.today_focus
          )}
        </TableCell>
        <TableCell>
          {isEditing ? (
            <TextField
              fullWidth
              multiline
              rows={2}
              value={editedData.accomplishments}
              onChange={handleChange('accomplishments')}
              size="small"
            />
          ) : (
            activity.accomplishments
          )}
        </TableCell>
        <TableCell>
          {isEditing ? (
            <TextField
              fullWidth
              multiline
              rows={2}
              value={editedData.blockers}
              onChange={handleChange('blockers')}
              size="small"
            />
          ) : (
            activity.blockers
          )}
        </TableCell>
        <TableCell>
        {isEditing ? (
          <TextField
            fullWidth
            size="small"
            value={editedData.loom_url || ""}
            onChange={handleVideoUrlChange}
            placeholder="Enter video URL"
            sx={{ minWidth: '200px' }}
          />
        ) : (
          activity.loom_url ? (
            <Button
              variant="text"
              size="small"
              onClick={() => handleViewAndTranscribe(activity.loom_url, activity.id)}
              startIcon={<VideoCallIcon />}
            >
              View Video
            </Button>
          ) : (
            "No video"
          )
        )}
      </TableCell>
        <TableCell>
          {moment(activity.createdAt).format('MM-DD-YYYY')}
        </TableCell>
        <TableCell>
          <Chip
            label={activity.posted_to_client === 1 
              ? intl.formatMessage({ id: "approved" })
              : intl.formatMessage({ id: "pending" })
            }
            color={activity.posted_to_client === 1 ? "success" : "warning"}
            size="small"
          />
        </TableCell>
        <TableCell>
          <Stack direction="row" spacing={1}>
            {/* {activity.loom_url && (
              <IconButton
                color="primary"
                size="small"
                onClick={() => handleViewAndTranscribe(activity.loom_url, activity.id)}
              >
                <VideoCallIcon />
              </IconButton>
            )} */}
            {!activity.posted_to_client && !isEditing && (
              <IconButton
                color="primary"
                size="small"
                onClick={handleEdit}
              >
                <EditIcon />
              </IconButton>
            )}
            {isEditing && (
              <>
                <IconButton
                  color="success"
                  size="small"
                  onClick={handleSave}
                >
                  <SaveIcon />
                </IconButton>
                <IconButton
                  color="error"
                  size="small"
                  onClick={handleCancel}
                >
                  <CancelIcon />
                </IconButton>
              </>
            )}
          </Stack>
        </TableCell>
      </TableRow>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

function PodActivity({ collapsed }) {
  // State declarations
  const [selectedPodId, setSelectedPodId] = useState([]);
  const [editableData, setEditableData] = useState([]);
  const [podDetails, setPodDetails] = useState([]);
  const [podActivities, setPodActivities] = useState([]);
  const [selectedPodDetails, setSelectedPodDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const token = useAuthToken();
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [selectedTeamMember, setSelectedTeamMember] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [downloadMenuAnchor, setDownloadMenuAnchor] = useState(null);
  const [userStories, setUserStories] = useState([]);
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  
  const [dateRange, setDateRange] = useState([
    {
      startDate: moment().subtract(7, "days").toDate(),
      endDate: moment().toDate(),
      key: "selection",
    },
  ]);

  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });

  // Statistics calculations
  const stats = [
    {
      title: intl.formatMessage({ id: "totalActivities" }),
      value: filteredActivities.length,
      color: "#1976d2",
    },
    {
      title: intl.formatMessage({ id: "pendingApproval" }),
      value: filteredActivities.filter(a => !a.posted_to_client).length,
      color: "#ed6c02",
    },
    {
      title: intl.formatMessage({ id: "approved" }),
      value: filteredActivities.filter(a => a.posted_to_client === 1).length,
      color: "#2e7d32",
    },
  ];

  // Fetch user stories
  useEffect(() => {
    const fetchUserStories = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_CLIENT_JIRAISSUESLISTTALENTS,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              c_id: user.data[0].c_id,
            }),
          }
        );
        
        const data = await response.json();
        if (data.statusCode === 200 && data.body) {
          const parsedBody = JSON.parse(data.body);
          if (Array.isArray(parsedBody.jiraIssues)) {
            setUserStories(parsedBody.jiraIssues);
          }
        }
      } catch (error) {
        console.error('Error fetching user stories:', error);
      }
    };

    fetchUserStories();
  }, [user.data]);

  // Fetch pod activities
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        process.env.REACT_APP_CLIENT_GETCLIENTPOD,
        {
          method: "POST",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            clientID: user.data[0].emp_id,
          }),
        }
      );

      const data = await response.json();
      setPodDetails(data.data.podDetails);
      const activities = data.data.activities;
      const sortedActivities = activities.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setPodActivities(sortedActivities);
      setFilteredActivities(sortedActivities);
    } catch (error) {
      console.error("Error fetching data:", error);
    }finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token, user.data]);

  const isActivityInRange = (activity, range) => {
    if (!activity.createdAt || !range.startDate || !range.endDate) {
      return false;
    }

    const activityDate = new Date(activity.createdAt);
    const startDate = new Date(range.startDate);
    const endDate = new Date(range.endDate);

    activityDate.setHours(0, 0, 0, 0);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    return activityDate >= startDate && activityDate <= endDate;
  };

  const handlePodSelect = (event) => {
    const selectedValues = event.target.value;
    setSelectedPodId(selectedValues);
    
    let filteredResult = podActivities;
    if (selectedValues.length > 0) {
      filteredResult = podActivities.filter((activity) =>
        selectedValues.some(podId => podId.toString() === activity.pod_id.toString())
      );
    }

    const lastSelectedPod = podDetails.find(
      (pod) => pod.id === selectedValues[selectedValues.length - 1]
    );
    setSelectedPodDetails(lastSelectedPod || null);
    setFilteredActivities(filteredResult);
    setCurrentPage(1);
  };

  const handleDateRangeChange = (ranges) => {
    setDateRange([ranges.selection]);
    const activities = podActivities.filter((activity) => {
      const isInDateRange = isActivityInRange(activity, ranges.selection);
      const matchesMember =
        !selectedTeamMember || activity.firstName === selectedTeamMember;
      return isInDateRange && matchesMember;
    });
    setFilteredActivities(activities);
  };

  const handleTeamMemberSelect = (event) => {
    const selectedMember = event.target.value;
    setSelectedTeamMember(selectedMember);
    
    const activities = selectedMember
      ? podActivities.filter((activity) => activity.firstName === selectedMember)
      : podActivities;
    
    setFilteredActivities(activities);
    setCurrentPage(1);
  };

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelectedIds([...selectedIds, id]);
    } else {
      setSelectedIds(selectedIds.filter(selectedId => selectedId !== id));
    }
  };

  const handleApprove = async () => {
    if (selectedIds.length === 0) {
      alert(intl.formatMessage({ id: "selectItemsWarning" }));
      return;
    }

    try {
      const response = await fetch(process.env.REACT_APP_CLIENT_PODACTIVITYPOSTLEAD, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          poddata: [{ id: selectedIds, manager_id: user.data[0].emp_id }],
          manager_id: user.data[0].emp_id,
        }),
      });

      if (response.ok) {
        alert(intl.formatMessage({ id: "approvalSuccess" }));
        fetchData();
        setSelectedIds([]);
      } else {
        throw new Error('Approval failed');
      }
    } catch (error) {
      console.error("Error:", error);
      alert(intl.formatMessage({ id: "approvalError" }));
    }
  };

  const handleViewAndTranscribe = async (loomUrl, id) => {
    try {
      const [filename] = loomUrl.split('?')[0].split('/').slice(-1);
      const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONTs3;
      const fullUrl = cloudFrontUrl + filename;

      const response = await axios.post(
        process.env.REACT_APP_TALENT_CLOUDFRONTAPI,
        { "url": fullUrl }
      );

      const responseBody = JSON.parse(response.data.body);
      const signedUrl = responseBody.signedUrl;

      if (!signedUrl) {
        console.error('Signed URL not found:', responseBody);
        alert('Error transcribing video. Signed URL not found.');
        return;
      }

      const updatedData = editableData.map((item) =>
        item.id === id ? { ...item, loom_url: signedUrl } : item
      );
      setEditableData(updatedData);
      window.open(signedUrl, '_blank');
    } catch (error) {
      console.error('Error transcribing video:', error);
      alert('Error transcribing video. Please try again later.');
    }
  };

  const handleDownload = async (format) => {
    if (format === "csv") {
      let csvContent =
        "data:text/csv;charset=utf-8," +
        "First Name,Categories,Jira Issue,Today's Focus,Accomplishments,Blocker,Video URL,Posted Date,Status\n";

      filteredActivities.forEach((activity) => {
        const row = `${activity.firstName},"${activity.categories}","${
          activity.jira_issue
        }","${activity.today_focus || ""}","${activity.accomplishments || ""}","${
          activity.blockers || ""
        }","${moment(activity.createdAt).format("MM-DD-YYYY")}","${
          activity.loom_url || ""
        }","${activity.posted_to_client === 1 ? "Approved" : "Pending"}"\n`;
        csvContent += row;
      });

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "pod_activities.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (format === "pdf") {
      if (filteredActivities.length > 0) {
        const doc = new jsPDF('landscape');
        const headers = [
          "First Name", "Categories", "Jira Issue", "Today's Focus",
          "Accomplishments", "Blocker","Video URL", "Posted Date",  "Status"
        ];
        
        const rows = filteredActivities.map((activity) => [
          activity.firstName,
          activity.categories,
          activity.jira_issue,
          activity.today_focus || "",
          activity.accomplishments || "",
          activity.blockers || "",
          moment(activity.createdAt).format("MM-DD-YYYY"),
          activity.loom_url || "",
          activity.posted_to_client === 1 ? "Approved" : "Pending",
        ]);

        doc.autoTable({
          head: [headers],
          body: rows,
          styles: { fontSize: 8, cellPadding: 3 },
          headStyles: { fillColor: [0, 180, 216] },
          margin: { top: 10 },
        });

        doc.save("pod_activities.pdf");
      }
    }
    setDownloadMenuAnchor(null);
  };

  const rowsPerPage = 10;
  const totalPages = Math.ceil(filteredActivities.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedActivities = filteredActivities.slice(startIndex, endIndex);
// Add this state at the top with other state declarations
const [statusFilter, setStatusFilter] = useState("all");

// Add this handler function with other handlers
const handleStatusFilterChange = (event) => {
  const status = event.target.value;
  setStatusFilter(status);

  let filteredResults = [...podActivities];

  // Apply selected pod filter if any
  if (selectedPodId.length > 0) {
    filteredResults = filteredResults.filter((activity) =>
      selectedPodId.some(podId => podId.toString() === activity.pod_id.toString())
    );
  }

  // Apply team member filter if any
  if (selectedTeamMember) {
    filteredResults = filteredResults.filter(
      (activity) => activity.firstName === selectedTeamMember
    );
  }

  // Apply status filter
  if (status !== "all") {
    filteredResults = filteredResults.filter((activity) => {
      if (status === "approved") {
        return activity.posted_to_client === 1;
      } else if (status === "pending") {
        return activity.posted_to_client === 0;
      }
      return true;
    });
  }

  setFilteredActivities(filteredResults);
  setCurrentPage(1);
};


  return (
    <ContentWrapper collapsed={collapsed}>
      {/* Header Section */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {intl.formatMessage({ id: "podActivity" })}
        </Typography>
        {isLoading ? (
          <LoaderWrapper>
            <CircularProgress size={60} />
            <Typography variant="h6" color="textSecondary">
              {intl.formatMessage({ id: "loadingData" })}
            </Typography>
          </LoaderWrapper>
        ) : (
          <>
        {/* Stats Cards */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
          {stats.map((stat, index) => (
            <Grid item xs={12} md={4} key={index}>
              <StyledCard>
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    {stat.title}
                  </Typography>
                  <Typography variant="h4" sx={{ color: stat.color }}>
                    {stat.value}
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>

        {/* Controls */}
        <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>{intl.formatMessage({ id: "selectPods" })}</InputLabel>
            <Select
              multiple
              value={selectedPodId}
              onChange={handlePodSelect}
              input={<OutlinedInput label={intl.formatMessage({ id: "selectPods" })} />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return intl.formatMessage({ id: "allPods" });
                }
                return podDetails
                  .filter((pod) => selected.includes(pod.id))
                  .map((pod) => pod.podName)
                  .join(", ");
              }}
            >
              {podDetails.map((pod) => (
                <MenuItem key={pod.id} value={pod.id}>
                  <Checkbox checked={selectedPodId.indexOf(pod.id) > -1} />
                  <ListItemText primary={pod.podName} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            startIcon={<FilterListIcon />}
            onClick={() => setShowFilters(!showFilters)}
          >
            {intl.formatMessage({ id: "filters" })}
          </Button>

          <Button
            variant="contained"
            startIcon={<DateRangeIcon />}
            onClick={() => setShowDateRangePicker(!showDateRangePicker)}
          >
            {intl.formatMessage({ id: "dateRange" })}
          </Button>

          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={(e) => setDownloadMenuAnchor(e.currentTarget)}
          >
            {intl.formatMessage({ id: "export" })}
          </Button>
        </Stack>

        {/* Dialogs and Menus */}
        <Menu
          anchorEl={downloadMenuAnchor}
          open={Boolean(downloadMenuAnchor)}
          onClose={() => setDownloadMenuAnchor(null)}
        >
          <MenuItem onClick={() => handleDownload("csv")}>
            <ListItemIcon>
              <TableRowsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>CSV</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleDownload("pdf")}>
            <ListItemIcon>
              <PictureAsPdfIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>PDF</ListItemText>
          </MenuItem>
        </Menu>

        <Dialog
          open={showDateRangePicker}
          onClose={() => setShowDateRangePicker(false)}
          maxWidth="md"
        >
          <DialogTitle>{intl.formatMessage({ id: "selectDateRange" })}</DialogTitle>
          <DialogContent>
            <DateRangePicker
              onChange={handleDateRangeChange}
             
              ranges={dateRange}
              months={2}
              direction="horizontal"
            />
          </DialogContent>
        </Dialog>

<Drawer
  anchor="right"
  open={showFilters}
  onClose={() => setShowFilters(false)}
>
  <Box sx={{ width: 300, p: 3 }}>
    <Typography variant="h6" gutterBottom>
      {intl.formatMessage({ id: "filters" })}
    </Typography>
    <Stack spacing={3}>
      <FormControl fullWidth>
        <InputLabel>{intl.formatMessage({ id: "teamMember" })}</InputLabel>
        <Select
          value={selectedTeamMember}
          onChange={handleTeamMemberSelect}
          input={<OutlinedInput label={intl.formatMessage({ id: "teamMember" })} />}
        >
          <MenuItem value="">
            {intl.formatMessage({ id: "allMembers" })}
          </MenuItem>
          {[...new Set(podActivities.map((activity) => activity.firstName))].map(
            (member) => (
              <MenuItem key={member} value={member}>
                {member}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel>{intl.formatMessage({ id: "status" })}</InputLabel>
        <Select
          value={statusFilter}
          onChange={handleStatusFilterChange}
          input={<OutlinedInput label={intl.formatMessage({ id: "status" })} />}
        >
          <MenuItem value="all">
            {intl.formatMessage({ id: "allStatus" })}
          </MenuItem>
          <MenuItem value="pending">
            {intl.formatMessage({ id: "pending" })}
          </MenuItem>
          <MenuItem value="approved">
            {intl.formatMessage({ id: "approved" })}
          </MenuItem>
        </Select>
      </FormControl>

      {/* Optional: Add clear filters button */}
      <Button
        variant="outlined"
        onClick={() => {
          setStatusFilter("all");
          setSelectedTeamMember("");
          setFilteredActivities(podActivities);
          setCurrentPage(1);
        }}
        fullWidth
      >
        {intl.formatMessage({ id: "clearFilters" })}
      </Button>
    </Stack>
  </Box>
</Drawer>

        {/* Main Table */}
        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    onChange={(e) => {
                      if (e.target.checked) {
                        const allIds = paginatedActivities
                          .filter((activity) => activity.posted_to_client !== 1)
                          .map((activity) => activity.id);
                        setSelectedIds(allIds);
                      } else {
                        setSelectedIds([]);
                      }
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>{intl.formatMessage({ id: "teamMember" })}</StyledTableCell>
                <StyledTableCell>{intl.formatMessage({ id: "jiraIssue" })}</StyledTableCell>
                <StyledTableCell>{intl.formatMessage({ id: "todaysFocus" })}</StyledTableCell>
                <StyledTableCell>{intl.formatMessage({ id: "accomplishments" })}</StyledTableCell>
                <StyledTableCell>{intl.formatMessage({ id: "blocker" })}</StyledTableCell>
                <StyledTableCell>{intl.formatMessage({ id: "videourl" })}</StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {intl.formatMessage({ id: "postedDate" })}
                    <IconButton
                      size="small"
                      onClick={() => {
                        const newDirection = sortConfig.direction === 'asc' ? 'desc' : 'asc';
                        setSortConfig({ key: 'createdAt', direction: newDirection });
                        const sorted = [...filteredActivities].sort((a, b) => {
                          return newDirection === 'asc'
                            ? new Date(a.createdAt) - new Date(b.createdAt)
                            : new Date(b.createdAt) - new Date(a.createdAt);
                        });
                        setFilteredActivities(sorted);
                      }}
                    >
                      {sortConfig.direction === 'asc' ? (
                        <ArrowUpwardIcon fontSize="small" />
                      ) : (
                        <ArrowDownwardIcon fontSize="small" />
                      )}
                    </IconButton>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>{intl.formatMessage({ id: "status" })}</StyledTableCell>
                <StyledTableCell>{intl.formatMessage({ id: "actions" })}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedActivities.map((activity) => (
                <EditableTableRow
                  key={activity.id}
                  activity={activity}
                  userStories={userStories}
                  selectedIds={selectedIds}
                  handleCheckboxChange={handleCheckboxChange}
                  handleViewAndTranscribe={handleViewAndTranscribe}
                  onSave={() => {
                    fetchData();
                  }}
                  token={token}
                  user={user}
                  intl={intl}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination and Approve Button */}
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(e, page) => setCurrentPage(page)}
            color="primary"
            shape="rounded"
          />
          <Button
            variant="contained"
            color="success"
            onClick={handleApprove}
            disabled={selectedIds.length === 0}
          >
            {intl.formatMessage({ id: "approve" })} ({selectedIds.length})
          </Button>
        </Box>
        </>
        )}
      </Box>
    </ContentWrapper>
  );
}

export default PodActivity;