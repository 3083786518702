
import React from 'react'
import { NavLink } from "react-router-dom";
import styled from 'styled-components';
import {darkThemeColor} from "../utils";
import './styles.css';
import {useSelector } from "react-redux";
import  {useState, useEffect} from 'react'
import reviews from './assets/reviews.png';
import briefcase from './assets/briefcase.png';
import group from './assets/group.png';
import sent from './assets/sent.png';
import home from './assets/home.png';
import pod from './assets/pod.png';
import chat1 from './assets/chat1.png';
import AWS from 'aws-sdk';
import defaultimg from './assets/defaultimge.png';
import {MdManageAccounts} from 'react-icons/md'
import {MdAssistant} from 'react-icons/md'
import {RiFindReplaceLine} from 'react-icons/ri'
import {FaTasks} from 'react-icons/fa'
import {VscGraph} from 'react-icons/vsc'
import {VscFeedback} from 'react-icons/vsc'
import {CgBoard} from 'react-icons/cg'
import { useIntl } from 'react-intl';
import PropTypes from "prop-types";
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useAuthToken } from "../TokenContext";

const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}

const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}

function Sidebar({expanded }) {
 
  const [toggleMenu, setToggleMenu] = useState(true);
  const [AuthImage, setAuthImage] = useState("");
 
 
  const token = useAuthToken();
  const [c1 ,setC1]=useState(false);
  const [c2 ,setC2]=useState(false);
  const [c3 ,setC3]=useState(false);
  const [c4 ,setC4]=useState(false);
  const [c5 ,setC5]=useState(false);
  const [c6,setC6]=useState(false);
  const [c7 ,setC7]=useState(false);
  const [c8 ,setC8]=useState(false);
  const [c9 ,setC9]=useState(false);
  const [c10 ,setC10]=useState(false);
  const [c11 ,setC11]=useState(false);
  const [c12 ,setC12]=useState(false);
  const { user } = useSelector(state => state.auth);
  const intl = useIntl();

  const myBucket = new AWS.S3(S3Config);

  const dashcolor = () => {
    setC1(true)
  setC2(false)
  setC3(false)
  setC4(false)
  setC5(false)
  setC6(false)
  setC7(false)
  setC8(false)
  setC9(false)
  setC10(false)
  setC11(false)
  setC12(false)
  }
  const projcolor = () => {
    setC1(false)
    setC2(true)
    setC3(false)
  setC4(false)
  setC5(false)
  setC6(false)
  setC7(false)
  setC8(false)
  setC9(false)
  setC10(false)
  setC11(false)
  setC12(false)
  }
  const ordercolor = () => {
    setC2(false)
    setC3(true)
    setC1(false)
    setC9(false)
  
    setC4(false)
    setC5(false)
    setC6(false)
    setC7(false)
    setC8(false)
    setC10(false)
    setC11(false)
    setC12(false)
  }
  const wrkstkcolor = () => {
    setC3(false)
    setC4(true)
    setC1(false)
    setC2(false)
  
    setC9(false)
    setC5(false)
    setC6(false)
    setC7(false)
    setC8(false)
    setC10(false)
    setC11(false)
    setC12(false)
  }
  const invcolor = () => {
    setC3(false)
    setC5(true)
    setC1(false)
    setC2(false)
    setC10(false)
    setC4(false)
    setC9(false)
    setC6(false)
    setC7(false)
    setC8(false)
    setC11(false)
    setC12(false)
  }
  const podcolor = () => {
    setC3(false)
    setC5(false)
    setC1(false)
    setC2(false)
    setC10(false)
    setC4(false)
  
    setC6(true)
    setC7(false)
    setC8(false)
    setC9(false)
    setC11(false)
    setC12(false)
  }
  const Msgcolor = () => {
    setC3(false)
    setC5(false)
    setC1(false)
    setC2(false)
    setC10(false)
    setC4(false)
  
    setC6(false)
    setC7(true)
    setC9(false)
    setC8(false)
    setC11(false)
    setC12(false)

  }
  const reviewcolor = () => {
    setC3(false)
    setC5(false)
    setC1(false)
    setC2(false)
    setC10(false)
    setC4(false)
  
    setC6(false)
    setC7(false)
    setC8(true)
    setC9(false)
    setC11(false)
    setC12(false)
  }
  const podcolorlead = () => {
    setC3(false)
    setC5(false)
    setC1(false)
    setC2(false)
    setC10(false)
    setC4(false)
  
    setC6(false)
    setC7(false)
    setC8(false)
    setC9(true)
    setC11(false)
    setC12(false)
  }

  const createpodcolorlead = () => {
    setC3(false)
    setC5(false)
    setC1(false)
    setC2(false)
    setC10(false)
    setC4(false)
  
    setC6(false)
    setC7(false)
    setC8(false)
    setC9(false)
    setC10(true)
    setC11(false)
    setC12(false)
  }
  const assist = () => {
    setC3(false)
    setC5(false)
    setC1(false)
    setC2(false)
  
    setC4(false)
  
    setC6(false)
    setC7(false)
    setC8(false)
    setC9(false)
    setC10(false)
    setC11(true)
  
    setC12(false)
  }
  const board = () => {
    setC3(false)
    setC5(false)
    setC1(false)
    setC2(false)
  
    setC4(false)
  
    setC6(false)
    setC7(false)
    setC8(false)
    setC9(false)
    setC10(false)
    setC11(false)
  
    setC12(true)
  }
  // function generatePreSignedPutUrl11() {
   
  //   const URL_EXPIRATION_TIME = 900;
  
  //                 const params = {
  //                   Bucket: process.env.REACT_APP_BUCKETNAME,
  //                   Key: user.data[0].profilePicture,
  //                   Expires: URL_EXPIRATION_TIME,
  //                 }
                 
  //              myBucket.getSignedUrl('getObject', params, (err, url) => {
  
  //               setAuthImage(url);
  
  //             });
  // }
  
  async function generatePreSignedPutUrl11() {
    try {
        // Define the request parameters
        const requestOptions = {
            method: 'POST',
            headers: {
              'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                url: process.env.REACT_APP_CLOUDFRONT +user.data[0].profilePicture
            })
        };
  
        // Make the API call
        const response = await fetch(process.env.REACT_APP_TALENT_CLOUDFRONTAPI, requestOptions);
        
        // Parse the response as JSON
        const data = await response.json();
  
        // Extract the signed URL from the response data
        const signedUrl = JSON.parse(data.body).signedUrl;
  
        
        // Use the signed URL as needed
        setAuthImage(signedUrl);
    } catch (error) {
        console.error("Error generating signed URL:", error);
    }
  }

  useEffect(() => {

     generatePreSignedPutUrl11();

  }, [token])
  
  return(
    <>
    
    
   
 
{toggleMenu && 
<Container   expanded= {expanded}>


       
        <ProfileContainer>
        <Row>

        {user.data[0].profilePicture == "" || user.data[0].profilePicture == null ?
         (
                <img
  src={defaultimg}
  alt=""
  style={{width:'50px',height:'50px',marginTop:"50px", borderRadius : "50px" }}
></img>
)


  :(
    <img src={AuthImage} alt='' style={{width:'50px',height:'50px',marginTop:"50px", borderRadius : "50px" }}></img>
) }
     
       
      
  <Detail>  

  <div style={{maxWidth:'150px',minWidth:'140px', height:'100px' ,marginTop:"25px" }} >
 
  {user.data[0].type === "Candidate"  ? (
    
  <>
   {expanded && (  <>
    <Name>{user.data[0].firstName}</Name>
    <p style={{ fontSize: '13px', fontFamily: 'Poppins, Medium', height: '10px', marginLeft: "1rem" }}>
      {user.data[0].work_position}
    </p>
    <Place>{user.data[0].country}</Place></>)}
    {/* Other sidebar content for Candidate */}
  </>

  ) : user.data[0].type == "Client" && user.data[0].emp_firstname ?(
<>
{expanded && (  <>
<Name>{user.data[0].emp_firstname}</Name>
<br></br>

<Place>{user.data[0].emp_country}</Place></>)}
{/* <Ratingicon><img src={star} style={{width:"11.49px",height:'11px'}}></img><text style={{width:'18px' ,height:'17px', fontSize:'12px',fontFamily:'Poppins, Medium'}}>4.5</text></Ratingicon> */}

</>

  ): user.data[0].type == "stakeholder" && user.data[0].stakeholder_firstname ?(
    <>
     {expanded && (  <>
    <Name>{user.data[0].stakeholder_firstname}</Name>
    <br></br>
    
    <Place>{user.data[0].stakeholder_country}</Place></>)}
    
    </>
    
      )
  :(<><Name>No Name</Name>
  </>)} 

  
</div>
</Detail>

</Row>

        
        </ProfileContainer>
        <LinksContainer expanded= {expanded} >

        
        <Links expanded= {expanded}>
     
        { user.data[0].type == "Candidate" ? (

<NavLink to='/candidate-dashboard' style={{ textDecoration: 'none' }} >
<Link1>
  <img onClick={dashcolor} title="Dashboard" src={home} alt='' style={{height:'25px',marginTop:"4px",color:c1?"white":'#d9d9d9',width:'25px'  ,opacity:c1?"100%":'60%'}}></img>
  {expanded && ( <>
  <h3  onClick={dashcolor} style={{ color:c1?"white":'#d9d9d9' ,fontWeight:'600' }}>{intl.formatMessage({ id: 'dashboard' })}</h3></> )}
 </Link1>
</NavLink>
) : user.data[0].type == "Client" ? (

  <NavLink to='/client-dashboard' style={{ textDecoration: 'none' }} >
 <Link1>
  <img onClick={dashcolor} src={home} title="Dashboard"  alt='' style={{height:'25px',marginTop:"4px", color:c1?"white":'#d9d9d9',width:'25px'  ,opacity:c1?"100%":'60%'}}></img>
  {expanded && (  <h3 onClick={dashcolor} style={{ color:c1?"white":'#d9d9d9' ,fontWeight:'600' }}>{intl.formatMessage({ id: 'dashboard' })}</h3>)}
 </Link1>
</NavLink>
) :user.data[0].type == "Provider" ? (

  <NavLink to='/provider-dashboard' style={{ textDecoration: 'none' }} >
 <Link1>
  <img src={home} alt='' onClick={dashcolor} title="Dashboard" style={{height:'25px',color:c1?"white":'#d9d9d9',marginTop:"4px",width:'25px'  ,opacity:c1?"100%":'60%'}}></img>
  {/* <h3 onClick={dashcolor} style={{ color:c1?"white":'#d9d9d9' ,fontWeight:'600' }}>Dashboard</h3> */}
 </Link1>
</NavLink>


): user.data[0].type == "stakeholder" ? (

  <NavLink to='/StakeholderDashboard' style={{ textDecoration: 'none' }} >
 <Link1>
  <img src={home} alt='' onClick={dashcolor} title="Dashboard" style={{height:'25px',color:c1?"white":'#d9d9d9',marginTop:"4px",width:'25px'  ,opacity:c1?"100%":'60%'}}></img>
  {expanded && ( <h3 onClick={dashcolor} style={{ color:c1?"white":'#d9d9d9' ,fontWeight:'600' }}>{intl.formatMessage({ id: 'dashboard' })}</h3> )}
 </Link1>
</NavLink>


):(<></>)}

{/* { user.data[0].type == "Candidate" ? (
  <Link >
<img src={setting}   style={{height:'25px',width:'25px',marginTop:"4px" , opacity:c2?"100%":'60%' }}></img>
   
        <h3 onClick={projcolor} style={{ color:c2?"white":'#d9d9d9' ,fontWeight:'600' }}  >Projects</h3>
        </Link>
) : (<></>)} */}


{ user.data[0].type == "Client" ? (

<a href='/FindPodsClient' style={{ textDecoration: 'none' }} >
<Link>
<RiFindReplaceLine onClick={projcolor} src={pod} title='Find Pods' alt='' style={{height:'25px',marginTop:"4px",width:'25px',color:c2?"white":'#d9d9d9' , opacity:c2?"100%":'60%'}}/>
{expanded && (  <h3 onClick={projcolor} style={{ color:c2?"white":'#d9d9d9' ,fontWeight:'600' }}>{intl.formatMessage({ id: 'findPods' })}</h3>)}
</Link>
</a>
) : (<></>)}

{ user.data[0].type == "Client" || user.data[0].type == 'Candidate' ? (

<a href='/OpenAi' style={{ textDecoration: 'none' }} >
<Link>
<MdAssistant  onClick={assist} alt='' title='Pod Assist' style={{height:'30px',marginTop:"4px",color:c11?"white":'#d9d9d9' ,width:'30px' , opacity:c11?"100%":'60%'}}/>
{expanded && (  <h3 onClick={assist} style={{ color:c11?"white":'#d9d9d9' ,fontWeight:'600' }}>{intl.formatMessage({ id: 'podAssist' })}</h3>)}
</Link>
</a>
) : (<></>)}
{ user.data[0].type == "Client" ? (

<a href='/PodInsights' style={{ textDecoration: 'none' }} >
<Link>
<AiOutlineInfoCircle onClick={createpodcolorlead} src={pod} title='Pod Insights' alt='' style={{height:'25px',marginTop:"4px",width:'25px',color:c10?"white":'#d9d9d9' , opacity:c10?"100%":'60%'}}/>
{expanded && (  <h3 onClick={createpodcolorlead} style={{ color:c10?"white":'#d9d9d9' ,fontWeight:'600' }}>Pod Insights</h3>)}
</Link>
</a>
) : (<></>)}
{ user.data[0].type == "Provider" ? (

<a href='/Podproviderpodactivity' style={{ textDecoration: 'none' }} >
<Link>
<VscFeedback src={pod} onClick={projcolor}title='POD Activity'  alt='' style={{height:'30px',color:c2?"white":'#d9d9d9' ,marginTop:"4px",width:'30px' , opacity:c2?"100%":'60%'}}/>
{/* <h3 onClick={projcolor} style={{ color:c2?"white":'#d9d9d9' ,fontWeight:'600' }}>POD Activity</h3> */}
</Link>
</a>
) : (<></>)}

{ user.data[0].type == "Client" ? (
  <a href='/client-createpod' style={{ textDecoration: 'none' }} >
<Link>
        <img src={sent} title='Create Pod' onClick={ordercolor}  style={{height:'25px',color:c3?"white":'#d9d9d9',width:'25px',marginTop:"4px" ,opacity:c3?"100%":'60%'} }></img>
      
        {expanded && (   <h3 onClick={ordercolor} style={{ color:c3?"white":'#d9d9d9' ,fontWeight:'600' }}>{intl.formatMessage({ id: 'createPod' })}</h3>)}
        </Link>
        </a>
) : (<></>)}

{ user.data[0].type == "Client" ? (
  <a href='/KanbanBoard' style={{ textDecoration: 'none' }} >
<Link>
       <div><CgBoard  title='Board' onClick={board}  style={{height:'30px',color:c12?"white":'#d9d9d9',width:'30px',marginTop:"4px" ,opacity:c12?"100%":'60%'} }/>
       </div> 
       {expanded && (   <h3 onClick={board} style={{ color:c12?"white":'#d9d9d9' ,fontWeight:'600' }}>{intl.formatMessage({ id: 'board' })}</h3>)}
        </Link>
        </a>
) : (<></>)}

{ user.data[0].type == "Provider" ? (

<Link>
        <img src={sent} onClick={ordercolor} title='Timesheets'  style={{height:'25px',color:c3?"white":'#d9d9d9' ,width:'25px',marginTop:"4px" ,opacity:c3?"100%":'60%'} }></img>
      
        {/* <h3 onClick={ordercolor} style={{ color:c3?"white":'#d9d9d9' ,fontWeight:'600' }}>Timesheets</h3> */}
        </Link>

) : (<></>)}

{ user.data[0].type == "Candidate" ? (
  <a href='/FindPods'  style={{ textDecoration: 'none' }}>

<Link>
        <RiFindReplaceLine src={sent} onClick={ordercolor} title='Find Pods'  style={{height:'30px',color:c3?"white":'#d9d9d9',width:'30px',marginTop:"4px" ,opacity:c3?"100%":'60%'} }/>
      
        {expanded && (   <h3 onClick={ordercolor} style={{ color:c3?"white":'#d9d9d9' ,fontWeight:'600' }}>{intl.formatMessage({ id: 'findPods' })}</h3>)}
        </Link>
        </a>
) : (<></>)}

        { user.data[0].type == "Candidate" ? (        
        <NavLink to='/Pods' style={{ textDecoration: 'none' }} >
        <Link>
        <img src={group} onClick= {podcolor} title='My Pods'  style={{height:'25px',color:c6?"white":'#d9d9d9',width:'25px',marginTop:"4px" ,opacity:c6?"100%":'60%'}}></img>
     
        {expanded && (   <h3    onClick= {podcolor}   style={{ color:c6?"white":'#d9d9d9' ,fontWeight:'600' }}>{intl.formatMessage({ id: 'myPods' })}</h3>)}
        </Link>
        </NavLink>
) : user.data[0].type == "Client" ? (        
        <NavLink to='/Team' style={{ textDecoration: 'none' }} >
        <Link>
        <img src={group} onClick= {podcolor} title='My Pods'  style={{color:c6?"white":'#d9d9d9',height:'25px',width:'30px',marginTop:"4px" ,opacity:c6?"100%":'60%'}}></img>
     
        {expanded && (   <h3    onClick= {podcolor}   style={{ color:c6?"white":'#d9d9d9' ,fontWeight:'600' }}>{intl.formatMessage({ id: 'myPods' })}</h3>)}
        </Link>
        </NavLink>
) : user.data[0].type == "Provider" ? (        
        <NavLink to='/Pods' style={{ textDecoration: 'none' }} >
        <Link>
        <img src={group} onClick= {podcolor} title='Pods'  style={{color:c6?"white":'#d9d9d9',height:'25px',width:'25px',marginTop:"4px" ,opacity:c6?"100%":'60%'}}></img>
     
        {/* <h3    onClick= {podcolor}   style={{ color:c6?"white":'#d9d9d9' ,fontWeight:'600' }}>Pods</h3> */}
        </Link>
        </NavLink>
) : (<></>)}
        { user.data[0].type == "Candidate" ? (
  <a href='/KanbanboardTalent' style={{ textDecoration: 'none' }} >
<Link>
       <div><CgBoard  title='Board' onClick={board}  style={{height:'30px',color:c12?"white":'#d9d9d9',width:'30px',marginTop:"4px" ,opacity:c12?"100%":'60%'} }/>
       </div> 
       {expanded && (   <h3 onClick={board} style={{ color:c12?"white":'#d9d9d9' ,fontWeight:'600' }}>{intl.formatMessage({ id: 'board' })}</h3>)}
        </Link>
        </a>
) : (<></>)}
       
        { user.data[0].type == "Client" ? (

<a href='/PodActivity' style={{ textDecoration: 'none' }} >
<Link>
        <VscFeedback src={briefcase} onClick={wrkstkcolor} title='POD Activity'  style={{color:c4?"white":'#d9d9d9',height:'30px',width:'30px',marginTop:"4px" ,opacity:c4?"100%":'60%'}}/>
      
        {expanded && (  <h3  onClick={wrkstkcolor}   style={{ color:c4?"white":'#d9d9d9' ,fontWeight:'600' }} >{intl.formatMessage({ id: 'podActivity' })}</h3> )}
        </Link>
</a>
) : (<></>)}


{ user.data[0].type == "Provider" ? (

<a href='/FindPodsClient' style={{ textDecoration: 'none' }} >
<Link>
        <RiFindReplaceLine src={briefcase} onClick={wrkstkcolor} title='Find Pods' style={{color:c4?"white":'#d9d9d9',height:'30px',width:'30px',marginTop:"4px" ,opacity:c4?"100%":'60%'}}/>
      
        {expanded && (  <h3  onClick={wrkstkcolor}   style={{ color:c4?"white":'#d9d9d9' ,fontWeight:'600' }} >{intl.formatMessage({ id: 'findPods' })}</h3>)}
        </Link>
</a>
) : (<></>)}
{ user.data[0].type == "stakeholder" ? (<>
 

<a href='/PodActivitystakeholderviewall' style={{ textDecoration: 'none' }} >
<Link>
        <VscFeedback src={chat1} onClick={projcolor} title='Pod Activity'  style={{color:c2?"white":'#d9d9d9',height:'30px',width:'30px',marginTop:"4px" ,opacity:c2?"100%":'60%'}}/>   
        {expanded && (<h3  onClick={projcolor}   style={{ color:c2?"white":'#d9d9d9' ,fontWeight:'600' }} >{intl.formatMessage({ id: 'podActivity' })}</h3> )}
        </Link>
</a>

{/* <a href='/PodMetricsmanager' style={{ textDecoration: 'none' }} >
        <Link>
        <img src={pod}   style={{height:'25px',width:'25px',marginTop:"4px" ,opacity:c7?"100%":'60%'}}></img>
       
        <h3  onClick={Msgcolor}  style={{ color:c7?"white":'#d9d9d9' ,fontWeight:'600' }} >Pod Metrics</h3>
        </Link>
    
        </a> */}
         
</>

) : (<></>)}
  { user.data[0].type == "Candidate" ? ( 
    <a href='/TalentViewTask' style={{ textDecoration: 'none' }} >

        <Link>
        <FaTasks src={briefcase} onClick={wrkstkcolor} title='My Tasks'  style={{color:c4?"white":'#d9d9d9',height:'30px',width:'30px',marginTop:"4px" ,opacity:c4?"100%":'60%'}}/>
      
        {expanded && (    <h3 onClick={wrkstkcolor} style={{ color:c4?"white":'#d9d9d9' ,fontWeight:'600' }} >{intl.formatMessage({ id: 'myTasks' })}</h3>)}
        </Link>
        </a>

        ) : (<></>)}

        { user.data[0].type == "Client" ? (

<a href='/ViewPodTasksManager' style={{ textDecoration: 'none' }} >
<Link >
        <FaTasks src={briefcase} onClick={invcolor} title='Pod Tasks'  style={{color:c5?"white":'#d9d9d9',height:'30px',width:'30px',marginTop:"4px" ,opacity:c5?"100%":'60%'}}/>
      
        {expanded && (  <h3  onClick={invcolor}   style={{ color:c5?"white":'#d9d9d9' ,fontWeight:'600' }} >{intl.formatMessage({ id: 'podTasks' })}</h3>)}
        </Link>
</a>
) : (<></>)}
  
{ user.data[0].type == "Provider" ? (

<a href='/' style={{ textDecoration: 'none' }} >
<Link>
        <img src={briefcase} title='Documents'  onClick={wrkstkcolor} style={{color:c4?"white":'#d9d9d9',height:'25px',width:'25px',marginTop:"4px" ,opacity:c4?"100%":'60%'}}></img>
      
        {/* <h3  onClick={wrkstkcolor}   style={{ color:c4?"white":'#d9d9d9' ,fontWeight:'600' }} >Documents</h3> */}
        </Link>
</a>
) : (<></>)}

{ user.data[0].type == "Client" || user.data[0].type == 'stakeholder'  ? (
<a href='/PodMetricsmanager' style={{ textDecoration: 'none' }} >
        <Link>
        <VscGraph src={chat1} onClick={Msgcolor} title='Pod Metrics' style={{color:c7?"white":'#d9d9d9',height:'30px',width:'30px',marginTop:"4px" ,opacity:c7?"100%":'60%'}}/>
       
        {expanded && (   <h3  onClick={Msgcolor}  style={{ color:c7?"white":'#d9d9d9' ,fontWeight:'600' }} >{intl.formatMessage({ id: 'podMetrics' })}</h3>)}
        </Link>
    
        </a>
         ) : (<></>)}

{ user.data[0].type == "Candidate" ? (
  <a href='/PodMetricsTalentDetailview' style={{ textDecoration: 'none' }} >

        <Link>
        <VscGraph src={chat1} onClick={Msgcolor} title='Pod Metrics'  style={{color:c7?"white":'#d9d9d9',height:'30px',width:'30px',marginTop:"4px" ,opacity:c7?"100%":'60%'}}/>
       
        {expanded && (  <h3  onClick={Msgcolor}  style={{ color:c7?"white":'#d9d9d9' ,fontWeight:'600' }} >{intl.formatMessage({ id: 'podMetrics' })}</h3>)}
       
        </Link>
    
        </a>
         ) : (<></>)}
        {/* <Link>
        <img src={invoices}   style={{height:'25px',width:'25px',marginTop:"4px" ,opacity:c5?"100%":'60%'}}></img>
     

        <h3  onClick={invcolor}   style={{ color:c5?"white":'#d9d9d9' ,fontWeight:'600' }}>Invoices</h3>
        </Link> */}


        
      
        { user.data[0].type == "Candidate" ? (
<>

<NavLink to='/Reviewsandratingcandidate' style={{ textDecoration: 'none' }} >
        <Link  >
        <img src={reviews}  onClick={reviewcolor} title='Pod Feedback' style={{color:c8?"white":'#d9d9d9',height:'25px',width:'25px',marginTop:"4px" ,opacity:c8?"100%":'60%'}}></img>
     
        {expanded && (   <h3   onClick={reviewcolor}  style={{  color:c8?"white":'#d9d9d9' ,fontWeight:'600' }}>   {intl.formatMessage({ id: 'podFeedback' })}</h3>)}
        </Link>
        </NavLink>
</>
):(<></>)}

{ user.data[0].type == "Client" ? (
<>
{/* <NavLink to='/ReviewsandratingClient' */}
<NavLink to='/Podretromanager' style={{ textDecoration: 'none' }} >
        <Link  >
        <img src={reviews}  onClick={reviewcolor} title='Pod Retro' style={{color:c8?"white":'#D9D9D9',height:'25px',width:'25px',marginTop:"4px" ,opacity:c8?"100%":'60%'}}></img>
        {expanded && (  <h3   onClick={reviewcolor}  style={{  color:c8?"white":'#D9D9D9' ,fontWeight:'600' }}>   {intl.formatMessage({ id: 'podRetro' })}</h3>)}
        </Link>
        </NavLink>
</>
):(<></>)}
{ user.data[0].type == "Provider" ? (
<>
<NavLink to='/ReviewsandratingClient' style={{ textDecoration: 'none' }} >
        <Link  >
        <img src={reviews}  onClick={reviewcolor} title='Pod Feedback' style={{color:c8?"white":'#D9D9D9',height:'25px',width:'25px',marginTop:"4px" ,opacity:c8?"100%":'60%'}}></img>
        {expanded && (  <h3   onClick={reviewcolor}  style={{  color:c8?"white":'#D9D9D9' ,fontWeight:'600' }}>   {intl.formatMessage({ id: 'podFeedback' })}</h3>)}
        </Link>
        </NavLink>
</>
):(<></>)}


{ 
user.data[0].type == "Candidate" && user.data[0].isPodLeader == "true" ? (
<>




<a href='/Leadpodrequests' style={{ textDecoration: 'none' }}>
<Link>
<img src={briefcase} onClick={podcolorlead} title='Pod Requests'  style={{color:c9?"white":'#D9D9D9',height:'25px',width:'25px',marginTop:"4px" ,opacity:c9?"100%":'60%'}}></img>
{/* <h3   onClick={podcolorlead}  style={{  color:c9?"white":'#D9D9D9' ,fontWeight:'600' }}>   Pod Requests</h3> */}
</Link>
</a>

</> ) : (
 
<></>
 

)}
{ 
user.data[0].type == "Candidate" && user.data[0].isPodLeader == "true" ? (
<>




<a href='/create-pod' style={{ textDecoration: 'none' }}>
<Link>
<img src={group}  title='Createpod'  onClick={createpodcolorlead} style={{color:c10?"white":'#D9D9D9',height:'25px',width:'25px',marginTop:"4px" ,opacity:c10?"100%":'60%'}}></img>
{expanded && (  <h3   onClick={createpodcolorlead}  style={{  color:c10?"white":'#D9D9D9' ,fontWeight:'600' }}>   {intl.formatMessage({ id: 'createPod' })}</h3>)}
</Link>
</a>

</> ) : (
 
<></>
 

)}




        { user.data[0].type == "Candidate" ? (
<>




<a href='/manage-profile' style={{ textDecoration: 'none' }}>
<Link>
<MdManageAccounts   title='Manage Pod' alt='' style={{height:'25px',marginTop:"4px",width:'25px',color:c2?"white":'#d9d9d9' , opacity:c2?"100%":'60%'}}/>

{expanded && ( <h3 style={{fontWeight:'600' }} >

  {intl.formatMessage({ id: 'manageProfile' })} </h3>)}
</Link>
</a>

</> ) :user.data[0].type == "Client" ? (
 
  <a href='/client-profile' style={{ textDecoration: 'none' }}>
  <Link>
  <MdManageAccounts   title='Manage Pod' alt='' style={{height:'25px',marginTop:"4px",width:'25px',color:c2?"white":'#d9d9d9' , opacity:c2?"100%":'60%'}}/>

   
  {expanded && ( <h3 style={{fontWeight:'600' }} >
    {intl.formatMessage({ id: 'manageProfile' })} </h3>)}
      
  </Link>
  </a>

):
user.data[0].type == "Provider" ?(
   <a href='/provider-profile' style={{ textDecoration: 'none' }}>
<Link>
<p style={{ backgroundColor: "white", padding: "13px" ,fontSize:"15px", color: "black",borderRadius:'5px',marginLeft:'10px',fontWeight:'bold',fontFamily:'Poppins,Medium'}} title='Manage Profile'><MdManageAccounts/></p>
</Link>
</a>):user.data[0].type == "stakeholder" ?( <a href='/stakeholder-profile' style={{ textDecoration: 'none' }}>
<Link>
<p style={{ backgroundColor: "white", padding: "13px" ,fontSize:"15px", color: "black",borderRadius:'5px',marginLeft:'10px',fontWeight:'bold',fontFamily:'Poppins,Medium'}} title='Manage Profile'><MdManageAccounts/></p>
</Link>
</a>):user.data[0].type == "ClientAdmin" ?( <a href='/costomer-profile' style={{ textDecoration: 'none' }}>
<Link>
<p style={{ backgroundColor: "white", padding: "13px" ,fontSize:"15px", color: "black",borderRadius:'5px',marginLeft:'10px',fontWeight:'bold',fontFamily:'Poppins,Medium'}} title='Manage Profile'><MdManageAccounts/></p>
</Link>
</a>):(<></>)}
     
     </Links>

        </LinksContainer>
        
    </Container>}
       
  
       </>
  );
}

Sidebar.propTypes = {
  expanded: PropTypes.array.isRequired,
};

const Container=styled.div`
min-width: ${(props) => (props.expanded ? 'max-width' : '120px')}; /* Access the expanded prop */
max-width: ${(props) => (props.expanded ? 'max-width' : '120px')}; /* Access the expanded prop */
height: max-content !important;
border-radius:20px;
background-color:#caf0f8  ;
display: flex;
flex-direction: column;
align-items: center;
justify-content:center;
gap: 2rem;
margin-top: 6rem;
margin-left:0.5rem;
box-shadow: 0px 3px 6px #00000029;

@media screen and (min-width:320px) and (max-width:1080px){
  min-width: ${(props) => (props.expanded ? '80%' : '80%')}; /* Access the expanded prop */
  max-width: ${(props) => (props.expanded ? '80%' : '80%')}; /* Access the expanded prop */
   height:max-content !important;
   margin-top:4rem;
   flex-direction:column;
   align-content:center;
   margin-right: -140px;
  }

`;


const Row = styled.div`
display: flex;
align-item:center;
margin-bottom:0.6rem;
color:white;

${({justify})=>
justify &&

`justify-content:space-around;
width:90%;
margin-left:6rem;
margin-bottom:0.6rem;


`}
`;
const Place= styled.h5`
color:black;
font-size: 13px;
height:10px;
width:2px;
font-family:Poppins;
font-weight: 600;
margin-left:1rem;
`;
const Ratingicon=styled.div`
margin-left:1rem;

`;
const ProfileContainer = styled.div`
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
//  width:200%;
height:10%;
margin-left:1rem;
margin-top:20px;

`;
const Detail = styled.div`
// margin-left:1rem;
margin-top:20px;

`;
const Name = styled.h4`
color:black;
height:20px;
width:100px;
overflow:hidden;
font-family:Poppins, Medium;
font-weight: 600;
font-size:17px;
margin-left:1rem;

`;
const LinksContainer=styled.div`
background-color:#03045e ;
min-height:85%;
max-height:90%;

min-width: ${(props) => (props.expanded ? '100%' : '120px')}; /* Access the expanded prop */
max-width: ${(props) => (props.expanded ? '100%' : '120px')}; /* Access the expanded prop */
border-bottom-right-radius:20px;
border-top-right-radius:100px;
font-family:Poppins, Medium;
box-shadow: 0px 3px 6px #00000029;
@media screen and (min-width:320px) and (max-width:1080px){

  // min-width: ${(props) => (props.expanded ? '100%' : '100%')}; /* Access the expanded prop */
  // max-width: ${(props) => (props.expanded ? '100%' : '100%')};
 }
`;
const Links = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  margin:auto;
  padding-left: 1rem;
  justify-content: center;
  margin-top: 2rem;

  height: 88%;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: ${(props) => (props.expanded ? 'column' : 'column')};
    gap: 3rem;
    flex-wrap: wrap; /* Avoid wrapping when not expanded */
    overflow-x: auto; /* Add horizontal scrollbar if content overflows */
  }
`;




const Link =styled.li`
// margin-left:15%;
margin-bottom:1.2rem;
display:flex;
width:max-width;
gap:1rem;
color:#e4e4e4;
cursor:pointer;
h3{
    font-size:1rem;
    font-weight:2px;
    padding-top:3px;
}
svg{
    
    margin-top:3%;
}

}
`;
const Link1 =styled.li`
// margin-left:15%;
margin-top:1rem;
margin-bottom:1.2rem;
display:flex;
gap:1rem;
color:#e4e4e4;
cursor:pointer;
h3{
    font-size:1rem;
    font-weight:2px;
    padding-top:3px;
}
svg{
    
    margin-top:3%;
}

}
`;

const ManageProfile =styled.div`
background-color:white;
border-radius:10px;
padding:0.5rem;
font-family:'Poppins,Medium';
display:flex;
justify-content:center;
aligm-items:center;

flex-direction:column;
color:${darkThemeColor};




&:hover {
  color: black;
  text-decoration: none;
}

@media screen and (min-width:320px) and (max-width:1080px){
    margin-bottom:2rem;
   }
`;


export default Sidebar;










