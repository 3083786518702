import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from "@mui/material/TablePagination";
import TableFooter from "@mui/material/TableFooter";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { useState,useEffect ,useMemo} from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useAuthToken } from "../TokenContext";
import moment from "moment";
import { useIntl } from 'react-intl';
import { MenuItem,Select } from '@mui/material';
const Viewpodretrotalent = ({Podid}) => {
    const [retrolisting, setRetrolisting] = useState([]);
    const [currentPageretro, setCurrentPageretro] = useState(1);
    const itemsPerPageretro = 4; // Number of items per page
    const hasMorePages = retrolisting.length === itemsPerPageretro; // Assume there are more pages if the number of fetched items equals itemsPerPage
    const token = useAuthToken();

    const intl = useIntl();
    const location = useLocation();
 
    const getRetroFields = (retroString) => {
      try {
        return JSON.parse(retroString) || {};
      } catch (error) {
        console.error('Error parsing retro:', error);
        return {};
      }
    };
    const fetchretro = async () => {
      const headers = {
        'Authorization' : token, // Replace with your actual token
        'Content-Type': 'application/json', // You can add more headers as needed
      };
        try {
          const response = await axios.post(process.env.REACT_APP_CLIENT_PODRETROLIST, {
            pods_id: Podid,
          },{headers:headers});
    
          const responseData = response.data.data;
            const processedData = responseData.map(item => ({
              ...item,
              retroFields: getRetroFields(item.retro),
            }));
          setRetrolisting(processedData);
        } catch (error) {
          console.error('Error fetching candidates:', error);
        }
      };
  
      useEffect(() => {
      
          fetchretro();
        
      }, [token]);
      

      const handlePageChangeretro = (newPage) => {
        // fetchretro(PodID); // No need to send page as a parameter
        setCurrentPageretro(newPage);

      };
   
      const [teamFilter, setTeamFilter] = useState('');  // For team member dropdown filter
      const [sortDirection, setSortDirection] = useState('asc');  // Sort direction for date
      
    // Calculate the startIndex and endIndex based on the current page
// Calculate the startIndex and endIndex based on the current page
const startIndex = (currentPageretro - 1) * itemsPerPageretro;
const endIndex = Math.min(startIndex + itemsPerPageretro, retrolisting.length);

// Memoize sorted items to prevent infinite re-renders
const sortedItems = useMemo(() => {
  // Filter items based on team selection
  const filteredItems = teamFilter ? retrolisting.filter(item => item.submitted_by === teamFilter) : retrolisting;
  
  // Sort the filtered items by date
  return [...filteredItems].sort((a, b) => {
    const dateA = moment(a.createdAt);
    const dateB = moment(b.createdAt);
    return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
  });
}, [teamFilter, retrolisting, sortDirection]); // Only recompute if one of these dependencies changes

// Calculate the items on the current page
const itemsOnPage = sortedItems.slice(startIndex, endIndex);

// Calculate pagination based on filtered and sorted items
const totalPages = Math.ceil(sortedItems.length / itemsPerPageretro);

// Adjust current page if it exceeds the total number of pages after filtering
useEffect(() => {
  if (currentPageretro > totalPages && totalPages > 0) {
    setCurrentPageretro(totalPages);
  }
}, [currentPageretro, totalPages]);

     // Get the items for the current page
     
      // Handle team member selection
      const handleTeamFilterChange = (event) => {
        console.log('Selected value:', event.target.value); // Log selected value
        setTeamFilter(event.target.value);
      };
      
     
      // Handle sorting toggle
      const handleSortDate = () => {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      };
  return (
    <Div style={{marginTop:'5rem'}}>
    <TableContainer component={Paper} sx={{marginTop: '2rem'}}>
    <Table component={Paper} sx={{ width: 900 ,backgroundColor:'#90E0EF',fontFamily:'Poppins,Medium', borderRadius: '10px'}}aria-label="simple table">
      <TableHead     sx={{ backgroundColor:'#00B4D8','& td, & th': {
        fontFamily: 'Poppins,Medium',
        fontSize: '15px',
        color: 'white',
        fontWeight:'600'
      },}}>
        <TableRow    > 

        <TableCell align="right" sx={{ fontWeight: 'bold' }}>
        <Select
        sx={{
           // Grey background color
          color: 'white', // White text color for the dropdown
          '& .MuiSelect-icon': {
            color: 'white', // Change the dropdown icon color if needed
          },
          '& .MuiInputBase-input': {
            color: 'white', // Change the input text color
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'grey.500', // Optional: Change the border color
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'black', // Change the border color on hover
          },
        }}
  value={teamFilter}
  onChange={handleTeamFilterChange}
  displayEmpty
  
  
>
  <MenuItem value="">
    <em style={{ color: "white" }}>{intl.formatMessage({ id: 'selectTeamMember' })}</em>
  </MenuItem>
  {Array.from(new Set(retrolisting.map(item => item.submitted_by))).map((member, index) => (
    <MenuItem style={{ color: 'black' }} key={index} value={member}>
      <span>{member}</span>
    </MenuItem>
  ))}
</Select>

                </TableCell>
                
                {/* Date Column with Sort */}
                <TableCell align="right" onClick={handleSortDate} style={{ cursor: 'pointer' }}>
                  {intl.formatMessage({ id: 'date' })}
                  {sortDirection === 'asc' ? ' ▲' : ' ▼'}
                </TableCell>
                  <TableCell align="right">{intl.formatMessage({ id: 'podFeedback' })}</TableCell>
                 
                 
                 
                  <TableCell align="right"sx={{fontWeight:'bold'}}>{intl.formatMessage({ id: 'sprint' })}</TableCell>
                  <TableCell align="right">{intl.formatMessage({ id: 'startDoing' })}</TableCell>
      <TableCell align="right">{intl.formatMessage({ id: 'stopDoing' })}</TableCell>
      <TableCell align="right">{intl.formatMessage({ id: 'keepDoing' })}</TableCell>
                
        </TableRow>
      </TableHead>
   
      {/* records tulasi*/}  
      <TableBody>
      {itemsOnPage.length > 0 ? (
          itemsOnPage.map((item) => (
<TableRow key={item.sprint_id}>
<TableCell align="right">{item.submitted_by}</TableCell>
      <TableCell align="right">  { moment(item.createdAt).format("MM-DD-YYYY")}</TableCell>
    
      
      <TableCell align="right">{item.pod_feedback}</TableCell>
      <TableCell align="right">{item.sprint}</TableCell>
    <TableCell align="right">{item.retroFields.start_doing}</TableCell>
      <TableCell align="right">{item.retroFields.stop_doing}</TableCell>
      <TableCell align="right">{item.retroFields.keep_doing}</TableCell>
</TableRow>
))
) : (
<TableRow>
<TableCell colSpan={2} align="center">
There are no reviews.
</TableCell>
</TableRow>
)}
</TableBody>
{/* Pagination */}
<TableFooter>
<TableRow>
<TablePagination
rowsPerPageOptions={[]}
colSpan={2} // Update this to match the number of table cells you have per row
count={retrolisting.length}
rowsPerPage={itemsPerPageretro}
page={currentPageretro - 1}
onPageChange={(event, newPage) => handlePageChangeretro(newPage + 1)}
ActionsComponent={(props) => (
<TablePaginationActions
  {...props}
  count={retrolisting.length}
  page={currentPageretro - 1}
  onPageChange={(event, newPage) => handlePageChangeretro(newPage + 1)}
/>
)}
/>
</TableRow>
</TableFooter>

        
    </Table>
    {/* <Button onClick={handleSubmit}>Approve</Button> */}
          
    </TableContainer>
    </Div>
  )
}
const Div =styled.div`

display:flex;

jutify-content:center;
flex-direction:column;
margin-top:6rem;
align-items:center;

margin-left:6.5rem;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  overflow:auto;
justify-content:center;
align-items:center;
margin:auto;
width:24rem;

  }
`;
export default Viewpodretrotalent
