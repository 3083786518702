// ResumeUploadPage.js

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthToken } from "../TokenContext";
import AWS from 'aws-sdk';

// S3 Configurations
const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
};

const s3 = new AWS.S3(S3Config);

const ResumeUploadPage = () => {
  const history = useHistory();
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const token = useAuthToken();
const [resumeData, setResumeData] = useState(null);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      setUploadStatus('Please select a file to upload.');
      return;
    }
  
    const fileName = selectedFile.name;
    const bucketName = process.env.REACT_APP_BUCKETNAME;
  
    // S3 Upload parameters
    const uploadParams = {
      Bucket: bucketName,
      Key: fileName,
      Body: selectedFile,
      ContentType: selectedFile.type,
    };
  
    try {
      // Step 1: Upload the file to S3
      const data = await s3.upload(uploadParams).promise();
      console.log('File uploaded successfully:', data.Location);
  
      // Step 2: Send the file name to the API
      const response = await fetch('https://zin1z72773.execute-api.us-east-1.amazonaws.com/dev/resumeparser', {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          file_name: fileName,
        }),
      });
  
      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData);
        setResumeData(responseData.body);
  
        // Pass responseData and S3 file location to AddCandidatePage
        history.push({
          pathname: '/CreateProfile',
          state: {
            resumeData: responseData.body,
            resumeFileKey: data.Location,  // This is the key to the uploaded file
          },
        });
      } else {
        setUploadStatus('Failed to upload the resume. Please try again.');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setUploadStatus('Error uploading file. Please try again.');
    }
  };
  

  return (
    <div style={{ padding: '20px' }}>
      <h1>Upload Your Resume</h1>
      <input type="file" accept=".pdf" onChange={handleFileChange} />
      <button onClick={handleFileUpload} style={{ marginLeft: '10px' }}>
        Upload
      </button>
      {uploadStatus && <p>{uploadStatus}</p>}
    </div>
  );
};

export default ResumeUploadPage;
