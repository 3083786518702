import React from 'react';
import styled from 'styled-components';

const ModalContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
margin:auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
 
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const PortfolioModal = ({ imageUrl, onClose }) => {
  return (
    <ModalContainer onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>×</CloseButton>
        <Image src={imageUrl} alt="Large Image" />
      </ModalContent>
    </ModalContainer>
  );
};

export default PortfolioModal;
