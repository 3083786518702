import React from 'react';

const Sonarprojectdetailsmetrics = ({ isOpen, onClose, title, data }) => {
  return (
    <>
      {isOpen && (
        <div className="popup-overlay">
          <div className="popup">
            <button className="close-button" onClick={onClose}>
              &times;
            </button>
            <h2>{title}</h2>
            {data.map((item, index) => (
              <div key={index}>
                <strong>Title:</strong> {item.title}
                <br />
                <strong>Author:</strong> {item.author}
                <br />
                <strong>Created At:</strong> {item.created_at}
                <br />
                <strong>Updated At:</strong> {item.updated_at}
                <br />
                <strong>State:</strong> {item.state}
                <br />
                <strong>Body:</strong> {item.body || 'N/A'}
                <br />
                <br />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Sonarprojectdetailsmetrics;
