import React, { useState } from 'react'
import styled from 'styled-components'
import profile from './assets/profile_img.png';
import "./FindPods.css";
import { useEffect } from 'react';
import axios from 'axios';
import userEvent from '@testing-library/user-event';
import { useDispatch, useSelector } from "react-redux";
import { data } from 'jquery';
import Avatar from "./assets/MaskGroup.png";
import { NavHashLink } from 'react-router-hash-link';
import star from './assets/star.png';
import { useParams } from 'react-router-dom';
import { Approval } from '@mui/icons-material';
import AWS from 'aws-sdk';
import defaultimg from './assets/defaultimge.png';
import { useAuthToken } from "../TokenContext";
const Poddetaillscandidate = () => {
  const token = useAuthToken();


  const config = {
    bucketName: process.env.REACT_APP_BUCKETNAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
  }
  
  const S3Config = {
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
  }
  

    const { isLoggedIn, user } = useSelector(state => state.auth);
    const [getpoddetails, setGetpoddetails] = useState({});
    const [getcandidates, setGetcandidates] = useState([]);
    const [applywait, setApplywait] = useState('Apply to Pod');

    const [getReq, setgetReq] = useState([]);
  
    const [podskill, setPodskill] = useState([]);
const[getid,setGetid]=useState();
const[status,setStatus]=useState('');
const[pendingapr,setPendingapr]=useState('');
    const [getData, setGetData] = useState("")
    const [podid, setPodid ]= useState("");
    const [candid, setCandid] = useState("");
    const [AuthImage, setAuthImage] = useState(""); 
    const [AuthImagecan, setAuthImagecan] = useState([]); 
const [candidateid,setCandidateid]=useState( "");
const [condition,setCondition]=useState("");

    const email = user.data[0].email
   
    const portfolioUrl = user.data[0].portfolioUrl
    
    const firstName = user.data[0].firstName
    
    const lastName = user.data[0].lastName
    
    const myBucket = new AWS.S3(S3Config);
// const skilldata = (data) => {

//     var mainskill = JSON.parse(data)
//     console.log("1112423435345345 :::: ",mainskill)
//     return(mainskill);

// }

const { id } = useParams();


    useEffect(() => {



        const body = {
           id: id
        }
        const headers = {
          'Authorization' : token, // Replace with your actual token
          'Content-Type': 'application/json', // You can add more headers as needed
        };

        // https://w3ten9gqf7.execute-api.us-east-1.amazonaws.com/dev/getpodbyid
        axios.post(process.env.REACT_APP_CLIENT_GETPODID, body, {headers:headers}).then((res) => {
               
                setGetData(res.data.data);
                setGetid(res.data.data.podDetails.id)
                
                setgetReq(res.data.data.podDetails.requrment);

                // setSkills(res.data.data.podDetails.requrment);
              
                setGetcandidates(res.data.data.candidates)
                setCandidateid(res.data.data.candidates.c_id)
                setGetpoddetails(res.data.data.podDetails)
                setPodid(res.data.data.podDetails.id)
                
                setCandid(res.data.data.podDetails.canID)
                 setPodskill(res.data.data.podDetails.skills)

         
                const URL_EXPIRATION_TIME = 900;

                const params = {
                  Bucket: process.env.REACT_APP_BUCKETNAME,
                  Key: res.data.data.podDetails.picture,
                  Expires: URL_EXPIRATION_TIME,
                }
        
                
             myBucket.getSignedUrl('getObject', params, (err, url) => {
       
              setAuthImage(url);
        
            });


            const params1 = {
              Bucket: process.env.REACT_APP_BUCKETNAME,
              Key: getcandidates.profilePicture,
              Expires: URL_EXPIRATION_TIME,
            }
    
            
         myBucket.getSignedUrl('getObject', params1, (err, url) => {
    
       
          setAuthImagecan(url);
    
        });

            }



          

            )
            .catch((err) => {
                console.log("catch", err);
            })




            const body1 = {
             
              candidateID : user.data[0].c_id
            }

           
            // https://ncco327erk.execute-api.us-east-1.amazonaws.com/dev/get_candidate_status
        axios.post(process.env.REACT_APP_TALENT_GETCANDIDATESTATUS, body1,{headers:headers}).then((res) => {
          
          res.data.data.map((result)=> {
            
            setStatus(result.pod_approved)
setPendingapr(result.status);
        })

          
        } )
          .catch((err) => {
              console.log("catch", err);
          })


    }, [token])

  

    let applypod = () => {

    //     let confirm = window.confirm("Do you want to apply this pod") 
      
    //     if (confirm == true) {

    //         if(user.subscriptiondata[0].Status == "active"){

    //             if(user.subscriptiondata[0].type == getpoddetails.status){
               
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    
    let podrecqbody = {
      podID : id,
      candidateID : user.data[0].c_id,
      status_data : "approval",
      pod_approved : 0
    }
   

    // https://wr8mspi5i0.execute-api.us-east-1.amazonaws.com/dev/approve_podreq
  axios.post(process.env.REACT_APP_TALENT_APPROVEPODREQ, podrecqbody, {headers:headers}).then((res) => {

   if(res){
      
      setApplywait(res.data.Message);
     
     
    alert(res.data.data);
    

    res.data.map((result)=> {
   
      setCondition(result.pod_approved);
      if(condition==1){

        let body = {
          podID : id,
          candidateID : user.data[0].c_id,
          status_data : "approved"
        }
    
        // https://tsddt5g2u5.execute-api.us-east-1.amazonaws.com/dev/Joinpod
      axios.post(process.env.REACT_APP_TALENT_JOINPOD, body,{headers:headers}).then((res) => {
    
       if(res){
          
          // setApplywait(res.data.Message);
         
         
        // alert(res.data.data,'Waiting for Approval');
      //  window.location.reload();
    
       }
    
      }
    
    
      )
      .catch((err) => {
        console.log("catch", err);
      })
    
    
       }
  })
  //  window.location.reload();

   }

  }


  )
  .catch((err) => {
    console.log("catch", err);
  })

       

    // }else{
    //     alert("You didn't have the " + getpoddetails.status + " subscription to hire this pod!")
    // }
      
    //   }else{
    //     alert("You Don't Have Subscription!");
    //   }
      
    //   }
    }
      


    return (
    <OL1>
 <Headingtext1> Find the best pod and get registered or create your own pod</Headingtext1>
    <Div >
   

        <li  >

            <div className='name'>  
            
            {AuthImage==null ? (
                <img
  src={defaultimg}
  alt=""
  style={{ width: 50, height: 50, borderRadius : "50px"  }}
></img>
)


  :(
    <img style={{ width: '50px', height: '50px' }} src={AuthImage}></img>
) }
            
            
             <p className='firstname'> {data.rating} </p> </div>

            <div className='name2'> <tr  className='TR'><td    className='TD'  >Name</td> <td>:</td><TD1 >{getpoddetails.podName} </TD1></tr></div>

            <div className='name2'> <tr className='TR' ><td className='TD'  >E-Mail</td> <td>:</td><TD1  > {getpoddetails.email}</TD1></tr></div>

            
            <div className='name2'> <tr  className='TR'><td className='TD'  >Clients</td> <td>:</td><TD1 > -- </TD1></tr></div>


            <div className='name2'> <tr  className='TR'><td className='TD' >Portfolio</td> <td>:</td><TD1>  {getpoddetails.portfolioUrl}</TD1></tr></div>

           




            

                                   
<div className='name2'> 
                                            <tr className='TR' ><td className='TD'  >Requirements </td> </tr>
                                            
                                            </div>
              
                    {getReq.map((hepline)=>{
return <div> <div className='name2'> <tr  className='TR'><td className='TD' >Position</td> <td>:</td><TD1> {hepline.position} </TD1></tr></div>
<div className='name2'> <tr  className='TR'><td className='TD' >Skills</td><td>:</td> <TD1>{ hepline.reqskill.map((sk) => { return   <span style={{margin:'5px'}}>{sk.skill}<span>,</span> </span> }) }</TD1></tr></div>
</div>})} 
                

                 
                    

                   
                 
                  
        
<div className='name2'> <tr  className='TR'><td className='TD' >Candidates</td> </tr></div>
{getcandidates.map((data)=>
   <div style={{display:"flex",flexDirection:"row"}}><tr>
    <td style={{paddingRight:"10px",paddingTop:"10px"}}> <img
   src={'https://globldataprod.s3.amazonaws.com/'+ data.profilePicture}
   alt=""
   style={{ width: "40px", height: "40px" }}
 ></img></td></tr>
 <tr style={{display:'flex',flexDirection:'column'}}> <TD2>  {data.firstName}  </TD2>  <TD3>{data.work_position}</TD3></tr>


 </div>        

   )}
  {status == 0 ? (
    <DetailsButton   onClick={applypod}>join pod</DetailsButton>
    )
    : status == 1  ?(
      <DetailsButton  disabled  onClick={applypod}>Approved</DetailsButton>
      ): status == 2  (
        <DetailsButton  disabled  onClick={applypod}>Rejected</DetailsButton>
        ) }


{/* {applywait == 'approval' ? (

    <DetailsButton disabled  onClick={applypod}>{applywait}</DetailsButton>  

    ) :(<DetailsButton   onClick={applypod}>{applywait}</DetailsButton>  
    )} */}
<br></br>

    </li>

</Div>
<br></br>

</OL1>                     

    )

}
    
export default Poddetaillscandidate;

const OL1 = styled.ol`
list-style: none;
margin:auto;


@media screen and (min-width:320px) and (max-width:1080px){
    display:flex;
flex-direction:column;
margin-top:10rem;
align-items:center;
justify-content:center;


}
`;

const Return = styled.ol`

margin:auto;

margin-top:5rem;
@media screen and (min-width:320px) and (max-width:1080px){
    margin:auto;
    margin-top:5rem;
    

}
`;

const TD1 =styled.td`
margin-left: 2rem;
width: 12rem;
overflow-wrap: anywhere;  font-weight: 600;
font-family: Poppins,medium;
@media screen and (min-width:320px) and (max-width:1080px){
 margin:auto;
 margin-left: 2rem;
    width: 8rem;
    overflow-wrap: anywhere;
}
`;
const TD2 =styled.td`

width: 12rem;
overflow-wrap: anywhere;
padding-top:10px;
font-weight: 600;
font-family: Poppins,medium;
@media screen and (min-width:320px) and (max-width:1080px){
 margin:auto;

    width: 8rem;
    overflow-wrap: anywhere;
}
`;

const TD3=styled.td`

width: 12rem;
overflow-wrap: anywhere;
font-weight: 600;
font-size:14px;
font-family: Poppins,medium;

@media screen and (min-width:320px) and (max-width:1080px){
 margin:auto;

    width: 8rem;
    overflow-wrap: anywhere;
}
`;



const Div = styled.div`


margin-top:5rem;
align-items:center;
justify-content:center;
padding-left:20px;
margin-left:4rem;

width:59rem;
border-radius:20px;
background-color:rgb(142, 174, 188,32%);
@media screen and (min-width:320px) and (max-width:1080px){
    margin:auto;

    width:20rem;

}
`;




const Num2 = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        margin:auto;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        width:100%;
}
        `;


const Numbers = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        align-items:center;
        justify-content:center;
        width:7rem;
}
        `;

        const DetailsButton=styled.button`
        display:flex;
        justify-content:center;
        align-items:center;
        margin:auto;
        padding:5px;
        margin-top:3rem;
        margin-bottom:2rem;
        color:white;
      border:none;
        border-radius:5px  ;
        background-color:#1DBF73;
        box-shadow: 1px 3px 6px #00000029;

      
        
        `;

        const Headingtext1=styled.h3`
        font-family:Poppins,Medium;
        display:flex;
        justify-content:center;
        align-items:center;
        margin:auto;
        margin-top: 5rem;
        font-weight: 600;
        font-size: 1.5rem;
        @media screen and (min-width:320px) and (max-width:1080px){
            display:flex;
            justify-content:center;
            align-items:center;
            margin:auto;
            margin-top: 7rem;
            }
        `;