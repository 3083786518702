import React from 'react'
import styled from 'styled-components';
import {IoStatsChart} from 'react-icons/io5';
import {themeColor,hoverEffect} from '../../utils';
import Path from '../assets/Path.png';

// import './styles.css';


function Earnings() {
  return (
  <EarningsCard>
     <CardContent> 
    <br></br>  
          <EarningsText> Your Posted Project Requirements </EarningsText>
{/* <Earning>$7,878</Earning>
<EarningsIncrease>+ 10%since last month</EarningsIncrease> */}
<br></br>
<h5 style={{ textAlign : "center"  ,fontFamily:'Poppins,Medium'}}>Coming Soon..</h5>

</CardContent>
      </EarningsCard>
  );
} 



const EarningsCard=styled.div`
height: 110%;
width: 16rem;
background-color: #00B4D8;
padding: 1rem;
border-radius:1rem;
color:white;
box-shadow: 0px 4px 6px #00000029;

transition: 0.4s ease-in-out;
&:hover{
    box-shadow:$(hoverEffect);
}
@media screen and (min-width:320px) and (max-width:1080px){
 width:60%;
 margin-right: 260px
}
 

`;
const CardContent=styled.div`
margin:1rem;
`;

const Chart=styled.div`
justify-content: center;
display: flex;

`;
const EarningsText=styled.div`
font-family:Poppins, Medium;
text-align:center;
font-weight:normal;
font-family:Poppins;
padding:0.4rem 0;

`;
const Earning=styled.div`
text-align:center;
font-size: 2rem;

`;
const EarningsIncrease=styled.div`

text-align:center;
font-family:Poppins, Medium;
font-weight:normal;
background-color:#48819A;
padding:0.1rem;
border-radius:5px;
margin-top: -0.1rem;
`;
export default Earnings;