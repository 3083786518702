import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Divider,
  CssBaseline,
} from '@mui/material';
import { FaHome,FaListAlt, FaTachometerAlt, FaQuestionCircle, FaSearch, FaChartLine , FaChartBar, FaTasks, FaComment, FaInfoCircle, FaChartPie, FaPlusCircle, FaClipboardList, FaBars ,FaRobot } from 'react-icons/fa';
import MenuIcon from '@mui/icons-material/Menu';
import styled, { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import PeopleIcon from '@mui/icons-material/People';
const drawerWidth = 240;
const collapsedWidth = 60;

const theme = {
  spacing: (factor) => `${factor * 8}px`,
  mixins: {
    toolbar: {
      minHeight: '64px',
    },
  },
};

const DrawerStyled = styled(Drawer)`
  & .MuiDrawer-paper {
    width: ${(props) => (props.collapsed ? `${collapsedWidth}px` : `${drawerWidth}px`)};
    transition: width 0.3s;
    box-sizing: border-box;
    background-color: black;
    color: white;
    overflow: auto !important; // Hide overflow to prevent unwanted scrollbars
   -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */

  &::-webkit-scrollbar {
    display: none !important; // Hide scrollbar for Webkit browsers
  }
  }
`;


const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
  min-height: ${(props) => props.theme.mixins.toolbar.minHeight};
  justify-content: flex-end;
`;

const StyledListItem = styled(ListItem)`
  color: white;
  padding-left: ${(props) => (props.collapsed ? '16px' : '24px')};

  &.Mui-selected {
    background-color: grey !important;
  }

  &:hover {
    background-color: grey !important;
  }

  & .MuiListItemIcon-root {
    color: white;
    min-width: ${(props) => (props.collapsed ? 'auto' : '56px')};
    justify-content: center;
  }

  & .MuiListItemText-root {
    display: ${(props) => (props.collapsed ? 'none' : 'block')};
    color: white;
  }
`;

const IconWrapper = styled.div`
  font-size: 24px;
`;

const Sidebarnew = ({ open, onClose, collapsed, setCollapsed }) => {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);

  const menuItems = [
    { text: 'Home', icon: <FaHome />, path: '/' },
    ...(user.data[0].type === 'Candidate'
      ? [
          { text: 'Dashboard', icon: <FaTachometerAlt />, path: '/candidate-dashboard' },
          { text: 'Pod Assist', icon: <FaQuestionCircle />, path: '/OpenAi' },
          { text: 'Find Pods', icon: <FaSearch />, path: '/FindPods' },
          { text: 'My Pods', icon: < PeopleIcon />, path: '/Pods' },
          { text: 'Pod Metrics', icon: <FaChartBar />, path: '/PodMetricsTalentDetailview' },
          { text: 'Board', icon: <FaTachometerAlt />, path: '/KanbanboardTalent' },
          { text: 'My Tasks', icon: <FaTasks />, path: '/TalentViewTask' },
          { text: 'Pod Feedback', icon: <FaComment />, path: '/Reviewsandratingcandidate' },
          { text: 'Manage Profile', icon: <FaInfoCircle />, path: '/Updateyourprofile' },
        ]
      : []),
    ...(user.data[0].type === 'Client'
      ? [
          { text: 'Dashboard', icon: <FaTachometerAlt />, path: '/client-dashboard' },
          ...(user.data[0].manager_type === 'PartnerClient' 
            ? [{ text: 'Partner Metrics', icon: <FaChartLine />, path: '/PartnerMetricsDashboard' }]
            : []
          ),
          { text: 'Pod Assist', icon: <FaQuestionCircle />, path: '/OpenAi' },
          { text: 'Find Pods', icon: <FaSearch />, path: '/FindPodsClient' },
          { text: 'My Pods', icon: <PeopleIcon />, path: '/Team' },
          { text: 'Pod Activity', icon: <FaListAlt />, path: '/PodActivity' },
          
          { text: 'Board', icon: <FaTachometerAlt />, path: '/KanbanBoard' },
          { text: 'Pod Automation', icon: <FaRobot />, path: '/PodAutomation' },
          
          { text: 'Pod Insights', icon: <FaChartPie />, path: '/PodInsights' },
          { text: 'Create Pod', icon: <FaPlusCircle />, path: '/client-createpod' },
          { text: 'Pod Tasks', icon: <FaTasks />, path: '/ViewPodTasksManagerbyid' },
          { text: 'Pod Metrics', icon: <FaChartBar />, path: '/PodMetricsmanager' },
          { text: 'Pod Retro', icon: <FaClipboardList />, path: '/Podretromanager' },
          { text: 'Manage Profile', icon: <FaInfoCircle />, path: '/client-profile' },
         
        ]
      : []),
      ...(user.data[0].type === 'stakeholder'
        ? [
            { text: 'Dashboard', icon: <FaTachometerAlt />, path: '/StakeholderDashboard' },
            { text: 'Pod Activity', icon: <FaListAlt />, path: '/PodActivitystakeholderviewall' },
            { text: 'Pod Metrics', icon: <FaChartBar />, path: '/PodMetricsmanager' },
            { text: 'Manage Profile', icon: <FaInfoCircle />, path: '/stakeholder-profile' },
          ]
        : []),
  ];

  const handleToggleCollapse = () => {
    setCollapsed(!collapsed); // This toggles the collapsed state
  };
  

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <DrawerStyled variant="persistent" anchor="left" open={open} collapsed={collapsed}>
      <DrawerHeader>
  <IconButton onClick={handleToggleCollapse}>
    <MenuIcon style={{ color: 'white' }} />
  </IconButton>
</DrawerHeader>

        <Divider />
        <List>
          {menuItems.map((item) => (
            <StyledListItem
              button
              key={item.text}
              component={Link}
              to={item.path}
              selected={location.pathname === item.path}
              collapsed={collapsed}
            >
              <ListItemIcon collapsed={collapsed}>
                <IconWrapper>{item.icon}</IconWrapper>
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </StyledListItem>
          ))}
        </List>
      </DrawerStyled>
    </ThemeProvider>
  );
};

export default Sidebarnew;
