import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './Popup.css';

const PopupDetailviewmetrics = ({ onClose, data }) => {
  if (!data) {
    // If data is null or undefined, display a message or return null
    return null;
  }

  return (
    <div className="popup">
      <div className="popup-inner">
        <h2>{data.Reporter}</h2>
        <p>Open User Stories: {data.open_user_stories}</p>
        <p>Completed User Stories: {data.completed_user_stories}</p>
        
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h5>Open Stories:</h5>
          <TableContainer component={Paper}>
            <Table
              component={Paper}
              sx={{ maxWidth: 900, fontFamily: 'Poppins,Medium', borderRadius: '10px' }}
              aria-label="open-stories-table"
            >
              <TableHead sx={{ backgroundColor: '#00B4D8', '& td, & th': { fontFamily: 'Poppins,Medium', fontSize: '15px', color: 'white', fontWeight: '600' } }}>
                <TableRow>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>Issue Key</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>Issue Summary</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.open_stories.map((story) => (
                  <TableRow key={story.issue_key}>
                    <TableCell align="right">{story.issue_key}</TableCell>
                    <TableCell align="right">{story.issue_summary}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h5>Closed Stories:</h5>
          <TableContainer component={Paper}>
            <Table
              component={Paper}
              sx={{ maxWidth: 900, fontFamily: 'Poppins,Medium', borderRadius: '10px' }}
              aria-label="closed-stories-table"
            >
              <TableHead sx={{ backgroundColor: '#00B4D8', '& td, & th': { fontFamily: 'Poppins,Medium', fontSize: '15px', color: 'white', fontWeight: '600' } }}>
                <TableRow>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>Issue Key</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>Issue Summary</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.closed_stories.map((story) => (
                  <TableRow key={story.issue_key}>
                    <TableCell align="right">{story.issue_key}</TableCell>
                    <TableCell align="right">{story.issue_summary}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default PopupDetailviewmetrics;
