import React from 'react'
import styled from 'styled-components';
import{FiSearch} from 'react-icons/fi';
import { useDispatch, useSelector } from "react-redux";

function ClientNavbar() {

    const { isLoggedIn, user } = useSelector(state => state.auth);


    //console.log(user)

  return (
      <NavbarConatiner>

      
          
         <Text>
            
              
         <span  style={{paddingLeft:'25px'}}></span><b>Welcome,</b><span style={{fontWeight:'700',paddingLeft:'5px',
    color:'black'}}>{user.data[0].emp_firstname}!</span>
  </Text>
          
          <InputContainer>
          
          <Icon>
              <FiSearch/>
              </Icon>
              <Input type="text" placeholder="Search for Candidate/Pods" />
              </InputContainer>
           
      </NavbarConatiner>
  );
    
}
const NavbarConatiner = styled.nav`
display:flex;
margin-top:-40px;
justify-content:space-between;
align-items:center;
height:10%;
]
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  flex-direction:column;
    margin-top:1rem;
    // margin-right: 9rem;
    justify-content:center;
    align-items:center;
  }
`;
const Text = styled.h3`
width : 100%;
@media screen and (min-width:320px) and (max-width:1080px){
    margin-top:3rem;
    margin-left: 3rem
  }
`;
const InputContainer = styled.div`
display : flex;
border-radius:10px;
justify-content: center;
margin-left:22.6rem;
border:1px solid #4E8FAE;
box-shadow: 3px 6px 9px #00000005;
@media screen and (min-width:320px) and (max-width:1080px){
  margin-top:10rem;
  margin-left: -22.6rem;
  margin-right : 4rem;
}
`;
const Row=styled.div`
`;
const Icon = styled.div`
height: 3rem;
width: 2rem;
background-color: #F2F3F4;
display:flex;
flex-direction:row
justify-content: center;
align-items: center;
border-top-left-radius:10px;
border-bottom-left-radius:10px;
svg{
    color:#555555;
}
`;
const Input = styled.input`
justify-content: center;
height: 3rem;
width: 25rem;
border:0px solid #4E8FAE;
background-color:#F2F3F4;
font-family:Poppins,Medium;
font-Weight:600;
border-top-right-radius:10px;
border-bottom-right-radius:10px;
&:focus{
    border:none;
    outline:none;
}
@media screen and (min-width:320px) and (max-width:1080px){
  width: 15rem;
}
`;

export default ClientNavbar