import { fontSize } from '@mui/system'
import React from 'react'
import styled from '@emotion/styled';
import MailIcon from '@mui/icons-material/Mail';
import { NavLink } from 'react-router-dom'

const PodCreatedSuccessFullyCan = props => {
  return (
    <div>
                                                                   

<p>Your profile is not authorized to cread pod!</p>


</div>
  )
}

const InputContainer = styled.div`

margin-left: 15rem;

`;

const Input = styled.input`
border:0px solid black;
justify-content: center;
height: 2.5rem;
width: 18rem;

background-color:white;
border-top-right-radius:10px;
border-bottom-right-radius:10px;
&:focus{
    border:none;
    outline:none;
}

`;
const Icon = styled.div`
height: 2.5rem;
width: 2rem;
background-color: white;
// border:1px solid black;
margin-Left:20rem;
display:flex;
flex-direction:row
justify-content: center;
align-items: center;
border-top-left-radius:10px;
border-bottom-left-radius:10px;
svg{
    color:#555555;
}
`;


export default PodCreatedSuccessFullyCan