import React, { useEffect, useState, useRef } from "react";
import { Chart } from "chart.js";
import "chartjs-plugin-annotation";
import { useSelector } from "react-redux";
import { useAuthToken } from "../TokenContext";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

// Separate component for the popup to reduce complexity
const TicketPopup = ({ tickets, onClose }) => (
  <div className="popup">
    <div className="popup-content">
      <h2>Ticket Details</h2>
      <button onClick={onClose}>Close</button>
      <ul>
        {tickets?.map(({ issue_id, issue_key, created_date, talent_name, ticket_summary, ticket_description, ticket_status }) => (
          <li key={issue_id}>
            <p>Issue ID: {issue_id}</p>
            <p>Issue Key: {issue_key}</p>
            <p>Created Date: {created_date}</p>
            <p>Talent Name: {talent_name}</p>
            <p>Ticket Summary: {ticket_summary}</p>
            <p>Ticket Description: {ticket_description}</p>
            <p>Ticket Status: {ticket_status}</p>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

// Chart configuration object
const getChartConfig = (chartData, handleChartClick) => ({
  type: "doughnut",
  data: {
    labels: ["Open Tickets", "Closed Tickets"],
    datasets: [{
      label: "Tickets",
      data: chartData,
      backgroundColor: ["lightgreen", "red"],
      borderWidth: 1
    }]
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      annotation: {},
      legend: {
        labels: {
          generateLabels: () => []
        }
      }
    },
    layout: {
      padding: {
        top: 10,
        bottom: 10,
        left: 10,
        right: 10
      }
    },
    tooltips: {
      enabled: true,
      mode: "single",
      callbacks: {
        label: (tooltipItem, data) => {
          const { dataIndex, datasetIndex } = tooltipItem;
          return data.datasets[datasetIndex].data[dataIndex];
        }
      }
    },
    onClick: handleChartClick
  }
});

const TicketsDetailviewmetricsTalent = ({ initialPodIds }) => {
  const [chartData, setChartData] = useState({
    talentData: [],
    totalTickets: { open_tickets: 0, closed_tickets: 0 },
    openTickets: [],
    closedTickets: []
  });
  const [uiState, setUiState] = useState({
    selectedTalent: "",
    selectedTicket: null,
    isPopupVisible: false,
    isDataAvailable: false
  });
  
  const chartRef = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const token = useAuthToken();
  const intl = useIntl();

  const fetchTalentMetrics = async () => {
    if (!token) return;

    try {
      const apiUrl = process.env.REACT_APP_TALENT_JIRATICKETSMETRICSTALENT;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token
        },
        body: JSON.stringify({
          talent_id: user.data[0].c_id,
          pod_id: initialPodIds || []
        })
      });

      if (!response.ok) throw new Error(response.statusText);

      const data = await response.json();
      if (Object.keys(data).length === 0) {
        console.log("No data available");
        return;
      }

      setChartData({
        talentData: data.talent_data,
        totalTickets: data.total_tickets,
        openTickets: data.open_tickets,
        closedTickets: data.closed_tickets
      });
    } catch (error) {
      console.error("Error fetching talent metrics:", error);
    }
  };

  const generatePieData = () => {
    const { totalTickets, talentData } = chartData;
    const { selectedTalent } = uiState;

    if (!totalTickets || Object.keys(totalTickets).length === 0) return null;
    
    if (!selectedTalent) {
      return [totalTickets.open_tickets || 0, totalTickets.closed_tickets || 0];
    }

    const talent = talentData.find(item => item.talent_name === selectedTalent);
    return talent ? [talent.open_tickets, talent.closed_tickets] : null;
  };

  const handleChartClick = (event, elements) => {
    if (elements.length > 0) {
      const segmentIndex = elements[0].index;
      const ticketData = segmentIndex === 0 ? chartData.openTickets : chartData.closedTickets;
      setUiState(prev => ({
        ...prev,
        selectedTicket: ticketData,
        isPopupVisible: true
      }));
    }
  };

  const updateChart = () => {
    const pieData = generatePieData();
    const canvas = document.getElementById("talentChart");
    
    if (!canvas) return;

    if (pieData === null) {
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.font = "16px Arial";
      ctx.textAlign = "center";
      ctx.fillText("No data available", canvas.width / 2, canvas.height / 2 + 8);
      setUiState(prev => ({ ...prev, isDataAvailable: false }));
      return;
    }

    setUiState(prev => ({ ...prev, isDataAvailable: true }));
    if (chartRef.current) chartRef.current.destroy();
    
    chartRef.current = new Chart(canvas, getChartConfig(pieData, handleChartClick));
  };

  useEffect(() => {
    fetchTalentMetrics();
  }, [initialPodIds, token, user.data]);

  useEffect(() => {
    updateChart();
  }, [uiState.selectedTalent, chartData]);

  return (
    <div className="chart-container">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h2 className="chart-heading">Tickets</h2>
        <br />
        <select
          value={uiState.selectedTalent}
          onChange={(e) => setUiState(prev => ({ ...prev, selectedTalent: e.target.value }))}
          style={{ width: "12rem" }}
        >
          <option value="">{intl.formatMessage({ id: "allReporters" })}</option>
          {chartData.talentData?.map(({ talent_name }) => (
            <option key={talent_name} value={talent_name}>
              {talent_name}
            </option>
          ))}
        </select>
        
        <div className="chart-wrapper" style={{
          width: "170px",
          height: "170px",
          margin: "auto",
          textAlign: "center",
          marginTop: "5px"
        }}>
          {chartData.talentData?.length > 0 && uiState.isDataAvailable ? (
            <canvas id="talentChart" />
          ) : (
            <p style={{ fontSize: "16px", textAlign: "center" }}>No data available</p>
          )}
        </div>
      </div>

      {uiState.isPopupVisible && (
        <TicketPopup 
          tickets={uiState.selectedTicket}
          onClose={() => setUiState(prev => ({
            ...prev,
            isPopupVisible: false,
            selectedTicket: null
          }))}
        />
      )}
    </div>
  );
};

TicketsDetailviewmetricsTalent.propTypes = {
  initialPodIds: PropTypes.array.isRequired
};

export default TicketsDetailviewmetricsTalent;