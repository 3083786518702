import React, { useState, useEffect } from 'react';
  import { useSelector } from 'react-redux';
  import { useAuthToken } from "../TokenContext";
  import axios from 'axios';
  import moment from 'moment';
  import styled from 'styled-components';
  import { useIntl } from 'react-intl';
  import { 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper,
    Dialog,
    DialogTitle,
    DialogContent
  } from '@mui/material';
  import { 
    MdSearch, 
    MdCalendarToday, 
    MdFilterList,
    MdEdit,
    MdVisibility,
    MdVideoLibrary,
    MdWarning,
    MdCheckCircle,
    MdAccessTime,
    MdArrowUpward,
    MdArrowDownward
  } from 'react-icons/md';
  

const Podactivityviewalltalent = ({ podId }) => {
  const token = useAuthToken();
  const { user } = useSelector(state => state.auth);
  
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  
  // Filtering and Sorting States
  const [searchTerm, setSearchTerm] = useState('');
  const [dateFilter, setDateFilter] = useState('all');
  const [statusFilter, setStatusFilter] = useState('all');
  const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'desc' });
  
  // Fetch activities
  useEffect(() => {
    const fetchActivities = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          process.env.REACT_APP_TALENT_PODACTIVITYDASHBORDTALENT,
          {
            talent_id: user.data[0].c_id,
            status: "view_all"
          },
          {
            headers: {
              'Authorization': token,
              'Content-Type': 'application/json',
            }
          }
        );

        if (response.data?.data?.data) {
          setActivities(response.data.data.data);
        }
      } catch (err) {
        console.error('Error fetching activities:', err);
        setError('Failed to load activities');
      } finally {
        setLoading(false);
      }
    };

    fetchActivities();
  }, [token, user.data]);

  // Handle video playback
  const handleViewVideo = async (loomUrl) => {
    try {
      const filename = loomUrl.split('/').slice(-1)[0];
      const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONTs3;
      const fullUrl = cloudFrontUrl + filename;

      const response = await axios.post(
        process.env.REACT_APP_TALENT_CLOUDFRONTAPI,
        { "url": fullUrl }
      );

      const responseBody = JSON.parse(response.data.body);
      const signedUrl = responseBody.signedUrl;

      if (signedUrl) {
        window.open(signedUrl, '_blank');
      }
    } catch (error) {
      console.error('Error accessing video:', error);
      alert('Error accessing video. Please try again later.');
    }
  };

  // Helper function to parse jira issues
const parseJiraIssues = (jiraIssue) => {
  if (!jiraIssue) return [];
  if (Array.isArray(jiraIssue)) return jiraIssue;
  try {
    // If it's a string, try to parse it as JSON
    const parsed = JSON.parse(jiraIssue);
    return Array.isArray(parsed) ? parsed : [parsed];
  } catch {
    // If it's a comma-separated string
    if (typeof jiraIssue === 'string') {
      return jiraIssue.split(',').map(issue => issue.trim()).filter(Boolean);
    }
    return [];
  }
};

// Update your filtering logic
const filteredActivities = activities.filter(activity => {
  const jiraIssues = parseJiraIssues(activity.jira_issue);
  
  const matchesSearch = searchTerm === '' || (
    (activity.today_focus?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    activity.accomplishments?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    jiraIssues.some(issue => issue.toLowerCase().includes(searchTerm.toLowerCase())))
  );

  const matchesDate = dateFilter === 'all' || (() => {
    const activityDate = moment(activity.createdAt);
    switch(dateFilter) {
      case 'today':
        return activityDate.isSame(moment(), 'day');
      case 'week':
        return activityDate.isAfter(moment().subtract(7, 'days'));
      case 'month':
        return activityDate.isAfter(moment().subtract(30, 'days'));
      default:
        return true;
    }
  })();

  const matchesStatus = statusFilter === 'all' || 
    (statusFilter === 'blocked' && activity.blockers) ||
    (statusFilter === 'onTrack' && !activity.blockers);

  return matchesSearch && matchesDate && matchesStatus;
});

// Update the table cell rendering


// And the dialog rendering


  // Sorting logic
  const sortedActivities = [...filteredActivities].sort((a, b) => {
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    if (sortConfig.direction === 'asc') {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  });

  const handleSort = (key) => {
    setSortConfig(prev => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

 // Add these new state variables at the top of your component
const [editMode, setEditMode] = useState(false);
const [editingActivity, setEditingActivity] = useState(null);

// Add this function to handle edit submission
const handleEditSubmit = async (updatedActivity) => {
  try {
    const updatedData = {
      poddata: [{
        today_focus: updatedActivity.today_focus,
        blockers: updatedActivity.blockers,
        loom_url: updatedActivity.loom_url,
        accomplishments: updatedActivity.accomplishments,
        categories: updatedActivity.categories,
        jira_issue: parseJiraIssues(updatedActivity.jira_issue),
        id: updatedActivity.id
      }]
    };

    const response = await axios.post(
      process.env.REACT_APP_TALENT_PODACTIVITYUPDATETALENT,
      updatedData,
      {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        }
      }
    );

    if (response.status === 200) {
      // Update the activities list with edited data
      setActivities(prevActivities => 
        prevActivities.map(activity => 
          activity.id === updatedActivity.id ? updatedActivity : activity
        )
      );
      setEditMode(false);
      setEditingActivity(null);
      alert("Successfully updated activity.");
    }
  } catch (error) {
    console.error('Error updating activity:', error);
    alert('Failed to update activity. Please try again.');
  }
};

// Add the EditDialog component
const EditDialog = ({ activity, open, onClose }) => {
  const [editedActivity, setEditedActivity] = useState({...activity});

  const handleChange = (field, value) => {
    setEditedActivity(prev => ({
      ...prev,
      [field]: value
    }));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Activity</DialogTitle>
      <DialogContent>
        <EditForm>
          <EditField>
            <DetailTitle>Jira Issues</DetailTitle>
            <EditInput
              value={editedActivity.jira_issue}
              onChange={(e) => handleChange('jira_issue', e.target.value)}
              placeholder="Comma-separated Jira issues"
            />
          </EditField>

          <EditField>
            <DetailTitle>Today's Focus</DetailTitle>
            <EditTextarea
              value={editedActivity.today_focus}
              onChange={(e) => handleChange('today_focus', e.target.value)}
              placeholder="Today's focus..."
            />
          </EditField>

          <EditField>
            <DetailTitle>Accomplishments</DetailTitle>
            <EditTextarea
              value={editedActivity.accomplishments}
              onChange={(e) => handleChange('accomplishments', e.target.value)}
              placeholder="Accomplishments..."
            />
          </EditField>

          <EditField>
            <DetailTitle>Blockers</DetailTitle>
            <EditTextarea
              value={editedActivity.blockers}
              onChange={(e) => handleChange('blockers', e.target.value)}
              placeholder="Any blockers..."
            />
          </EditField>

          <ButtonGroup>
            <CancelButton onClick={onClose}>
              Cancel
            </CancelButton>
            <SaveButton onClick={() => {
              handleEditSubmit(editedActivity);
              onClose();
            }}>
              Save Changes
            </SaveButton>
          </ButtonGroup>
        </EditForm>
      </DialogContent>
    </Dialog>
  );
};


 
    const ActivityDetailDialog = ({ activity, open, onClose }) => (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Activity Details</DialogTitle>
        <DialogContent>
          <DetailContainer>
            <DetailHeader>
              <DetailDate>
                <MdCalendarToday />
                {moment(activity.createdAt).format('MMMM DD, YYYY')}
              </DetailDate>
              <StatusBadge isBlocked={activity.blockers}>
                {activity.blockers ? "Blocked" : "On Track"}
              </StatusBadge>
            </DetailHeader>
  
            
            <DetailSection>
  <DetailTitle>Jira Issues</DetailTitle>
  <BadgeContainer>
    {parseJiraIssues(activity.jira_issue).map((issue, index) => (
      <IssueBadge key={`${issue}-${index}`}>{issue}</IssueBadge>
    ))}
  </BadgeContainer>
</DetailSection>
  
            <DetailSection>
              <DetailTitle>Today's Focus</DetailTitle>
              <DetailText>{activity.today_focus}</DetailText>
            </DetailSection>
  
            {activity.accomplishments && (
              <DetailSection>
                <DetailTitle>Accomplishments</DetailTitle>
                <DetailText>{activity.accomplishments}</DetailText>
              </DetailSection>
            )}
  
            {activity.blockers && (
              <DetailSection>
                <DetailTitle>Blockers</DetailTitle>
                <DetailText color="red">{activity.blockers}</DetailText>
              </DetailSection>
            )}
  
            {activity.loom_url && (
              <DetailSection>
                <DetailTitle>Video Update</DetailTitle>
                <VideoButton onClick={() => handleViewVideo(activity.loom_url)}>
                  <MdVideoLibrary />
                  View Video
                </VideoButton>
              </DetailSection>
            )}
          </DetailContainer>
        </DialogContent>
      </Dialog>
    );
    const [dialogOpen, setDialogOpen] = useState(false);
  
    return (
      <Container>
        <Header>
          <Title>Activity History</Title>
        </Header>
  
        <FiltersContainer>
          <SearchContainer>
            <SearchIcon />
            <SearchInput
              placeholder="Search activities..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </SearchContainer>
          
          <FilterSelect
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
          >
            <option value="all">All Time</option>
            <option value="today">Today</option>
            <option value="week">This Week</option>
            <option value="month">This Month</option>
          </FilterSelect>
  
          <FilterSelect
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <option value="all">All Status</option>
            <option value="blocked">Blocked</option>
            <option value="onTrack">On Track</option>
          </FilterSelect>
        </FiltersContainer>
  
        {loading ? (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        ) : error ? (
          <ErrorContainer>
            <MdWarning size={32} />
            <ErrorText>{error}</ErrorText>
          </ErrorContainer>
        ) : sortedActivities.length === 0 ? (
          <EmptyContainer>
            <MdAccessTime size={48} />
            <EmptyTitle>No activities found</EmptyTitle>
            <EmptyText>Try adjusting your filters or create a new activity.</EmptyText>
          </EmptyContainer>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell 
                    onClick={() => handleSort('createdAt')}
                    style={{ cursor: 'pointer' }}
                  >
                    Date
                    {sortConfig.key === 'createdAt' && (
                      sortConfig.direction === 'asc' ? 
                      <MdArrowUpward /> : 
                      <MdArrowDownward />
                    )}
                  </StyledTableCell>
                  <StyledTableCell>Talent Name</StyledTableCell>
                  <StyledTableCell>Jira Issues</StyledTableCell>
                  <StyledTableCell>Today's Focus</StyledTableCell>
                  <StyledTableCell>Accomplishments</StyledTableCell>

                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedActivities.map((activity) => (
                  <StyledTableRow key={activity.id}>
                    <TableCell>
                      {moment(activity.createdAt).format('MMM DD, YYYY')}
                    </TableCell>
                    <TableCell style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {activity.pod_candidates}
                    </TableCell>
                    <TableCell>
  <BadgeContainer>
    {parseJiraIssues(activity.jira_issue).map((issue, index) => (
      <IssueBadge key={`${issue}-${index}`}>{issue}</IssueBadge>
    ))}
  </BadgeContainer>
</TableCell>
                    <TableCell style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {activity.today_focus}
                    </TableCell>

                    <TableCell style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {activity.accomplishments}
                    </TableCell>
                    <TableCell>
                      <StatusBadge isBlocked={activity.blockers}>
                        {activity.blockers ? (
                          <><MdWarning /> Blocked</>
                        ) : (
                          <><MdCheckCircle /> On Track</>
                        )}
                      </StatusBadge>
                    </TableCell>
                    

<TableCell>
  <ActionButtons>
    <ActionButton onClick={() => {
      setSelectedActivity(activity);
      setDialogOpen(true);
    }}>
      <MdVisibility />
    </ActionButton>
    
    {activity.posted_to_client === 0 && (
      <ActionButton onClick={() => {
        setEditingActivity(activity);
        setEditMode(true);
      }}>
        <MdEdit />
      </ActionButton>
    )}
  </ActionButtons>
</TableCell>


{editMode && editingActivity && (
  <EditDialog
    activity={editingActivity}
    open={editMode}
    onClose={() => {
      setEditMode(false);
      setEditingActivity(null);
    }}
  />
)}

                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
  
        {selectedActivity && (
          <ActivityDetailDialog 
            activity={selectedActivity}
            open={dialogOpen}
            onClose={() => {
              setDialogOpen(false);
              setSelectedActivity(null);
            }}
          />
        )}
      </Container>
    );
  };
  
  // Styled Components
  const Container = styled.div`
    // padding: 2rem;
    // background: white;
    // border-radius: 8px;
    // box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  `;
  
  const Header = styled.div`
    margin-bottom: 2rem;
  `;
  
  const Title = styled.h2`
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
  `;
  
  const FiltersContainer = styled.div`
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
    flex-wrap: wrap;
    
    @media (max-width: 768px) {
      flex-direction: column;
    }
  `;
  
  const SearchContainer = styled.div`
    position: relative;
    flex: 1;
  `;
  
  const SearchIcon = styled(MdSearch)`
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #6B7280;
  `;
  
  const SearchInput = styled.input`
    width: 100%;
    padding: 0.75rem 0.75rem 0.75rem 2.5rem;
    border: 1px solid #D1D5DB;
    border-radius: 4px;
    outline: none;
    
    &:focus {
      border-color: #00B4D8;
    }
  `;
  
  const FilterSelect = styled.select`
    padding: 0.75rem;
    border: 1px solid #D1D5DB;
    border-radius: 4px;
    min-width: 180px;
    outline: none;
    
    &:focus {
      border-color: #00B4D8;
    }
  `;
  
  const StyledTableCell = styled(TableCell)`
    background-color: #00B4D8;
    color: white;
    font-weight: 600;
  `;
  
  const StyledTableRow = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: #caf0f8;
    }
    &:nth-of-type(even) {
      background-color: #90E0EF;
    }
  `;
  
  const BadgeContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  `;
  
  const IssueBadge = styled.span`
    padding: 0.25rem 0.5rem;
    background: #E5E7EB;
    border-radius: 9999px;
    font-size: 0.875rem;
  `;
  
  const StatusBadge = styled.span`
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.875rem;
    color: white;
    background: ${props => props.isBlocked ? '#EF4444' : '#10B981'};
  `;
  
  const ActionButtons = styled.div`
    display: flex;
    gap: 0.5rem;
  `;
  
  const ActionButton = styled.button`
    padding: 0.5rem;
    border: none;
    background: none;
    color: #4B5563;
    cursor: pointer;
    transition: color 0.2s;
    
    &:hover {
      color: #00B4D8;
    }
  `;
  
  // Detail Dialog Styled Components
  const DetailContainer = styled.div`
    padding: 1.5rem;
  `;
  
  const DetailHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  `;
  
  const DetailDate = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #6B7280;
  `;
  
  const DetailSection = styled.div`
    margin-bottom: 1.5rem;
  `;
  
  const DetailTitle = styled.h4`
    font-size: 0.875rem;
    font-weight: 500;
    color: #6B7280;
    margin-bottom: 0.5rem;
  `;
  
  const DetailText = styled.p`
    font-size: 0.875rem;
    color: ${props => props.color || '#374151'};
  `;
  
  const VideoButton = styled.button`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid #D1D5DB;
    border-radius: 4px;
    background: white;
    color: #374151;
    cursor: pointer;
    transition: all 0.2s;
    
    &:hover {
      border-color: #00B4D8;
      color: #00B4D8;
    }
  `;
  
  const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  `;
  
  const Spinner = styled.div`
    border: 4px solid #f3f3f3;
    border-top: 4px solid #00B4D8;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;
  
  const ErrorContainer = styled.div`
    text-align: center;
    padding: 2rem;
    color: #EF4444;
  `;
  
  const ErrorText = styled.p`
    margin-top: 1rem;
  `;
  
  const EmptyContainer = styled.div`
    text-align: center;
    padding: 3rem;
    color: #6B7280;
  `;
  
  const EmptyTitle = styled.h3`
    margin-top: 1rem;
    font-size: 1.125rem;
    font-weight: 500;
    color: #111827;
  `;
  
  const EmptyText = styled.p`
    margin-top: 0.5rem;
    font-size: 0.875rem;
  `;
  // Add these new styled components
const EditForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
`;

const EditField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const EditInput = styled.input`
  padding: 0.5rem;
  border: 1px solid #D1D5DB;
  border-radius: 4px;
  font-size: 0.875rem;
  
  &:focus {
    outline: none;
    border-color: #00B4D8;
  }
`;

const EditTextarea = styled.textarea`
  padding: 0.5rem;
  border: 1px solid #D1D5DB;
  border-radius: 4px;
  font-size: 0.875rem;
  min-height: 100px;
  resize: vertical;
  
  &:focus {
    outline: none;
    border-color: #00B4D8;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
`;

const SaveButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: #00B4D8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  
  &:hover {
    background-color: #0096C7;
  }
`;

const CancelButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: #F3F4F6;
  color: #4B5563;
  border: 1px solid #D1D5DB;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  
  &:hover {
    background-color: #E5E7EB;
  }
`;
  export default Podactivityviewalltalent;