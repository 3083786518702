import React, { useState } from "react";
import styled from "styled-components";
import profile from "./assets/profile_img.png";
import "./FindPods.css";
import { useEffect } from "react";
import axios from "axios";
import userEvent from "@testing-library/user-event";
import { useDispatch, useSelector } from "react-redux";
import { data } from "jquery";
import FindPodDetailsClient from "./FindPodDetailsClient";
import { NavHashLink } from "react-router-hash-link";
import star from "./assets/star.png";
import { useAuthToken } from "../TokenContext";
import { useIntl } from "react-intl";
import defaultimg from './assets/defaultimge.png';

const FindPodsClient = () => {
  const token = useAuthToken();
  const intl = useIntl();

  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(3);
  const [loading, setLoading] = useState(true);
  const [Subscription, setSubscription] = useState({});
  const [subscrictionfetch, setSubscriptionfetch] = useState([]);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const [getData, setGetData] = useState([]);
  const [requirement, setRequirement] = useState([]);
  const [sec, setSec] = useState("");
  const [psize, setPsize] = useState("");
  const [mis, setMis] = useState("");
  const [tz, setTz] = useState("");
  const [Title, setTitle] = useState("");
  const [pref, setPref] = useState("");

  useEffect(() => {
    const body = {
      client_code: user.data[0].client_code,
    };

    const headers = {
      Authorization: token, // Replace with your actual token
      "Content-Type": "application/json", // You can add more headers as needed
    };

    // Check if the client code contains "PARTNER"
    if (user.data[0].client_code.includes("PARTNER")) {
      if (!token) {
        console.log("Token is missing. API requests will not be made.");
        return;
      }
      // Second API call
      axios
        .post(process.env.REACT_APP_PARTNER_FTECHSUBSCCRIPTIONPARTNER, body, {
          headers: headers,
        })
        .then((res) => {
          // Process the response as needed for the second API
        
          // Set state with the response data
          setSubscription(res.data.data);
          setSubscriptionfetch(res.data.data[0].find_pods);
          setLoading(false);
        })
        .catch((err) => {
          console.log("Error in the second API call:", err);
          // Handle errors if needed
          setLoading(false);
        });
    } else {
      // First API call
      if (!token) {
        console.log("Token is missing. API requests will not be made.");
        return;
      }
      axios
        .post(process.env.REACT_APP_FETCHSUBSCRIPTIONDATA, body, {
          headers: headers,
        })
        .then((res) => {
          // Process the response as needed for the first API
       
          setSubscription(res.data.data);
          setSubscriptionfetch(res.data.data[0].find_pods);
          setLoading(false);
        })
        .catch((err) => {
          console.log("Error in the first API call:", err);
          setLoading(false);
        });
    }
  }, [token]);

  const jobtitle = (val) => {
    setTitle(val.target.value);
  };

  const secondaryfield = (val) => {
    setSec(val.target.value);
  };

  const podsizefield = (val) => {
    setPsize(val.target.value);
  };
  const resetSecondaryField = () => {
    setSec("");
  };

  function secskill(val) {
    setSec(val.target.value);
  }
  const timezonefield = (val) => {
    setTz(val.target.value);
  };
  const resetTimezoneField = () => {
    setTz("");
  };

  function tmzone(val) {
    setTz(val.target.value);
  }

  const handleJob = (val) => {
    setMis(val.target.value);
  };
  const resetJobField = () => {
    setMis("");
  };

  function getData1(val) {
    setMis(val.target.value);
  }

  const preferfield = (val) => {
    setPref(val.target.value);
  };
  const resetPreferField = () => {
    setPref("");
  };
  function preferworkhours(val) {
    setPref(val.target.value);
    console.log(val.target.value);
  }

  const per = () => {
    const kk = mis != "default" ? mis : "";
    const tmz = tz != "default" ? tz : "";
    const ss = sec != "default" ? sec : "";
    const Prefered = pref != "default" ? pref : "";
    const size = psize != "default" ? psize : "";
    const body = {
      emp_id: user.data[0].emp_id,
      Client_Code: user.data[0].client_code,
      podlocation: kk,
      podtype: ss,
      budget: Prefered,
      podSize: size,
      timezone: tmz,
      manager_type: user.data[0].manager_type,
    };

    const headers = {
      Authorization: token, // Replace with your actual token
      "Content-Type": "application/json", // You can add more headers as needed
    };

    // https://3jexqpgo7j.execute-api.us-east-1.amazonaws.com/dev/clientfindpods
    axios
      .post(process.env.REACT_APP_CLIENT_CLIENTFINDPODS, body, {
        headers: headers,
      })

      .then((res) => {
        setGetData(res.data.data);
      })
      .catch((err) => {
        console.log("catch", err);
      });
  };

  useEffect(
    (token) => {
      per(token);
    },
    [token]
  );

  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(getData.length / itemsPerPage); i++) {
    pages.push(i);
  }
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = getData.slice(indexOfFirstItem, indexOfLastItem);
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  const renderdata = (getData) => {
    return (
      <Cards>
        {getData && getData.length > 0 ? (
          getData.map((data, i) => {
            return (
              <OL1>
                <Div>
                  <li>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <div className="name">
                        {" "}
                        {data.picture == null || data.picture == '' ? (
              <img
                src={defaultimg}
                alt=""
                style={{ width: 50, height: 50, borderRadius: "50px" }}
              ></img>
            ) : (
              <img
                style={{ width: '50px', height: '50px', borderRadius: "50px" }}
                src={'https://globldataprod.s3.amazonaws.com/' + data.picture}
                alt=""
              ></img>
            )}
                        <p className="firstname">
                          <img width="18px" height="18px" src={star}></img>

                          {data.avgrating == null ? 0 : data.avgrating}
                        </p>
                      </div>
                      <div>
                        {data.Client_Code === user.data[0].Client_Code ? (
                          <TD1 style={{ color: "blue" }}>
                            {intl.formatMessage({ id: "internalPod" })}{" "}
                          </TD1>
                        ) : (
                          <TD1 style={{ color: "red" }}>
                            {intl.formatMessage({ id: "externalPod" })}{" "}
                          </TD1>
                        )}
                      </div>
                    </div>
                    <div className="name2">
                      {" "}
                      <tr className="TR">
                        <td className="TD">
                          {intl.formatMessage({ id: "podName" })}
                        </td>{" "}
                        <td>:</td>
                        <TD1> {data.podName} </TD1>
                      </tr>
                    </div>

                    <div className="name2">
                      {" "}
                      <tr className="TR">
                        <td className="TD">
                          {intl.formatMessage({ id: "email" })}
                        </td>{" "}
                        <td>:</td>
                        <TD1> {data.email}</TD1>
                      </tr>
                    </div>

                    {/*                                     
                                    <div className='name2'> <tr  className='TR'><td className='TD'  >Clients</td> <td>:</td><TD1 > hg</TD1></tr></div> */}

                    {/* <div className='name2'> 
                                    <tr  className='TR'><td className='TD' >{intl.formatMessage({ id: 'portfolio' })}</td> <td>:</td><TD1 > {data.portfolioUrl}</TD1></tr></div> */}

                    <div className="name2">
                      <tr className="TR">
                        <td className="TD">
                          {intl.formatMessage({ id: "country" })}
                        </td>{" "}
                        <td>:</td>
                        <TD1> {data.location}</TD1>
                      </tr>
                    </div>

                    <div className="name2">
                      {" "}
                      <tr className="TR">
                        <td className="TD">
                          {intl.formatMessage({ id: "podSize" })}
                        </td>{" "}
                        <td>:</td>
                        <TD1> {data.podsize} </TD1>
                      </tr>
                    </div>
                    {user.data[0].manager_type !== "PartnerClient" ? (
                      <div className="name2">
                        {" "}
                        <tr className="TR">
                          <td className="TD">
                            {intl.formatMessage({ id: "podrate" })}
                          </td>{" "}
                          <td>:</td>
                          <TD1> {data.pototalrate} </TD1>
                        </tr>
                      </div>
                    ) : (
                      <div className="name2">
                        {" "}
                        <tr className="TR">
                          <td className="TD">
                            {intl.formatMessage({ id: "podrate" })}
                          </td>{" "}
                          <td>:</td>
                          <TD1> {data.pod_rate} </TD1>
                        </tr>
                      </div>
                    )}
                    <div className="name2">
                      {" "}
                      <tr className="TR">
                        <td className="TD">
                          {intl.formatMessage({ id: "podType" })}
                        </td>{" "}
                        <td>:</td>
                        <TD1> {data.podtype} </TD1>
                      </tr>
                    </div>

                    {/* { 
                                             data.requrment.map((hepline)=>{
return <div> <div className='name2'> <tr  className='TR'><td className='TD' >Position</td> <td>:</td>  <TD1  >{hepline.position} </TD1> </tr></div>
<div className='name2'> <tr  className='TR'><td className='TD' >Skills</td><td>:</td>{ hepline.reqskill.map((sk) => { return   <span style={{margin:'5px'}}>{sk.skill}<span>,</span> </span> }) }</tr></div>
</div>})}  */}

                    <NavHashLink to={`/FindPodDetailsClient/${data.id}`} smooth>
                      <DetailsButton>
                        {intl.formatMessage({ id: "viewPodDetails" })}{" "}
                      </DetailsButton>
                    </NavHashLink>
                  </li>
                </Div>
              </OL1>
            );
          })
        ) : (
          <p style={{ textAlign: "center" }}>
            {intl.formatMessage({ id: "noPods" })}{" "}
          </p>
        )}
      </Cards>
    );
  };

  return (
    <Return>
      {loading ? (
        <div>{intl.formatMessage({ id: "loading" })}...</div> // Show a loading indicator while waiting for the API call to complete.
      ) : Subscription === "There Are No subscriction data." ||
        subscrictionfetch === "False" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: "8rem",
          }}
        >
          {intl.formatMessage({ id: "noSubscription" })}
        </div>
      ) : (
        <div>
          <Headingtext1>
            {" "}
            {intl.formatMessage({ id: "findBestPod" })}
          </Headingtext1>

          <ol className="numbers">
            <Num2>
              <li>
                <button
                  onClick={handlePrevbtn}
                  disabled={currentPage == pages[0] ? true : false}
                >
                  {intl.formatMessage({ id: "prev" })}
                </button>
              </li>
              <Numbers>{renderPageNumbers} </Numbers>
              <li>
                <button
                  onClick={handleNextbtn}
                  disabled={
                    currentPage == pages[pages.length - 1] ? true : false
                  }
                >
                  {" "}
                  {intl.formatMessage({ id: "next" })}
                </button>
              </li>
            </Num2>
          </ol>

          <Main>
            <Table>
              <Row>
                {/* <tr> */}
                <Row1>
                  {/*                  
                    <td><select className='options'  value={sec} onChange={((val)=>setSec(val.target.value), secondaryfield )} >
                      <option value='default' >Secondary Skill </option>
                      <option  value='Docker' >     Docker</option>
      <option value='PHP'  >PHP</option>
      <option   value='SQL'   >SQL</option>
      <option  value='Git'  >Git</option>
      <option value='Perl'  >Perl</option>
      <option  value='JavaScript'   >JavaScript</option>
      <option  value='Kotlin'   >Kotlin</option>
      <option value='Azure'  >Azure</option>
      <option   value='AWS'   >AWS</option>
      <option   value='AWS'   >Java</option>
      <option   value='AWS'   >c++</option>
      <option value='html'> HTML/CSS</option>
                      <option  value='JavaScript'>JavaScript/jQuery</option>
                      <option value='Adobe'>Adobe Illustrator</option>
                      <option value='React'>ReactJS</option>
                      <option value='AngularJS'>AngularJS</option>
                      <option value='Vue'>Vue</option>
                      <option value='Next'>Next</option>
                      <option value='Typescript'> Typescript</option>
                      <option  value='Gatsby'>Gatsby</option>

<option  value='ThreeJs'>Three.js</option>
                      <option value='NodeJs'>Node.js</option>
                      <option  value='Java'>Java</option>

                      </select></td> */}

                  <td>
                    <select
                      className="options"
                      value={tz}
                      onChange={
                        ((val) => setTz(val.target.value), timezonefield)
                      }
                    >
                      <option value="default">Time Zone</option>
                      <option
                        style={{ color: "black" }}
                        value="Indian standard time"
                      >
                        Indian standard time
                      </option>
                      <option
                        style={{ color: "black" }}
                        value="Eastern standard time"
                      >
                        Eastern standard time
                      </option>
                      <option
                        style={{ color: "black" }}
                        value="Central standard time"
                      >
                        Central standard time
                      </option>
                      <option
                        style={{ color: "black" }}
                        value="Mountain standard time"
                      >
                        Mountain standard time
                      </option>
                      <option
                        style={{ color: "black" }}
                        value="Pacific standard time"
                      >
                        Pacific standard time
                      </option>
                    </select>
                  </td>
                  <td>
                    <select
                      className="options"
                      value={psize}
                      onChange={
                        ((val) => setPsize(val.target.value), podsizefield)
                      }
                    >
                      <option value="default">Pod Size</option>
                      <option>7</option>
                      <option>5</option>
                      <option>2</option>
                    </select>
                  </td>
                  <td>
                    <select
                      className="options"
                      value={sec}
                      onChange={
                        ((val) => setSec(val.target.value), secondaryfield)
                      }
                    >
                      <option value="default">Pod Type</option>
                      <option value="Saas">Saas</option>
                      <option value="Web app">Web app</option>
                      <option value="Java Developer">Java Developer</option>
                    </select>
                  </td>
                </Row1>

                <Row2>
                  <td>
                    <select
                      className="options"
                      value={mis}
                      onChange={((val) => setMis(val.target.value), handleJob)}
                    >
                      <option value="default">Location</option>
                      <option value="usa">Asia</option>
                      <option value="india">North America</option>
                      <option value="canada">Latin America</option>
                    </select>
                  </td>
                </Row2>

                <tr></tr>
                <tr></tr>
                {/* </tr> */}
                <tr>
                  {" "}
                  <td>
                    <button
                      className="optionsaplly"
                      style={{
                        width: "10rem",
                        height: "2rem",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        backgroundColor: "#1DBF73",
                        boxShadow: "1px 6px 9px #00000029",
                        fontFamily: "Poppins,Regular",
                        fontWeight: "500",
                      }}
                      onClick={() => per()}
                    >
                      Apply Filters
                    </button>
                  </td>
                </tr>
              </Row>
            </Table>
          </Main>

          {renderdata(currentItems)}
        </div>
      )}
    </Return>
  );
};

export default FindPodsClient;

const OL1 = styled.ol`
  list-style: none;
  margin: auto;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
  }
`;

const Return = styled.ol`
  margin: auto;

  margin-top: 5rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
  }
`;

const Div = styled.div`
  margin-top: 5rem;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  margin-left: 4rem;
  height: 38rem;
  width: 25rem;
  border-radius: 20px;
  margin-bottom: 5rem;
  background-color: #caf0f8;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    margin: auto;
    width: 80vw;
    height: max-height;
    margin-top: 5rem;
    margin-left:3rem;
  }
`;

const Cards = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;

    flex-direction: column;
    align-items: center;
    margin: auto;
    justify-content: center;
  }
`;

const TD1 = styled.td`
  margin-left: 2rem;
  width: 12rem;
  overflow-wrap: anywhere;
  font-weight: 600;
  font-family: Poppins, medium;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    margin: auto;
    margin-left: 2rem;
    width: 7rem;
    overflow-wrap: anywhere;
  }
`;

const Num2 = styled.li`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;

    flex-direction: column;

    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
  }
`;

const Numbers = styled.li`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7rem;
  }
`;

const DetailsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 5px;

  color: white;
  border: none;
  border-radius: 5px;
  background-color: #1dbf73;
  box-shadow: 1px 3px 6px #00000029;

  margin-top: 4rem;
  margin-bottom: 2rem;
`;
const CreateButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding: 5px;
  width: 10rem;
  height: 3rem;
  font-size: 18px;
  font-weight: 600;
  color: white;
  border: none;
  border-radius: 5px;
  background-color: #1dbf73;
  box-shadow: 1px 3px 6px #00000029;

  margin-top: 4rem;
  margin-bottom: 2rem;
`;

const Headingtext1 = styled.h3`
  font-family: Poppins, Medium;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 5rem;
  font-weight: 600;
  font-size: 1.5rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    margin: auto;
    width: 80vw;

    padding-bottom: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 7rem;
    margin-left:4rem;
  }
`;

const Main = styled.tr`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    width: 100%;
  }
`;
const Table = styled.table`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    width: 20rem;
    height: max-content;
  }
`;
const Row = styled.div`
  background-color: white;
  height: max-content;
  padding-bottom: 1rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;
const Row1 = styled.tr`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    // margin:auto;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;
const Row2 = styled.tr`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    // margin:auto;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;
