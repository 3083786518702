import React, { useEffect, useState, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-plugin-annotation';
import './BarGraph.css';
import { v4 as uuidv4 } from 'uuid';
import { useAuthToken } from "../TokenContext";
const Githublinespiecharttalent = () => {
  const [metrics, setMetrics] = useState(null);
  const [talentUsers, setTalentUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const chartRefs = useRef({});
  const isMounted = useRef(true);
  const chartId = useRef(uuidv4()); // Generate a unique ID for the chart canvas

  const token = useAuthToken();

  useEffect(() => {
    Chart.register(...registerables);
    fetchMetrics();
    return () => {
      isMounted.current = false;
    };
  }, [selectedUser,token]);

  const fetchMetrics = async () => {
    try {
      //https://vp8gpv1v9a.execute-api.us-east-1.amazonaws.com/dev/githublinesofcodebytalent
      const response = await fetch(
        process.env.REACT_APP_TALENT_GITHUBLINESOFCODEBYTALENT,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : token,
          },
          body: JSON.stringify({
            github_username: "krishnareddy97",
            repository_name: 'testing',
            github_access_token:
              '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',
          }),
        }
      );

      const data = await response.json();
      if (isMounted.current) {
        if (data && data.body) {
          const { talent_users, total_lines } = JSON.parse(data.body);
         
          setTalentUsers(talent_users);
          const linesChangedThisWeek = total_lines.total_lines_changed_this_week;
          const linesChangedLastWeek = total_lines.total_lines_changed_last_week;
          setMetrics({ linesChangedThisWeek, linesChangedLastWeek });
        }
      }
    } catch (error) {
      console.log('Error fetching metrics:', error);
    }
  };
  useEffect(() => {
    if (metrics) {
      const { linesChangedThisWeek, linesChangedLastWeek } = metrics;
      if (selectedUser === '') {
        // If "All" is selected, display the total lines for all users
        const totalLinesChangedThisWeek = talentUsers.reduce((acc, user) => acc + user.total_lines_changed_this_week, 0);
        const totalLinesChangedLastWeek = talentUsers.reduce((acc, user) => acc + user.total_lines_changed_last_week, 0);
        displayChart('lines', [totalLinesChangedThisWeek, totalLinesChangedLastWeek]);
      } else {
        const userData = talentUsers.find((user) => user.github_user === selectedUser);
        if (userData) {
         
          const userLinesChangedThisWeek = userData.total_lines_changed_this_week;
          const userLinesChangedLastWeek = userData.total_lines_changed_last_week;
          displayChart('lines', [userLinesChangedThisWeek, userLinesChangedLastWeek]);
        }
      }
    }
  }, [metrics, selectedUser, talentUsers]);
  
  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  // ... (rest of the code)

  const displayChart = (chartLabel, chartData) => {
    if (chartRefs.current[chartId.current]) {
      chartRefs.current[chartId.current].destroy();
    }
  
    const ctx = document.getElementById(chartId.current);
  
    chartRefs.current[chartId.current] = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['linesChangedLastWeek', 'linesChangedThisWeek'],
        datasets: [
          {
            label: chartLabel,
            data: chartData,
            backgroundColor: ['seagreen', 'red'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          annotation: {},
          legend: {
            display: false,
          },
        },
        layout: {
          padding: {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10,
          },
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: function (tooltipItem, data) {
              const dataIndex = tooltipItem.dataIndex;
              const datasetIndex = tooltipItem.datasetIndex;
              const value = data.datasets[datasetIndex].data[dataIndex];
              const label = data.labels[dataIndex];
              return `${label}: ${value}`;
            },
          },
        },
      },
    });
  
    // If "All" is selected, display the total lines for all users
    if (selectedUser === '') {
      const totalLinesChangedThisWeek = talentUsers.reduce((acc, user) => acc + user.total_lines_changed_this_week, 0);
      const totalLinesChangedLastWeek = talentUsers.reduce((acc, user) => acc + user.total_lines_changed_last_week, 0);
      chartRefs.current[chartId.current].data.datasets[0].data = [totalLinesChangedLastWeek, totalLinesChangedThisWeek];
      chartRefs.current[chartId.current].update();
    }
  };
  



  return (
     <div className="chart-container">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 className="chart-heading">Lines of Code</h2>
        <br />
        <div className="chart-wrapper">
          {/* Use the generated unique ID for the chart canvas */}
          <canvas  id={chartId.current} width="150" height="150"></canvas> {/* Decreased width and height */}
        </div>
      </div>
    </div>
  );
};

export default Githublinespiecharttalent;
