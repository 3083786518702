import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useAuthToken } from "../TokenContext";
import { styled } from '@mui/material/styles';
import {
  Box,
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  Grid,
  Pagination,
  IconButton,
  Chip,
  Alert,
  InputLabel,
  Stack,
  Button,
  useTheme,
  InputAdornment
} from '@mui/material';
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Warning as WarningIcon,
  CheckCircle as CheckCircleIcon,
  Schedule as ScheduleIcon,
  FilterList as FilterListIcon,
  Clear as ClearIcon
} from '@mui/icons-material';



const StyledContainer = styled(Container)(({ theme, collapsed }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin:"auto",
  paddingTop: '80px', // Instead of margin-top
  marginBottom: theme.spacing(4),
 marginLeft: collapsed ? "60px" : "240px",
  transition: 'margin-left 0.3s ease',


  [theme.breakpoints.down('sm')]: {
    marginLeft: '0px',
    width: '90%',
    padding: theme.spacing(2),
    paddingTop: '80px',
    margin: '4rem auto', // Adjusted margin for mobile
  }
}));

const FilterPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey[50],
  borderRadius: theme.spacing(1),
  display: 'flex',
  gap: theme.spacing(2),
  boxShadow: 'none'
}));
const TalentViewTask = ({collapsed}) => {
  const [tasks, setTasks] = useState([]);
  const [sortedTasks, setSortedTasks] = useState([]);
  const [sortOrder, setSortOrder] = useState('desc');
  const [podNameFilter, setPodNameFilter] = useState('all');
  const [statusFilter, setStatusFilter] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const token = useAuthToken();
  const intl = useIntl();
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    fetchTasks();
  }, [token, user]);

  const fetchTasks = async () => {
    const headers = {
      'Authorization': token,
      'Content-Type': 'application/json',
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_TALENT_CLIENTVIEWMYTASK,
        { candidate_Id: user.data[0].c_id },
        { headers }
      );
      setTasks(response.data.data);
      setSortedTasks(response.data.data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  const handleTaskComplete = async (taskId, jiraItem, podId, candidateId, taskname) => {
    try {
      const payload = {
        pod_task_id: taskId,
        jira_item: jiraItem,
        pod_id: podId,
        candidateID: candidateId,
        status: 'done',
        pod_task_name: taskname,
      };
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json',
      };
      await axios.post(process.env.REACT_APP_TALENT_UPDATEPODTASKSTATUS, payload, { headers });
      fetchTasks();
    } catch (error) {
      console.error('Error marking task as complete:', error);
    }
  };

  const handleClearFilters = () => {
    setPodNameFilter('all');
    setStatusFilter('all');
  };

  const getStatusChipColor = (status) => {
    switch (status.toLowerCase()) {
      case 'done':
        return 'success';
      case 'in progress':
        return 'warning';
      default:
        return 'default';
    }
  };

  const getStatusIcon = (status) => {
    switch (status.toLowerCase()) {
      case 'done':
        return <CheckCircleIcon fontSize="small" />;
      case 'in progress':
        return <ScheduleIcon fontSize="small" />;
      default:
        return null;
    }
  };

  const filteredAndSortedTasks = sortedTasks
    .filter((task) => {
      const isStatusMatch = statusFilter === 'all' || task.status === statusFilter;
      const isPodNameMatch = podNameFilter === 'all' || task.project_key.toLowerCase() === podNameFilter;
      return isStatusMatch && isPodNameMatch;
    })
    .sort((a, b) => {
      const dateA = new Date(a.due_date);
      const dateB = new Date(b.due_date);
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });

  const currentTasks = filteredAndSortedTasks.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const totalPages = Math.ceil(filteredAndSortedTasks.length / rowsPerPage);

  return (
    <StyledContainer collapsed={collapsed}>
      <Box sx={{ 
       display: 'flex', 
       flexDirection: 'column',
       gap: 3,
       width: '100%',
       maxWidth: '1000px', // Match the container's child maxWidth
       margin: '0 auto'
      }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2
        }}>
          <Typography variant="h4" sx={{ 
            fontWeight: 600, 
            color: theme.palette.primary.main
          }}>
            {intl.formatMessage({ id: 'taskManager' })}
          </Typography>
          
          <FilterPaper>
            <FormControl size="small" sx={{ minWidth: 180 }}>
                <InputLabel>{intl.formatMessage({ id: 'podName' })}</InputLabel>
                <Select
                  value={podNameFilter}
                  onChange={(e) => setPodNameFilter(e.target.value)}
                  label={intl.formatMessage({ id: 'podName' })}
                  startAdornment={
                    <InputAdornment position="start">
                      <FilterListIcon sx={{ color: theme.palette.primary.main }} />
                    </InputAdornment>
                  }
                >
                  <MenuItem value="all">{intl.formatMessage({ id: 'all' })}</MenuItem>
                  {Array.from(new Set(tasks.map((task) => task.project_key))).map((podName) => (
                    <MenuItem key={podName} value={podName.toLowerCase()}>{podName}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl size="small" sx={{ minWidth: 180 }}>
                <InputLabel>{intl.formatMessage({ id: 'status' })}</InputLabel>
                <Select
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                  label={intl.formatMessage({ id: 'status' })}
                  startAdornment={
                    <InputAdornment position="start">
                      <FilterListIcon sx={{ color: theme.palette.primary.main }} />
                    </InputAdornment>
                  }
                >
                  <MenuItem value="all">{intl.formatMessage({ id: 'all' })}</MenuItem>
                  <MenuItem value="To Do">
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Chip size="small" label="To Do" />
                      {intl.formatMessage({ id: 'toDo' })}
                    </Box>
                  </MenuItem>
                  <MenuItem value="In Progress">
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Chip size="small" color="warning" label="In Progress" />
                      {intl.formatMessage({ id: 'inProgress' })}
                    </Box>
                  </MenuItem>
                  <MenuItem value="Done">
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Chip size="small" color="success" label="Done" />
                      {intl.formatMessage({ id: 'Done' })}
                    </Box>
                  </MenuItem>
                </Select>
              </FormControl>

              {(podNameFilter !== 'all' || statusFilter !== 'all') && (
                <Button
                  size="small"
                  startIcon={<ClearIcon />}
                  onClick={handleClearFilters}
                  sx={{ 
                    color: theme.palette.grey[700],
                    '&:hover': {
                      bgcolor: theme.palette.grey[200]
                    }
                  }}
                >
                  Clear Filters
                </Button>
              )}
                      </FilterPaper>
        </Box>



        <TableContainer component={Paper} elevation={2} sx={{ borderRadius: 2 }}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: theme.palette.primary.main }}>
                <TableCell sx={{ color: 'white', fontWeight: 600 }}>
                  {intl.formatMessage({ id: 'podTask' })}
                </TableCell>
                <TableCell sx={{ color: 'white', fontWeight: 600 }}>
                  {intl.formatMessage({ id: 'description' })}
                </TableCell>
                <TableCell sx={{ color: 'white', fontWeight: 600 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                    onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}>
                    {intl.formatMessage({ id: 'dueDate' })}
                    <IconButton size="small" sx={{ color: 'white', ml: 1 }}>
                      {sortOrder === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </Box>
                </TableCell>
                <TableCell sx={{ color: 'white', fontWeight: 600 }}>
                  {intl.formatMessage({ id: 'status' })}
                </TableCell>
                <TableCell sx={{ color: 'white', fontWeight: 600 }}>
                  {intl.formatMessage({ id: 'blocker' })}
                </TableCell>
                <TableCell sx={{ color: 'white', fontWeight: 600 }}>
                  {intl.formatMessage({ id: 'markComplete' })}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentTasks.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Alert severity="info" sx={{ justifyContent: 'center' }}>
                      {intl.formatMessage({ id: 'noPodTasks' })}
                    </Alert>
                  </TableCell>
                </TableRow>
              ) : (
                currentTasks.map((task) => (
                  <TableRow key={task.pod_id} hover>
                    <TableCell>{task.pod_task_name}</TableCell>
                    <TableCell>{task.description}</TableCell>
                    <TableCell>
                      {moment(task.due_date).format('MMM DD, YYYY')}
                    </TableCell>
                    <TableCell>
                      <Chip
                        icon={getStatusIcon(task.status)}
                        label={task.status}
                        color={getStatusChipColor(task.status)}
                        size="small"
                        sx={{ fontWeight: 500 }}
                      />
                    </TableCell>
                    <TableCell>
                      {task.blockercheck === 1 && (
                        <Chip
                          icon={<WarningIcon />}
                          label="Blocker"
                          color="error"
                          size="small"
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={task.status === 'done'}
                        disabled={task.status === 'done'}
                        onChange={() => handleTaskComplete(
                          task.pod_task_id,
                          task.jira_item,
                          task.pod_id,
                          task.candidate_id,
                          task.pod_task_name
                        )}
                        color="success"
                      />
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(_, value) => setCurrentPage(value)}
            color="primary"
            size="large"
            showFirstButton
            showLastButton
            sx={{
              '& .MuiPaginationItem-root': {
                fontWeight: 500
              }
            }}
          />
        </Box>
      </Box>
    </StyledContainer>
  );
};

export default TalentViewTask;