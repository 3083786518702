import React from 'react'
import './Projectrequirements.css';
import styled from '@emotion/styled';
import { NavLink } from "react-router-dom";
import MailIcon from '@mui/icons-material/Mail';
import { Button } from '@mui/material';
import  {useState, useEffect} from 'react'


export default function Home() {
  const [toggleMenu, setToggleMenu] = useState(true)
   return (
<div>
{toggleMenu && <div>
  <div style={{marginTop:'5rem'}}>
   <NavLink to='/'  style={{  marginLeft:'60px' ,color:'grey'  }} >Home</NavLink>
<span style={{marginLeft:'20px'}}  ><span style={{marginLeft:'20px',color:'#8EAEBC'}}>Create Profile</span></span>
</div>
    <div  className='headingtext' >
<p>Are you looking to hire the best remote engineers</p>
<p> with one or more of the tech skills below?</p>
    </div>
    <div  className='container' style={{ width : "90rem",  backgroundColor : "#DFE8EC", height: "30rem" }}>
        <p className='keyheadings'>Front-End Development</p>
{/* <ul className='ulproject'>
    <li className='liproject'>ReactJS</li>
    <li className='liproject'>AngularJS</li>
    <li className='liproject'>PHP</li>
    </ul> */}
     <ul className='ulproject' style={{float:'left',marginLeft:'0.5rem',marginTop:'-10px'}}>
     <li className='liproject' style={{marginLeft:'-10px'}}>ReactJS</li>
    <li className='liproject' style={{marginLeft:'10px'}}>AngularJS</li>
    <li className='liproject'  style={{marginLeft:'-30px'}}>PHP</li>
   </ul>
   <p className='Back-EndDevelopment' >Back-End Development</p>
   <ul className='ulproject'  style={{ marginLeft: '16.8rem',float:'left',marginTop:'-10px'}}>
      <li className='liproject'>Python</li>
      <li className='liproject'>NodeJS</li>
      <li className='liproject' style={{marginLeft:'-20px'}} >Java</li>
   </ul>
   <p className='MobileAppDevelopment' >Mobile App Development</p>
   <ul className='ulproject'  style={{marginLeft: '17rem', float:'left',marginTop:'-10px'}}>
      <li className='liproject' >Android- Kotlin/Java</li>
      <li className='liproject' style={{marginLeft:'-78px'}}>IOS- Swift</li>
      <li className='liproject' style={{marginLeft:'-60px'}}>React Native</li>
   </ul>
   <p className='Containers' >Containers</p>
   <ul className='ulproject'  style={{ marginLeft: '11rem', float:'left',marginTop:'-10px'}}>
      <li className='liproject' style={{marginLeft:'-16px'}}>Docker</li>
      <li className='liproject' style={{marginLeft:'15px'}} >Kubernetes</li>
      <li className='liproject' style={{marginLeft:'10px'}} >Hashicorp</li>
   </ul>
   <p className='UI' >UI/UX Designing</p>
   <ul className='ulproject'  style={{ marginLeft: '-72rem',marginTop:'-20px'}}>
      <li className='liproject' style={{marginLeft:'10px'}}>Wireframes</li>
      <li className='liproject' style={{marginLeft:'-10px'}}>Mockups</li>
      <li className='liproject' style={{marginLeft:'70px'}}>Interaction Designs</li>
   </ul>
   <p className='grapic' >Graphic Designing</p>
   <ul className='ulproject'  style={{ marginLeft: '26rem',float:'left',marginTop:'-10px'}}>
      <li className='liproject' style={{marginLeft:'-50px'}}>Logo Designs</li>
      <li className='liproject' style={{marginLeft:'-50px'}}>Flyer Designs</li>
      <li className='liproject' style={{marginLeft:'-25px'}}>Motion Graphics</li>
   </ul>
   <p className='data' >Data Engineering/Analytics</p>
   <ul className='ulproject'  style={{ marginLeft: '46rem', float:'left',marginTop:'-110px'}}>
      <li className='liproject'  style={{marginLeft:'-50px'}}>Big-Data</li>
      <li className='liproject' style={{marginLeft:'20px'}}>Machine Learning</li>
      <li className='liproject' style={{marginLeft:'-5px'}}>Deep Learning</li>
   </ul>
   <p className='cloud' >Cloud/DevSecops</p>
   <ul className='ulproject'  style={{ marginLeft: '67.5rem', float:'left',marginTop:'-115px'}}>
      <li className='liproject' style={{marginLeft:'-30px'}}>AWS</li>
      <li className='liproject' style={{marginLeft:'-25px'}}>Azure</li>
      <li className='liproject'  style={{marginLeft:'40px'}}>Google Cloud</li>
   </ul>
    </div>
    <NavLink to='/create-profile' style={{ textDecoration: 'none' }} onClick={()=>setToggleMenu(false)}  >
<button  style={{backgroundColor:'#1DBF73',color:'white',width:'15rem',height:'4rem',borderRadius:'10px',marginLeft:'46rem',marginTop:'10rem', border: "none"}} >Create Profile</button>
</NavLink>
    <div style={{height:'21rem',width:'100vw',marginLeft:'-1rem',marginTop:'12rem',border:'2px solid #00B4D8',backgroundColor:'#00B4D8'}}>
     <div style={{marginLeft:'5rem',marginTop:'2.5rem',color:'white'}}>
     <InputContainer  >
              <Input type="text" placeholder="Enter Your E-mail Address" />
              <span style={{marginLeft:'-19.8rem',width:'30px',backgroundColor:'white',height:'40px',color:'grey',paddingTop:'6px',
borderTopLeftRadius:'10px',
borderBottomLeftRadius:'10px'}}>
              <MailIcon/>
              </span>
              </InputContainer>
              <p style={{marginLeft:'75rem',borderRadius:'10px',marginTop:'-2.4rem',cursor:'pointer',height:'2.3rem',width:'10rem' ,backgroundColor:'white',color:'gray',textAlign:'center',padding:'6px'}}>  <a>Subscribe</a></p>
            <p style={{paddingTop:'0.5rem'}} >Search Terms</p>
      <p style={{paddingTop:'0.1rem'}}>Privacy and Cookie Policy</p>
      <p style={{paddingTop:'0.1rem'}}>Advanced Search</p>
      <p style={{paddingTop:'0.1rem'}}>Orders and Returns</p>
      <p style={{paddingTop:'0.1rem',paddingBottom:'1rem'}}>Contact Us</p>
      </div>
<div style={{height:'3rem', backgroundColor:'black'}}><p style={{color:'white',fontWeight:'200', paddingTop:'10px',textAlign:'center' }}>Copyright © 2014-present Digitivy Technology Solutions, Inc. All rights reserved.</p></div>
    </div>
   </div>
}
     </div>
   )
}
const InputContainer = styled.div`
display : flex;
// border:2px solid #90E0EF;
// border-radius:1rem;
// justify-content: center;
 margin-left:55rem;
`;
const Input = styled.input`
border:0px solid black;
justify-content: center;
height: 2.5rem;
width: 18rem;
background-color:white;
border-top-right-radius:10px;
border-bottom-right-radius:10px;
&:focus{
    border:none;
    outline:none;
}
`;
const Icon = styled.div`
height: 3rem;
width: 2rem;
background-color: white;
// border:1px solid black;
display:flex;
flex-direction:row
justify-content: center;
align-items: center;
border-top-left-radius:10px;
border-bottom-left-radius:10px;
svg{
    color:#555555;
}
`;