import React from 'react';
import { styled } from '@mui/material/styles';
import { 
  Card, 
  CardContent, 
  Typography,
  Grid,
  Box
} from '@mui/material';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import PercentIcon from '@mui/icons-material/Percent';
import GroupIcon from '@mui/icons-material/Group';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StarIcon from '@mui/icons-material/Star';

// Styled components
const PageWrapper = styled('div')({
    backgroundColor: '#f0f0f0',
    minHeight: '100vh',
    width: '100%',
    
    
  });
  
  const DashboardContainer = styled('div', {
    shouldForwardProp: (prop) => prop !== 'collapsed'
  })(({ collapsed }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent:"center",
    margin:"auto",

    marginLeft: collapsed ? "60px" : "240px",
  
   
    padding: '20px',
    backgroundColor: '#f0f0f0',
    width: "100vw",
    height: 'auto',
    overflowY: 'scroll', // Fixed hyphenated property name
    msOverflowStyle: 'none', // Fixed hyphenated property name
    scrollbarWidth: 'none', // Fixed hyphenated property name
    marginTop: '1rem',
    fontFamily: 'Poppins, sans-serif',
    '&::-webkit-scrollbar': {  // Add webkit scrollbar hiding
      display: 'none'
    },
    '@media (max-width: 768px)': {
      marginLeft: '60px',
      width: 'calc(100vw - 80px)',
      padding: '10px',
      overflow: 'visible',
    },
  }));
  
  // Rest of the styled components remain the same...
  
  const OpportunityCard = styled(Card)(({ status }) => ({
    backgroundColor: 
      status === 'new' ? '#7A5195' :
      status === 'inProgress' ? '#EF5675' :
      '#FFA600',
    color: 'white',
    padding: '16px',
    height: '100%',
    overflow: 'visible',         // Added to ensure content is visible
    '@media (max-width: 768px)': {
      marginBottom: '20px',      // Added spacing between cards on mobile
    }
  }));
  
  const OpportunityItem = styled(Box)({
    marginTop: '8px',
    padding: '12px',
    backgroundColor: 'rgba(255,255,255,0.1)',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.2)',
      cursor: 'pointer',
    },
    '@media (max-width: 768px)': {
      marginBottom: '10px',      // Added spacing between items on mobile
    }
  });

const CardsContainer = styled('div')({
  display: 'flex',
  gap: '20px',
  marginTop:"5rem",
  marginBottom: '20px',
  '@media (max-width: 768px)': {
    flexDirection: 'column',
  },
});

const CardWrapper = styled('div')({
  position: 'relative',
  flex: 1,
});

const DataCard = styled(Card)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  position: 'relative',
  padding: '20px',
  backgroundColor: '#fff',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
  height: '10rem',
  fontSize: '18px',
  fontFamily: 'Poppins, Medium',
  overflow: 'auto',
  borderRadius: '20px',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

const CardIcon = styled('div')({
  position: 'absolute',
  top: '-30px',
  left: '20px',
  width: '60px',
  height: '60px',
  borderRadius: '20px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '18px',
  color: 'white',
  zIndex: 10,
});



// Chart data
const utilData = [
  { month: 'Jan', rate: 75 },
  { month: 'Feb', rate: 78 },
  { month: 'Mar', rate: 82 },
  { month: 'Apr', rate: 85 },
  { month: 'May', rate: 83 },
  { month: 'Jun', rate: 88 }
];

const revenueData = [
  { month: 'Jan', revenue: 120000 },
  { month: 'Feb', revenue: 125000 },
  { month: 'Mar', revenue: 135000 },
  { month: 'Apr', revenue: 142000 },
  { month: 'May', revenue: 148000 },
  { month: 'Jun', revenue: 155000 }
];

// Opportunity data
const opportunityData = {
  previousWeek: {
    new: [
      {
        id: "OPP-001",
        clientName: "Tech Corp",
        requirement: "Senior React Developer",
        probability: "75%",
        status: "new",
        nextAction: "Technical Interview",
        dueDate: "2024-12-20",
        value: 150000,
        location: "Remote",
      },
      {
        id: "OPP-002",
        clientName: "Digital Solutions",
        requirement: "Full Stack Developer",
        probability: "60%",
        status: "new",
        nextAction: "Initial Discussion",
        dueDate: "2024-12-21",
        value: 120000,
        location: "Hybrid",
      }
    ],
    inProgress: [
      {
        id: "OPP-003",
        clientName: "Innovation Labs",
        requirement: "DevOps Engineer",
        probability: "85%",
        status: "inProgress",
        nextAction: "Final Interview",
        dueDate: "2024-12-18",
        value: 180000,
        location: "Remote",
      }
    ],
    followUps: [
      {
        id: "OPP-004",
        clientName: "Cloud Systems",
        requirement: "Cloud Architect",
        probability: "90%",
        status: "followUp",
        nextAction: "Contract Review",
        dueDate: "2024-12-19",
        value: 200000,
        location: "Onsite",
      }
    ]
  },
  currentWeek: {
    new: [
      {
        id: "OPP-005",
        clientName: "Data Analytics Co",
        requirement: "Data Engineer",
        probability: "65%",
        status: "new",
        nextAction: "Skills Assessment",
        dueDate: "2024-12-24",
        value: 160000,
        location: "Remote",
      }
    ],
    inProgress: [
      {
        id: "OPP-006",
        clientName: "Health Systems",
        requirement: "System Architect",
        probability: "95%",
        status: "inProgress",
        nextAction: "Final Approval",
        dueDate: "2024-12-22",
        value: 220000,
        location: "Remote",
      }
    ],
    followUps: [
      {
        id: "OPP-007",
        clientName: "Retail Solutions",
        requirement: "QA Engineer",
        probability: "75%",
        status: "followUp",
        nextAction: "Test Plan Review",
        dueDate: "2024-12-20",
        value: 110000,
        location: "Remote",
      }
    ]
  }
};

const PartnerMetricsDashboard = ({ collapsed }) => {
  // Helper function to render opportunity cards
  const renderOpportunityCards = (weekData, weekTitle) => (
    <Box mb={4}>
      <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: '#666' }}>
        {weekTitle}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <OpportunityCard status="new">
            <Typography variant="h6">New ({weekData.new.length})</Typography>
            {weekData.new.map(opp => (
              <OpportunityItem key={opp.id}>
                <Typography variant="subtitle2">{opp.clientName}</Typography>
                <Typography variant="body2">{opp.requirement}</Typography>
                <Typography variant="caption">Due: {opp.dueDate}</Typography>
                <Typography variant="caption" sx={{ display: 'block' }}>
                  Next: {opp.nextAction}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                  <Typography variant="caption">{opp.location}</Typography>
                  <Typography variant="caption">Probability: {opp.probability}</Typography>
                </Box>
              </OpportunityItem>
            ))}
          </OpportunityCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <OpportunityCard status="inProgress">
            <Typography variant="h6">In Progress ({weekData.inProgress.length})</Typography>
            {weekData.inProgress.map(opp => (
              <OpportunityItem key={opp.id}>
                <Typography variant="subtitle2">{opp.clientName}</Typography>
                <Typography variant="body2">{opp.requirement}</Typography>
                <Typography variant="caption">Due: {opp.dueDate}</Typography>
                <Typography variant="caption" sx={{ display: 'block' }}>
                  Next: {opp.nextAction}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                  <Typography variant="caption">{opp.location}</Typography>
                  <Typography variant="caption">Probability: {opp.probability}</Typography>
                </Box>
              </OpportunityItem>
            ))}
          </OpportunityCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <OpportunityCard status="followUp">
            <Typography variant="h6">Follow Ups ({weekData.followUps.length})</Typography>
            {weekData.followUps.map(opp => (
              <OpportunityItem key={opp.id}>
                <Typography variant="subtitle2">{opp.clientName}</Typography>
                <Typography variant="body2">{opp.requirement}</Typography>
                <Typography variant="caption">Due: {opp.dueDate}</Typography>
                <Typography variant="caption" sx={{ display: 'block' }}>
                  Next: {opp.nextAction}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                  <Typography variant="caption">{opp.location}</Typography>
                  <Typography variant="caption">Probability: {opp.probability}</Typography>
                </Box>
              </OpportunityItem>
            ))}
          </OpportunityCard>
        </Grid>
      </Grid>
    </Box>
  );

  const quoteData = [
    {
      customerName: "Tech Solutions Inc",
      quoteDate: "2024-12-10",
      description: "Full Stack Development Team",
      budget: "$250,000",
      status: "Pending",
      amount: "$245,000"
    },
    {
      customerName: "Global Systems Corp",
      quoteDate: "2024-12-11",
      description: "DevOps Implementation",
      budget: "$180,000",
      status: "Approved",
      amount: "$175,000"
    },
    {
      customerName: "DataFlow Analytics",
      quoteDate: "2024-12-12",
      description: "Data Engineering Team",
      budget: "$200,000",
      status: "Under Review",
      amount: "$198,000"
    },
    {
      customerName: "Cloud Innovate",
      quoteDate: "2024-12-13",
      description: "Cloud Architecture Team",
      budget: "$300,000",
      status: "Pending",
      amount: "$295,000"
    }
  ];
  
  // Add these styled components with your other styled components
  const QuoteTable = styled('table')({
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0',
    backgroundColor: '#fff',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  });
  
  const TableHeader = styled('th')({
    backgroundColor: '#f5f5f5',
    padding: '16px',
    textAlign: 'left',
    fontWeight: '600',
    color: '#333',
    borderBottom: '2px solid #e0e0e0',
    '&:first-of-type': {
      paddingLeft: '24px',
    },
    '&:last-of-type': {
      paddingRight: '24px',
    },
  });
  
  const TableCell = styled('td')({
    padding: '16px',
    borderBottom: '1px solid #e0e0e0',
    color: '#666',
    '&:first-of-type': {
      paddingLeft: '24px',
    },
    '&:last-of-type': {
      paddingRight: '24px',
    },
  });
  
  const StatusChip = styled('span')(({ status }) => ({
    padding: '6px 12px',
    borderRadius: '16px',
    fontSize: '0.875rem',
    fontWeight: '500',
    backgroundColor:
      status === 'Approved' ? '#e6f4ea' :
      status === 'Pending' ? '#fff3e0' :
      '#f3e5f5',
    color:
      status === 'Approved' ? '#1b5e20' :
      status === 'Pending' ? '#e65100' :
      '#4a148c',
  }));
  

  return (
    <DashboardContainer collapsed={collapsed}>
        <div style={{display: 'flex',
    flexDirection: 'column',
    justifyContent:"center",
    margin:"auto",width:"90vw"}} >
      {/* Metric Cards */}
      <CardsContainer>
        <CardWrapper>
          <CardIcon style={{ backgroundColor: "#1976d2" }}>
            <PercentIcon />
          </CardIcon>
          <DataCard>
            <CardContent>
              <Typography variant="h6">Billable %</Typography>
              <Typography variant="h4">85%</Typography>
              <Typography variant="body2">Currently Assigned to PODs</Typography>
            </CardContent>
          </DataCard>
        </CardWrapper>

        <CardWrapper>
          <CardIcon style={{ backgroundColor: "#2e7d32" }}>
            <GroupIcon />
          </CardIcon>
          <DataCard>
            <CardContent>
              <Typography variant="h6">Bench Count</Typography>
              <Typography variant="h4">12</Typography>
              <Typography variant="body2">Available Talent</Typography>
            </CardContent>
          </DataCard>
        </CardWrapper>

        <CardWrapper>
          <CardIcon style={{ backgroundColor: "#9c27b0" }}>
            <AttachMoneyIcon />
          </CardIcon>
          <DataCard>
            <CardContent>
              <Typography variant="h6">Revenue Forecast</Typography>
              <Typography variant="h4">$155,000</Typography>
              <Typography variant="body2">This Month</Typography>
            </CardContent>
          </DataCard>
        </CardWrapper>

        <CardWrapper>
          <CardIcon style={{ backgroundColor: "#ed6c02" }}>
            <StarIcon />
          </CardIcon>
          <DataCard>
            <CardContent>
              <Typography variant="h6">Talent Quality Score</Typography>
              <Typography variant="h4">4.8/5.0</Typography>
              <Typography variant="body2">Average Rating</Typography>
            </CardContent>
          </DataCard>
        </CardWrapper>
      </CardsContainer>

      {/* Charts */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={6}>
          <Card sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>Billable Utilization Rate (BUR)</Typography>
            <Box sx={{ height: 300 }}>
              <ResponsiveContainer>
                <LineChart data={utilData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="rate" stroke="#1976d2" />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </Card>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Card sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>Revenue Growth</Typography>
            <Box sx={{ height: 300 }}>
              <ResponsiveContainer>
                <LineChart data={revenueData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="revenue" stroke="#2e7d32" />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </Card>
        </Grid>
      </Grid>

      {/* Opportunity Activity */}
      <Card sx={{   p: 3, 
      overflow: 'visible',     // Added to ensure content is visible
      '@media (max-width: 768px)': {
        p: 2,                  // Reduced padding on mobile
      }}}>
        <Typography variant="h5" gutterBottom>Opportunity Activity</Typography>
        {renderOpportunityCards(opportunityData.previousWeek, "Previous Week")}
        {renderOpportunityCards(opportunityData.currentWeek, "Current Week")}
      </Card>
      <Card sx={{ 
  p: 3, 
  mt: 4,
  overflow: 'visible',
  '@media (max-width: 768px)': {
    p: 2,
  }
}}>
  <Typography variant="h5" gutterBottom>Quote Requests</Typography>
  <Box sx={{ overflowX: 'auto' }}>
    <QuoteTable>
      <thead>
        <tr>
          <TableHeader>Customer Name</TableHeader>
          <TableHeader>Quote Date</TableHeader>
          <TableHeader>Quote Description</TableHeader>
          <TableHeader>Budget</TableHeader>
          <TableHeader>Status</TableHeader>
          <TableHeader>Quote Amount</TableHeader>
        </tr>
      </thead>
      <tbody>
        {quoteData.map((quote, index) => (
          <tr key={index}>
            <TableCell>{quote.customerName}</TableCell>
            <TableCell>{new Date(quote.quoteDate).toLocaleDateString()}</TableCell>
            <TableCell>{quote.description}</TableCell>
            <TableCell>{quote.budget}</TableCell>
            <TableCell>
              <StatusChip status={quote.status}>{quote.status}</StatusChip>
            </TableCell>
            <TableCell>{quote.amount}</TableCell>
          </tr>
        ))}
      </tbody>
    </QuoteTable>
  </Box>
</Card>
      </div>
    </DashboardContainer>

  );
};

export default PartnerMetricsDashboard;