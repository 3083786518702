import React from 'react'
import { Link } from "react-router-dom";

export default function EmployerHomeContent() {
  return (
    <section className="container">
        <div className="columns features">
            <div className="column is-4">
                <div className="card is-shady">
                    <div className="card-content">
                        <div className="content">
                            <h4>Add Requirements</h4>
                            <Link class="button is-success is-medium" to="/employeeprojectreq">Post New Requirement</Link>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="column is-4">
                <div className="card is-shady">
                    <div className="card-content">
                        <div className="content">
                            <h4>Browse Candidates</h4>
                            <Link class="button is-success is-medium" to="/candidatesearch">Search Now</Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="column is-4">
                <div className="card is-shady">
                    <div className="card-content">
                        <div className="content">
                            <h4>Profile</h4>
                            <Link class="button is-success is-medium" to="/employeepf">Manage Profile</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
