import React, { useEffect, useState, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-plugin-annotation';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

Chart.register(...registerables);

const DetailsTaskmetricsTalent = ({ initialPodIds, selectedSprint, sprintStartDate, sprintEndDate }) => {
  const token = useAuthToken();
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);

  const [metrics, setMetrics] = useState(null);
  const [open, setOpen] = useState(false);
  const [taskData, setTaskData] = useState([]);
  const [selectedTalent, setSelectedTalent] = useState('');
  const chartRef = useRef(null);
  const chartId = useRef(`completedChart-${Math.random().toString(36).substring(2, 9)}`).current;

  useEffect(() => {
    fetchMetrics();
  }, [initialPodIds, token, selectedSprint]);

  useEffect(() => {
    if (metrics) {
      let chartData;
      if (selectedTalent) {
        const talentData = metrics.pod_tasks_by_talent.find(t => t.Talent_name === selectedTalent);
        if (talentData) {
          chartData = [talentData.Complete || 0, talentData.Open || 0];
        }
      } else {
        chartData = [metrics.status_counts.Complete, metrics.status_counts.Open];
      }
      displayChart(chartData);
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [metrics, selectedTalent]);

  const fetchMetrics = async () => {
    if (!token) return;

    try {
      const response = await fetch(process.env.REACT_APP_TALENT_PODTASKSMETRICSTALENT, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            talent_id: user.data[0].c_id,
          pod_id: initialPodIds,
        }),
      });

      const responseData = await response.json();
      const parsedData = responseData.body;

      if (parsedData && parsedData.status_counts && parsedData.pod_tasks_by_talent) {
        setMetrics(parsedData);
      } else {
        setMetrics(null);
      }
    } catch (error) {
      console.error('Error fetching metrics:', error);
      setMetrics(null);
    }
  };

  const displayChart = (chartData) => {
    const ctx = document.getElementById(chartId);
    if (!ctx) return;

    if (chartRef.current) {
      chartRef.current.destroy();
    }

    chartRef.current = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['Completed Tasks', 'Open Tasks'],
        datasets: [
          {
            data: chartData,
            backgroundColor: ['lightgreen', 'red'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          annotation: {},
          legend: {
            display: false,
          },
        },
        layout: {
          padding: 10,
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: function (tooltipItem, data) {
              const value = data.datasets[0].data[tooltipItem.dataIndex];
              return value;
            },
          },
        },
        onClick: (event, elements) => {
          if (elements.length > 0) {
            setTaskData(metrics.pod_tasks);
            setOpen(true);
          }
        },
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxHeight: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
  };

  return (
    <div className="chart-container">
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h2 className="chart-heading">{intl.formatMessage({ id: 'Tasks' })}</h2>
        <br/>
        <select
          
          value={selectedTalent}
          onChange={(e) => setSelectedTalent(e.target.value)}
          
          style={{ width: '12rem',  }}
        >
          <option value="">All Reporters</option>
          {metrics?.pod_tasks_by_talent?.map((talent, index) => (
            <option key={index} value={talent.Talent_name}>
              {talent.Talent_name}
            </option>
          ))}
        </select>
        <br/>
        <div className="chart-wrapper" style={{ width: "145px", height: "145px", margin: "auto",marginTop:"5px" }}>
          {metrics && (metrics.status_counts.Open !== 0 || metrics.status_counts.Complete !== 0) ? (
            <canvas id={chartId}></canvas>
          ) : (
            <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
          )}
        </div>
      </div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <h2>{intl.formatMessage({ id: 'taskDetails' })}</h2>
          <Table component={Paper} sx={{ maxWidth: 900, fontFamily: 'Poppins,Medium', borderRadius: '10px' }} aria-label="simple table">
            <TableHead
              sx={{
                backgroundColor: '#00B4D8',
                '& td, & th': {
                  fontFamily: 'Poppins,Medium',
                  fontSize: '15px',
                  color: 'white',
                  fontWeight: '600',
                },
              }}
            >
              <TableRow>
                <TableCell>{intl.formatMessage({ id: 'candidateName' })}</TableCell>
                <TableCell>{intl.formatMessage({ id: 'projectKey' })}</TableCell>
                <TableCell>{intl.formatMessage({ id: 'podTaskName' })}</TableCell>
                <TableCell>{intl.formatMessage({ id: 'category' })}</TableCell>
                <TableCell>{intl.formatMessage({ id: 'issueType' })}</TableCell>
                <TableCell>{intl.formatMessage({ id: 'status' })}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {taskData.map((task, index) => (
                <TableRow key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0, fontFamily: 'Poppins,Medium', fontSize: '15px' },
                    '&:nth-child(even)': {
                      backgroundColor: '#90E0EF',
                    },
                    '&:nth-child(odd)': {
                      backgroundColor: '#caf0f8',
                    },
                    '& td, & th': {
                      fontFamily: 'Poppins,Medium',
                      fontSize: '15px',
                      color: 'black',
                    },
                  }}>
                  <TableCell>{task.candidate_name}</TableCell>
                  <TableCell>{task.project_key}</TableCell>
                  <TableCell>{task.pod_task_name}</TableCell>
                  <TableCell>{task.category}</TableCell>
                  <TableCell>{task.issuetype}</TableCell>
                  <TableCell>{task.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Modal>
    </div>
  );
};

export default DetailsTaskmetricsTalent;
