import React, { useState } from 'react'
import styled from 'styled-components'
import profile from './assets/profile_img.png';
import "./FindPods.css";
import { useEffect } from 'react';
import axios from 'axios';
import userEvent from '@testing-library/user-event';
import { useDispatch, useSelector } from "react-redux";
import { data } from 'jquery';
import Avatar from "./assets/MaskGroup.png";
import { NavHashLink } from 'react-router-hash-link';
import star from './assets/star.png';
import { useParams } from 'react-router-dom';
import {AiOutlineStar,AiFillStar} from 'react-icons/ai';
import { useAuthToken } from "../TokenContext";
import defaultimg from './assets/defaultimge.png';

const FindPodDetailsClient = () => {
  const token = useAuthToken();

    const { isLoggedIn, user } = useSelector(state => state.auth);
    const [getpoddetails, setGetpoddetails] = useState({});
    const [getcandidates, setGetcandidates] = useState([]);
    const [getReq, setgetReq] = useState([]);

    const [podskill, setPodskill] = useState([]);
const[getid,setGetid]=useState();
    const [getData, setGetData] = useState("")
const [candidateid,setCandidateid]=useState( "");

   
    const email = user.data[0].email
   
    const portfolioUrl = user.data[0].portfolioUrl
    
    const firstName = user.data[0].firstName
    
    const lastName = user.data[0].lastName
    
// const skilldata = (data) => {

//     var mainskill = JSON.parse(data)
//     console.log("1112423435345345 :::: ",mainskill)
//     return(mainskill);

// }

const { id } = useParams();


    useEffect(() => {





        const body = {
           id: id
        }
        const headers = {
          'Authorization' : token, // Replace with your actual token
          'Content-Type': 'application/json', // You can add more headers as needed
        };

        // https://w3ten9gqf7.execute-api.us-east-1.amazonaws.com/dev/getpodbyid
        axios.post(process.env.REACT_APP_CLIENT_GETPODID, body, {headers:headers}).then((res) => {
               
                setGetData(res.data.data);
                setGetid(res.data.data.podDetails.id)
                


                setGetcandidates(res.data.data.candidates)
                setCandidateid(res.data.data.candidates.c_id)
                setGetpoddetails(res.data.data.podDetails)
                //console.log("getpoddetails",res.data.data.podDetails)
                // setPodskill(res.data.data.podDetails.skills)

                // console.log("this is get data", res.data.data.candidates);
                // console.log("this is get data2", res.data.data.podDetails);

            }
            )
            .catch((err) => {
                console.log("catch", err);
            })


            return () => {

              setGetData([]);
              setGetid()

              setgetReq([]);


              setGetcandidates([])
              setGetpoddetails({})
              
            };


    }, [token])


    const intervals = [
      { label: 'year', seconds: 31536000 },
      { label: 'month', seconds: 2592000 },
      { label: 'day', seconds: 86400 },
      { label: 'hour', seconds: 3600 },
      { label: 'minute', seconds: 60 },
      { label: 'second', seconds: 1 }
    ];
  

    function timeSince(date) {
      const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
      const interval = intervals.find(i => i.seconds < seconds);
      const count = Math.floor(seconds / interval.seconds);
  
     
      return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
    }
  
    
    let hirePod = (podID) => {
        
        let confirm = window.confirm("Do you want to hire this pod") 
      
        if (confirm == true) {
if(user.subscriptiondata.length > 0){
            if(user.subscriptiondata[0].Status == "active"){

                // if(user.subscriptiondata[0].type == getpoddetails.status){
               
       
          let body = {
            "podID" : getpoddetails.id,
            "clientID" : user.data[0].emp_id
          }
          // let body1 = {
          //   "podID" : 966,
          //   "clientID" : user.data[0].emp_id
          // }
     
      // https://szio9bm79h.execute-api.us-east-1.amazonaws.com/dev/hirepod
        fetch(process.env.REACT_APP_CLIENT_HIREPOD, {
          method:"POST",
          body:JSON.stringify(body),
          headers:{
            'Authorization' : token, // Replace with your actual token
            'Content-Type': 'application/json', // You can add more headers as needed
          },
        }).then((res) => {
      
         if(res){
          alert(res.body.data);
          window.location.reload();

         } 
        }
      
      
        )
        .catch((err) => {
          console.log("catch", err);
        })
      

    // }else{
    //     alert("You didn't have the " + getpoddetails.status + " subscription to hire this pod!")
    // }
      
      }else{
        alert("You Don't Have Active Subscription!");
      }
      
      }else{
        alert("You Didn't Have Subscription, Please subscribe to hire pod.");

      }
    }
  }
      

  
    return (
    <OL1>
 <Headingtext1> Find the best pod and get registered or create your own pod</Headingtext1>
    <Div >
   

        <li  >

            <div className='name'>
            {getpoddetails.picture == null || getpoddetails.picture == '' ? (
              <img
                src={defaultimg}
                alt=""
                style={{ width: 50, height: 50, borderRadius: "50px" }}
              ></img>
            ) : (
              <img
                style={{ width: '50px', height: '50px', borderRadius: "50px" }}
                src={'https://globldataprod.s3.amazonaws.com/' + getpoddetails.picture}
                alt=""
              ></img>
            )}
         
           <span style={{marginLeft:"10px"  }}>{getpoddetails.avgrating} <AiFillStar style={{ color: "orange", width : "20px", height : "20px"}}></AiFillStar>  
           </span>
              
            </div>

            <div className='name2'> <tr  className='TR'><td    className='TD'  >Pod Name</td> <td>:</td><TD1 >{getpoddetails.podName} </TD1></tr></div>

            <div className='name2'> <tr className='TR' ><td className='TD'  >E-Mail</td> <td>:</td><TD1  > {getpoddetails.email}</TD1></tr></div>

            

            {/* <div className='name2'> <tr  className='TR'><td className='TD' >Portfolio</td> <td>:</td><TD1>  {getpoddetails.portfolioUrl}</TD1></tr></div> */}

            <div className='name2'> <tr  className='TR'><td className='TD'  >Description</td> <td>:</td><TD1 > {getpoddetails.podDescription} </TD1></tr></div>



<div className='name2'> <tr  className='TR'><td className='TD' >POD Team Members</td> </tr></div>
{getcandidates.map((data)=>
   <div style={{display:"flex",flexDirection:"row", margin : "20px"}}><tr>
    <td style={{paddingRight:"10px",paddingTop:"10px"}}>   {data.profilePicture == null || data.profilePicture == '' ? (
              <img
                src={defaultimg}
                alt=""
                style={{ width: 50, height: 50, borderRadius: "50px" }}
              ></img>
            ) : (
              <img
                style={{ width: '50px', height: '50px', borderRadius: "50px" }}
                src={'https://globldataprod.s3.amazonaws.com/' + data.profilePicture}
                alt=""
              ></img>
            )}</td></tr>
 <a href={`/candidatebyid/${data.c_id}`} >
 <tr style={{display:'flex',flexDirection:'column', cursor : "pointer"}}> <TD2>  {data.firstName}  </TD2>  <TD3>{data.work_position}</TD3></tr>

 </a>
 </div>      

   )}

{user?.data[0]?.type !== "PartnerGuest" && (
  getpoddetails.status === "Hired" ? (
    <p>This POD is Already Hired by You!</p>
  ) : (
    <DetailsButton onClick={hirePod}>Hire Pod</DetailsButton>
  )
)}


   


<br></br>

    </li>

</Div>

<Div2>
<TitleText>Pod Reviews</TitleText>
<RecommendProject>
<CardContent>
{ 

getReq.length > 0 ? 

getReq.map((item)=> {

return <Divseperator >


<Row>
            <Detail>
              <InfoContainer>


  
              <img
                  src={item.profilePicture}
                  alt=""
                  style={{ width: 50, height: 50, borderRadius : "50px" }}
                ></img>

                <Info>
                  <InfoName>Client</InfoName>
                  <InfoUpdate> {timeSince(new Date(item.r_createdAt))} </InfoUpdate>

                </Info>
              </InfoContainer>


            </Detail>
          </Row>
      

   
    <ProjectInfo>

    {Array(Number(item.rating)).fill().map((_,index)=>(
   
     <AiFillStar style={{ color: "orange", width : "18px", height : "18px"  }}></AiFillStar>  

    ))}
    <br></br><br></br>


    <Position1> {item.review}</Position1> 
   
   
    
    </ProjectInfo>
    <Div1>
    


      
      
     
           
           
 
      <ButtonEdit 
      
       
      >
{/* <NavHashLink to = {`/EditPostRequirementCand/${item.id}`}  smooth>
      
<Badge content="Edit" glow1 />     </NavHashLink>  */}

       
      </ButtonEdit>
      
    </Div1>
   
   
    {/* {show ? <p> hello miss !</p> : null} */}
    </Divseperator>
         
     }) : (<>No Pod Reviews</>) 

    }   

  </CardContent>
 


</RecommendProject>
</Div2>

<br></br>

</OL1>                     

    )

}
    
export default FindPodDetailsClient;

const OL1 = styled.ol`
list-style: none;
margin:auto;


@media screen and (min-width:320px) and (max-width:1080px){
    display:flex;
flex-direction:column;
margin-top:10rem;
align-items:center;
justify-content:center;


}
`;

const Return = styled.ol`

margin:auto;

margin-top:5rem;
@media screen and (min-width:320px) and (max-width:1080px){
    margin:auto;
    margin-top:5rem;
    

}
`;

const TD1 =styled.td`
margin-left: 2rem;
width: 12rem;
overflow-wrap: anywhere;  font-weight: 600;
font-family: Poppins,medium;
@media screen and (min-width:320px) and (max-width:1080px){
 margin:auto;
 margin-left: 2rem;
    width: 8rem;
    overflow-wrap: anywhere;
}
`;
const TD2 =styled.td`

width: 12rem;
overflow-wrap: anywhere;
padding-top:10px;
font-weight: 600;
font-family: Poppins,medium;
@media screen and (min-width:320px) and (max-width:1080px){
 margin:auto;

    width: 8rem;
    overflow-wrap: anywhere;
}
`;

const TD3=styled.td`

width: 12rem;
overflow-wrap: anywhere;
font-weight: 600;
font-size:14px;
font-family: Poppins,medium;

@media screen and (min-width:320px) and (max-width:1080px){
 margin:auto;

    width: 8rem;
    overflow-wrap: anywhere;
}
`;



const Div = styled.div`


margin-top:5rem;
align-items:center;
justify-content:center;
padding-left:20px;

width:59rem;
border-radius:20px;
background-color:rgb(142, 174, 188,32%);
@media screen and (min-width:320px) and (max-width:1080px){
    margin:auto;

    width:20rem;

}
`;




const Num2 = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        margin:auto;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        width:100%;
}
        `;


const Numbers = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        align-items:center;
        justify-content:center;
        width:7rem;
}
        `;

        const DetailsButton=styled.button`
        display:flex;
        justify-content:center;
        align-items:center;
        margin:auto;
        padding:5px;
        margin-top:3rem;
        margin-bottom:2rem;
        color:white;
      border:none;
        border-radius:5px  ;
        background-color:#1DBF73;
        box-shadow: 1px 3px 6px #00000029;

      
        
        `;

        const Headingtext1=styled.h3`
        font-family:Poppins,Medium;
        display:flex;
        justify-content:center;
        align-items:center;
        margin:auto;
        margin-top: 5rem;
        font-weight: 600;
        font-size: 1.5rem;
        @media screen and (min-width:320px) and (max-width:1080px){
            display:flex;
            justify-content:center;
            align-items:center;
            margin:auto;
            margin-top: 7rem;
            }
        `;


        const Div2 = styled.div`
  // display:flex;
  display: inline;
  jutify-content: center;
  flex-direction: column;
  margin-top: 6rem;
  align-items: center;

  margin-left: 6.5rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 25rem;
  }
`;
const Div1 = styled.div`
  display: flex;

  jutify-content: center;
  flex-direction: row;

  align-items: center;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
  }
`;

const ButtonEdit =styled.button`

  text-decoration: none;
  border: none;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top:2rem;
  }

`;
const RecommendProject=styled.div`

 display:flex;

jutify-content:center;
flex-direction:row;
@media screen and (min-width:320px) and (max-width:1080px){
    width:100%;
    height:max-content;
    margin:auto;
    
 display:flex;


 flex-direction:column;
    
   }
`;

const Row = styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
`;
const TitleText = styled.h4`
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
   margin-top:2rem;
  }

`;

const Position = styled.p`
  font-weight: 600;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:4rem;
  }
`;


const Position1 = styled.p`
  font-weight: 600;
  width:15rem;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:17rem;
    overflow-wrap: anywhere;
  }
`;



const Answer = styled.p`
  font-weight: 600;
  width:15rem;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:7rem;
    overflow-wrap: anywhere;
  }
`;



const CardContent = styled.div`
  margin: 0.4rem;
  width: 40rem;
  padding:1rem;
  
  height:max-content;
  border-radius:10px;
  font-family:Poppins,Medium;
  background-color:#F2F4F5;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 22rem;
    
  margin: auto;
  }
`;
const Divseperator = styled.div`
border-bottom:1px solid #CECECE;
padding:1rem;
`;
const Detail = styled.div`
  display: flex;
  justify-content: space-around;
  aligh-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const InfoName = styled.h6`
  font-weight: 600;
`;
const InfoUpdate = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;
const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  padding: 0.3rem;

  border-radius: 7px;
  height: 100%;
  width: max-width;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    // gap:0.4rem;
    width: 60%;
    height: 100%;
    margin-right: auto;
  }
`;
const Price = styled.p`
  color: black;
  border:1px solid #1DBF73;
  border-radius:10px;
width:max-width;
  padding: 3px;
  font-weight:600;
  font-family:Poppins, Medium;
`;

const Price2 = styled.p`
  color: black;
  border:1px solid #1DBF73;
  border-radius:10px;
width:max-width;
  padding: 3px;
  margin-left:10px;
  font-weight:600;
  font-family:Poppins, Medium;
`;
const Price1 = styled.div`
  display:flex;
  flex-direction:row;
  margin-top:10px;
`;


const ABOUT = styled.p`
color: black;

font-family:Poppins, Medium;
padding: 3px;

font-weight:600;
`;
const Certificatetab = styled.div`
display:flex;
flex-direction:column;
margin-top:10px;
`;


const CertificateName = styled.p`
color: black;

font-family:Poppins, Medium;

 
  font-weight:600;
`;


const CertificateNameinsti = styled.p`
color: black;
font-family:Poppins, Medium;
font-weight:600;
font-size:12px;
margin-top:-5px;

 
  font-weight:600;

`;





const Title = styled.h6`
  font-weight: 600;
  display:flex;
  flex-direction:row;
`;
const ProjectInfo = styled.h6`
font-weight: 600;

`;

const SideCard=styled.div`
margin-left:5rem;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
border-radius:20px;
font-family:Poppins, Medium;
width:20rem;
height:38rem;

padding: 1rem;
background-color: #F2F4F5;

transition:0.45 ease-in-out;
&:hover {

}
@media screen and (min-width: 320px) and (max-width: 1080px) {
  width:22rem;

  margin: auto;
  margin-top:2rem;
}
`;


const SideCardContent=styled.div`

width:17rem;
height:12.66rem;
border-bottom:1px solid #CECECE;
display:flex;
flex-direction:column;
padding:3px;



`;

