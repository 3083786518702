export const frontend = [
   'ReactJs',
   'Php',
   'Angular'
];

export const backend = [
    'Node js',
    'Python',
    'Go gang',
    'Java'
];
export const mobiletech = [
    'Android-Kotlin',
    'Android-Java',
    'Ios-swift',
    'React-native'
];

 


