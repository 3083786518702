import React, { useState, useEffect, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import Select from "react-select";
import { useAuthToken } from "../TokenContext";
import { useIntl } from "react-intl";
import PersonIcon from "@mui/icons-material/Person";
import moment from "moment";
import { Drawer, TextField, Button, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import "./kanband.css";
import { FaBug, FaJira } from 'react-icons/fa';

const KanbanBoard = () => {
  // State declarations
  const [tasks, setTasks] = useState([]);
  const [columns, setColumns] = useState({
    "To Do": { title: "To Do", items: [], type: "open" },
    "In Progress": { title: "In Progress", items: [], type: "open" },
    Done: { title: "Done", items: [], type: "closed" },
  });
  const [selectedPodId, setSelectedPodId] = useState([]);
  const [authorizationDetails, setAuthorizationDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState([]);
  const [podNames, setPodNames] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [newComment, setNewComment] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  const token = useAuthToken();
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);
  const scrollContainerRef = useRef(null);

  // Initial data fetching
  useEffect(() => {
    fetchPodNames();
  }, [token]);

  useEffect(() => {
    if (selectedPodId && selectedPodId.length > 0) {
      fetchAuthorizationTokensAndList();
    } else {
      resetStates();
    }
  }, [selectedPodId, selectedCandidate]);

  const resetStates = () => {
    setCandidates([]);
    setTasks([]);
    setColumns({
      "To Do": { title: "To Do", items: [], type: "open" },
      "In Progress": { title: "In Progress", items: [], type: "open" },
      Done: { title: "Done", items: [], type: "closed" },
    });
  };

  // Helper functions
  const parseStatusString = (statusString) => {
    if (!statusString) return [];
    return statusString.split(",").map((status) => status.trim());
  };

  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 300;
      const currentScroll = scrollContainerRef.current.scrollLeft;
      scrollContainerRef.current.scrollTo({
        left: currentScroll + (direction === "left" ? -scrollAmount : scrollAmount),
        behavior: "smooth",
      });
    }
  };

  // Column initialization
  const initializeColumns = (authData) => {
    if (!authData) return columns;

    const openStatuses = parseStatusString(authData.jira_open_story_bug_status?.[0]);
    const closedStatuses = parseStatusString(authData.jira_closed_story_bug_status?.[0]);

    const newColumns = {};
    openStatuses.forEach((status) => {
      newColumns[status] = { title: status, items: [], type: "open" };
    });
    closedStatuses.forEach((status) => {
      newColumns[status] = { title: status, items: [], type: "closed" };
    });

    return newColumns;
  };

  // Task grouping
  const groupTasksByStatus = (tasks, columns) => {
    const groupedColumns = { ...columns };
    Object.keys(groupedColumns).forEach((key) => {
      groupedColumns[key].items = [];
    });

    tasks.forEach((task) => {
      const status = task.status || "To Do";
      if (groupedColumns[status]) {
        groupedColumns[status].items.push(task);
      }
    });

    return groupedColumns;
  };

  // Comment rendering and handling functions
  const renderJiraComment = (content) => {
    if (!content) return null;

    return content.map((item, index) => {
      if (item.type === 'paragraph') {
        return (
          <div key={index} style={{ marginBottom: '8px' }}>
            {item.content?.map((contentItem, cIndex) => {
              if (contentItem.type === 'text') {
                return <span key={cIndex}>{contentItem.text}</span>;
              }
              if (contentItem.type === 'mention') {
                return (
                  <span 
                    key={cIndex} 
                    style={{ 
                      color: '#0052CC', 
                      fontWeight: '500',
                      backgroundColor: '#EAE6FF',
                      padding: '0 4px',
                      borderRadius: '3px',
                      margin: '0 2px'
                    }}
                  >
                    {contentItem.attrs.text}
                  </span>
                );
              }
              return contentItem.type === 'hardBreak' ? <br key={cIndex} /> : null;
            })}
          </div>
        );
      }
      return null;
    });
  };

  const renderComments = () => {
    if (!selectedTask) return null;

    // Handle POD task comments
    if (selectedTask.pod_task_id && selectedTask.comment) {
      try {
        const podComments = JSON.parse(selectedTask.comment);
        return podComments.map((comment, index) => (
          <div
            key={index}
            className="comment"
            style={{
              marginBottom: "20px",
              padding: "12px",
              backgroundColor: "#f8f9fa",
              borderRadius: "8px",
              boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            }}
          >
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "12px",
              alignItems: "center",
            }}>
              <span style={{
                backgroundColor: "#0073e6",
                color: "white",
                padding: "4px 8px",
                borderRadius: "4px",
                fontSize: "14px",
                fontWeight: "500",
              }}>
                 {comment.author}
              </span>
              <small style={{ color: "#666" }}>
                {moment(comment.timestamp).format("MMM DD, YYYY HH:mm")}
              </small>
            </div>
            <div style={{
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
              lineHeight: "1.5",
            }}>
              {comment.text}
            </div>
          </div>
        ));
      } catch (error) {
        console.error("Error parsing POD comments:", error);
        return null;
      }
    }

    // Handle Jira comments
    return selectedTask.comments?.map((comment, index) => (
      <div
        key={index}
        className="comment"
        style={{
          marginBottom: "20px",
          padding: "12px",
          backgroundColor: "#f8f9fa",
          borderRadius: "8px",
          boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
        }}
      >
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "12px",
          alignItems: "center",
        }}>
          <span style={{
            backgroundColor: "#0073e6",
            color: "white",
            padding: "4px 8px",
            borderRadius: "4px",
            fontSize: "14px",
            fontWeight: "500",
          }}>
            {comment.author}
          </span>
          <small style={{ color: "#666" }}>
            {moment(comment.created).format("MMM DD, YYYY HH:mm")}
          </small>
        </div>
        <div style={{
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
          lineHeight: "1.5",
        }}>
          {renderJiraComment(comment.body?.content)}
        </div>
      </div>
    ));
  };

 
const handleAddComment = async () => {
  if (!newComment.trim() || !selectedTask) return;

  const headers = {
    Authorization: token,
    "Content-Type": "application/json",
  };

  try {
    if (selectedTask.pod_task_id) {
      // For POD tasks, prepare array of all comments including the new one
      let existingComments = [];
      if (selectedTask.comment) {
        try {
          existingComments = JSON.parse(selectedTask.comment);
        } catch (error) {
          console.error("Error parsing existing comments:", error);
          existingComments = [];
        }
      }

      // Create the new comment object
      const newCommentObj = {
        pod_task_id: parseInt(selectedTask.pod_task_id),
        user_id: parseInt(user.data[0].emp_id),
        new_text: newComment,
        is_reply: false,
        author:user.data[0].emp_firstname
      };

      // Create array of all comments (previous + new)
      const allComments = existingComments.map(comment => ({
        pod_task_id: parseInt(selectedTask.pod_task_id),
        user_id: parseInt(comment.user_id),
        new_text: comment.text,
        is_reply: false
      }));
      allComments.push(newCommentObj);

      // Send request with all comments
      const response = await axios.post(
        "https://6w88fzkr0j.execute-api.us-east-1.amazonaws.com/prod/addCommentpodtask",
        [newCommentObj],
        { headers }
      );

      if (response.status === 200) {
        setSelectedTask(prevTask => ({
          ...prevTask,
          comment: JSON.stringify([
            ...existingComments,
            {
              text: newComment,
              user_id: parseInt(user.data[0].emp_id),
              timestamp: new Date().toISOString(),
              replies: []
            }
          ])
        }));
        setNewComment("");
      }
    } else if (selectedTask.key) {
      // Jira comment handling remains the same
      const response = await axios.post(
        "https://o0quulyyma.execute-api.us-east-1.amazonaws.com/prod/addCommentjirapodboard",
        {
          jiraUrl: authorizationDetails.Jira_URL,
          issueKey: selectedTask.key,
          commentText: newComment,
          username: authorizationDetails.Jira_admin_login_email_address,
          password: authorizationDetails.Jira_admin_API_Token,
        },
        { headers }
      );

      if (response.status === 200) {
        setSelectedTask((prevTask) => ({
          ...prevTask,
          comments: [
            ...(prevTask.comments || []),
            {
              author: user.data[0].emp_name || "current_user",
              body: {
                type: "doc",
                version: 1,
                content: [
                  {
                    type: "paragraph",
                    content: [{ type: "text", text: newComment }],
                  },
                ],
              },
              created: new Date().toISOString(),
            },
          ],
        }));
        setNewComment("");
      }
    }
  } catch (error) {
    console.error("Error adding comment:", error);
    alert("Failed to add comment. Please try again.");
  }
};

  // Data fetching functions
  const fetchPodNames = async () => {
    const headers = {
      Authorization: token,
      "Content-Type": "application/json",
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_FETCHPODSFORMANAGER,
        { clientID: user.data[0].emp_id },
        { headers }
      );

      const data = response.data.data;
      const podNameOptions = data.map((pod) => ({
        label: pod.podName,
        value: pod.id,
      }));

      setPodNames(podNameOptions);
    } catch (error) {
      console.error("Error fetching pod names:", error);
    }
  };

  const fetchAuthorizationTokensAndList = async () => {
    setLoading(true);
    
    if (!selectedPodId || selectedPodId.length === 0) {
      setLoading(false);
      return;
    }
  
    const headers = {
      Authorization: token,
      "Content-Type": "application/json",
    };
  
    try {
      // Fetch authorization tokens
      const authorizationResponse = await axios.post(
        process.env.REACT_APP_CUSTOMERADMIN_FETCHAUTHORIZATIONTOKENS,
        { Organizational_Code: user.data[0].client_code },
        { headers }
      );
  
      const authorizationData = authorizationResponse.data.body.data[0];
      setAuthorizationDetails(authorizationData);
  
      // Initialize columns
      const initialColumns = initializeColumns(authorizationData);
      setColumns(initialColumns);
  
      // Fetch candidates
      const emailFetchingResponse = await axios.post(
        process.env.REACT_APP_CLIENT_FETCHPODCANDIDATES,
        { podID: selectedPodId },
        { headers }
      );
  
      const allCandidates = emailFetchingResponse?.data?.data || [];
      setCandidates(allCandidates);
      const candidateNames = selectedCandidate.map(option => option.value);

      if (allCandidates.length > 0) {
        // Fetch tasks
        const listPayload = {
          pod_Id: selectedPodId,
          username: authorizationData.Jira_admin_login_email_address,
          password: authorizationData.Jira_admin_API_Token,
          projectKey: authorizationData.Jira_Kanben_Project_name,
          jira_url: authorizationData.Jira_URL,
          assignees: allCandidates.map(candidate => candidate.email),
          candidateName: candidateNames || [],
        };
  
        const response = await axios.post(
          "https://irl3zjh84e.execute-api.us-east-1.amazonaws.com/prod/testSingleviewtask",
          listPayload,
          { headers }
        );
  
        // Combine tasks
        const combinedTasks = [
          ...(response.data.data.podMembers || []).map((task) => ({
            ...task,
            comments: task.comments || [],
          })),
          ...(response.data.data.jiraIssues || []).map((task) => ({
            ...task,
            comments: task.comments || [],
          })),
        ];
  
        setTasks(combinedTasks);
        setColumns(groupTasksByStatus(combinedTasks, initialColumns));
      }
    } catch (error) {
      console.error("Error in fetchAuthorizationTokensAndList:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTaskClick = async (task) => {
    const fullTask = tasks.find(
      (t) => (t.key || t.pod_task_id) === (task.key || task.pod_task_id)
    );
    
    if (fullTask) {
      setSelectedTask(fullTask);
      if (fullTask.pod_task_id) {
        try {
          const headers = {
            Authorization: token,
            "Content-Type": "application/json",
          };
          
          const response = await axios.post(
            "https://6w88fzkr0j.execute-api.us-east-1.amazonaws.com/prod/fetchpodtaskComment",
            { pod_task_id: parseInt(fullTask.pod_task_id) },
            { headers }
          );

          // if (response.status === 200) {
          //   setSelectedTask(prevTask => ({
          //     ...prevTask,
          //     comment: response.data.comment
          //   }));
          // }
        } catch (error) {
          console.error("Error fetching pod task comments:", error);
        }
      }
    } else {
      setSelectedTask({
        ...task,
        comments: [],
      });
    }
  };

  const handleDrawerClose = () => {
    setSelectedTask(null);
    setNewComment("");
  };

  // Drag and drop handlers
  const onDragEnd = async (result) => {
    if (!result.destination || isUpdating) return;

    const { source, destination, draggableId } = result;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const task = Object.values(columns).reduce((found, column) => {
      if (found) return found;
      return column.items.find(
        (task) => (task.key || task.pod_task_id).toString() === draggableId
      );
    }, null);

    if (!task) return;

    const oldColumns = columns;
    const newColumns = { ...columns };
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];

    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];

    const [removed] = sourceItems.splice(source.index, 1);
    const movedTask = { ...removed, status: destination.droppableId };
    destItems.splice(destination.index, 0, movedTask);

    newColumns[source.droppableId] = {
      ...sourceColumn,
      items: sourceItems,
    };
    newColumns[destination.droppableId] = {
      ...destColumn,
      items: destItems,
    };

    setColumns(newColumns);
    setIsUpdating(true);

    try {
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };

      if (!authorizationDetails?.Jira_admin_API_Token || 
          !authorizationDetails?.Jira_admin_login_email_address || 
          !authorizationDetails?.Jira_URL) {
        throw new Error("Missing authorization details");
      }

      if (task.pod_task_id) {
        // Update pod task
        const updateResponse = await axios.put(
          process.env.REACT_APP_CLIENT_UPDATEKANBANBOARDPODTASK,
          {
            pod_task_id: task.pod_task_id,
            status: destination.droppableId,
          },
          { headers }
        );

        if (updateResponse.status !== 200) {
          throw new Error("Failed to update pod task status");
        }
      } else {
        // Update JIRA task
        const transitionId = await fetchTransitionId(
          destination.droppableId,
          task.key
        );

        if (!transitionId) {
          throw new Error("Invalid transition for this status change");
        }

        const updateResponse = await axios.put(
          process.env.REACT_APP_CLIENT_KANBANBOARDUPDATE,
          {
            Jira_URL: authorizationDetails.Jira_URL,
            Jira_admin_login_email_address: authorizationDetails.Jira_admin_login_email_address,
            Jira_admin_API_Token: authorizationDetails.Jira_admin_API_Token,
            key: task.key,
            transitionId: transitionId,
            status: destination.droppableId,
          },
          {
            headers,
            timeout: 10000
          }
        );

        if (updateResponse.status !== 200) {
          throw new Error("Failed to update JIRA task status");
        }
      }
    } catch (error) {
      console.error("Error updating task status:", error);
      setColumns(oldColumns);
      alert("Failed to update task status. Please try again.");
    } finally {
      setIsUpdating(false);
    }
  };

  const fetchTransitionId = async (status, key) => {
    if (!status || !key) return null;

    const headers = {
      Authorization: token,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_FETCHSTATUSFORSPECIFICJIRAISSUE,
        {
          Jira_admin_login_email_address: authorizationDetails.Jira_admin_login_email_address,
          Jira_admin_API_Token: authorizationDetails.Jira_admin_API_Token,
          key: key,
          Jira_URL: authorizationDetails.Jira_URL,
          status: status,
        },
        {
          headers,
          timeout: 5000
        }
      );

      if (!response.data?.body) {
        throw new Error("Invalid response format");
      }

      const statusData = response.data.body;
      const matchingStatus = statusData.find((s) => s.name === status);

      return matchingStatus?.id || null;
    } catch (error) {
      console.error("Error fetching transitionId:", error);
      return null;
    }
  };
// Return/Render section
return (
  <div className="kanban-board">
    <h1>{intl.formatMessage({ id: "podBoard" })}</h1>
    
    {/* Pod and Candidate Selection Controls */}
    <div className="board-controls-wrapper">
      <Select
        isMulti
        options={podNames}
        value={podNames.filter((option) => selectedPodId.includes(option.value))}
        onChange={(selectedOptions) => setSelectedPodId(selectedOptions.map((opt) => opt.value))}
        placeholder={intl.formatMessage({ id: 'selectPod' })}
        className="select-field"
      />
      
      <Select
        isMulti
        options={candidates.map(candidate => ({
          value: candidate.Candidate_Name,
          label: candidate.Candidate_Name
        }))}
        value={selectedCandidate}
        onChange={(selectedOptions) => setSelectedCandidate(selectedOptions || [])}
        placeholder="Select Candidates"
        className="select-field"
        styles={{
          control: (base) => ({
            ...base,
            marginTop: '10px'
          })
        }}
      />
    </div>

    {/* Kanban Board */}
    <div style={{ position: "relative", width: "100%" }}>
      <button
        className="scroll-button scroll-left"
        onClick={() => handleScroll("left")}
      >
        ←
      </button>

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="scrollable-board" ref={scrollContainerRef}>
          {Object.entries(columns).map(([columnId, column]) => (
            <Droppable key={columnId} droppableId={columnId}>
              {(provided, snapshot) => (
                <div
                  className="column"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <div className="task-list">
                    <h6
                      style={{
                        color: "grey",
                        fontWeight: "600",
                        borderBottomColor:
                          column.type === "closed" ? "green" : "yellow",
                      }}
                    >
                      {column.title} ({column.items.length})
                    </h6>

                    <div className="tasks-container">
                      {loading ? (
                        <div className="loading-container">
                          <CircularProgress size={24} />
                        </div>
                      ) : (
                        column.items.map((task, index) => (
                          <Draggable
                            key={task.key || task.pod_task_id}
                            draggableId={(task.key || task.pod_task_id).toString()}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="task"
                                onClick={() => handleTaskClick(task)}
                                style={{
                                  ...provided.draggableProps.style,
                                  cursor: "pointer",
                                }}
                              >
                                <div className="task-content">
                                  <div className="task-header">
                                    <div className="task-type-icon">
                                      {task.key ? (
                                        <FaJira 
                                          size={16} 
                                          className="jira-icon" 
                                          title="JIRA Issue"
                                        />
                                      ) : (
                                        <FaBug 
                                          size={16} 
                                          className="pod-icon" 
                                          title="POD Task"
                                        />
                                      )}
                                    </div>
                                    <div className="task-title">
                                      {task.summary || task.pod_task_name}
                                    </div>
                                  </div>
                                  <div className="task-sprint">
                                    {task.key}
                                  </div>
                                  <div className="task-assignee">
                                    <PersonIcon style={{ color: "green" }} />
                                    {task.Candidate_Name}
                                  </div>
                                  <div className="task-due-date">
                                    Due Date:{" "}
                                    {moment(task.due_date).format("MM-DD-YYYY") || "Not Set"}
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))
                      )}
                      {provided.placeholder}
                    </div>
                  </div>
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </DragDropContext>

      <button
        className="scroll-button scroll-right"
        onClick={() => handleScroll("right")}
      >
        →
      </button>
    </div>

    {/* Comment Drawer */}
    <Drawer 
      anchor="right" 
      open={!!selectedTask} 
      onClose={handleDrawerClose}
    >
      <div style={{ width: "400px", padding: "20px" }}>
        {/* Task Header */}
        <div
          className="drawer-header"
          style={{
            borderBottom: "1px solid #eee",
            paddingBottom: "10px",
            marginBottom: "20px",
          }}
        >
          <h5 style={{ margin: 0 }}>
            {selectedTask?.key || selectedTask?.pod_task_id} - {selectedTask?.summary || selectedTask?.pod_task_name}
          </h5>
          <div style={{ fontSize: "0.9em", color: "#666", marginTop: "4px" }}>
            Status: {selectedTask?.status}
          </div>
        </div>

        {/* Comments Section */}
        <div
          className="comments-section"
          style={{
            maxHeight: "calc(100vh - 300px)",
            overflowY: "auto",
            padding: "0 4px",
          }}
        >
          {renderComments()}
        </div>

        {/* Add Comment Section */}
        <div
          style={{
            borderTop: "1px solid #eee",
            paddingTop: "20px",
            marginTop: "20px",
            background: "white",
            position: "sticky",
            bottom: 0,
          }}
        >
          <TextField
            label="Add a comment"
            multiline
            rows={3}
            fullWidth
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            variant="outlined"
            size="small"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddComment}
            disabled={!newComment.trim()}
            style={{ marginTop: "10px", float: "right" }}
          >
            Add Comment
          </Button>
        </div>
      </div>

      <style jsx>{`
        .comments-section::-webkit-scrollbar {
          width: 6px;
        }

        .comments-section::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 3px;
        }

        .comments-section::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 3px;
        }

        .comments-section::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        .comment:hover {
          background-color: #f1f5f9 !important;
        }
      `}</style>
    </Drawer>
  </div>
);
};

export default KanbanBoard;