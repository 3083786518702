import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
const LanguageSelectorWrapper = styled.div`
  
display: inline-flex;
flex-direction: row;
justify-content: center;
align-items: center;
margin: auto;
margin-top: 12rem;

`;

const Select = styled.select`
padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: none;
  width: 12rem;
  cursor: pointer;
`;



const Option = styled.option`
  padding: 8px;
`;

const LanguageSelector = ({ currentLocale, changeLocale }) => {
  
  const handleLanguageChange = (event) => {
    const newLocale = event.target.value;
    localStorage.setItem('locale', newLocale); // Store selected language
    changeLocale(newLocale);
  };

  return (
    <select style={{width:"15rem" }} value={currentLocale} onChange={handleLanguageChange}>
      <option value="en">Preferred Language</option>
      <option value="en">English</option>
      <option value="fr">French</option>
      <option value="es">Spanish</option>
    </select>
  );
};

LanguageSelector.propTypes = {
  currentLocale: PropTypes.string.isRequired,
  changeLocale: PropTypes.func.isRequired,
};
export default LanguageSelector;
