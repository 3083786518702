import React, { useEffect, useState, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-plugin-annotation';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

Chart.register(...registerables);

const DashboardTaskmetricsManager = ({  initialPodIds,selectedSprint,sprintStartDate,sprintEndDate  }) => {
  const token = useAuthToken();
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);

  const [metrics, setMetrics] = useState(null);
  const chartRef = useRef(null);
  const chartId = useRef(`completedChart-${Math.random().toString(36).substring(2, 9)}`).current;

  useEffect(() => {
    fetchMetrics();
  }, [initialPodIds, token, selectedSprint]);

  useEffect(() => {
    if (metrics) {
      const chartData = [metrics.Complete, metrics.Open];
      displayChart(chartData);
    }

    return () => {
      // Cleanup chart instance on component unmount or before re-rendering
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [metrics]);

  const fetchMetrics = async () => {
    if (!token) return; // Exit early if there is no token

    try {
      const response = await fetch(process.env.REACT_APP_CLIENT_PODTASKSDASHBOARDMANAGER, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            manager_id: user.data[0].emp_id,
          pod_id: initialPodIds,
          
        }),
      });

      const responseData = await response.json();
      const parsedData = responseData.body; // Assuming responseData.body is already parsed

      if (parsedData && parsedData.Open !== undefined && parsedData.Complete !== undefined) {
        setMetrics(parsedData);
      } else {
        setMetrics(null);
      }
    } catch (error) {
      console.error('Error fetching metrics:', error);
      setMetrics(null);
    }
  };

  const displayChart = (chartData) => {
    const ctx = document.getElementById(chartId);
    if (!ctx) return;

    if (chartRef.current) {
      chartRef.current.destroy();
    }

    chartRef.current = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['Completed Tasks', 'Open Tasks'],
        datasets: [
          {
            data: chartData,
            backgroundColor: ['lightgreen', 'red'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          annotation: {},
          legend: {
            display: false,
          },
        },
        layout: {
          padding: 10,
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: function (tooltipItem, data) {
              const value = data.datasets[0].data[tooltipItem.dataIndex];
              return value;
            },
          },
        },
      },
    });
  };

  return (
    <div className="chart-container">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 className="chart-heading">Tasks</h2>
        <div className="chart-wrapper"  style={{ width: "145px", height: "145px", margin: "auto",marginTop:"15px"}} >
          {metrics && (metrics.Open !== 0 || metrics.Complete !== 0) ? (
            <canvas  id={chartId}    ></canvas>
          ) : (
            <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardTaskmetricsManager;
