import React, { useState, useEffect, useMemo } from 'react';
import { 
  Box,
  Typography,
  Paper,
  Card,
  CardContent,
  TextField,
  Select,
  MenuItem,
  useTheme,
  Button,
  Stack,
  Alert,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  Breadcrumbs,
  Divider,
  Chip,
  Fade,
  alpha
} from '@mui/material';
import { 
  IconButton, 
  Tooltip 
} from '@mui/material';
import {
  Assignment as AssignmentIcon,
  Visibility as VisibilityIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  Person as PersonIcon,
  CalendarToday as CalendarIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Refresh as RefreshIcon
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useAuthToken } from "../TokenContext";
import { useLocation, Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { 
  ArrowBack as ArrowBackIcon 
} from '@mui/icons-material';


const TabButton = ({ active, icon: Icon, label, onClick }) => (
  <Button
    onClick={onClick}
    sx={{
      minWidth: 160,
      py: 2,
      px: 4,
      borderRadius: '16px',
      backgroundColor: active ? 'primary.main' : 'transparent',
      color: active ? 'white' : 'text.primary',
      '&:hover': {
        backgroundColor: active ? 'primary.dark' : alpha('#1976d2', 0.1),
        transform: 'translateY(-2px)',
      },
      transition: 'all 0.3s ease',
      boxShadow: active ? '0 4px 12px rgba(0,0,0,0.15)' : 'none',
      display: 'flex',
      gap: 1.5,
      alignItems: 'center',
      textTransform: 'none',
      fontSize: '1rem',
      fontWeight: 600,
    }}
  >
    <Icon sx={{ fontSize: 24 }} />
    {label}
  </Button>
);

// Styled Components
const MainContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 1200,
  margin: '0 auto',
  padding: theme.spacing(4),
  background: alpha(theme.palette.background.paper, 0.8),
  borderRadius: theme.shape.borderRadius * 2,
  backdropFilter: 'blur(8px)',
  '@media (max-width: 1080px)': {
    padding: theme.spacing(2),
    maxWidth: '100%'
  }
}));

const StyledTabs = styled(Box)(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(1),
  marginBottom: theme.spacing(4),
  '& button': {
    flex: 1,
    padding: theme.spacing(1.5),
    border: 'none',
    borderRadius: theme.shape.borderRadius,
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 600,
    fontSize: '1rem',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&.active': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    }
  }
}));

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  width: '100%',
  maxWidth: 800,
  margin: '0 auto'
}));

const PodRetroActivity = () => {
  const theme = useTheme();
  const intl = useIntl();
  const token = useAuthToken();
  const { user } = useSelector(state => state.auth);
  const location = useLocation();
  const { podid } = location.state;

  // State
  const [activeTab, setActiveTab] = useState('post');
  const [sprintData, setSprintData] = useState([]);
  const [selectedSprint, setSelectedSprint] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [retroData, setRetroData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage,setRowsPerPage] = useState(5);
  const [sprintFilter, setSprintFilter] = useState('');
  const [teamFilter, setTeamFilter] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');

  // Form state
  const [formData, setFormData] = useState({
    pod_feedback: '',
    start_doing: '',
    stop_doing: '',
    keep_doing: '',
    sprint: '',
  });

  // Fetch sprint data
  useEffect(() => {
    if (token) {
      fetchSprintData();
      fetchRetroData();
    }
  }, [token]);

  const fetchSprintData = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_TALENT_SPRINTFILTERTAENT,
        { talent_id: user.data[0].c_id },
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.statusCode === 200 && response.data.body?.sprint_filter) {
        setSprintData(response.data.body.sprint_filter);
      }
    } catch (error) {
      showSnackbar('Failed to fetch sprint data', 'error');
    }
  };

  const fetchRetroData = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_PODRETROLIST,
        { pods_id: podid },
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.data) {
        const processedData = response.data.data.map(item => ({
          ...item,
          retroFields: parseRetroFields(item.retro)
        }));
        setRetroData(processedData);
      }
    } catch (error) {
      showSnackbar('Failed to fetch retro data', 'error');
    }
  };

  const parseRetroFields = (retroString) => {
    try {
      return JSON.parse(retroString) || {};
    } catch (error) {
      return {};
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const retroFeedback = {
      start_doing: formData.start_doing,
      stop_doing: formData.stop_doing,
      keep_doing: formData.keep_doing
    };

    const payload = {
      pod_feedback: formData.pod_feedback,
      retro: retroFeedback,
      sprint: selectedSprint,
      status: "Completed",
      pods_id: podid,
      submitted_by: user.data[0].firstName,
      submitted_by_id: user.data[0].c_id
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_TALENT_PODRETROS,
        payload,
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.status === 200) {
        showSnackbar('Retro posted successfully', 'success');
        resetForm();
        fetchRetroData();
      } else {
        throw new Error(response.data.data || 'Failed to post retro');
      }
    } catch (error) {
      showSnackbar(error.message || 'Failed to post retro', 'error');
    }
  };

  // Helper Functions
  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const resetForm = () => {
    setFormData({
      pod_feedback: '',
      start_doing: '',
      stop_doing: '',
      keep_doing: '',
      sprint: '',
    });
    setSelectedSprint('');
  };

  // Filtered and sorted data
  const filteredAndSortedData = useMemo(() => {
    let result = retroData;

    if (teamFilter) {
      result = result.filter(item => item.submitted_by === teamFilter);
    }

    return result.sort((a, b) => {
      const dateA = moment(a.createdAt);
      const dateB = moment(b.createdAt);
      return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
    });
  }, [retroData, teamFilter, sortDirection]);

  // Render Functions
  const renderPostForm = () => (
    <Card elevation={0} sx={{ borderRadius: theme.shape.borderRadius * 2 }}>
      <CardContent sx={{ p: 4 }}>
        <Typography variant="h6" gutterBottom color="primary" fontWeight={600}>
          New Retro
        </Typography>
        <Divider sx={{ mb: 4 }} />
        
        <StyledForm onSubmit={handleSubmit}>
          <TextField
            label="Pod Retro Feedback"
            multiline
            rows={4}
            value={formData.pod_feedback}
            onChange={(e) => setFormData(prev => ({ ...prev, pod_feedback: e.target.value }))}
            required
          />

          <Select
            value={selectedSprint}
            onChange={(e) => setSelectedSprint(e.target.value)}
            displayEmpty
            required
          >
            <MenuItem value="">Select Sprint</MenuItem>
            {sprintData.map((sprint, index) => (
              <MenuItem key={index} value={sprint.sprint_name}>
                {sprint.sprint_name}
              </MenuItem>
            ))}
          </Select>

          <TextField
            label="Start Doing"
            multiline
            rows={3}
            value={formData.start_doing}
            onChange={(e) => setFormData(prev => ({ ...prev, start_doing: e.target.value }))}
            required
          />

          <TextField
            label="Stop Doing"
            multiline
            rows={3}
            value={formData.stop_doing}
            onChange={(e) => setFormData(prev => ({ ...prev, stop_doing: e.target.value }))}
            required
          />

          <TextField
            label="Keep Doing"
            multiline
            rows={3}
            value={formData.keep_doing}
            onChange={(e) => setFormData(prev => ({ ...prev, keep_doing: e.target.value }))}
            required
          />

          <Button
            type="submit"
            variant="contained"
            sx={{
              mt: 2,
              py: 1.5,
              borderRadius: theme.shape.borderRadius,
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`
            }}
          >
            Post Retro
          </Button>
        </StyledForm>
      </CardContent>
    </Card>
  );

  const renderTabs = () => (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        mb: 4,
        p: 1,
        borderRadius: '20px',
        backgroundColor: alpha(theme.palette.background.paper, 0.6),
        backdropFilter: 'blur(8px)',
      }}
    >
      <TabButton
        active={activeTab === 'post'}
        icon={AssignmentIcon}
        label="Post Retro"
        onClick={() => setActiveTab('post')}
      />
      <TabButton
        active={activeTab === 'view'}
        icon={VisibilityIcon}
        label="View Retros"
        onClick={() => setActiveTab('view')}
      />
    </Stack>
  );

  const renderFilters = () => (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      spacing={2}
      sx={{
        mb: 3,
        p: 2,
        borderRadius: '16px',
        backgroundColor: alpha(theme.palette.background.paper, 0.8),
        backdropFilter: 'blur(8px)',
      }}
    >
      <Select
        value={teamFilter}
        onChange={(e) => setTeamFilter(e.target.value)}
        displayEmpty
        startAdornment={<PersonIcon sx={{ mr: 1, color: 'primary.main' }} />}
        sx={{
          minWidth: 200,
          '& .MuiSelect-select': {
            py: 1.5,
          },
        }}
      >
        <MenuItem value="">All Team Members</MenuItem>
        {Array.from(new Set(retroData.map(item => item.submitted_by)))
          .map((member, index) => (
            <MenuItem key={index} value={member}>
              <Stack direction="row" spacing={1} alignItems="center">
                <PersonIcon fontSize="small" />
                {member}
              </Stack>
            </MenuItem>
          ))}
      </Select>

      <Select
        value={sprintFilter}
        onChange={(e) => setSprintFilter(e.target.value)}
        displayEmpty
        startAdornment={<RefreshIcon sx={{ mr: 1, color: 'primary.main' }} />}
        sx={{ minWidth: 200 }}
      >
        <MenuItem value="">All Sprints</MenuItem>
        {Array.from(new Set(retroData.map(item => item.sprint)))
          .map((sprint, index) => (
            <MenuItem key={index} value={sprint}>{sprint}</MenuItem>
          ))}
      </Select>

      <Button
        startIcon={sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
        onClick={() => setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc')}
        variant="outlined"
        sx={{ minWidth: 140 }}
      >
        Sort by Date
      </Button>
    </Stack>
  );

  // First, calculate the filtered data
const filteredData = useMemo(() => {
  return retroData
    .filter(item => !teamFilter || item.submitted_by === teamFilter)
    .filter(item => !sprintFilter || item.sprint === sprintFilter)
    .sort((a, b) => {
      const dateA = moment(a.createdAt);
      const dateB = moment(b.createdAt);
      return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
    });
}, [retroData, teamFilter, sprintFilter, sortDirection]);
useEffect(() => {
  setPage(0);
}, [teamFilter, sprintFilter]); // Reset to first page when filters change
  const renderRetroTable = () => (
    
    <Fade in>
      <Card elevation={0} sx={{ borderRadius: '20px', overflow: 'hidden' }}>
        <CardContent sx={{ p: 0 }}>
          {renderFilters()}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.1) }}>
                  <TableCell>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <PersonIcon color="primary" />
                      <Typography fontWeight={600}>Team Member</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <CalendarIcon color="primary" />
                      <Typography fontWeight={600}>Date</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>Sprint</TableCell>
                  <TableCell>Start Doing</TableCell>
                  <TableCell>Stop Doing</TableCell>
                  <TableCell>Keep Doing</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .filter(item => !teamFilter || item.submitted_by === teamFilter)
                  .filter(item => !sprintFilter || item.sprint === sprintFilter)
                  .sort((a, b) => {
                    const dateA = moment(a.createdAt);
                    const dateB = moment(b.createdAt);
                    return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
                  })
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map((item, index) => (
                    <TableRow 
                      key={item.id}
                      sx={{
                        '&:hover': {
                          backgroundColor: alpha(theme.palette.primary.main, 0.05),
                        },
                        transition: 'background-color 0.3s ease',
                      }}
                    >
                      <TableCell>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Chip
                            avatar={<PersonIcon />}
                            label={item.submitted_by}
                            variant="outlined"
                            color="primary"
                          />
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Chip
                          icon={<CalendarIcon />}
                          label={moment(item.createdAt).format('MMM DD, YYYY')}
                          variant="outlined"
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <Chip
                          icon={<RefreshIcon />}
                          label={item.sprint}
                          variant="outlined"
                          color="secondary"
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            p: 1,
                            borderRadius: 1,
                            backgroundColor: alpha(theme.palette.success.main, 0.1),
                            color: 'success.main',
                          }}
                        >
                          {item.retroFields.start_doing}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            p: 1,
                            borderRadius: 1,
                            backgroundColor: alpha(theme.palette.error.main, 0.1),
                            color: 'error.main',
                          }}
                        >
                          {item.retroFields.stop_doing}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            p: 1,
                            borderRadius: 1,
                            backgroundColor: alpha(theme.palette.info.main, 0.1),
                            color: 'info.main',
                          }}
                        >
                          {item.retroFields.keep_doing}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Chip
                          icon={item.status === 'Completed' ? <CheckCircleIcon /> : <CancelIcon />}
                          label={item.status}
                          color={item.status === 'Completed' ? 'success' : 'error'}
                          variant="outlined"
                          size="small"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
  component="div"
  count={filteredData.length} // Use filtered data length instead of total data length
  page={page}
  onPageChange={(_, newPage) => setPage(newPage)}
  rowsPerPage={rowsPerPage}
  rowsPerPageOptions={[5]}
  // Reset page to 0 when filters change
  onRowsPerPageChange={(event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }}
/>

        </CardContent>
      </Card>
    </Fade>
  );


  return (
    <MainContainer
      sx={{
        p: 3,
        minHeight: '100vh',
        margin:"auto",
        marginTop:"5rem",
        backgroundColor: alpha(theme.palette.background.default, 0.8),
      }}
    >
      <Stack spacing={3}>
      <Stack 
    direction="row" 
    alignItems="center" 
    spacing={2}
    sx={{ mb: 2 }}
  >
    <Tooltip title="Back to My Pods">
      <IconButton
        component={Link}
        to="/Pods"
        sx={{ 
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
          }
        }}
      >
        <ArrowBackIcon />
      </IconButton>
    </Tooltip>
    <Breadcrumbs>
      <Link
        to="/Pods"
        style={{
          color: theme.palette.primary.main,
          textDecoration: 'none',
          fontWeight: 600,
        }}
      >
        My Pods
      </Link>
      <Typography color="textSecondary" fontWeight={600}>
        {activeTab === 'post' ? 'Post Retro' : 'View Retros'}
      </Typography>
    </Breadcrumbs>
  </Stack>

        {renderTabs()}
        
        {activeTab === 'view' ? renderRetroTable() : renderPostForm()}
      </Stack>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={snackbar.severity}
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </MainContainer>
  );
}

export default PodRetroActivity;