import React, { useEffect, useState, useRef } from "react";
import { Chart } from "chart.js";
import axios from "axios";
import { useAuthToken } from '../TokenContext';
import { useSelector } from 'react-redux';
import './time.css';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

const TimeDetailView = ({ initialPodIds,selectedSprint,sprintStartDate,sprintEndDate }) => {
  const [timeData, setTimeData] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const chartRef = useRef(null);
  const token = useAuthToken();
  const { user } = useSelector((state) => state.auth);
  const intl = useIntl();

  useEffect(async () => {
    if (!token) {
      return; // Exit early if there is no token
    }
    if (user.data[0].type === "stakeholder") {
      const headers = {
        Authorization: token,
        'Content-Type': 'application/json',
      };
      try {
        const payload = {};
    
       
          payload.pod_id = initialPodIds || [];
     
          // If pod_id is not available, check if org_code is available, send only org_code
          payload.org_code = user.data[0].client_code;
        
        payload.sprint_name = selectedSprint || '';
        payload.sprint_start_date = sprintStartDate || '';
        payload.sprint_sprint_end_datename = sprintEndDate || '';
        const response = await axios.post(process.env.REACT_APP_STAKEHOLDER_JIRASHOWTIMEMETRICSSTAKEHOLDER,  payload,
          { headers: headers });
        const responseData = JSON.parse(response.data.body);
        setTimeData(responseData);
        // console.log('time', responseData);
      } catch (error) {
        console.error("Error fetching time details:", error);
      }
    } else {
      // If user type is not stakeholder, fetch data using the existing logic
      fetchData();
    }
  
    // Cleanup function to cancel ongoing tasks when the component is unmounted
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [token, user.data[0].type, initialPodIds,selectedSprint,sprintStartDate,sprintEndDate]);
  

  const fetchData = async () => {
    const headers = {
      Authorization: token,
      'Content-Type': 'application/json',
    };
    try {
      const response = await axios.post(process.env.REACT_APP_CLIENT_JIRASHOWTIMEMANAGERID, { manager_id: user.data[0].emp_id, pod_id: initialPodIds || null,   sprint_name: selectedSprint || '',
      sprint_start_date:sprintStartDate||'',
      sprint_end_date: sprintEndDate ||'' },
        { headers: headers });
      const responseData = JSON.parse(response.data.body);
      setTimeData(responseData);
      console.log('time', responseData);
    } catch (error) {
      console.error("Error fetching time details:", error);
    }
  };

  useEffect(() => {
    if (timeData ) {
      renderChart();
    }
  }, [timeData, selectedFilter]);

  const renderChart = () => {
    if (!timeData || !timeData.body) {
      console.log('No data available');
      return;
    }
  
    let selectedData;
  
    if (!selectedFilter || selectedFilter === 'total_time_by_pods') {
      // Show total time distribution across all pods by default
      selectedData = timeData.body.total_time_by_pods;
    } else if (selectedFilter === 'jira_show_time_by_this_week') {
      selectedData = timeData.body.jira_show_time_by_this_week;
    } else if (selectedFilter === 'jira_show_time_by_last_week') {
      selectedData = timeData.body.jira_show_time_by_last_week;
    } else {
      // Find the selected pod or candidate based on the filter
      selectedData = timeData.body.jira_show_time_by_pod.find(
        (pod) => pod.pod_name.trim() === selectedFilter.trim()
      );
  
      if (!selectedData) {
        // If the selected filter is not a pod name, it must be a candidate name
        selectedData = timeData.body.jira_show_time_by_talent.find(
          (talent) => talent.candidate_name.trim() === selectedFilter.trim()
        );
      }
    }
  
    // If still no data is found, default to total time distribution
    if (!selectedData) {
      selectedData = timeData.body.total_time_by_pods;
    }
  
    // Filter categories to include only Bug-Fixing, Deployment, Design, Development, Performance, Security
    const includedCategories = ['Bug-Fixing', 'Deployment', 'Design', 'Development', 'Performance', 'Security'];
    const filteredData = Object.keys(selectedData)
      .filter(category => includedCategories.includes(category))
      .reduce((obj, key) => {
        obj[key] = selectedData[key];
        return obj;
      }, {});
  
    // Format the data for the pie chart
    const labels = Object.keys(filteredData).map((category) => ({
      label: `${category}\n${convertMinutesToTime(filteredData[category])}`,
      value: parseFloat(filteredData[category]),
    }));
  
    const data = labels.map((labelObj) => labelObj.value);
  
    const ctx = document.getElementById("timeChart").getContext("2d");
  
    const backgroundColors = [
      "#FF6384",
      "#36A2EB",
      "#FFCE56",
      "#FF5733",
      "#44A7EB",
      "#EFC656",
      // Add more colors as needed
    ];
  
    // Clear the previous chart
    if (chartRef.current) {
      chartRef.current.destroy();
    }
  
    chartRef.current = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: labels.map((labelObj) => labelObj.label),
        datasets: [{
          data: data,
          backgroundColor: backgroundColors,
          hoverBackgroundColor: backgroundColors,
        }],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          annotation: {},
          legend: {
            display: false,
          },
          labels: {
            display: false, // Do not show labels outside the pie chart
          },
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: (tooltipItem, data) => {
              const index = tooltipItem.index;
              const labelObj = labels[index];
              const formattedTime = convertMinutesToTime(labelObj.value);
              return `${labelObj.label}: ${formattedTime}`;
            },
          },
        },
      },
    });
  };
  
  

  
  
  
  
  
  
  
  
  
  
  
  
  
  const formatTime = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return `${hours}h${minutes}m${seconds}s`;
  };
  
  
  
  
  
  
  

  
  
  // Function to convert time in "hh:mm:ss" format to minutes
  const convertTimeToMinutes = (timeString) => {
    if (!timeString) {
      return 0;
    }
  
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return hours * 60 + minutes + seconds / 60;
  };
  
  const convertMinutesToTime = (timeString) => {
    console.log('Received time string:', timeString);
  
    if (!timeString || typeof timeString !== 'string') {
      console.log('Invalid time string:', timeString);
      return 'Invalid Time';
    }
  
    // Split the time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
  
    // Calculate the total minutes
    const totalMinutes = hours * 60 + minutes + seconds / 60;
  
    // Use String formatting to ensure leading zeros
    const formattedHours = String(Math.floor(totalMinutes / 60)).padStart(2, '0');
    const formattedMinutes = String(Math.floor(totalMinutes % 60)).padStart(2, '0');
  
    return `${formattedHours}h${formattedMinutes}m`;
  };
  
  
  
  

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  return (
    <div className="chart-container">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 className="chart-heading"> {intl.formatMessage({ id: 'timeLog' })}</h2>
        <br/>
      
        
        
        <select style={{ width: '12rem' }} onChange={handleFilterChange} value={selectedFilter || ""}>
  <option value="">All Reporters</option>
  {/* <optgroup label="Pods">
    {timeData && timeData.body && timeData.body.jira_show_time_by_pod ? (
      timeData.body.jira_show_time_by_pod.map((pod) => (
        <option key={pod.pod_id} value={pod.pod_name}>
          {pod.pod_name}
        </option>
      ))
    ) : (
      <option value="" disabled>Loading...</option>
    )}
  </optgroup> */}
  
    {timeData && timeData.body && timeData.body.jira_show_time_by_talent ? (
      timeData.body.jira_show_time_by_talent.map((candidate) => (
        <option key={candidate.candidate_id} value={candidate.candidate_name}>
          {candidate.candidate_name}
        </option>
      ))
    ) : (
      <option value="" disabled>Loading...</option>
    )}

  {/* Add options for jira_show_time_by_this_week and jira_show_time_by_last_week */}
  <optgroup label="Time This Week">
    <option value="jira_show_time_by_this_week">This Week</option>
  </optgroup>
  <optgroup label="Time Last Week">
    <option value="jira_show_time_by_last_week">Last Week</option>
  </optgroup>
</select>






          <div className="chart-wrapper" style={{ width: "140px", height: "150px", margin: "auto",marginTop:"35px"}}>
          {timeData && timeData.body && timeData.body.total_time_by_pods ? (
  <canvas id="timeChart" ></canvas>
) : (
  <p> {intl.formatMessage({ id: 'noDataAvailable' })}</p>
)}

          </div>
        
      </div>
    </div>
  );
};


TimeDetailView.propTypes = {
  initialPodIds: PropTypes.array.isRequired,
};
export default TimeDetailView;
