import React, { useState, useEffect } from "react";
import { useLocation, useHistory  } from 'react-router-dom';
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
  Stack,
  Checkbox,
  FormControlLabel,
  Alert,
} from "@mui/material";
import axios from "axios";
import { useAuthToken } from "../TokenContext";
import { useIntl } from "react-intl";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

// Styled components
const ContentWrapper = styled(Box)(({ theme, collapsed }) => ({
  marginLeft: collapsed ? "60px" : "240px",
  marginTop: "5rem",
  width: collapsed ? "80vw" : "70vw",
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    marginLeft: "4rem",
    width: "80vw",
    padding: theme.spacing(1),
  },
}));

const ErrorMessage = styled(Alert)({
  marginBottom: '1rem',
});

const TaskForm = ({ collapsed }) => {
  const location = useLocation();
  const history = useHistory ();
  const token = useAuthToken();
  const intl = useIntl();
  const { user } = useSelector(state => state.auth);
  
  // State management
  const { Id: podId, podname } = location.state || {};
  const [pods, setPods] = useState([]);
  const [selectedPodId, setSelectedPodId] = useState(podId || '');
  const [selectedPodName, setSelectedPodName] = useState(podname || '');
  const [podMembers, setPodMembers] = useState([]);
  const [slackUsers, setSlackUsers] = useState([]);
  const [userStories, setUserStories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [formData, setFormData] = useState({
    podTaskName: "",
    description: "",
    dueDate: "",
    taskOwner: "",
    category: "",
    status: "",
    jiraItems: [],
    notificationRecipient: "",
    hasBlocker: false
  });

  // Validation function
  const validateForm = () => {
    const errors = [];
    
    if (!selectedPodId) {
      errors.push(intl.formatMessage({ id: "selectPodError" }));
    }
    if (!formData.podTaskName?.trim()) {
      errors.push(intl.formatMessage({ id: "podTaskNameError" }));
    }
    if (!formData.description?.trim()) {
      errors.push(intl.formatMessage({ id: "descriptionError" }));
    }
    if (!formData.dueDate) {
      errors.push(intl.formatMessage({ id: "dueDateError" }));
    }
    if (!formData.taskOwner) {
      errors.push(intl.formatMessage({ id: "taskOwnerError" }));
    }
    if (!formData.status) {
      errors.push(intl.formatMessage({ id: "statusError" }));
    }
    if (!formData.category) {
      errors.push(intl.formatMessage({ id: "categoryError" }));
    }

    return errors;
  };

  // API calls
  const fetchPods = async () => {
    if (!user?.data?.[0]?.emp_id || !token) return;
    
    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_GETMULTIPLECLIENTPODS,
        { clientID: user.data[0].emp_id },
        { headers: { Authorization: token } }
      );
      
      if (response.data?.data?.podDetails) {
        setPods(response.data.data.podDetails);
      } else {
        setError(intl.formatMessage({ id: "noPodsFound" }));
      }
    } catch (err) {
      console.error("Error fetching pods:", err);
      setError(intl.formatMessage({ id: "podsFetchError" }));
    }
  };

  const fetchPodMembers = async () => {
    if (!selectedPodId || !token) return;
    
    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_CLIENTFETCHPODMEMBERS,
        { pod_Id: selectedPodId },
        { headers: { Authorization: token } }
      );
      
      if (response.data?.data) {
        setPodMembers(response.data.data);
      } else {
        setError(intl.formatMessage({ id: "noPodMembersFound" }));
      }
    } catch (err) {
      console.error("Error fetching pod members:", err);
      setError(intl.formatMessage({ id: "podMembersFetchError" }));
    }
  };

  const fetchJiraIssues = async () => {
    if (!formData.taskOwner || !selectedPodId || !token) return;
    
    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_JIRAISSUESLISTTALENTS,
        {
          pod_id: selectedPodId,
          c_id: formData.taskOwner
        },
        { headers: { Authorization: token } }
      );
      
      if (response.data.statusCode === 200 && response.data.body) {
        const parsed = JSON.parse(response.data.body);
        setUserStories(parsed.jiraIssues || []);
      }
    } catch (err) {
      console.error("Error fetching JIRA issues:", err);
      setError(intl.formatMessage({ id: "jiraIssuesFetchError" }));
    }
  };

  const fetchSlackUsers = async () => {
    if (!token) return;
    
    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_SLACKUSERSFETCH,
        { token: process.env.REACT_APP_SLACK_TOKEN },
        { headers: { Authorization: token } }
      );
      if (response.data?.body?.users) {
        setSlackUsers(response.data.body.users);
      }
    } catch (err) {
      console.error("Error fetching Slack users:", err);
    }
  };

  // Submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    
    if (validationErrors.length > 0) {
      setError(validationErrors.join("\n"));
      return;
    }

    setLoading(true);
    setError("");

    try {
      // Create task
      await axios.post(
        process.env.REACT_APP_CLIENT_CLIENTCREATEPODTASK,
        {
          project_key: selectedPodName,
          pod_task_name: formData.podTaskName,
          description: formData.description,
          due_date: formData.dueDate,
          candidate_id: parseInt(formData.taskOwner),
          category: formData.category,
          status: formData.status,
          pod_id: selectedPodId,
          issue_key: formData.jiraItems,
          blockercheck: formData.hasBlocker ? 1 : 0
        },
        { headers: { Authorization: token } }
      );

      // Send Slack notification if recipient selected
      if (formData.notificationRecipient) {
        try {
          await axios.post(
            process.env.REACT_APP_CLIENT_SLACKINDIVIDUALTASKNOTIFICATION,
            {
              token: process.env.REACT_APP_SLACK_TOKEN,
              user_id: formData.notificationRecipient,
              task_name: formData.podTaskName
            },
            { headers: { Authorization: token } }
          );
        } catch (slackError) {
          console.error("Slack notification failed:", slackError);
        }
      }

      setSuccess(intl.formatMessage({ id: "taskCreatedSuccess" }));
      setTimeout(() => {
        history.push(`/ViewPodTasksManagerbyid`);
      }, 2000);
      
    } catch (err) {
      console.error("Task creation failed:", err);
      setError(intl.formatMessage({ id: "taskCreationError" }));
    } finally {
      setLoading(false);
    }
  };

  // Effects
  useEffect(() => {
    fetchPods();
    fetchSlackUsers();
  }, [token, user]);

  useEffect(() => {
    if (selectedPodId) {
      fetchPodMembers();
    }
  }, [selectedPodId, token]);

  useEffect(() => {
    if (formData.taskOwner && selectedPodId) {
      fetchJiraIssues();
    }
  }, [formData.taskOwner, selectedPodId, token]);

  return (
    <ContentWrapper collapsed={collapsed}>
      <Typography variant="h4" component="h1" gutterBottom>
        {intl.formatMessage({ id: "CreateTasks" })}
      </Typography>

      {error && <ErrorMessage severity="error">{error}</ErrorMessage>}
      {success && <ErrorMessage severity="success">{success}</ErrorMessage>}

      <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 800, mx: "auto" }}>
        <Stack spacing={3}>
          <FormControl fullWidth required>
            <InputLabel>{intl.formatMessage({ id: "selectPod" })}</InputLabel>
            <Select
              value={selectedPodId}
              onChange={e => {
                const pod = pods.find(p => p.id === e.target.value);
                setSelectedPodId(e.target.value);
                setSelectedPodName(pod?.podName || '');
              }}
              label={intl.formatMessage({ id: "selectPod" })}
            >
              {pods.map((pod) => (
                <MenuItem key={pod.id} value={pod.id}>
                  {pod.podName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            required
            fullWidth
            label={intl.formatMessage({ id: "podTaskName" })}
            value={formData.podTaskName}
            onChange={e => setFormData(prev => ({ ...prev, podTaskName: e.target.value }))}
            inputProps={{ maxLength: 50 }}
          />

          <TextField
            required
            fullWidth
            multiline
            rows={3}
            label={intl.formatMessage({ id: "podTaskDescription" })}
            value={formData.description}
            onChange={e => setFormData(prev => ({ ...prev, description: e.target.value }))}
            inputProps={{ maxLength: 100 }}
          />

          <TextField
            required
            fullWidth
            type="date"
            label={intl.formatMessage({ id: "podTaskDueDate" })}
            value={formData.dueDate}
            onChange={e => setFormData(prev => ({ ...prev, dueDate: e.target.value }))}
            InputLabelProps={{ shrink: true }}
          />

          <FormControl fullWidth required>
            <InputLabel>{intl.formatMessage({ id: "podTaskOwner" })}</InputLabel>
            <Select
              value={formData.taskOwner}
              onChange={e => setFormData(prev => ({ ...prev, taskOwner: e.target.value }))}
              label={intl.formatMessage({ id: "podTaskOwner" })}
            >
              {podMembers.map(member => (
                <MenuItem key={member.c_id} value={member.c_id}>
                  {member.firstName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth required>
            <InputLabel>{intl.formatMessage({ id: "podTaskStatus" })}</InputLabel>
            <Select
              value={formData.status}
              onChange={e => setFormData(prev => ({ ...prev, status: e.target.value }))}
              label={intl.formatMessage({ id: "podTaskStatus" })}
            >
              <MenuItem value="To Do">To Do</MenuItem>
              <MenuItem value="In Progress">In Progress</MenuItem>
              <MenuItem value="Done">Done</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth required>
            <InputLabel>{intl.formatMessage({ id: "podCategories" })}</InputLabel>
            <Select
              value={formData.category}
              onChange={e => setFormData(prev => ({ ...prev, category: e.target.value }))}
              label={intl.formatMessage({ id: "podCategories" })}
            >
              <MenuItem value="Design">Design</MenuItem>
              <MenuItem value="Coding">Coding</MenuItem>
              <MenuItem value="Testing">Testing</MenuItem>
              <MenuItem value="Documentation">Documentation</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>{intl.formatMessage({ id: "Jiraissues" })}</InputLabel>
            <Select
              multiple
              value={formData.jiraItems}
              onChange={e => setFormData(prev => ({ 
                ...prev, 
                jiraItems: Array.isArray(e.target.value) ? e.target.value : [e.target.value]
              }))}
              label={intl.formatMessage({ id: "Jiraissues" })}
            >
              {userStories.map(issue => (
                <MenuItem key={issue.issue_key} value={issue.issue_key}>
                  {`${issue.issue_key} - ${issue.issue_summary} (${issue.issue_status})`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>{intl.formatMessage({ id: "notificationRecipient" })}</InputLabel>
            <Select
              value={formData.notificationRecipient}
              onChange={e => setFormData(prev => ({ ...prev, notificationRecipient: e.target.value }))}
              label={intl.formatMessage({ id: "notificationRecipient" })}
            >
              {slackUsers.map(user => (
                <MenuItem key={user.user_id} value={user.user_id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox
                checked={formData.hasBlocker}
                onChange={e => setFormData(prev => ({ ...prev, hasBlocker: e.target.checked }))}
              />
            }
            label={intl.formatMessage({ id: "checkBoxBlocker" })}
          />

          <Button
            type="submit"
            variant="contained"
            disabled={loading}
            fullWidth
            sx={{ mt: 2 }}
          >
            {loading 
              ? intl.formatMessage({ id: "submitting" })
              : intl.formatMessage({ id: "submit" })
            }
          </Button>
        </Stack>
      </Box>
    </ContentWrapper>
  );
};

export default TaskForm;