import React from 'react';
import { Link , useHistory} from "react-router-dom";

export default function Welcome() {
  return (
      <div className="container">
        <h1 className='register-header'>Welcome!</h1>
        <br></br>
        <p>You have successfully registered with PrismPod</p>
        <br></br>
        <div className="field">
                <p className="control">
                  <Link to="/login">Login</Link>
                </p>
              </div>

      </div>
  )
}
