import React, { Component } from 'react';
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import { Auth } from 'aws-amplify';

class ForgotPasswordVerification extends Component {
  state = {
    verificationcode: "",
    email: "",
    newpassword: "",
    errors: {
      cognito: null,
      blankfield: false
    }
  };

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false
      }
    });
  };

  passwordVerificationHandler = async event => {
    
    event.preventDefault();

    const email = localStorage.getItem("Forgetemail"); 
 
    // console.log(email,
    //   this.state.verificationcode,
    //   this.state.newpassword);

    // AWS Cognito integration here
    try {
      await Auth.forgotPasswordSubmit(
        email,
        this.state.verificationcode,
        this.state.newpassword
      );
      window.alert("Password has been reset successfully.");
       window.location.href = "/login";
    }catch(error) {
      alert(error)
    }
  };

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  
   onInputChange = e => {
    // console.log(e.target.id)
    this.setState((prevState) => {
      return { ...prevState, [e.target.id]: e.target.value };
    });

  }

  render() {
    return (
      <div className="container ">

      <div className="row">
      <div className="col-sm-3"></div>


<div className="col-sm-6"  style={{ boxShadow: "1px 3px 6px #00000029", border: "1px solid #D8D8D8", borderRadius: "16px", opacity: 1 , padding: "30px",boxShadow: '1px 3px 6px #00000029', width: "470px"}} >     

<h1 className='register-header' style={{ marginTop: "100px" }}>Reset Password</h1>
<br></br>
<br></br>



          <form onSubmit={this.passwordVerificationHandler}>
            <div className="field">
              <p className="control">
                <input
                  type="text"
                  className="input2"
                  id="verificationcode"
                  aria-describedby="verificationCodeHelp"
                  placeholder="Enter Code"
                  value={this.state.verificationcode}
                  onChange={this.onInputChange}
                  style={{ 
                    "width": "408px",
                    "height": "54px",
                   fontFamily:"Poppins,Medium",
                   fontWeight:'600',
                    "border":" 1px solid #B7B7B7",
                    "border-radius": "10px",
                    "opacity": 1 }}
                />
              </p>
            </div>
   
            <div className="field">
              <p className="control">
                <input
                  type="password"
                  className="input2"
                  id="newpassword"
                  placeholder="Enter New Password"
                  value={this.state.newpassword}
                  onChange={this.onInputChange}
                  style={{ 
                    "width": "408px",
                    "height": "54px",
                    fontFamily:"Poppins,Medium",
                   fontWeight:'600',
                    "border":" 1px solid #B7B7B7",
                    "border-radius": "10px",
                    "opacity": 1 }}
                />
              </p>
            </div>
            <br></br>
            <br></br>
            <br></br>
           

            <div className="field">
              <p className="control">
              <button className="login" type='submit' style={{ "width": "400px", height: "50px", marginTop: "20px" ,boxShadow: '15px 15px 25px #00000029', fontFamily:"Poppins,Medium",
                   fontWeight:'600' }}>
                  Reset Password
                </button>
              </p>
            </div>
          </form>
        </div>
        <div className="col-sm-3"></div>

        </div>
        </div>
    );
  }
}

export default ForgotPasswordVerification;