import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Card, CardContent, Typography } from '@mui/material';
import {  CheckCircle,  PieChart as PieChartIcon } from '@mui/icons-material';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer ,LabelList} from 'recharts';
import { useAuthToken } from '../TokenContext';
import { Assignment, BugReport, Code, CheckBoxOutlineBlank,Timer } from '@mui/icons-material';
import { Speed as SpeedIcon, Code as CodeIcon } from '@mui/icons-material'; // Import the desired icons
import {useSelector } from "react-redux";

const DashboardContainer = styled.div`
display: flex;
flex-direction: column;
padding: 20px;
background-color: #f0f0f0;
// height: 100vh;

margin-top:1rem;
 font-family: 'Poppins', sans-serif; /* 'Poppins' should be used with quotes */
 @media (max-width: 768px) {
   height: auto;
  }
 `;

const CardsContainer = styled.div`
display: flex;
gap: 20px;
margin-bottom: 20px;
 @media (max-width: 768px) {
   display: flex;
   flex-direction: column;
  }
`;

const CardWrapper = styled.div`
position: relative;
flex: 1;
`;

const DataCard = styled(Card)`
display: flex;
flex-direction: row;
justify-content: center;
position: relative;
padding: 20px 20px 20px 20px;
background-color: #fff;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

height: 10rem;
font-size: 18px;
font-family: Poppins, Medium;
overflow: auto !important; // Hide overflow to prevent unwanted scrollbars
   -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */

  &::-webkit-scrollbar {
    display: none !important; // Hide scrollbar for Webkit browsers
  }
`;

const CardIcon = styled.div`
position: absolute;
top: -30px;
left: 20px;
width: 60px;
height: 60px;
background-color: black;
border-radius: 1rem;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
display: flex;
align-items: center;
justify-content: center;
font-size: 18px;
color: white;
z-index: 10;
`;

const GraphsContainer = styled.div`
display: flex;
gap: 20px;
margin-top:5rem;
// margin-bottom: 20px;
@media (max-width: 768px) {
   display: flex;
   flex-direction: column;
   gap:2rem;
  }
`;

const GraphCardContainer = styled.div`
position: relative;
flex: 1;
width: 100%;
`;

const BackgroundCard = styled(Card)`
position: relative;
background-color: #fff;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3) !important;
border-radius: 8px;
height: 300px;
width: 100%;
`;

const ForegroundCard = styled(Card)`
  position: absolute;
  top: -30px; /* Adjusts the vertical position */
  left: 50%; /* Centers horizontally */
  width: 90%; /* Sets the width */
  height: 80%; /* Adjust height if needed */
  background-color: #4caf50;
 box-shadow:  0 4px 8px rgba(0, 0, 0, 0.3) !important; ;

  border-radius: 8px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%); /* Centers the card horizontally */
`;


const GraphLabel = styled.div`
position: absolute;
top: -30px;
width: 100%;
text-align: center;
color: #333;
font-weight: bold;
background-color: #fff;
padding: 5px;
border-radius: 8px;
`;

const NameLabel = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px;
  font-weight: 600;
  color: #333;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  gap: 10px; /* Space between the icon and the name */
`;
const StyledXAxisTick = styled.text`
  font-size: 16px;
  font-weight: bold;
  fill: white;
  cursor: pointer; /* Indicates interactivity */

  @media (max-width: 768px) {
    font-size: 12px; /* Adjust font size for mobile */
  }
`;

const TooltipWrapper = styled.div`
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
`;

const CustomXAxisTick = (props) => {
  const { x, y, payload } = props;

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Map full names to abbreviated forms
  const nameMapping = {
    "Previous Week": "PW",
    "Current Week": "CW"
  };

  // Determine display name based on screen size
  const displayName = isMobile ? nameMapping[payload.value] || payload.value : payload.value;

  return (
    <StyledXAxisTick x={x} y={y + 10} textAnchor="middle" data-fullname={payload.value}>
      {displayName}
    </StyledXAxisTick>
  );
};
const Dashboardnew = () => {
  const [storiesData, setStoriesData] = useState({ open: 0, completed: 0 });
  const [defectsData, setDefectsData] = useState({ open: 0, resolved: 0 });
  const [tasksData, setTasksData] = useState({ open: 0, completed: 0 });
  const [showTimeData, setShowTimeData] = useState({ last_week: "", this_week: "" });
  const [sprintVelocityData, setSprintVelocityData] = useState([]);
  const [codeByDayData, setCodeByDayData] = useState([]);
  const token = useAuthToken();
  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    if (!token) return;

    fetchStoriesData();
    fetchDefectsData();
    fetchTasksData();
    fetchShowTimeData();
    fetchSprintVelocityData();
    fetchCodeByDayData();
  }, [token]);

  const fetchStoriesData = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_TALENT_JIRASTORIESDASHBOARDTALENT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          "c_id":user.data[0].c_id,
          "pod_id": [],
          "sprint_name": ""
        })
      });
      const data = await response.json();
      const { open_user_stories, completed_user_stories } = JSON.parse(data.body);
      setStoriesData({ open: open_user_stories, completed: completed_user_stories });
    } catch (error) {
      console.error('Error fetching stories data:', error);
    }
  };

  const fetchDefectsData = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_TALENT_JIRADEFECTSDASHBOARDTALENT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          "c_id": user.data[0].c_id,
          "pod_id": [],
          "sprint_name": ""
        })
      });
      const data = await response.json();
      const { open_defects, resolved_defects } = JSON.parse(data.body);
      setDefectsData({ open: open_defects, resolved: resolved_defects });
    } catch (error) {
      console.error('Error fetching defects data:', error);
    }
  };

  const fetchTasksData = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_TALENT_PODTASKSDASHBOARDTALENT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          "talent_id":user.data[0].c_id,
          "pod_id": []
        })
      });
      const data = await response.json();
      const { Open, Complete } = data.body;
      setTasksData({ open: Open, completed: Complete });
    } catch (error) {
      console.error('Error fetching tasks data:', error);
    }
  };

  const fetchShowTimeData = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_TALENT_TIMLOGDASHBOARDTALENT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          "c_id": user.data[0].c_id,
          "pod_id": [],
          "sprint_name": "",
          "sprint_start_date": "",
          "sprint_end_date": ""
        })
      });
      const data = await response.json();
      setShowTimeData({
        last_week: data?.body?.last_week ?? 'Data not available',
        this_week: data?.body?.this_week ?? 'Data not available'
      });
    } catch (error) {
      console.error('Error fetching show time data:', error);
    }
  };
  

  const fetchSprintVelocityData = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_TALENT_SPRINTVELOCITYMETRISTALENT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          "c_id":user.data[0].c_id,
          "pod_id": []
        })
      });
      const data = await response.json();
      setSprintVelocityData(JSON.parse(data.body));
    } catch (error) {
      console.error('Error fetching sprint velocity data:', error);
    }
  };

  const fetchCodeByDayData = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_TALENT_DASHBOARDVIEWTALENT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          "pod_id": [],
          "talent_id": user.data[0].c_id,
          
      })
      });
      const result = await response.json();

      // Transform the data into a format suitable for the chart
      const data = [
        {
          name: 'Previous Week',
          stories: result.body.jira_issues.last_week_completed_stories || 0,
          defects: result.body.jira_issues.last_week_completed_defects || 0,
          pod_tasks: result.body.pod_tasks.last_week_completed_pod_tasks || 0,
        },
        {
          name: 'Current Week',
          stories: result.body.jira_issues.this_week_completed_stories || 0,
          defects: result.body.jira_issues.this_week_completed_defects || 0,
          pod_tasks: result.body.pod_tasks.this_week_completed_pod_tasks || 0,
        }
      ];
  
      setCodeByDayData(data);
    } catch (error) {
      console.error('Error fetching code by day data:', error);
      setCodeByDayData([]);
    }
  };

  // Log the data for debugging purposes
  useEffect(() => {
    
  }, [sprintVelocityData, codeByDayData]);

  return (
    <DashboardContainer>
      <CardsContainer>
        <CardWrapper>
        <CardIcon style={{ backgroundColor: "black",borderRadius:'20px' }}>
              <Assignment />
            </CardIcon>
          <DataCard style={{ borderRadius:'20px' }}>
         
            <CardContent  >
              <Typography variant="h6">User Stories</Typography>
              <Typography variant="body2">Open: {storiesData.open}</Typography>
              <Typography variant="body2">Completed: {storiesData.completed}</Typography>
            </CardContent>
          </DataCard>
        </CardWrapper>
        <CardWrapper>
        <CardIcon style={{ backgroundColor: "red",borderRadius:'20px' }}>
              <BugReport />
            </CardIcon>
          <DataCard style={{ borderRadius:'20px' }}>
           
            <CardContent style={{ borderRadius:'20px' }}>
              <Typography variant="h6">Defects</Typography>
              <Typography variant="body2">Open: {defectsData.open}</Typography>
              <Typography variant="body2">Resolved: {defectsData.resolved}</Typography>
            </CardContent>
          </DataCard>
        </CardWrapper>
        <CardWrapper>
        
        <CardIcon style={{ backgroundColor: "#87CEFA",borderRadius:'20px' }}>
              <CheckBoxOutlineBlank />
            </CardIcon>
          <DataCard style={{ borderRadius:'20px' }}>
            
            <CardContent style={{ borderRadius:'20px' }}>
              <Typography variant="h6">Tasks</Typography>
              <Typography variant="body2">Open: {tasksData.open}</Typography>
              <Typography variant="body2">Completed: {tasksData.completed}</Typography>
            </CardContent>
          </DataCard>
        </CardWrapper>
        <CardWrapper>
        <CardIcon style={{ backgroundColor: "#90ee90",borderRadius:'20px' }}>
              <Timer />
            </CardIcon>
          <DataCard style={{ borderRadius:'20px' }}>
         
            <CardContent style={{ borderRadius:'20px' }}>
            <Typography variant="h6">Time Log</Typography>
            <Typography variant="body2">Last Week: {showTimeData.last_week}</Typography>
      <Typography variant="body2">This Week: {showTimeData.this_week}</Typography>
  
</CardContent>

          </DataCard>
        </CardWrapper>
      </CardsContainer>
      <GraphsContainer>
        <GraphCardContainer>
        <BackgroundCard  style={{ borderRadius:'20px' }}>
           <NameLabel>  <SpeedIcon style={{color:"blue"}}/> Sprint Velocity Chart</NameLabel>
          </BackgroundCard>
          <ForegroundCard style={{ backgroundColor: "#1E90FF",borderRadius:'20px' }}>
  <ResponsiveContainer width="100%" height="100%">
    <LineChart data={sprintVelocityData}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="sprint_name"   tick={{ 
    fill: 'white', 
    fontSize: '16px', 
    fontWeight: 'bold' 
  }} />
      <YAxis  tick={{ 
    fill: 'white', 
    fontSize: '16px', 
    fontWeight: 'bold' 
  }} />
      <Tooltip contentStyle={{ 
          backgroundColor: '#000', 
          border: '1px solid #ccc', 
          color: '#fff' // Tooltip text color
        }} 
        itemStyle={{ color: '#fff' }} // Tooltip label color
      />
      <Legend />
      <Line type="monotone" dataKey="sprint_velocities" stroke="white" strokeWidth={3} />
    </LineChart>
  </ResponsiveContainer>
</ForegroundCard>

       
          
        </GraphCardContainer>
        <GraphCardContainer>
      <BackgroundCard style={{ borderRadius: '20px' }}>
        <NameLabel><CodeIcon style={{ color: "#3CB371" }} />POD Productivity</NameLabel>
      </BackgroundCard>
      <ForegroundCard style={{ backgroundColor: "#003F5C", borderRadius: '20px' }}>
        {codeByDayData.length === 0 ? (
          <Typography variant="body2" style={{ color: 'white', padding: '20px' }}>
            No items completed recently.
          </Typography>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={codeByDayData}>
              
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
        dataKey="name"
        tick={<CustomXAxisTick />}
        tickLine={false}
        interval={0} // Ensure all ticks are shown
      />
              <YAxis tick={{ fill: 'white', fontSize: '16px', fontWeight: 'bold' }} />
              <Tooltip  contentStyle={{ backgroundColor: '#000', border: '1px solid #ccc', color: '#fff' }} />
              <Legend
                wrapperStyle={{ color: 'white' }}
                formatter={(value, entry) => (
                  <span style={{ color: 'white' ,fontWeight:"500"}}>
                   
                    {value}
                  </span>
                )}
              />
              {/* Stacked Bars for Previous Week and Current Week */}
              <Bar dataKey="stories" stackId="a" fill="#7A5195" name="Stories" > <LabelList dataKey="stories" position="inside" fill="#FFF" /></Bar>
              <Bar dataKey="defects" stackId="a" fill="#EF5675" name="Defects" > <LabelList dataKey="defects" position="inside" fill="#FFF" /> </Bar>
              <Bar dataKey="pod_tasks" stackId="a" fill="#FFA600" name="Pod Tasks" > <LabelList dataKey="pod_tasks" position="inside" fill="#FFF" /> </Bar>
            </BarChart>
          </ResponsiveContainer>
        )}
      </ForegroundCard>
    </GraphCardContainer>
      </GraphsContainer>
    </DashboardContainer>
  );
};

export default Dashboardnew;
