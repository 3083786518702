import React, { useState, useEffect } from 'react';
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import { Auth } from "aws-amplify";
import axios from 'axios';
import '../css/register.css';
import { Link , useHistory} from "react-router-dom";
import { partner} from '../action/auth';
import { useDispatch, useSelector } from "react-redux";

import styled from 'styled-components';

import AWS from 'aws-sdk';



const S3Config = {
    region: "us-east-1",
    accessKeyId: 'AKIA6CNQFW6ISZIFRSNJ',
    secretAccessKey: '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'
  }

const Tulsi = () => {
    const dispatch = useDispatch();
    const [Confirm, setConfirm] = useState(false); 
    const [loading, setLoading] = useState(false);
   
      
    const { isLoggedIn, user } = useSelector(state => state.auth);
  

const history = useHistory();

  const [User, setUser] = useState({

    authCode: "",
   
    errors: {
      cognito: null,
      blankfield: false,
      passwordmatch: false
    }
  })

   const clearErrorState = () => {
    setUser({
      ...User,
      errors: {
        cognito: null,
        blankfield: false,
        passwordmatch: false
      }
    });
  }

  const confirmSignUp = async event => {

    event.preventDefault();

    try {

     const confirm = await Auth.confirmSignUp(User.username, User.authCode);

     const usertypevalue = User.usertype == "candidate" ? 1 : User.usertype == "podprovider" ? 2 : 0;

     if(confirm == "SUCCESS"){



      dispatch(partner(usertypevalue, User.email))
      .then((data) => {
   
        var params = {
          Source: 'contact@digitivy.com',  /* required */
          Destination: { /* required */
            ToAddresses: [
              'contact@digitivy.com'
              /* more items */
            ]
          },
          ReplyToAddresses: [
            'contact@digitivy.com'
           /* more items */
         ],
          Message: { /* required */
            Body: { /* required */
              Html: {
               Charset: "UTF-8",
               Data: "<p>Hi,   <br></br> <br></br> " + User.usertype + " : <b>" + User.email + "</b> is registered and waiting for the approval.  <br></br>  <p>Thanks.</p>"
              },
              Text: {
               Charset: "UTF-8",
               Data: "" + User.usertype + " : <b>" + User.email + "</b> is registered and waiting for the approval"
              }
             },
             Subject: {
              Charset: 'UTF-8',
              Data: "" + User.usertype + " : <b>" + User.email + "</b> is registered and waiting for the approval"
            }
            },
         
        };
        
        // Create the promise and SES service object
        var sendPromise = new AWS.SES({
          region: 'us-east-1',
          accessKeyId: S3Config.accessKeyId,
          secretAccessKey: S3Config.secretAccessKey
           
         }).sendEmail(params).promise();
        
        // Handle promise's fulfilled/rejected states
        sendPromise.then(
          function(data) {
            console.log(data);
          }).catch(
            function(err) {
            console.log(err);
          });
    
        



        alert("Your Account has been Successfully Verified!");
        window.location.href = "/dashboard";
      })    
      .catch((err) => {
        console.log(err);
      });
    }
      
    } catch (error) {
      console.log(error)
      let err = null;
      !error.message ? err = { "message": error } : err = error;
      setUser({
        ...User,
        errors: {
          cognito: err
        }
      });
    }
  };


   const onInputChange = e => {
    clearErrorState();
    setUser((prevState) => {
      return { ...prevState, [e.target.id]: e.target.value };
    });

    document.getElementById(e.target.id).classList.remove("is-danger");
  }

   const handleChange = e => {
    const { name, value } = e.target;

    setUser({
      [name]: value
    });  };


  return (
  <div className="container" >
 <FormErrors formerrors={User.errors} />
        

          <form onSubmit={confirmSignUp}>
            <MAN2>

          <h1 className='register-header'>Welcome!</h1>
          <br></br>
          <br></br>
        <b>You have successfully registered with PrismPod</b>
        <br></br>
        <br></br>
        <b>We've sent you an email. Please enter the code to verify your account</b>
         <br></br>
         <br></br>
         <br></br>
         <br></br>
            <div className="field">
                <p className="control">
                  <input 
                    type="number"
                    id="authCode"
                    aria-describedby="userNameHelp"
                    placeholder="Enter Confirmation Code"
                    value={User.authCode}
                    onChange={onInputChange}
                    
                      defaultValue={User.username}

                  />
                </p>
              </div>
              <br></br>
              <br></br>
         <br></br>
         <br></br>
              <div className="field">
                <p className="control">
                  <button className="login"  style={{ "width": "400px", height: "50px", marginTop: "20px" , fontFamily:"Poppins,Medium",
               fontWeight:'600',boxShadow:'15px 15px 25px #00000029'}}>
                    Submit
                  </button>
                </p>
              </div>

 </MAN2></form>





       

       
         </div>
  )
}

export default Tulsi;

const MAN2 =styled.div`
margin:auto;
display:flex;
align-items:center;
justify-content :center;
flex-direction:column;
box-shadow: 1px 3px 6px #00000029;
border: 1px solid #D8D8D8;
border-radius: 16px;
opacity: 1;
padding: 30px;
width: 650px;
@media screen and (min-width:320px) and (max-width:1080px){
  margin:auto;
 width:20rem;
 display:flex;
align-items:center;
justify-content :center;
over-flow :hidden;

}
`;


const Field1 =styled.div`

 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
margin-bottom: 3rem;
@media screen and (min-width:320px) and (max-width:1080px){

  display: flex;
  flex-direction: column;
  margin  margin:auto;
  justify-content: center;
  align-items: center;
 
 }
 `;



const InputL =styled.input`
display:flex;
align-items:center;
justify-content :center;
  width: 420px;
  height: 54px;
  font-family: Poppins, Regular;
  font-weight: 600;

  border:1px solid #B7B7B7;
  border-radius: 10px;
  opacity: 1;

  margin:auto;
@media screen and (min-width:320px) and (max-width:1080px){
 font-size :12px;
  width: 215px;
  height: 54px;
 

}
`;

const Button =styled.button`
display:flex;
align-items:center;
justify-content :center;
  width: 420px;
  height: 50px;
  margin-Top:40px;
  font-family:Poppins, Medium;
  font-weight:600;
  top: 869px;
    left: 757px;
    width: 407px;
    height: 54px;

    border-radius: 10px;
    opacity: 1;
background-color: #1DBF73;
color: white;
border: 0px;
box-shadow: -15px -15px 25px #FFFFFF;
border-radius: 10px;
@media screen and (min-width:320px) and (max-width:1080px){
 
  width: 208px;
  height: 54px;
}
`;