import styled from 'styled-components';
import React, { useEffect }  from 'react'
import Badge from './Badge';
import Avatar from "./assets/MaskGroup.png";
import {cardShadow,hoverEffect,themeColor} from '../utils';
import { useState } from 'react';
import ShowMore from 'react-show-more-button/dist/components/ShowMore';
import { NavHashLink } from 'react-router-hash-link';

import { useParams,useHistory, Link } from 'react-router-dom';

import Select from "react-select";

import{FiSearch} from 'react-icons/fi';
import axios from 'axios';
import {FiImage} from "react-icons/fi";
import {BsEmojiSmile} from "react-icons/bs";

import { NavLink } from 'react-router-dom';
import { data } from 'jquery';
import { useDispatch, useSelector } from "react-redux";

import {VscClose} from "react-icons/vsc";
import Statusupdate from "./Statusupdate";
import { useAuthToken } from "../TokenContext";


const Updatedailystatus = () => {
    
  const token = useAuthToken();


  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(2);
  const [paginationdate, setPaginationdate] = useState([]);
 
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  

 const[removebutton,setRemovebutton]=useState(0);
    const { isLoggedIn, user } = useSelector(state => state.auth);

    const[getsavedata,setSaveddata]=useState([]);
    

    const [updatedataD, setUpdatedD] = useState([]);
  
  const [poddata,setPoddata]=useState([
])
  
    const [rdata, setRdata] = useState([{categories:'',pod_candidates:'', today_update:'', lead_id: ''.c_id,id:''}]);
    
    
const[value,setvalue] = useState('fruit');
const[podcan,setPodcan] = useState('');
const[update,setUpdate] = useState('');
const handleChange = (e,i) =>{
  setvalue(e.target.value);
  
  // console.log('hghghghg',e.target.value);
};



const handleChangecan = (e,i) =>{
  setPodcan(e.target.value);
  
};
const handleChangeupdate = (e) =>{
  setUpdate(e.target.value);
  

};
  const params =useParams();
  const handleinputchange=(e)=>{
   
    setSaveddata(e.target.value);
   
  }

  // const handlchangevalue=(e)=>{
  
  //   console.log('1name',e.target.value, '','2name',)
   

  // }
  function handleSubmit(name){
  
    var options = {'poddata':poddata[name] , status: "no_draft" }



// https://5dfoo4zhok.execute-api.us-east-1.amazonaws.com/dev/pod_activity_update
 fetch(process.env.REACT_APP_TALENT_PODACTIVITYUPDATE,{
  method:'POST',
headers: {'Authorization' : token,'Content-Type': 'application/json' },
body:JSON.stringify(options)
}).then(()=>{

  alert("Posted Podactivity Successfully.");
  setTimeout(() => {
    window.location.reload();            
  }, 2000);
   
});

  }
  
 
 const handleSubmitOptions = (e) => {

 var options = 
  {poddata , status: "no_draft" }



//  fetch('https://5dfoo4zhok.execute-api.us-east-1.amazonaws.com/dev/pod_activity_update',{
//   method:'POST',
// headers: { 'Content-Type': 'application/json' },
// body:JSON.stringify(options)
// }).then(()=>{

//   alert("Posted Podactivity Successfully.");
//   setTimeout(() => {
//     window.location.reload();            
//   }, 2000);
//   console.log("submit",options );
// });

};


// const[savedraftdata,setSavedraftdata]=useState({
//   c_id : user.data[0].c_id
// })


useEffect(() => {

  const body = {
  "lead_id": user.data[0].c_id,
  };
  const headers = {
    'Authorization' : token, // Replace with your actual token
    'Content-Type': 'application/json', // You can add more headers as needed
  };
var itemList = []

// https://8r6f1qtf53.execute-api.us-east-1.amazonaws.com/dev/get_pod_activity_submit
  axios.post(process.env.REACT_APP_TALENT_GETPODACTIVITYSUBMIT, body, {headers:headers})

    .then((res) => {
      
   
     
          
        setPaginationdate( res.data.data)
         
  
    
      setSaveddata(res.data.data)
      {res.data.data.map((Itemdata) => {
          
 itemList.push(Itemdata.items)
     }) }

     setPoddata(itemList);
    }


    )
    .catch((err) => {
      console.log("catch", err);
    })


}, [token]);



 

 const Candname = user.data[0].firstName
 const profilePic = user.data[0].profilePicture
 
 const[getcandlistdata,setGetcandlistdata]=useState([]);
       
    const[Clistdata,setClistdata]=useState([]);

    useEffect(() => {
      const requestOptions = {
        method: 'POST',
        headers: { 'Authorization' : token,'Content-Type': 'application/json' },
        body: JSON.stringify({
          lead_id: user.data[0].c_id
          
      })
      
      };
      // https://r9421gdng7.execute-api.us-east-1.amazonaws.com/dev/getcandidatelist
      fetch(process.env.REACT_APP_TALENT_GETCANDIDATELIST,requestOptions)
      .then((response) => response.json())
      
      
      .then((json) => { 
    
    
        var selectvaluefromallergens = []
        {json.data.data.map((clist) => {
          
           selectvaluefromallergens.push({value:clist.c_id,pod_candidates:clist.firstName});
      
         
    
        }) } 
        setGetcandlistdata(json.data.data);
      
        setClistdata(selectvaluefromallergens);
      
      
      });
      
      
     
    
    }, [token])
    
    
    const handleClick = (event) => {
      setcurrentPage(Number(event.target.id));
    }
  
  
    const pages = [];
    for (let i = 1; i <= Math.ceil(getsavedata.length / itemsPerPage); i++) {
      pages.push(i);
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItems = getsavedata.slice(indexOfFirstItem, indexOfLastItem);
    const renderPageNumbers = pages.map(number => {
      if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
       
        return (
          <div>
          <li key={number} id={number} onClick={handleClick} 
          className={currentPage == number ? 'active' : null}>{number}</li>
       
          </div>
          );
      } else {
        return null;
      }
    });

   
  
    const handleNextbtn = () => {
      setcurrentPage(currentPage + 1);
      if (currentPage + 1 > maxPageNumberLimit) {
        setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
        setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
      }
    };
    const handlePrevbtn = () => {
      setcurrentPage(currentPage - 1);
      if ((currentPage - 1) % pageNumberLimit == 0) {
        setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
        setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
      }
      
    };
  
   


    const renderdata = (getsavedata) => {
  return (

   
    <Div >

  

<TitleText>Edit Pod Activity</TitleText>
    
<ol className='pageNumbers' >
          <Num2>
            <li>
              <button onClick={handlePrevbtn}
                disabled={currentPage == pages[0] ? true : false} >
                Prev
              </button>
            </li>
            <Numbers>{renderPageNumbers} </Numbers>
            <li>
              <button onClick={handleNextbtn}
                disabled={currentPage == pages[pages.length - 1] ? true : false}
              > Next</button>
            </li>
          </Num2>
        </ol>
<RecommendProject>

{/* 
<ol className='pageNumbers' >
          <Num2>
            <li>
              <button onClick={handlePrevbtn}
                disabled={currentPage == pages[0] ? true : false} >
                Prev
              </button>
              
            </li>
            <Numbers>{renderPageNumbers} </Numbers>
            <li>
              <button onClick={handleNextbtn}
                disabled={currentPage == pages[pages.length - 1] ? true : false}
              > Next</button>
            </li>
          </Num2>
        </ol> */}






<div>


{
  getsavedata.map( (x1,i)=>(
    

  <CardContent>

<p style={{fontSize:'12px',color:'#00B4D8',fontWeight:'600',fontFamily:'cursive'}}>Save Draft <span> : {x1.date} </span> </p>

{
  x1.items.map( (x,j)=>( 
<div> 


<div> 
<Row1>
<label style={{fontWeight:'600',color:'grey'}} required>  categories   <span>:</span>
        </label>
<SelectField 
id="categories"
name='categories'
defaultValue={x.categories}
 //onChange={ e=>{handleChange(e,i)}}
 onChange={(e)=>{

  let changeditem = poddata.filter((ele)=>ele.find(Ine=>Ine.id==x.id))
  let changeditemresult=changeditem[0].filter((ele)=>ele.id==x.id)
  let unchangeditemresult = changeditem[0].filter((ele)=>ele.id!=x.id)
  changeditemresult[0].categories=e.target.value
  unchangeditemresult.push(changeditemresult[0])
  // .filter(match => match.players.find(player => player.country === country))
// console.log(unchangeditemresult);
// setUpdatedD(unchangeditemresult);

  }}
    required
        >
          
          <option  value ="Categories"  >Categories</option>
       
        <option value="Design" >Design</option>
        <option  value="Coding">Coding</option>
        <option  value="Testing">Testing</option>
        <option  value="Resolving Bugs">Resolving Bugs</option>
        <option  value="Deployment">Deployment</option>

</SelectField>
</Row1>
<Row1>
        <label style={{fontWeight:'600',color:'grey'}}>   Pod team member   <span>:</span>    </label>

<SelectField 
  
  id="pod_candidates"
  name='pod_candidates' 
  defaultValue ={x.pod_candidates}
  // onChange={ e=>{ handleChangecan(e,i)}}
  onChange={(e)=>{

    let changeditem = poddata.filter((ele)=>ele.find(Ine=>Ine.id==x.id))
    let changeditemresult=changeditem[0].filter((ele)=>ele.id==x.id)
    let unchangeditemresult = changeditem[0].filter((ele)=>ele.id!=x.id)
    changeditemresult[0].pod_candidates=e.target.value
    unchangeditemresult.push(changeditemresult[0])
    // .filter(match => match.players.find(player => player.country === country))
  // console.log(unchangeditemresult);
  //setUpdatedD(unchangeditemresult);
  
    }}

  required
      >
      
      {
Clistdata.map( (data,i)=>{
  return <option key={i} pod_candidates= {data.pod_candidates}>{data.pod_candidates}</option>
})}
      </SelectField>

      </Row1>
      <Row1>
        <label style={{fontWeight:'600',color:'grey'}}>   Pod team member   <span>:</span>    </label>

<Textarea 
name='today_update'
defaultValue={x.today_update}
// onChange={ e =>{
// var changeditem = poddata.filter((ele)=>{ele.id === x.id});
// console.log(changeditem);
// }}



onChange={(e)=>{
  // console.log('oldpoddata',poddata);
  let changeditem = poddata.filter((ele)=>ele.find(Ine=>Ine.id==x.id))
   let unchangeditem = poddata.filter((ele)=>ele.find(Ine=>Ine.id!=x.id))
  let changeditemresult=changeditem[0].filter((ele)=>ele.id==x.id)
  let unchangeditemresult = changeditem[0].filter((ele)=>ele.id!=x.id)
  changeditemresult[0].today_update=e.target.value
  // unchangeditemresult.push(changeditemresult[0])
  // unchangeditem.push(unchangeditemresult[0])
  // .filter(match => match.players.find(player => player.country === country))
 //console.log(unchangeditemresult);
// setUpdatedD(unchangeditemresult);
//setPoddata(unchangeditem)
//console.log('unchangeditem',unchangeditem);
  }}

placeholder="Today's Update . . ."/>
 </Row1>

 </div>
        
</div>
 ))}
 <Button   onClick={()=>
  handleSubmit(i)
 }>Post</Button>



                  
                  </CardContent>
                   
               
  ))}
  
</div>


  



             





</RecommendProject>

     </Div>
)
}
return (<Div>

  {renderdata(currentItems)}
  </Div>
 )
}
const Div =styled.div`

// display:flex;
display:inline;
jutify-content:center;
flex-direction:column;
margin-top:6rem;
align-items:center;

margin-left:6.5rem;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;

  overflow:hidden;
justify-content:center;
align-items:center;
margin:auto;
width:25rem;
  }
`;

const RecommendProject=styled.div`

 display:flex;

jutify-content:center;
flex-direction:row;
@media screen and (min-width:320px) and (max-width:1080px){
  flex-direction:column;
    width:80%;
    height:max-content;
    margin:0.4rem;
    
   }
`;


const TitleText=styled.h4`
font-weight:600;

@media screen and (min-width:320px) and (max-width:1080px){

  margin-top:2rem;
 
}
`;
const CardContent=styled.div`
margin:0.4rem;
margin-top:2rem;
display:flex;
flex-direction:column;
// justify-content:center;
// align-items:center;


border-radius:20px;
font-family:Poppins,Medium;
width:40rem;
height:max-height;
padding: 1rem;
background-color: #F2F4F5;

box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};
}

@media screen and (min-width:320px) and (max-width:1080px){
  width:20rem;
  margin-top:2rem;
 
  
 }
`;





const InputField=styled.input`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
padding-left:1.5rem;
font-weight:600;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;

  
 }
`;


const SelectField=styled.select`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
font-weight:600;

color:grey;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;

  
 }
`;
const InputFieldCandidates=styled.input`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
font-weight:600;
color:grey;
margin-left:10px;
padding-left:1.5rem;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;

  margin-left:-2px;
 }
`;


const SelectField1=styled.div`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
font-weight:600;
color:grey;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;

  
 }
`;


const Button=styled.button`
margin-left:auto;
border:none;
border-radius: 10px;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
width:8rem;
height:2rem;
font-size:18px;
font-weight:600;
color:white;
margin-top:4rem;
@media screen and (min-width:320px) and (max-width:1080px){

  width:7rem;
}
`;


const Button1=styled.button`
margin-right:auto;
border:none;
border-radius: 10px;
background-color:#00B4D8
;
box-shadow: 1px 3px 6px #00000029;
width:8rem;
height:max-height;
font-size:16px;
padding:8px;
font-weight:600;
color:white;
margin-top:1rem;
@media screen and (min-width:320px) and (max-width:1080px){

  width:7rem;
}
`;




const SideCard=styled.div`
margin-left:5rem;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
border-radius:20px;
font-family:Poppins,Medium;
width:20rem;
height:32rem;

padding: 1rem;
background-color: #F2F4F5;

box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};
}

@media screen and (min-width:320px) and (max-width:1080px){

margin:auto;
margin-top:3rem;
  width:20rem;
}
`;


const SideCardContent=styled.div`
background-color:#90E0EF;
width:17rem;
display:flex;
flex-direction:column;
padding:3px;


border-radius:20px;
`;


const SideCardContent1=styled.div`
background-color:#90E0EF;
width:17rem;
display:flex;
margin-top:1rem;
flex-direction:column;
height:10rem;
justify-content:center;
align-items:center;
border-radius:20px;

`;





const Detail = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer = styled.div`
  display: flex;
margin:auto;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const Row = styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
`;
const Row1 = styled.div`
  display: flex;
  flex-direction:column;
margin-top:2rem;

  align-item: center;
  margin-bottom: 0.8rem;
  @media screen and (min-width:320px) and (max-width:1080px){
    display: flex;
    flex-direction:column;
  }
`;
const InfoName = styled.h6`
  font-weight: 600;
`;
const InfoUpdate = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;

const Title = styled.h6`
  font-weight: 600;
`;
const ProjectInfo = styled.p`
padding:5px;
margin:3px;
background-color:white;
border-radius:5px  ;
font-size:13px;
color:black;
font-weight:600;
margin-bottom:1rem
`;

const ViewAll =styled.button`
width:5rem;
font-weight:600;
height:2rem;
margin-left:auto;
margin-top:1rem;
border: 2px solid #1DBF73;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;

const View =styled.button`
width:8rem;
font-weight:600;
height:2rem;
color:black;
background-color:white;
border-radius:5px  ;
margin-bottom:1rem;
margin-top:1rem;
border: none;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;

const H1 = styled.h1`
font-size:22px;
font-weight:600;
margin-bottom:1.2rem;
`;

const InputContainer = styled.div`
display : flex;
border-radius:10px;
justify-content: center;
margin-left:auto;
width:25rem;
margin-bottom:1rem;
border:1px solid #4E8FAE;
box-shadow: 3px 6px 9px #00000005;
@media screen and (min-width:320px) and (max-width:1080px){
  margin:auto;
  margin-top:2rem;

    width: 20rem;
  
 
`;

const Icon = styled.div`
height: 3rem;
width: 2rem;
background-color: #F2F3F4;
display:flex;
flex-direction:row
justify-content: center;
align-items: center;
border-top-left-radius:10px;
border-bottom-left-radius:10px;
svg{
    color:#555555;
}
`;

const Input = styled.input`
justify-content: center;
height: 3rem;
width: 25rem;
border:0px solid #4E8FAE;
background-color:#F2F3F4;
font-family:Poppins,Medium;
font-Weight:600;
border-top-right-radius:10px;
border-bottom-right-radius:10px;
&:focus{
    border:none;
    outline:none;
}
@media screen and (min-width:320px) and (max-width:1080px){
  width: 20rem;
}
`;

const InfoPod = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-left: 15px;
`;


const Textarea = styled.textarea`
width:25rem;

margin-top:1rem;
tex-decoration:none;
border:1px solid #cccccc;
border-radius:5px;

padding:1rem;
font-size:14px;
font-family:Poppins,Medium;
font-Weight:600;
  outline: none;
  @media screen and (min-width:320px) and (max-width:1080px){
    width: 15rem;
  }


`;

const TextDiv = styled.div`
display:flex;
flex-direction:row;




`;

const Buttonadd = styled.button`
tex-decoration:none;
border:none;
display:flex;
// margin-right:auto;
border-radius:5px;
font-size:14px;
font-family:Poppins,Medium;
font-Weight:600;
  outline: none;
width:4rem;
justify-content:center;
align-items:center;
height:2.5rem;
margin-top:0.5rem;
color:white;
background-color:#00B4D8;

`;

const ButtonRemove = styled.button`
text-decoration:none;
border:none;
border-radius:5px;
font-size:14px;
font-family:Poppins,Medium;
font-Weight:600;
  outline: none;
width:4.5rem;
height:2.5rem;
margin-top:0.5rem;
margin-left:10px;
color:white;
background-color:#00B4D8;

`;

const Num2 = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        margin:auto;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        width:100%;
}
        `;
        const Numbers = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        align-items:center;
        justify-content:center;
        width:7rem;
}
        `;


export default Updatedailystatus



