import Select, { components as Components } from "react-select";
import styled from "styled-components";


const ValuesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width:22rem;
font-weight:600;
font-family:Poppins,Medium;
  @media screen and (min-width:320px) and (max-width:1080px){
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
   
    width:15rem;
    
  
    }
 
`;
const CreateDiv = styled.div`
// width:22rem;
// font-weight:600;
// font-family:Poppins,Medium;

 
`;




const Value = styled.div`

  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  margin: 10px 10px; 
  font-size: 0.75rem;
  color: black;
  
  background-color: #00B4D8;
  user-select: none;

`;

const XButton = styled.button`
  all: unset;
  margin-left: 0.3rem;
  color: black;
  transition: fill 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    color: #bb392d;
  }
  &:focus {
    color: #c82f21;
  }
`;




let addedskills = []


const CreatePodSkillspage = (props) => {
  const { isMulti, value, onChange } = props;


  const handleRemoveValue = (index) => {
    if (!onChange) return;
    const updatedValue = [...value];
    updatedValue.splice(index, 1);
    onChange(updatedValue);
  };
  
  







  return (

    <CreateDiv>

<Select
  {...props}
  controlShouldRenderValue={!isMulti}
  components={{
    ...Components,
    MultiValueRemove: (props) => (
      <Components.MultiValueRemove {...props} onClick={() => handleRemoveValue(props.data)} />
    )
  }}
/>





      <ValuesContainer>
      {isMulti && value.length > 0 && (
  <ValuesContainer>
    {value.map((val) => (
      <Value key={val.value}>
        {val.label}
        <XButton onClick={() => handleRemoveValue(val)}>
          ✕
        </XButton>
      </Value>
    ))}
  </ValuesContainer>
)}

  

      </ValuesContainer>

    </CreateDiv>
  );
};

export default CreatePodSkillspage;
