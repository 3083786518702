import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuthToken } from "../TokenContext";
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";

const Dorametricslinegraph = ({ initialPodIds }) => {
  const [metricsData, setMetricsData] = useState(null);
  const token = useAuthToken();
  const { user } = useSelector(state => state.auth);

  useEffect(async () => {
    if (user.data[0].type === "stakeholder") {
      try {
        if (!token) {
          console.log('Token is missing. API requests will not be made.');
          return;
        }
        const payload = {};
    
        // Check if pod_id is available, send only pod_id
        
          payload.pod_id = initialPodIds || [];
        
          // If pod_id is not available, check if org_code is available, send only org_code
          payload.org_code = user.data[0].client_code;
        
        // If user type is stakeholder, make a request to the specified API_URL for DORA metrics
        const response = await fetch(process.env.REACT_APP_STAKEHOLDER_JDORAMETRICSSTAKEHOLDER, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify(payload),
        });
  
        // Parse the response body to JSON
        const data = await response.json();
        
        if (data?.body) {
          const parsedData = JSON.parse(data.body);
          setMetricsData(parsedData);
        }
      } catch (error) {
        console.error('Error fetching DORA metrics:', error);
      }
    } else {
      // If user type is not stakeholder, fetch data using the existing logic
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json',
        // You can add more headers as needed
      };
      try {
        if (!token) {
          console.log('Token is missing. API requests will not be made.');
          return;
        }
        const response = await axios.post(
          process.env.REACT_APP_CLIENT_DORAMETRICSMANAGER, {
            "manager_id": user.data[0].emp_id,
            "pod_id": initialPodIds,
          }, { headers: headers });
  
        const parsedData = JSON.parse(response.data.body);
        setMetricsData(parsedData);
      } catch (error) {
        console.error('Error fetching DORA metrics:', error);
      }
    }
  }, [token, user.data[0].type, initialPodIds]);
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 'auto' }}>
      {metricsData && Object.entries(metricsData).map(([label, value], index) => (
        <div key={label} style={{ padding: "10px", cursor: "pointer", marginTop: "0.5rem", backgroundColor: '#EAEAEA', borderRadius: '8px', width: '300px' }} className="metric-container">
          <p className="metric-label" style={{ marginBottom: '0.5rem', color: '#333' }}>
            {label} - {parseFloat(value)}
          </p>
        </div>
      ))}
    </div>
  );
  
};
Dorametricslinegraph.propTypes = {
  initialPodIds: PropTypes.array.isRequired,
};
export default Dorametricslinegraph;
