import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useAuthToken } from "../../TokenContext";


function PartnerSettings() {
  const [fetchlist, setFetchlist] = useState([]);
    const [fetchlistid, setFetchlistid] = useState('');
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [ARTsList, setARTsList] = useState([]); // Store the list of ARTs
  const token = useAuthToken();


  const [formData, setFormData] = useState({
 Jira_URL:'',
    Jira_admin_login_email_address:'',
    Jira_admin_API_Token:'',
 
    Github_username:'',
    Github_API_Token:'',
  Github_Repo_name:'',
  ART_id:'',
  bitbucket_username:'',
  bitbucket_app_password:'',
  bitbucket_workspace:'',
  aws_region:'',
    Organizational_Code:user.data[0].partner_code,
    Jira_Kanben_Project_name:"",
    circleci_github_username:"",
    circleci_github_repo:'',
 circleci_api_token:'',
    sonarqube_cloud_url:'',
    sonarqube_api_token:'',
   aws_access_key_id:'',
    aws_secret_access_key:'',






  });



  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      
      ...prevFormData,
      [name]: value,
    }));
    // console.log('FormData.ART_id:', formData.ART_id);
  };

  const handleSubmit = async (event) => {
   
    event.preventDefault();
    try {
      const response = await fetch(process.env.REACT_APP_CUSTOMERADMIN_AUTHORIZATIONTOKEN, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({ 
          ART_id:parseInt(selectedARTId),
          ...formData}),
      });
  
      if (response.status==400) {
        alert('Details Successfully Updated');
          window.location.reload();
      } else {
        alert('error fecthing 400');
        console.error('API call failed');
      }
    } catch (error) {
      console.error('An error occurred', error);
    }
  };
  
  
 


  useEffect(() => {
    fetchauthorisationlist();
    fetchARTsList();
  }, [token]);

  
  const fetchARTsList = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_CUSTOMERADMIN_GENERICGIGLIST,
        { Organizational_Code: user.data[0].partner_code }
      );
  
      if (response.status === 200 && response && response.data && response.data.data) {
        setARTsList(response.data.data);
      } else {
        // Handle unexpected response or data format errors
        console.error('Unexpected API response:', response);
        // You can set an error state here if needed
      }
    } catch (error) {
      // Handle network or other errors
      console.error('Error fetching ARTs list:', error);
      // You can set an error state here if needed
    }
  };
  
 

  
  

  const fetchauthorisationlist = async () => {
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_CUSTOMERADMIN_FETCHAUTHORIZATIONTOKENS,
        { Organizational_Code: user.data[0].partner_code },{headers:headers}
      );

      if (response.data && response.data.length > 0) {
        // If the API returns data, set the formData state with the fetched data
        setFormData(response.data[0]);
      }

      setFetchlist(response.data.body.data);
      setFetchlistid(response.data.body.data[0]);
      // Inside the fetchauthorisationlist function after setting fetchlist
if (response.data && response.data.body && response.data.body.data.length > 0) {
    // If the API returns data, set the formData state with the fetched data
    const fetchedData = response.data.body.data[0];
   
    setFormData({
      Jira_URL: fetchedData.Jira_URL || '',
      Jira_admin_login_email_address: fetchedData.Jira_admin_login_email_address || '',
      Jira_admin_API_Token: fetchedData.Jira_admin_API_Token || '',
      Jira_Kanben_Project_name: fetchedData.Jira_Kanben_Project_name || '',
      Github_username: fetchedData.Github_username || '',
      Github_API_Token: fetchedData.Github_API_Token || '',
      circleci_api_token: fetchedData.circleci_api_token || '',
      sonarqube_cloud_url:fetchedData.sonarqube_cloud_url || '',
      sonarqube_api_token:fetchedData.sonarqube_api_token || '',
     aws_access_key_id:fetchedData.aws_access_key_id || '',
      aws_secret_access_key:fetchedData.aws_secret_access_key || '',
      Github_Repo_name: fetchedData.Github_Repo_name || '',
      Jira_Kanben_Project_name:fetchedData.Jira_Kanben_Project_name || '',
      circleci_github_username:fetchedData.circleci_github_username || '',
      circleci_github_repo:fetchedData.circleci_github_repo || '',
      bitbucket_username:fetchedData.bitbucket_username || '',
      bitbucket_app_password: fetchedData.bitbucket_app_password || '',
      bitbucket_workspace: fetchedData.bitbucket_workspace || '',
      aws_region: fetchedData.aws_region || '',
           
      ART_id:fetchedData.ARTs_id|| '',
    
      Organizational_Code:  user.data[0].partner_code,
    });
  }
  
     
    } catch (error) {
      console.error('Error fetching owners:', error);
    }
  };

  const [selectedARTId, setSelectedARTId] = useState(formData.ART_id || '');
  useEffect(() => {
    setSelectedARTId(formData.ART_id || '');
  }, [formData.ART_id]);
  
  // Handle ART selection from the dropdown
  const handleARTSelect = (event) => {
    const selectedARTId = event.target.value;
    setSelectedARTId(selectedARTId); // Update the selected ART ID
    setFormData((prevFormData) => ({
      ...prevFormData,
      ART_id: selectedARTId, // Include selectedARTId in the formData
    }));
  
  };
  const handleUpdate = async (event) => {
    const headers = {
      Authorization: token,
      'Content-Type': 'application/json',
    };
    event.preventDefault(); // Prevent the default form submission behavior
    try {
      const response = await axios.post(
        process.env.REACT_APP_CUSTOMERADMIN_UPDATEAUTHORIZATIONTOKENS,
        {
          id: fetchlistid.id, // Assuming that fetchlist contains the ID
          ...formData,
          ART_id: fetchlistid.ART_id,
        }, { headers: headers }
      );
  
      if (response.status === 200) {
        alert('Updated successfully');
        // console.log('id',fetchlistid.id)
        window.location.reload();
      } else {
        console.error('Update API call failed');
      }
    } catch (error) {
      console.error('An error occurred while updating', error);
    }
  };

  
      return (
        <Div>
          <TitleText>Settings</TitleText>
          <RecommendProject>
            {fetchlist.length === 0 ? (
              <form onSubmit={handleSubmit}>
                <CardContent>
                <Postfield>
                  <Label>Select a ART:</Label>
                  <Select value={selectedARTId} onChange={handleARTSelect}   required>
  <option value="">Select an ART</option>
  {Array.isArray(ARTsList) &&
    ARTsList.map((ART) => (
      <option key={ART.ART_id} value={ART.ART_id}>
        {ART.ART_Name}
      </option>
    ))}
</Select>

        </Postfield>
        <Postfield>
                    <Label>Jira ProjectKey:</Label>
                    <Input
                      type="text"
                      name="Jira_Kanben_Project_name"
                      value={formData.Jira_Kanben_Project_name}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Jira URL:</Label>
                    <Input
                      type="url"
                      name="Jira_URL"
                      value={formData.Jira_URL}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Jira Admin Email:</Label>
                    <Input
                      type="email"
                      name="Jira_admin_login_email_address"
                      value={formData.Jira_admin_login_email_address}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Jira Admin API Token:</Label>
                    <Input
                      type="password"
                      name="Jira_admin_API_Token"
                      value={formData.Jira_admin_API_Token}
                      onChange={handleChange}
                    />
                  </Postfield>
                 
                  <Postfield>
                    <Label>Circleci Api Token:</Label>
                    <Input
                      type="text"
                      name="circleci_api_token"
                      value={formData.circleci_api_token}
                      onChange={handleChange}
                    />
                  </Postfield>
                 
                  <Postfield>
                    <Label>Sonarqube Cloud Url:</Label>
                    <Input
                      type="url"
                      name="sonarqube_cloud_url"
                      value={formData.sonarqube_cloud_url}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Sonarqube Api Token:</Label>
                    <Input
                      type="name"
                      name="sonarqube_api_token"
                      value={formData.sonarqube_api_token}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>AWS Access Key Id:</Label>
                    <Input
                      type="id"
                      name="aws_access_key_id"
                      value={formData.aws_access_key_id}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>AWS Secret Access Key :</Label>
                    <Input
                      type="text"
                      name="aws_secret_access_key"
                      value={formData.aws_secret_access_key}
                      onChange={handleChange}
                    />
                  </Postfield>
               
                  <Postfield>
                    <Label>Aws Region:</Label>
                    <Input
                      type="text"
                      name="aws_region"
                      value={formData.aws_region}
                      onChange={handleChange}
                    />
                  </Postfield>
                  
                  <Postfield>
                    <Label>Github Username:</Label>
                    <Input
                      type="text"
                      name="Github_username"
                      value={formData.Github_username}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Github Repo Name:</Label>
                    <Input
                      type="text"
                      name="Github_Repo_name"
                      value={formData.Github_Repo_name}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Github API Token:</Label>
                    <Input
                      type="text"
                      name="Github_API_Token"
                      value={formData.Github_API_Token}
                      onChange={handleChange}
                    />
                  </Postfield>
               
                  <Postfield>
                    <Label>Circleci Github Username:</Label>
                    <Input
                      type="text"
                      name="circleci_github_username"
                      value={formData.circleci_github_username}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Circleci Github Repo:</Label>
                    <Input
                      type="text"
                      name="circleci_github_repo"
                      value={formData.circleci_github_repo}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Bitbucket Username:</Label>
                    <Input
                      type="text"
                      name="bitbucket_username"
                      value={formData.bitbucket_username}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Bitbucket App Password:</Label>
                    <Input
                      type="text"
                      name="bitbucket_app_password"
                      value={formData.bitbucket_app_password}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Bitbucket Workspace:</Label>
                    <Input
                      type="text"
                      name="bitbucket_workspace"
                      value={formData.bitbucket_workspace}
                      onChange={handleChange}
                    />
                  </Postfield>
                 
                  
                  {/* Other input fields */}
                  <Button type="submit">Submit</Button>
                </CardContent>
              </form>
            ) : (
              <form onSubmit={handleUpdate}>
                <CardContent>
                {/* <Postfield>
  <Label>Select a ART:</Label>
  <Select
    value={selectedARTId} // Use formData.ART_id to pre-fill
    onChange={(event) => setSelectedARTId(event.target.value)}
  >
    <option value="">Select a ART</option>
    {ARTsList.map((ART) => (
      <option key={ART.ART_id} value={ART.ART_id}>
        {ART.ART_Name}
      </option>
    ))}
  </Select>
</Postfield> */}

<Postfield>
                    <Label>Jira Project Key :</Label>
                    <Input
                      type="text"
                      name="Jira_Kanben_Project_name"
                      value={formData.Jira_Kanben_Project_name}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Jira URL:</Label>
                    <Input
                      type="url"
                      name="Jira_URL"
                      value={formData.Jira_URL}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Jira Admin Email:</Label>
                    <Input
                      type="email"
                      name="Jira_admin_login_email_address"
                      value={formData.Jira_admin_login_email_address}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Jira Admin API Token:</Label>
                    <Input
                      type="password"
                      name="Jira_admin_API_Token"
                      value={formData.Jira_admin_API_Token}
                      onChange={handleChange}
                    />
                  </Postfield>
               
                 
                  <Postfield>
                    <Label>Circleci API Token:</Label>
                    <Input
                      type="text"
                      name="circleci_api_token"
                      value={formData.circleci_api_token}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Sonarqube Cloud Url:</Label>
                    <Input
                      type="url"
                      name="sonarqube_cloud_url"
                      value={formData.sonarqube_cloud_url}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Sonarqube Api Token:</Label>
                    <Input
                      type="name"
                      name="sonarqube_api_token"
                      value={formData.sonarqube_api_token}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>AWS Access Key Id:</Label>
                    <Input
                      type="id"
                      name="aws_access_key_id"
                      value={formData.aws_access_key_id}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>AWS Secret Access Key :</Label>
                    <Input
                      type="text"
                      name="aws_secret_access_key"
                      value={formData.aws_secret_access_key}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Jira Project Key :</Label>
                    <Input
                      type="text"
                      name="Jira_Kanben_Project_name"
                      value={formData.Jira_Kanben_Project_name}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Github Username:</Label>
                    <Input
                      type="text"
                      name="Github_username"
                      value={formData.Github_username}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Github Repo Name:</Label>
                    <Input
                      type="text"
                      name="Github_Repo_name"
                      value={formData.Github_Repo_name}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Github API Token:</Label>
                    <Input
                      type="text"
                      name="Github_API_Token"
                      value={formData.Github_API_Token}
                      onChange={handleChange}
                    />
                  </Postfield>
                 
                  <Postfield>
                    <Label>Circleci Github Username :</Label>
                    <Input
                      type="text"
                      name="circleci_github_username"
                      value={formData.circleci_github_username}
                      onChange={handleChange}
                    />
                  </Postfield>

                  <Postfield>
                    <Label>Circleci Github Repo :</Label>
                    <Input
                      type="text"
                      name="circleci_github_repo"
                      value={formData.circleci_github_repo}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Bitbucket Username:</Label>
                    <Input
                      type="text"
                      name="bitbucket_username"
                      value={formData.bitbucket_username}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Bitbucket App Password:</Label>
                    <Input
                      type="text"
                      name="bitbucket_app_password"
                      value={formData.bitbucket_app_password}
                      onChange={handleChange}
                    />
                  </Postfield>
                  <Postfield>
                    <Label>Bitbucket Workspace:</Label>
                    <Input
                      type="text"
                      name="bitbucket_workspace"
                      value={formData.bitbucket_workspace}
                      onChange={handleChange}
                    />
                  </Postfield>
                  
                  
                  {/* Other input fields */}
                  <Button type='submit'>Update</Button>
                </CardContent>
              </form>
            )}
           
          </RecommendProject>
        </Div>
      );
      
}



export default PartnerSettings;
const Button=styled.button`
display:flex;
justify-content:center;
align-items:center;
margin:auto;
margin-top:2rem;
font-family:Poppins,Medium;
    background-color: #1DBF73;
    color: white;
   font-size: 15px;
     border-radius: 10px;
     height: 54px;
        width:22rem;
border:none;
    font-weight: 600;
    font-family: Poppins,Medium;
    box-shadow: 1px 3px 6px #00000029;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        }
`;
const TitleText = styled.h4`
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
   margin-top:2rem;
  }

`;
const Div = styled.div`
   display:flex;
   margin:auto;
  justify-content: center;
  align-items:center;
  flex-direction: column;
 
  margin-top: 6rem;



  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 25rem;
  }
`;
const RecommendProject = styled.div`

 display:flex;
 align-items:center;
jutify-content:center;
margin:auto;
flex-direction:row;
@media screen and (min-width:320px) and (max-width:1080px){
    width:100%;
    height:max-content;
    margin:auto;
    
 display:flex;


 flex-direction:column;
    
   }
`;
const CardContent = styled.div`
display:flex;

flex-direction:column;
align-items:center;
jutify-content:center;

  margin: 0.4rem;
  width: 40rem;
  padding:1rem;
  
  height:max-height;

  border-radius:15px;
  font-family:Poppins,Medium;
  background-color:#F2F4F5;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 20rem;
    margin:auto;
  }
`;
const Postfield=styled.div`
font-family:Poppins,Medium;
display:flex;
justify-content:center;
align-items:center;
margin:auto;
padding:10px

@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  flex-direction:column;
`;

const Label =styled.label`
font-family:Poppins,Medium;
font-weight: bold;
font-size:16px;
color :black;
width:15rem;
`;



const Select=styled.select`
font-family:Poppins,Medium;
width: 22rem;
height:50px;
border-radius: 10px;
border: 2px solid #B7B7B7;
padding-left:25px;
font-weight:600;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        }
`;
const Input=styled.input`
font-family:Poppins,Medium;
width: 22rem;
height:50px;
border-radius: 10px;
border: 2px solid #B7B7B7;
padding-left:25px;
font-weight:600;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        }
`;