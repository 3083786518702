import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { FaCog, FaUserCircle, FaBell, FaBars } from 'react-icons/fa';
import styled from 'styled-components';

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto; /* Align icons to the right */
`;

const AppBarIcons = ({ onMenuClick }) => {
  return (
    <IconsContainer>
      <IconButton color="inherit" onClick={onMenuClick}>
        <FaBars />
      </IconButton>
      <IconButton color="inherit">
        <FaCog />
      </IconButton>
      <IconButton color="inherit">
        <FaUserCircle />
      </IconButton>
      <IconButton color="inherit">
        <FaBell />
      </IconButton>
    </IconsContainer>
  );
};

export default AppBarIcons;
