import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    UPDATE_PROFILE
  } from "../action/types";
  const user = JSON.parse(localStorage.getItem("user"));
  
  const initialState = user ?((user !=null ) ? (user.status == 200?{ isLoggedIn: true, user }:{ isLoggedIn: false, user: null }) : { isLoggedIn: false, user: null }):{ isLoggedIn: false, user: null };

  
  
  
  const updateduser = [];
  

  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case REGISTER_SUCCESS:
        return {
          ...state,
          isLoggedIn: false,
        };
      case REGISTER_FAIL:
        return {
          ...state,
          isLoggedIn: false,
        };
      case LOGIN_SUCCESS:
        return {
          ...state,
          isLoggedIn: true,
          user: payload.user,
        };
      case LOGIN_FAIL:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
        };
      case LOGOUT:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
        };
        case UPDATE_PROFILE:
          // console.log(payload);

          return {
            ...state,
            user : payload.user         };
         
      default:
        return state;
    }
  }