import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  CircularProgress,
  Alert,
  IconButton,
  Tooltip
} from '@mui/material';
import {
  Lock as LockIcon,
  Public as PublicIcon,
  Link as LinkIcon,
  ContentCopy as ContentCopyIcon,
  Visibility as VisibilityIcon
} from '@mui/icons-material';
import { useAuthToken } from "./TokenContext";
import { useSelector } from "react-redux";
import axios from 'axios';

const ProfileVisibilityMUI = () => {
  const [visibility, setVisibility] = useState('private');
  const [profileLink, setProfileLink] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const token = useAuthToken();
  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    fetchInitialVisibility();
  }, [token, user?.data]);

  const fetchInitialVisibility = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        process.env.REACT_APP_TALENT_GETUPDATEDLINKANDVISIBILITY,
        {
          id: user.data[0].c_id
        },
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          }
        }
      );

      if (response?.data?.data?.[0]) {
        setVisibility(response.data.data[0].visibility);
        setProfileLink(response.data.data[0].profilelink);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error fetching profile visibility:', error);
      setError('Failed to fetch profile visibility.');
    } finally {
      setLoading(false);
    }
  };

  const handleVisibilityChange = async (event) => {
    const selectedVisibility = event.target.value;
    setVisibility(selectedVisibility);

    let profilelink = '';
    if (selectedVisibility === 'link') {
      profilelink = `${window.location.origin}/profile/${user.data[0].firstName}/${user.data[0].c_id}?token=${btoa(user.data[0].c_id)}`;
    } else if (selectedVisibility === 'public') {
      profilelink = `${window.location.origin}/profile/${user.data[0].firstName}/${user.data[0].c_id}`;
    }

    setLoading(true);
    setError(null);

    try {
      await axios.put(
        process.env.REACT_APP_TALENT_GETUPDATEVISIBILITY,
        {
          id: user.data[0].c_id,
          visibility: selectedVisibility,
          profilelink,
        },
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          }
        }
      );

      setProfileLink(profilelink);
      alert('Profile visibility updated successfully.');
    } catch (error) {
      console.error('Error updating profile visibility:', error);
      setError('Failed to update profile visibility.');
    } finally {
      setLoading(false);
    }
  };

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(profileLink);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <Card>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <VisibilityIcon sx={{ mr: 1 }} />
          <Typography variant="h6">Profile Visibility</Typography>
        </Box>

        <RadioGroup 
          value={visibility} 
          onChange={handleVisibilityChange}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            flexWrap: 'wrap'
          }}
        >
          <FormControlLabel
            value="private"
            control={<Radio />}
            label={
              <Box display="flex" alignItems="center">
                <LockIcon sx={{ mr: 1 }} fontSize="small" />
                <Typography>Restricted</Typography>
              </Box>
            }
          />
          
          <FormControlLabel
            value="link"
            control={<Radio />}
            label={
              <Box display="flex" alignItems="center">
                <LinkIcon sx={{ mr: 1 }} fontSize="small" />
                <Typography>Shareable Link</Typography>
              </Box>
            }
          />
          
          <FormControlLabel
            value="public"
            control={<Radio />}
            label={
              <Box display="flex" alignItems="center">
                <PublicIcon sx={{ mr: 1 }} fontSize="small" />
                <Typography>Public</Typography>
              </Box>
            }
          />
        </RadioGroup>

        {loading && (
          <Box display="flex" alignItems="center" mt={2}>
            <CircularProgress size={20} sx={{ mr: 1 }} />
            <Typography>Updating visibility...</Typography>
          </Box>
        )}

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        {visibility !== 'private' && profileLink && !loading && !error && (
          <Alert severity="info" sx={{ mt: 2 }}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle2">Profile Link</Typography>
              <Tooltip title={copySuccess ? "Copied!" : "Copy to clipboard"}>
                <IconButton onClick={handleCopyLink} size="small">
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ mt: 1, wordBreak: 'break-all' }}>
              <a 
                href={profileLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:underline"
              >
                {profileLink}
              </a>
            </Box>
          </Alert>
        )}
      </CardContent>
    </Card>
  );
};

export default ProfileVisibilityMUI;