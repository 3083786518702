import React from 'react'
import styled from 'styled-components';
import {themeColor} from '../../utils';
function Badge({content,
  clean=false,
  glow = false,
  paid=false,
  cancelled=false,
  UXDesign=false,
}) {
  return ( <Div glow={glow} paid={paid} cancelled={cancelled} UXDesign={UXDesign} clean={clean} >
    {content} 
    </Div>
  );
}

const Div = styled.div`
padding: 0.3rem 1rem;
border-radius:1rem;
font-weight:400;
color:white;
background-color:${themeColor};
cursor:pointer;



${({clean}) => clean && `


border:1px solid #90E0EF;
color:black;
width:50%;
height:15%;
background-color:white;
font-size:12px;
border-radius:0.5rem;

// margin-left:1rem;
`}




${({glow}) => glow && `


font-size:0.8rem;
border:3px solid #1DBF73;
font-weight:normal;
 color:black;
 
background-color:white;
`}

${({UXDesign}) => UXDesign && `


font-size:1rem;
border:2px solid #1DBF73;
font-weight:3px;
 color:black;
background-color:white;
border-radius:none;
`}

${({paid})=>paid && `
background-color:#1DBF73;
width:6rem;
text-align:center;

`
}

${({cancelled})=>cancelled && `
background-color:#EC0303;
width:6.5rem;
text-align:center;
`
}

`;




export default Badge;