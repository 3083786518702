import React, { useState, useEffect, useRef } from 'react';
import { Chart } from 'chart.js';
import { useSelector } from 'react-redux';
import Modal from "react-modal";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

Modal.setAppElement('#root'); // '#root' should be the id of your root HTML element


const CloudCost = ({initialPodIds}) => {
  const [chartData, setChartData] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const chartRef = useRef(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const token = useAuthToken();
  const intl = useIntl();

  const { user } = useSelector((state) => state.auth);

  useEffect(async () => {
    if (user.data[0].type === "stakeholder") {
      try {
        if (!token) {
          console.log('Token is missing. API requests will not be made.');
          return;
        }
        const payload = {};
    
        // Check if pod_id is available, send only pod_id
      
          payload.pod_id = initialPodIds || [];
      
          // If pod_id is not available, check if org_code is available, send only org_code
          payload.org_code = user.data[0].client_code;
        
        // If user type is stakeholder, make a request to the specified API_URL for AWS cloud cost metrics
        const response = await fetch(process.env.REACT_APP_STAKEHOLDER_CLOUDCOSTETRICSSTAKEHOLDER, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify(payload),
        });
  
        const responseData = await response.json();
    const parsedData = responseData.body ? JSON.parse(responseData.body) : null;

    // Check if both last_month_name and this_month_name exist in the parsed data
    if ('last_month_name' in parsedData && 'this_month_name' in parsedData) {
      setChartData(parsedData);
    } else {
      console.error('Missing month names in API response:', parsedData);
    }
  } catch (error) {
    console.log('Error fetching data:', error);
  }
    } else {
      // If user type is not stakeholder, fetch data using the existing logic
      fetchData();
    }
  }, [initialPodIds, token, user.data[0].type]);
  

  useEffect(() => {
    if (chartData) {
      renderPieChart();
    }
  }, [chartData]);

 
const fetchData = async () => {
  try {
    if (!token) {
      console.log('Token is missing. API requests will not be made.');
      return;
    }
    const response = await fetch(
      
      process.env.REACT_APP_CLIENT_AWSCLOUDCOST,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          manager_id: user.data[0].emp_id,
          org_code: user.data[0].client_code,
        }),
      }
    );

    const responseData = await response.json();
    const parsedData = responseData.body ? JSON.parse(responseData.body) : null;

    // Check if both last_month_name and this_month_name exist in the parsed data
    if ('last_month_name' in parsedData && 'this_month_name' in parsedData) {
      setChartData(parsedData);
    } else {
      console.error('Missing month names in API response:', parsedData);
    }
  } catch (error) {
    console.log('Error fetching data:', error);
  }
};


const renderPieChart = () => {
  if (!chartData || !chartData.last_month_costs || !chartData.this_month_costs) {
    return;
  }

  const ctx = chartRef.current.getContext('2d');

  if (chartRef.current.chart) {
    chartRef.current.chart.destroy();
  }

  const lastMonthCost = calculateTotalCost(chartData.last_month_costs);
  const thisMonthCost = calculateTotalCost(chartData.this_month_costs);
 
  const newChartInstance = new Chart(ctx, {
    type: 'doughnut',
    data: {
      labels: [chartData.last_month_name, chartData.this_month_name],
      datasets: [
        {
          data: [lastMonthCost, thisMonthCost],
          backgroundColor: ['#FF6384', '#36A2EB'],
        },
      ],
    },
    options: {
      cutoutPercentage: 50,
      maintainAspectRatio: false,
      plugins: {
        legend: { display: false },
        annotation: {},
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: (tooltipItem, data) => {
            const label = data.labels[tooltipItem.index];
            const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            return `${label}: $${value}`;
          },
        },
      },
    },
  });

  // Add a click event listener directly to the canvas element
  ctx.canvas.onclick = (event) => {
    const activePoints = newChartInstance.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);

    if (activePoints.length > 0) {
      const selectedIndex = activePoints[0].index;
      const selectedMonth = newChartInstance.data.labels[selectedIndex];

      console.log('Chart Clicked on month:', selectedMonth);

      // Open the modal with the selected data
      const selectedData =
        selectedIndex === 0 ? chartData.last_month_costs : chartData.this_month_costs;

      setModalData(selectedData);
      setModalOpen(true);
    }
  };

  chartRef.current.chart = newChartInstance;
};

const calculateTotalCost = (costs) => {
  if (!costs || typeof costs !== 'object') {
    return 0;
  }

  return Object.values(costs).reduce((total, cost) => {
    // Extract numerical value from the string and convert it to a float
    const costValue = parseFloat(cost.replace('$', '') || 0);
    return total + costValue;
  }, 0);
};









  
  
  
  
  
  
  
 
 
  



  return (
    <div className="chart-container">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 className="chart-heading">{intl.formatMessage({ id: 'cloudCost' })}</h2>
        <br />
        <br/>
        <br/>
        <br/>
        
          <div className="chart-wrapper"style={{width:"150px",height:"150px",margin:"auto",textAlign:"center",marginLeft:"2rem"}}>
          {chartData ? (
            <canvas
              id="cloud_cost_chart"
              ref={chartRef}
              style={{ width: '200px', height: '300px' }}
            ></canvas>
          ) : (
            <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
  
          )}
          </div>
        

       
      </div>
   
      <Modal isOpen={isModalOpen} onRequestClose={() => setModalOpen(false)} style={{
          content: {
            width: '50%',
            maxWidth: '800px',
            margin: 'auto',
            marginTop: '5rem',
          },
        }}>
  <h6>{intl.formatMessage({ id: 'costDetails' })}</h6>
  {modalData && (
     <TableContainer component={Paper}>
     <Table component={Paper} sx={{ maxWidth: 900, fontFamily: 'Poppins,Medium', borderRadius: '10px' }} aria-label="simple table">
       <TableHead
         sx={{
           backgroundColor: '#00B4D8',
           '& td, & th': {
             fontFamily: 'Poppins,Medium',
             fontSize: '15px',
             color: 'white',
             fontWeight: '600',
           },
         }}
       >
          <TableRow>
          <TableCell> {intl.formatMessage({ id: 'services' })}</TableCell>
            <TableCell align="right"> {intl.formatMessage({ id: 'cost' })}</TableCell>
           </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(modalData).map(([key, value]) => (
            <TableRow key={key} sx={{
              '&:last-child td, &:last-child th': { border: 0, fontFamily: 'Poppins,Medium', fontSize: '15px' },
              '&:nth-child(even)': {
                backgroundColor: '#90E0EF',
              },
              '&:nth-child(odd)': {
                backgroundColor: '#caf0f8',
              },
              '& td, & th': {
                fontFamily: 'Poppins,Medium',
                fontSize: '15px',
                color: 'black',
              },
            }}>
              <TableCell>{key}</TableCell>
              <TableCell align="right">{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )}
  <button onClick={() => {
    console.log('Close Modal Clicked');
    setModalOpen(false);
  }}>{intl.formatMessage({ id: 'done' })}</button>
</Modal>



    </div>
  );
};

CloudCost.propTypes = {
  initialPodIds: PropTypes.array.isRequired,
};
export default CloudCost;