import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import PropTypes from "prop-types";
import styled from 'styled-components';

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  height: 30rem;
  width: 100%;
  padding 10px;
`;

const ChartWrapper = styled.div`
  height: 100%;
  height: 25rem;
  background-color: #3CB371;
  color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
`;

const ChartHeading = styled.h2`
  font-size: 1rem;
  margin-bottom: 1rem;
  color: black; /* Set heading color to white */
`;

const SelectWrapper = styled.div`
  margin-bottom: 1rem;
  select {
    width: 12rem;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
`;

const Bitbucketcodebydaywisemanager = ({ initialPodIds }) => {
  const [data, setData] = useState(null);
  const [selectedCandidateId, setSelectedCandidateId] = useState('');
  const [talentUsers, setTalentUsers] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const token = useAuthToken();
  const intl = useIntl();

  useEffect(() => {
    fetchData();
    fetchDataManager();
  }, [token, user.data, initialPodIds]);

  const fetchData = async () => {
    try {
      if (!token) {
        return;
      }
      const payload = {};
      if (user.data[0].type === "stakeholder") {
        if (initialPodIds && initialPodIds.length > 0) {
          payload.pod_id = initialPodIds;
        } else if (user.data[0].client_code) {
          payload.org_code = user.data[0].client_code;
        }

        const response = await fetch(process.env.REACT_APP_STAKEHOLDER_CODEBYDAYSTAKEHOLDER, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify(payload),
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const responseData = await response.json();
        setData(responseData.body);
        setTalentUsers(JSON.parse(responseData.body).talent_users);
        return;
      }
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  const fetchDataManager = async () => {
    const requestBody = {
      manager_id: user.data[0].emp_id,
      pod_id: initialPodIds || [],
    };

    try {
      const response = await fetch(process.env.REACT_APP_CLIENT_CODEBYDAYMANAGER, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      setData(responseData.body);
      setTalentUsers(responseData.body?.talent_users);
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  const formatDataForChart = () => {
    if (!data || data === '{}' || data === '[]') {
      return null;
    }
    try {
      const parsedData = data;

      if (!parsedData || !Array.isArray(parsedData.talent_users)) {
        return null;
      }
      const labels = [];
      const checkInsData = [];
      const linesAddedData = [];

      const selectedUser = selectedCandidateId || 'all';

      parsedData.talent_users.forEach((user) => {
        if (
          (selectedUser === 'all' || user.talent_user === selectedUser) &&
          Array.isArray(user.stats)
        ) {
          user.stats.forEach((item) => {
            labels.push(item.weekday);
            checkInsData.push(item.checkins_count);
            linesAddedData.push(item.lines_added_count);
          });
        }
      });

      const chartData = {
        labels: labels,
        datasets: [
          {
            label: intl.formatMessage({ id: 'checkIns' }),
            data: checkInsData,
            backgroundColor: 'rgba(255,255,255,0.4)',
            borderColor: 'rgba(255,255,255,1)',
            borderWidth: 1,
          },
          {
            label: intl.formatMessage({ id: 'linesAdded' }),
            data: linesAddedData,
            backgroundColor: 'rgba(255,255,255,0.4)',
            borderColor: 'rgba(255,255,255,1)',
            borderWidth: 1,
          },
        ],
      };

      return chartData;
    } catch (error) {
      console.log('Error parsing data:', error);
      return null;
    }
  };

  const chartData = formatDataForChart();
  const renderChartContent = () => {
    if (chartData === null) {
      return <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>;
    } else if (chartData?.labels.length > 0) {
      return (
        <div style={{ height: '15rem' }}>
          <Bar
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  stacked: true,
                  ticks: {
                    color: 'white',
                  },
                  grid: {
                    display: false,
                  },
                },
                y: {
                  stacked: true,
                  ticks: {
                    color: 'white',
                  },
                  grid: {
                    color: 'white',
                  },
                },
              },
              plugins: {
                legend: {
                  labels: {
                    color: 'white',
                  },
                },
              },
            }}
          />
        </div>
      );
    } else {
      return <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>;
    }
  };

  const renderUserOptions = () => {
    if (talentUsers?.length > 0) {
      return talentUsers.map((user) => (
        <option
          key={user.talent_user}
          value={user.talent_user}
        >
          {user.talent_user}
        </option>
      ));
    } else {
      return <option value="">{intl.formatMessage({ id: 'noUsers' })}</option>;
    }
  };

  return (
    <ChartContainer>
      <ChartHeading>{intl.formatMessage({ id: 'linesOfCodeByDay' })}</ChartHeading>

      <ChartWrapper>
        <SelectWrapper>
          <select
            value={selectedCandidateId}
            onChange={(event) => setSelectedCandidateId(event.target.value)}
          >
            <option value="">{intl.formatMessage({ id: 'selectAUser' })}</option>
            {renderUserOptions()}
          </select>
        </SelectWrapper>
        {renderChartContent()}
      </ChartWrapper>
    </ChartContainer>
  );
};

Bitbucketcodebydaywisemanager.propTypes = {
  initialPodIds: PropTypes.array.isRequired,
};

export default Bitbucketcodebydaywisemanager;
