import React, {useEffect} from 'react';
import styled from "styled-components";
import Navbar from '../Dashboard/Navbar';
import Earnings from './Earnings';
import Info from './Info';
import Leaddashboardpodactivity from './Leaddashboardpodactivity';
import Invoices from './Invoices';
import ProjectRecommendation from './ProjectRecommendation';
import Sidebar from './Sidebar';
import Header from './Header';
import Talentdasboardpodactivity from './Talentdasboardpodactivity';
import './css/Main.css';

import { useParams,useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
function MainContent({expanded}) {
  const { isLoggedIn, user } = useSelector(state => state.auth);
 
  



  return (
   <Container>
     <Header/>
     {/* <Navbar/> */}
       <SubContainer>
        
           <SectionOne>
           <ColumnOne1>
           
           <Earnings  expanded={expanded}/>
         
       
 


          
           <Info/>
           </ColumnOne1>
           {/* <ColumnTwo1>
            <Info/>
           <ProjectRecommendation/> 
           </ColumnTwo1>  */}
           </SectionOne>
           <br/>
           <SectionTwo>
           <ColumnOne2>
           <InvoiceContainer>
           <Talentdasboardpodactivity  expanded={expanded}/>
             {/* <Invoices/> */}
             
             </InvoiceContainer>
           
           
</ColumnOne2>
{/* <ColumnTwo2>
<ProjectRecommendation/>
</ColumnTwo2> */}
          
           </SectionTwo>
       </SubContainer>
       
   </Container>
  );
}
const Container = styled.div`
width:40%;
// margin-top:20px;

background:linear-gradient(to bottom right, white 0%right, #e6e4ff 70%);
border-bottom-right-radius:2rem;
border-top-right-radius:2rem;
margin: 8rem 8rem 1rem 4rem;

@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  overflow : hidden;
  width:100%;
  margin:1rem 0 0 0;
  flex-direction:column;
}
`;



const SubContainer = styled.div`

height:80%;
width:100%;
display:flex;
margin-top:-2rem;
flex-direction:column;
gap:3rem;
@media screen and (min-width:320px) and (max-width:1080px){
  height:20%;
}

`;
const SectionOne = styled.div`
display:flex;

justify-content:space-between;
height:40%;
gap:1.5rem;
width:150%;
@media screen and (min-width:320px) and (max-width:1080px){
  flex-direction:column;
  align-items:center;
  height:max-content;
  margin:auto;
  width:100%;

}
`;
const SectionTwo = styled.div`
display:flex;
gap:1.5rem;
height: 26vh;
margin-top:5rem;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  justify-content:center;
  align-items:center;
  margin:auto;
  flex-direction:column;
  height:max-content;
  // width:70%;
}

`;
const ColumnOne1 = styled.div`
display:flex;

gap:2.5rem;
@media screen and (min-width:320px) and (max-width:1080px){
  flex-direction:column;
  justify-content:center;
  align-items:center;
  gap:1rem;
 
}
`;
const ColumnOne2 = styled.div`
gap:1.5rem;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  width:100%;
  
}
`;
const InvoiceContainer= styled.div`
@media screen and (min-width:320px) and (max-width:1080px){
  height:max-content;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  width:100%;
}
height:60%;
`;
const ColumnTwo1 = styled.div`
display:flex;
flex-direction:column;
height:115%;
width:50%;
@media screen and (min-width:320px) and (max-width:1080px){
  height:max-content;
  justify-content:center;
  align-items:center;
}
`;
const ColumnTwo2 = styled.div`
@media screen and (min-width:320px) and (max-width:1080px){
display:flex;
justify-content:center;
align-items:center;
  flex-direction:column;
}
`;
export default MainContent;