import React, { useEffect, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './Popup.css';

const CicdPopup = ({ onClose, selectedSegment, deploymentDetails }) => {
  const chartId = 'popupChart'; // Use a fixed chart ID for the popup chart
  const chartRef = useRef(null);

  useEffect(() => {
    // Display the pie chart when the component mounts
    displayPopupChart();
  }, []);

  const displayPopupChart = () => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const ctx = document.getElementById(chartId);
    const chartData = getChartDataForSelectedSegment();

    chartRef.current = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: ['Selected Segment', 'Other Segment'],
        datasets: [
          {
            label: 'Selected Segment',
            data: [chartData, 1 - chartData],
            backgroundColor: ['green', 'lightgrey'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          annotation: {},
          legend: {
            display: true,
          },
        },
        layout: {
          padding: {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10,
          },
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: function (tooltipItem, data) {
              const dataIndex = tooltipItem.dataIndex;
              const datasetIndex = tooltipItem.datasetIndex;
              const value = data.datasets[datasetIndex].data[dataIndex];
              const label = data.labels[dataIndex];
              return `${label}: ${value}`;
            },
          },
        },
      },
    });
  };

  const getChartDataForSelectedSegment = () => {
    if (!deploymentDetails || !deploymentDetails.deployments) {
      return 0;
    }

    if (selectedSegment === 'successful_deployments') {
      return deploymentDetails.deployments.successful_deployments;
    } else if (selectedSegment === 'deployment_fequency') {
      return parseFloat(deploymentDetails.deployments.deployment_fequency.split(' ')[0]);
    } else {
      return 0;
    }
  };

  return (
    <div className="popup">
      <div className="popup-inner">
        <h2>Pie Chart for Selected Segment</h2>
        <div className="chart-wrapper">
          {/* Decrease the width and height using CSS */}
          <canvas id={chartId}></canvas>
        </div>
      
        {deploymentDetails && deploymentDetails.deployment_details && deploymentDetails.deployment_details.length > 0 ? (
          <div>
            <h4>Deployment Details:</h4>
            <TableContainer component={Paper}>
                  <Table component={Paper} 
                  sx={{ maxWidth: 900 ,fontFamily:'Poppins,Medium', borderRadius: '10px'}}  aria-label="simple table">
                  <TableHead    sx={{ backgroundColor:'#00B4D8','& td, & th': {
                                fontFamily: 'Poppins,Medium',
                                fontSize: '15px',
                                color: 'white',
                                fontWeight:'600'
                              },}}>
                    <TableRow>
                    <TableCell align="right" sx={{fontWeight:'bold'}}>Pipeline Number</TableCell>
      <TableCell align="right" sx={{fontWeight:'bold'}}>Workflow Name</TableCell>
      <TableCell align="right" sx={{fontWeight:'bold'}}>Deployment Status</TableCell>
      <TableCell align="right" sx={{fontWeight:'bold'}}>Deployment Date</TableCell>
    </TableRow>
  </TableHead>
            {/* <ul> */}
              {deploymentDetails.deployment_details.map((detail, index) => (
                 <TableBody>

 
                 <TableRow
                   key={""}
                   sx={{ '&:last-child td, &:last-child th': { border: 0 , fontFamily:'Poppins,Medium',fontSize: '15px'},
                   '&:nth-child(even)': {
                    backgroundColor: '#90E0EF'
                  },
                  '&:nth-child(odd)': {
                    backgroundColor: '#caf0f8'
                  },
                  '& td, & th': {
                    fontFamily: 'Poppins,Medium',
                    fontSize: '15px',
                    color: 'black',
                  },
                 }}
                 >
                    <TableCell align="right"> {detail.pipeline_number}</TableCell>
                    <TableCell align="right"> {detail.workflow_name}</TableCell>
                    <TableCell align="right"> {detail.deployment_status}</TableCell>
                    <TableCell align="right"> {detail.deployment_date}</TableCell>
                    </TableRow>
      
      </TableBody> ))}
                {/* <li key={index}>
                  <strong>Pipeline Number:</strong> {detail.pipeline_number}<br />
                  <strong>Workflow Name:</strong> {detail.workflow_name}<br />
                  <strong>Deployment Status:</strong> {detail.deployment_status}<br />
                  <strong>Deployment Date:</strong> {detail.deployment_date}<br />
                </li>
              ))}
            </ul> */}
            </Table>
</TableContainer>  
          </div>
        ) : (
          <p>No deployment details available.</p>
        )}
        <button onClick={() => { chartRef.current.destroy(); onClose(); }}>Close</button>
      </div>
    </div>
  );
};

export default CicdPopup;
