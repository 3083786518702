import React, { useState, useEffect } from 'react';
import { NavHashLink } from 'react-router-hash-link';
import { useSelector } from 'react-redux';
import { useAuthToken } from '../../TokenContext';
import { useIntl } from 'react-intl';
import axios from 'axios';
import moment from 'moment';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import styled from 'styled-components';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, 
  TableRow, Paper, Chip, Button, Menu, MenuItem
} from '@mui/material';

// Custom styled components
const Container = styled.div`
  overflow: hidden;
  background-color: white;
  border-radius: 1rem;
  height: 35rem;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 20px;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 20rem;
  }
`;

const Title = styled.h4`
  height: 8%;
  color: black;
  font-weight: 600;
  font-size: 18px;
  font-family: Poppins, Medium;
  
  &:hover {
    color: #00B4D8;
  }
`;

// Table styles configuration
const tableStyles = {
  container: {
    backgroundColor: 'white',
    borderRadius: '10px',
    fontFamily: 'Poppins, Medium',
    borderCollapse: 'separate'
  },
  header: {
    '& td, & th': {
      fontFamily: 'Poppins, Medium',
      fontSize: '15px',
      color: '#17a2b8',
      fontWeight: 'bold'
    }
  },
  row: {
    '&:last-child td, &:last-child th': {
      border: 0,
      fontFamily: 'Poppins, Medium',
      fontSize: '15px'
    },
    '& td, & th': {
      fontFamily: 'Poppins, Medium',
      fontSize: '15px',
      color: 'black',
      fontWeight: '600',
      borderBottom: '1px solid #ccc',
      borderRight: 'none',
      borderLeft: 'none'
    }
  }
};

const TeamMemberFilter = ({ selectedMembers, onMemberSelect, activities, intl }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const uniqueMembers = [...new Set(activities.map(data => data.pod_candidates))];

  return (
    <>
      <Button 
        onClick={e => setAnchorEl(e.currentTarget)} 
        variant="outlined" 
        style={{ marginBottom: '1rem' }}
      >
        {selectedMembers.length === 0 
          ? intl.formatMessage({ id: 'selectPodTeamMember' })
          : selectedMembers.map(item => (
              <Chip
                key={item}
                label={item}
                color="primary"
                style={{ marginRight: 5 }}
              />
            ))
        }
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {uniqueMembers.map(name => (
          <MenuItem
            key={name}
            onClick={() => {
              onMemberSelect(name);
              setAnchorEl(null);
            }}
            selected={selectedMembers.includes(name)}
          >
            {selectedMembers.includes(name) && (
              <Chip label={name} color="primary" style={{ marginRight: 5 }} />
            )}
            {name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const ActivityTable = ({ activities, sortOrder, onSort, onVideoView, intl }) => (
  <TableContainer component={Paper}>
    <Table sx={tableStyles.container}>
      <TableHead sx={tableStyles.header}>
        <TableRow>
          <TableCell onClick={onSort}>
            {intl.formatMessage({ id: 'submittedDate' })}
            {sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />}
          </TableCell>
          <TableCell>{intl.formatMessage({ id: 'teamMember' })}</TableCell>
          <TableCell>{intl.formatMessage({ id: 'jiraIssue' })}</TableCell>
          <TableCell>{intl.formatMessage({ id: 'todaysFocus' })}</TableCell>
          <TableCell>{intl.formatMessage({ id: 'accomplishments' })}</TableCell>
          <TableCell>{intl.formatMessage({ id: 'blockers' })}</TableCell>
          <TableCell>{intl.formatMessage({ id: 'videoUrl' })}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {activities.length > 0 ? (
          activities.map((item, index) => (
            <TableRow key={index} sx={tableStyles.row}>
              <TableCell>{moment(item.createdAt).format("MM-DD-YYYY")}</TableCell>
              <TableCell>{item.pod_candidates}</TableCell>
              <TableCell>{item.jira_issue}</TableCell>
              <TableCell>{item.today_focus}</TableCell>
              <TableCell>{item.accomplishments}</TableCell>
              <TableCell>{item.blockers}</TableCell>
              <TableCell>
                {item.loom_url ? (
                  <Button onClick={() => onVideoView(item.loom_url, item.id)}>
                    {intl.formatMessage({ id: "viewVideo" })}
                  </Button>
                ) : (
                  <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
                )}
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={7}>
              {intl.formatMessage({ id: 'noDataAvailable' })}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);

const Invoices = ({ expanded }) => {
  const [activities, setActivities] = useState([]);
  const [sortOrder, setSortOrder] = useState('desc');
  const [selectedMembers, setSelectedMembers] = useState([]);
  const { user } = useSelector(state => state.auth);
  const token = useAuthToken();
  const intl = useIntl();

  const fetchActivities = async () => {
    if (!token) return;

    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_GETLATESTPODACTIVITIESDASHBOARD,
        { clientID: user.data[0].emp_id },
        { headers: { Authorization: token, 'Content-Type': 'application/json' }}
      );

      const sortedData = response.data.data?.activities?.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
      }) || [];

      setActivities(sortedData);
    } catch (error) {
      console.error("Error fetching activities:", error);
      setActivities([]);
    }
  };

  const handleVideoView = async (loomUrl, id) => {
    try {
      const [filename] = loomUrl.split('?')[0].split('/').slice(-1);
      const fullUrl = `${process.env.REACT_APP_CLOUDFRONTs3}${filename}`;
      
      const response = await axios.post(
        process.env.REACT_APP_TALENT_CLOUDFRONTAPI,
        { url: fullUrl }
      );

      const { signedUrl } = JSON.parse(response.data.body);
      if (!signedUrl) {
        throw new Error('Signed URL not found');
      }

      window.open(signedUrl, '_blank');
    } catch (error) {
      console.error('Error processing video:', error);
      alert(intl.formatMessage({ id: 'videoError' }));
    }
  };

  useEffect(() => {
    fetchActivities();
  }, [token]);

  const filteredActivities = selectedMembers.length === 0
    ? activities
    : activities.filter(data => selectedMembers.includes(data.pod_candidates));

  return (
    <div expanded={expanded}>
      {activities.length === 0 ? (
        <p>{intl.formatMessage({ id: 'noPodActivity' })}</p>
      ) : (
        <Container>
          <NavHashLink to="/PodActivity" smooth>
            <Title>{intl.formatMessage({ id: 'podActivity' })}</Title>
          </NavHashLink>

          <TeamMemberFilter
            selectedMembers={selectedMembers}
            onMemberSelect={member => {
              setSelectedMembers(prev => 
                prev.includes(member)
                  ? prev.filter(item => item !== member)
                  : [...prev, member]
              );
            }}
            activities={activities}
            intl={intl}
          />

          <ActivityTable
            activities={filteredActivities}
            sortOrder={sortOrder}
            onSort={() => {
              setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
              setActivities(prev => [...prev].reverse());
            }}
            onVideoView={handleVideoView}
            intl={intl}
          />
        </Container>
      )}
    </div>
  );
};

export default Invoices;