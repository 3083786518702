import React from 'react';

export default function EmployerHero() {
  return (
    <section >
      <div >
        <div className="container is-max-widescreen">
          <img src="employer.jpg" alt="prism pod" />
        </div>
      </div>
    </section>
  )
}
