import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { useAuthToken } from "../TokenContext";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { styled } from '@mui/material/styles';

// MUI Components
import {
  Box,
  Container,
  Paper,
  Typography,
  Table,
  Tooltip,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Select,
  MenuItem,
  IconButton,
  Breadcrumbs,
  Stack,
  Alert,
  Snackbar,
  FormControl,
  Card,
  CardContent,
  
  useTheme,
  alpha,
} from '@mui/material';

// Icons
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  CalendarToday as CalendarIcon,
  Save as SaveIcon,
  ArrowBack as ArrowBackIcon
} from '@mui/icons-material';

const categories = [
  'Design',
  'Development',
  'Bug-Fixing',
  'Testing',
  'Performance',
  'Security',
  'Deployment'
];

const hoursOptions = ['00', ...Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'))];
const minutesOptions = ['00', '05', '15', '30', '45', '55', '60'];

const initialTimeEntry = {
  userStory: '',
  category: '',
  hours: '',
  minutes: '',
  podname: '',
};

// Styled Components
const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  fontSize: '1rem',
  '&:focus': {
    outline: 'none',
    borderColor: theme.palette.primary.main,
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,
  '& .MuiTableHead-root': {
    '& .MuiTableCell-head': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontWeight: 600,
    },
  },
  '& .MuiTableBody-root .MuiTableRow-root:hover': {
    backgroundColor: alpha(theme.palette.primary.light, 0.1),
  },
}));

const TimeSubmit = () => {
  const theme = useTheme();
  const token = useAuthToken();
  const [podData, setPodData] = useState([]);
  const [timeEntries, setTimeEntries] = useState([initialTimeEntry]);
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  
  const { podName, podid } = location.state || {};
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [userStories, setUserStories] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    fetchUserStories();
    fetchPodData();
  }, []);

  const fetchUserStories = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_CLIENT_JIRAISSUESLISTTALENTS, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          pod_id: podid,
          c_id: user.data[0].c_id,
        }),
      });
      const data = await response.json();
      if (data.statusCode === 200 && data.body) {
        const parsedBody = JSON.parse(data.body);
        if (Array.isArray(parsedBody.jiraIssues)) {
          setUserStories(parsedBody.jiraIssues);
        }
      }
    } catch (error) {
      showSnackbar('Error fetching user stories', 'error');
    }
  };

  const fetchPodData = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_TALENT_GETPODSFORTALENT, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ c_id: user.data[0].c_id }),
      });
      const data = await response.json();
      if (data.statusCode === 200 && Array.isArray(data.body)) {
        setPodData(data.body);
      }
    } catch (error) {
      showSnackbar('Error fetching pod data', 'error');
    }
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const addRow = () => {
    setTimeEntries([...timeEntries, { ...initialTimeEntry }]);
  };

  const removeRow = (index) => {
    if (timeEntries.length > 1) {
      const updatedEntries = [...timeEntries];
      updatedEntries.splice(index, 1);
      setTimeEntries(updatedEntries);
    }
  };

  const handleDropdownChange = (field, value, index) => {
    const updatedEntries = [...timeEntries];
    updatedEntries[index][field] = value;
    setTimeEntries(updatedEntries);
  };

  const handleSubmit = async () => {
    const hasEmptyFields = timeEntries.some(
      (entry) => !entry.userStory || !entry.category || !entry.hours || !entry.minutes
    );

    if (hasEmptyFields) {
      showSnackbar('Please fill in all fields', 'error');
      return;
    }

    const payload = timeEntries.map((entry) => {
      const selectedUserStory = userStories.find((story) => story.issue_key === entry.userStory);
      
      if (selectedUserStory) {
        return {
          userStory: entry.userStory,
          category: entry.category,
          time: `${entry.hours}:${entry.minutes}`,
          podname: podName,
          pod_id: podid,
          candidate_id: user.data[0].c_id,
          candidate_name: user.data[0].firstName,
          org_code: user.data[0].talent_code,
          jira_issue_id: selectedUserStory.issue_key,
          jira_summary: selectedUserStory.issue_summary,
          sprint_name: '',
          jira_project_id: '',
          workeddate: selectedDate.toISOString().split('T')[0],
          dayoftheweek: new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(selectedDate),
        };
      }
      return null;
    }).filter(Boolean);

    if (payload.length === 0) {
      showSnackbar('No valid entries to submit', 'error');
      return;
    }

    try {
      const response = await fetch(process.env.REACT_APP_TALENT_JIRAISSUESTIMELOGFUNCTION, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      showSnackbar('Time entries submitted successfully', 'success');
      setTimeout(() => {
        // navigate('/Pods');
      }, 1500);
    } catch (error) {
      showSnackbar('Error submitting time entries', 'error');
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ mb: 4 ,marginTop:"5rem"}}>
        <Stack direction="row" alignItems="center" spacing={2}>
         
          <Tooltip title="Back to My Pods">
      <IconButton
        component={Link}
        to="/Pods"
        sx={{ 
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
          }
        }}
      >
        <ArrowBackIcon />
            </IconButton>
          </Tooltip>
          <Breadcrumbs>
            <Link
              to="/Pods"
              style={{
                color: theme.palette.primary.main,
                textDecoration: 'none',
                fontWeight: 600,
              }}
            >
              My Pods
            </Link>
            <Typography color="text.secondary" fontWeight={600}>
              Time Sheet
            </Typography>
          </Breadcrumbs>
        </Stack>
      </Box>

      <Card sx={{ borderRadius: 2, mb: 4 }}>
        <CardContent>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontWeight: 700,
              color: theme.palette.primary.main,
              mb: 3,
            }}
          >
            Pod TimeSheet
          </Typography>

          <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 3 }}>
            <Box sx={{ width: 200 }}>
              <DatePicker
                selected={selectedDate}
                onChange={setSelectedDate}
                dateFormat="MM/dd/yyyy"
                minDate={new Date()}
                todayButton="Today"
                customInput={
                  <Button
                    variant="outlined"
                    fullWidth
                    startIcon={<CalendarIcon />}
                    sx={{ justifyContent: 'flex-start', textTransform: 'none' }}
                  >
                    {selectedDate.toLocaleDateString()}
                  </Button>
                }
              />
            </Box>
            <Typography
              variant="subtitle1"
              sx={{
                color: theme.palette.primary.main,
                fontWeight: 600,
              }}
            >
              {new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(selectedDate)}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              onClick={addRow}
              startIcon={<AddIcon />}
              variant="outlined"
              sx={{ textTransform: 'none' }}
            >
              Add Row
            </Button>
          </Stack>

          <TableContainer component={Paper} sx={{ borderRadius: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>User Story</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Hours</TableCell>
                  <TableCell>Minutes</TableCell>
                  <TableCell width={100}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {timeEntries.map((entry, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <FormControl fullWidth>
                        <Select
                          value={entry.userStory}
                          onChange={(e) => handleDropdownChange('userStory', e.target.value, index)}
                          displayEmpty
                        >
                          <MenuItem value="">Select Story</MenuItem>
                          {userStories.map((story) => (
                            <MenuItem key={story.issue_key} value={story.issue_key}>
                              {story.issue_key}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <Select
                          value={entry.category}
                          onChange={(e) => handleDropdownChange('category', e.target.value, index)}
                          displayEmpty
                        >
                          <MenuItem value="">Select Category</MenuItem>
                          {categories.map((category) => (
                            <MenuItem key={category} value={category}>
                              {category}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <Select
                          value={entry.hours}
                          onChange={(e) => handleDropdownChange('hours', e.target.value, index)}
                          displayEmpty
                        >
                          <MenuItem value="">Hours</MenuItem>
                          {hoursOptions.map((hour) => (
                            <MenuItem key={hour} value={hour}>
                              {hour}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <Select
                          value={entry.minutes}
                          onChange={(e) => handleDropdownChange('minutes', e.target.value, index)}
                          displayEmpty
                        >
                          <MenuItem value="">Minutes</MenuItem>
                          {minutesOptions.map((minute) => (
                            <MenuItem key={minute} value={minute}>
                              {minute}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => removeRow(index)}
                        color="error"
                        disabled={timeEntries.length === 1}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              size="large"
              startIcon={<SaveIcon />}
              sx={{
                textTransform: 'none',
                minWidth: 200,
                borderRadius: theme.shape.borderRadius,
              }}
            >
              Submit Time Entries
            </Button>
          </Box>
        </CardContent>
      </Card>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          variant="filled"
          sx={{ borderRadius: theme.shape.borderRadius }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default TimeSubmit;