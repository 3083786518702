import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './Popup.css';

const PopupDefectsdetailviewchart = ({ title, openDefects, closedDefects, onClose }) => {
  return (
    <div className="popup">
      <div className="popup-inner">
        <div  style={{display:"flex",flexDirection:'row'}}>
        {/* <h3>{title}</h3> */}
        <div style={{display:"flex",flexDirection:"column"}}>
        <h4>Open Defects</h4>
        {openDefects.length > 0 ? (
          <>
                <TableContainer component={Paper}>
                <Table component={Paper} 
                sx={{ maxWidth: 900 ,fontFamily:'Poppins,Medium', borderRadius: '10px'}}  aria-label="simple table">
                <TableHead    sx={{ backgroundColor:'#00B4D8','& td, & th': {
                              fontFamily: 'Poppins,Medium',
                              fontSize: '15px',
                              color: 'white',
                              fontWeight:'600'
                            },}}>
                  <TableRow>
                  <TableCell align="right" sx={{fontWeight:'bold'}}>Issue Key</TableCell>
                  <TableCell align="right" sx={{fontWeight:'bold'}}>Issue Summary</TableCell>
  </TableRow>
</TableHead>
          {/* <ul> */}
            {openDefects.map((defect, index) => (
                <TableBody>

 
                <TableRow
                  key={""}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 , fontFamily:'Poppins,Medium',fontSize: '15px'},
                  '&:nth-child(even)': {
                   backgroundColor: '#90E0EF'
                 },
                 '&:nth-child(odd)': {
                   backgroundColor: '#caf0f8'
                 },
                 '& td, & th': {
                   fontFamily: 'Poppins,Medium',
                   fontSize: '15px',
                   color: 'black',
                 },
                }}
                >
                   <TableCell align="right"> {defect.issue_key}</TableCell>
                   <TableCell align="right"> {defect.issue_summary}</TableCell>
                    </TableRow>
      
      </TableBody> ))}
      </Table>
</TableContainer>  
</>
              
        ) : (
          <p>No open defects</p>
        )}
</div>
<div style={{display:"flex",flexDirection:"column",marginLeft:"2rem"}}>
        <h4>Closed Defects</h4>
        {closedDefects.length > 0 ? (
             <TableContainer component={Paper}>
             <Table component={Paper} 
             sx={{ maxWidth: 900 ,fontFamily:'Poppins,Medium', borderRadius: '10px'}}  aria-label="simple table">
             <TableHead    sx={{ backgroundColor:'#00B4D8','& td, & th': {
                           fontFamily: 'Poppins,Medium',
                           fontSize: '15px',
                           color: 'white',
                           fontWeight:'600'
                         },}}>
               <TableRow>
               <TableCell align="right" sx={{fontWeight:'bold'}}>Issue Key</TableCell>
               <TableCell align="right" sx={{fontWeight:'bold'}}>Issue Summary</TableCell>
</TableRow>
</TableHead>
          
            {closedDefects.map((defect, index) => (
                 <TableBody>

 
                 <TableRow
                   key={""}
                   sx={{ '&:last-child td, &:last-child th': { border: 0 , fontFamily:'Poppins,Medium',fontSize: '15px'},
                   '&:nth-child(even)': {
                    backgroundColor: '#90E0EF'
                  },
                  '&:nth-child(odd)': {
                    backgroundColor: '#caf0f8'
                  },
                  '& td, & th': {
                    fontFamily: 'Poppins,Medium',
                    fontSize: '15px',
                    color: 'black',
                  },
                 }}
                 >
                    <TableCell align="right"> {defect.issue_key}</TableCell>
                    <TableCell align="right"> {defect.issue_summary}</TableCell>
                     </TableRow>
       
       </TableBody> ))}
       </Table>
 </TableContainer>  
        ) : (
          <p>No closed defects</p>
        )}
        </div>
</div>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default PopupDefectsdetailviewchart;
