import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useIntl } from "react-intl";
import { useAuthToken } from "../TokenContext";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

const OpenAi = ({ collapsed }) => {
  const [inputText, setInputText] = useState("");
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const intl = useIntl();
  const [recentSearches, setRecentSearches] = useState([]);
  const [showRecentSearches, setShowRecentSearches] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(350); // Initial width of the sidebar

  const token = useAuthToken();

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage(null);

    const headers = {
      Authorization: token,
      "Content-Type": "application/json",
    };

    try {
      const payload = { story_description: inputText };
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_JIRAAUTOMATE,
        payload,
        { headers: headers }
      );

      // Parse the response and format it
      const formattedResponse = response.data
        .replace(/\\n/g, "\n")
        .split("\n")
        .map((line, index) => <p key={index}>{line}</p>);

      setMessages([
        ...messages,
        { type: "user", text: inputText },
        { type: "response", text: formattedResponse },
      ]);
      setRecentSearches([...recentSearches, inputText]); // Add current search to recent searches
      setInputText("");
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
      setErrorMessage("Error fetching data: " + error.message);
    }
  };

  const toggleRecentSearches = () => {
    setShowRecentSearches(!showRecentSearches);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const newWidth = e.clientX;
    setSidebarWidth(newWidth);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  return (
    <Container collapsed={collapsed}>
      <RecentSearches
        showRecentSearches={showRecentSearches}
        width={sidebarWidth}
      >
        <h4
          style={{
            display: "flex",
            margin: "auto",
            marginTop: "5rem",
            paddingBottom: "1rem",
          }}
        >
          Recent Searches
        </h4>
        <ul>
          {recentSearches.map((search, index) => (
            <li key={index}>{search}</li>
          ))}
        </ul>
      </RecentSearches>
      <ToggleRecentSearchesButton onClick={toggleRecentSearches}>
        {showRecentSearches ? <IoIosArrowBack /> : <IoIosArrowForward />}
      </ToggleRecentSearchesButton>
      <MainContent
        showRecentSearches={showRecentSearches}
        sidebarWidth={sidebarWidth}
      >
        <MessagesContainer>
          {messages.map((message, index) => (
            <Message key={index} type={message.type}>
              {message.type === "response" ? (
                message.text
              ) : (
                <p>{message.text}</p>
              )}
            </Message>
          ))}
          {isLoading && (
            <Message type="loading">
              {intl.formatMessage({ id: "loadingAcceptanceCriteria" })}...
            </Message>
          )}
          {errorMessage && <Message type="error">{errorMessage}</Message>}
        </MessagesContainer>
      </MainContent>
      <Form onSubmit={handleSubmit} collapsed={collapsed}>
        <Input
          type="text"
          placeholder={intl.formatMessage({ id: "enterStoryDescription" })}
          value={inputText}
          onChange={handleInputChange}
          required
        />
        <Button type="submit">{intl.formatMessage({ id: "submit" })}</Button>
      </Form>
    </Container>
  );
};

export default OpenAi;

const Container = styled.div.attrs((props) => ({
  "data-collapsed": props.collapsed.toString(),
}))`
  display: flex;
  font-family: "Poppins", sans-serif;

  overflow: hidden; /* Prevent full page scroll */
  transition: margin-left 0.3s, width 0.3s;
  margin-left: ${(props) =>
    props["data-collapsed"] === "true" ? "60px" : "240px"};
  width: ${(props) =>
    props["data-collapsed"] === "true"
      ? "calc(100% - 60px)"
      : "calc(100% - 240px)"};
`;

const RecentSearches = styled.div`
  width: ${({ showRecentSearches }) => (showRecentSearches ? "350px" : "0")};
  min-width: 250px;
  padding: 20px;
  background-color: #03045e;
  color: white;
  font-weight: 600;
  transition: width 0.3s ease-in-out;
  overflow-y: auto; /* Allow scrolling */
  height: 100vh; /* Make the sidebar full height */
`;

const MainContent = styled.div`
  display: flex;
  justify-content: center; /* Center the form horizontally */
  align-items: center; /* Center the form vertically */
  flex: 1;

  flex-direction: column;
  justify-content: space-between;
  margin-top: 5rem;
  width: calc(
    100% -
      ${({ showRecentSearches, sidebarWidth }) =>
        showRecentSearches ? sidebarWidth + "px" : "0"}
  );
  overflow: hidden; /* Prevent full page scroll */
`;

const MessagesContainer = styled.div`
  flex: 1;
  overflow-y: auto; /* Allow scrolling within the messages container */
  padding: 20px;
  margin-bottom: 80px; /* Ensure there is space for the form */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const Message = styled.div`
  background-color: ${(props) =>
    props.type === "user"
      ? "#caf0f8"
      : props.type === "response"
      ? "#0077b6"
      : props.type === "error"
      ? "#0077b6"
      : "transparent"};
  color: ${(props) =>
    props.type === "user"
      ? "black"
      : props.type === "response"
      ? "white"
      : props.type === "error"
      ? "white"
      : "white"};
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  max-width: 80%;
  word-wrap: break-word;
`;

const Form = styled.form.attrs((props) => ({
  "data-collapsed": props.collapsed.toString(),
}))`
  display: flex;
  justify-content: center; /* Center the form horizontally */
  align-items: center; /* Center the form vertically */
  padding: 10px;
  background-color: #03045e;
  border-top: 1px solid #0077b6;
  height: 80px; /* Fixed height for the form */
  position: fixed; /* Fix the form at the bottom */
  bottom: 0;
  width: ${(props) =>
    props["data-collapsed"] === "true"
      ? "calc(95% - 60px)"
      : "calc(95% - 240px)"};

  z-index: 1; /* Ensure the form is above the sidebar */
`;

const Input = styled.input`
  flex: 1;
  border-radius: 6px;
  height: 50px;
  padding: 0 10px;
  font-size: 1rem;
  font-weight: 600;
`;

const Button = styled.button`
  background-color: #00b4d8;
  color: white;
  height: 50px;
  border-radius: 6px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #0090ad;
  }
`;

const ToggleRecentSearchesButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: #f5f5f5;
  border: none;
  cursor: pointer;
  height: 30px;
  width: 30px;
  position: fixed; /* Fix the button at the left */
  left: 10px;
  top: 10px;

  &:hover {
    text-decoration: underline;
  }
`;

const DragHandle = styled.div`
  width: 100%;
  height: 8px;
  background-color: #ddd;
  cursor: ew-resize;
`;
