

import axios from "axios";

// https://xvc2hr1788.execute-api.us-east-1.amazonaws.com/dev/
const LOGIN_API_URL = process.env.REACT_APP_LOGINAPI;

const register = (usertype, email,code) => {
  // https://cups2r7ev8.execute-api.us-east-1.amazonaws.com/dev/candidateSignup
  return axios.post(process.env.REACT_APP_CANDIDATESIGNUP, {   email : email,  usertype : usertype,code:code,
  });
};


const login = async (email,table) => {
 
  const data = {
    "email" : email, "table" : table 
  }
 
//  https://xvc2hr1788.execute-api.us-east-1.amazonaws.com/dev/Get_Candidate
  const response = await axios.post(process.env.REACT_APP_TALENT_GETCANDIDATE, data);
        // console.log(response.data)
    if (response.data.data.length > 0) {
        localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
};
const logout = () => {
  localStorage.removeItem("user");
};


const updateProfile = (data,headers) => {
  // https://zfnddk64nb.execute-api.us-east-1.amazonaws.com/dev/Edit_Candidate
  return axios.put(process.env.REACT_APP_TALENT_EDITCANDIDATE, data,{headers});
};

const podproviderupdate = (providerdata,headers) => {
  // https://82uzdjuwk6.execute-api.us-east-1.amazonaws.com/dev/podproviderupdate
  return axios.post(process.env.REACT_APP_PODPROVIDERUPDATE, providerdata,{headers});
};

const updateclientProfile = (data,headers) => {
  // https://nqg55lo7jk.execute-api.us-east-1.amazonaws.com/dev/Edit_EmployerS
  return axios.put(process.env.REACT_APP_EDITEMPLOYERS, data,{headers});
};

const CostomerupdateProfileapi = (data,headers) => {
  //https://r0fd3uf932.execute-api.us-east-1.amazonaws.com/dev/editCustomer
  return axios.put(process.env.REACT_APP_CUSTOMERADMIN_EDITCOSTOMER, data,{headers});
};

const StakeholderupdateProfileapi=(data,headers) =>{
  return axios.put(process.env.REACT_APP_STAKEHOLDER_EDITSTAKEHOLDER, data,{headers});
}

const PartnerguestupdateProfileapi=(data,headers) =>{
  return axios.post(process.env.REACT_APP_PARTNER_EDITPARTNERGUEST, data,{headers});
}

const postproject = (data,headers) => {
  // https://y6k41htfzl.execute-api.us-east-1.amazonaws.com/dev/add_project
  return axios.post(process.env.REACT_APP_ADDPROJECT, data,{headers});
};

const postpodproject = (data) => {
  // https://0bkrod0hlf.execute-api.us-east-1.amazonaws.com/dev/add_podproject
  return axios.post(process.env.REACT_APP_ADDPODPROJECT, data);
};


export default {
  register,
  login,
  logout,
  updateProfile,
  updateclientProfile,
  CostomerupdateProfileapi,
  StakeholderupdateProfileapi,
  PartnerguestupdateProfileapi,
  postproject,
  postpodproject,
  podproviderupdate
};

