import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import { useAuthToken } from "../TokenContext";
import { useSelector } from 'react-redux';
import styled from 'styled-components';
const Dorametircslinegraphtalent = ({ initialPodIds}) => {
  const [chartData, setChartData] = useState(null);
  const token = useAuthToken();
  const { user } = useSelector(state => state.auth);
  
  useEffect(() => {
  

    fetchDoraMetrics();
  }, [token,initialPodIds]);
 
const fetchDoraMetrics = async () => {
  const headers = {
    'Authorization' : token, // Replace with your actual token
    'Content-Type': 'application/json', // You can add more headers as needed
  };
  try {
    if (!token) {
      return; // Exit early if there is no token
    }
    const response = await axios.post(
      process.env.REACT_APP_TALENT_DORAMETRICSTALENT, {
        "c_id": user.data[0].c_id,
        "pod_id": initialPodIds,
      },{headers:headers});

    const parsedData = JSON.parse(response.data.body);

    const chartValues = Object.entries(parsedData).map(([label, value]) => ({
      label,
      value: parseFloat(value.replace(/[^\d.]/g, '')),
    }));

    const newChartData = {
      labels: chartValues.map((item) => item.label),
      datasets: [
        {
          label: 'Performance Metrics',
          data: chartValues.map((item) => item.value),
          fill: false,
          borderColor: 'rgba(75,192,192,1)',
          lineTension: 0.1,
        },
      ],
    };

    setChartData(newChartData);
  } catch (error) {
    console.error('Error fetching DORA metrics:', error);
  }
};
  return (
    <Div>
    {chartData && (
    <div style={{display:'flex' ,justifyContent:"center",alignItems:"center",flexDirection:"column",margin:"auto", width: '30rem', height: '15rem' }}>


    {chartData.labels.map((label, index) => (
      <div key={label} style={{ padding: '10px', cursor: 'pointer', marginTop: '0.5rem', backgroundColor: '#EAEAEA', borderRadius: '8px', width: '300px' }} className="metric-container">
        
      
        <p className="metric-label" style={{ marginBottom: '0.5rem', color: '#333' }}>
         
            <span key={label}>
              {label} - {parseFloat(chartData.datasets[0].data[index])}
              <br />
            </span>
         
        </p>
       
      </div>
      ))}
    
        
      </div>
      )}
</Div>

);
};
const Div=styled.div`

display: flex;

align-items: center;
margin: auto;
overflow:auto;


 @media screen and (min-width:320px) and (max-width:1080px){

  width:15rem;
  overflow:auto;
 }
`;
export default Dorametircslinegraphtalent;
