
import styled from 'styled-components';
import React  from 'react'
import Badge from './Badge';
import Avatar from "./assets/MaskGroup.png";
import {cardShadow,hoverEffect,themeColor} from '../utils';
import { useState } from 'react';

import ShowMore from 'react-show-more-button/dist/components/ShowMore';
import { NavHashLink } from 'react-router-hash-link';

import ViewPostedProReq from './ViewPostedProReq';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import allergens from './allergens';
import OptionsOutsideSelect from '../OptionsOutsideSelect';
import Select from "react-select";
import { useParams,useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import MultiSelect from 'react-select';
import{FiSearch} from 'react-icons/fi';
import { useAuthToken } from "../TokenContext";
const PodactivityviewalltalentEdit = () => {

const [categories,setCategories]=useState('');
const [pod_candidates,setPod_candidates]=useState("");
const [today_focus,settoday_focus]=useState("");
const [loom_url,setLoom_url]=useState("");
const [accomplishments,setaccomplishments]=useState("");
 
const [blockers,setBlockers]=useState("");
const [getresdata,setGetresdata]=useState([]);
const { isLoggedIn, user } = useSelector(state => state.auth);

const token = useAuthToken();

let path = window.location.pathname;
let path1 = path && path.split(',')

let c_id =  path1 && path1.length > 0 && path1[0].split('/') 

let c = c_id && c_id.length > 0 && c_id[2]


 


   
   const handleSubmit = (e) => {
    e.preventDefault();

 var poddata = [{today_focus:today_focus,blockers:blockers,loom_url:loom_url,accomplishments:accomplishments,categories:categories,id:c}];

    var abc = {poddata}
  

    fetch(
      process.env.REACT_APP_TALENT_PODACTIVITYUPDATETALENT,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token, 
        },
        body: JSON.stringify(abc),
      }
    ).then(() => {
      
    alert("Edited Details successfully.")
     window.location.href = "/Podactivityviewalltalent"
    });
  };

const params =useParams();
const history=useHistory();





const {id}=useParams();




const getDetails=async()=>{

  const headers = {
    'Authorization' : token, // Replace with your actual token
    'Content-Type': 'application/json', // You can add more headers as needed
  };
axios.post( process.env.REACT_APP_TALENT_PODACTIVITYBYIDTALENT,{id:c},{headers:headers})

.then((res)=>
{ 
 
 
 setGetresdata(res.data.data)



 
  res.data.data.map( (x1,i)=>(
    x1.items.map( (x,j)=>{

setCategories(x.categories)
setPod_candidates(x.pod_candidates)
settoday_focus(x.today_focus)
setaccomplishments(x.accomplishments)
setLoom_url(x.loom_url)
setBlockers(x.blockers)

})))


}
)
      .catch((err)=>{
        console.log("catch",err);
      })
    }

useEffect(()=>{
  console.warn(params);
  getDetails();
 },[token]);


    


  const onInputChangecategory = (e) => {
setCategories(e.target.value);



  };
 

  const onInputcandidates =(e)=>{
    setPod_candidates(e.target.value);
  }
  
  const onInputChangeupdate =(e)=>{
    settoday_focus(e.target.value);
  }

  const onInputChangeblockers = (e) => {
    setBlockers(e.target.value);
   
  };

 
 
  
  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white",height:'45px', marginTop:'1rem',
  
    
    color:'grey',
    fontWeight:'600',
    paddingLeft:"12px"
  })
   
  };


  return (

   
    <Div >

      

<TitleText>Edit Requirement</TitleText>
{/* <InputContainer>
          
          <Icon>
              <FiSearch/>
              </Icon>
              <Input type="text" placeholder="Search for projects" />
              </InputContainer> */}
<RecommendProject>


<CardContent>
   

<CardContent1>




<Row1>
        <Label>  categories   <span>:</span>    </Label>
<SelectField 

name='categories'
value={categories}
onChange={(e) => setCategories(e.target.value)}

required

>
 

  <option  value="Categories" defaultValue >Categories</option>
          <option value="Design" >Design</option>
          <option  value="Coding">Coding</option>
          <option  value="Testing">Testing</option>
          <option  value="Resolving Bugs">Resolving Bugs</option>
          <option  value="Deployment">Deployment</option>


    </SelectField>

    </Row1>
    <Row1>
<Label>Video url :</Label>
            <InputField
   id="loom_url"
   type='url'
   onChange={onInputChangeupdate}
   placeholder="https://www.example.com"

/>
</Row1>
    <Row1>
        <Label>  Today's Focus   <span>:</span>    </Label>
<Textarea 
name='today_focus'
onChange={onInputChangeupdate}
value={today_focus}

required
placeholder="Today's Focus . . ."/>
   </Row1>
   <Row1>
        <Label>  Accomplishments  <span>:</span>    </Label>
<Textarea 
name='accomplishments'
onChange={onInputChangeupdate}
value={accomplishments}

required
placeholder="Accomplishments . . ."/>
   </Row1>
   
   
   <Row1>
        <Label >  Blocker   <span>:</span>    </Label>
   <Textarea 
name='blockers'
onChange={onInputChangeblockers}
value={blockers}


placeholder="Blocker . . ."/>
    
    </Row1>
    

<Button onClick={handleSubmit}>Save</Button>
</CardContent1>



</CardContent>


<SideCard>
<H1>Recommended Pods</H1>
  {/* <SideCardContent>
    
<Row>
 
            <Detail>
              <InfoContainer>
                <img
                  src={Avatar}
                  alt=""
                  style={{ width: 50, height: 50 }}
                ></img>
                <Info>
                  <InfoName>Thomas Martin</InfoName>
                  <InfoUpdate>10 days ago</InfoUpdate>
                </Info>
              </InfoContainer>
            </Detail>
          </Row>
          <Badge content="Design/" />

          <Title>
            Need a designer to form branding essentials for my bussiness

            
          </Title>
          <ProjectInfo>
          Required Position: UX/UI Designer
          </ProjectInfo>

          </SideCardContent> */}
         {/* <ViewAll>View All</ViewAll> */}

          
  <SideCardContent1>


          <Title>
          Your Posted Pod Activity
            

          </Title>
          <View>
            
       <NavHashLink to = '/Editsavedraft'  smooth>
       
            View</NavHashLink> 
            
             </View>

          </SideCardContent1>
</SideCard>


</RecommendProject>

     </Div>
  )
}


const Div =styled.div`

// display:flex;
display:inline;
jutify-content:center;
flex-direction:column;
margin-top:6rem;
align-items:center;
overflow: auto;
margin-left:6.5rem;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  overflow:hidden;
justify-content:center;
align-items:center;
margin:auto;
width:24rem;
  }
`;
const Row1 = styled.div`
  display: flex;
margin-top:2rem;
  align-item: center;
  margin-bottom: 0.8rem;
  @media screen and (min-width:320px) and (max-width:1080px){
    display: flex;
    flex-direction:column;
  }
`;
const Row2 = styled.div`
  display: flex;
  flex-direction:row;
margin-top:2rem;

  align-item: center;
  margin-bottom: 0.8rem;
  @media screen and (min-width:320px) and (max-width:1080px){
    display: flex;
    flex-direction:column;
  }
`;
const Textarea = styled.textarea`
width:25rem;
margin-top:1rem;

tex-decoration:none;
border:1px solid #cccccc;
border-radius:5px;

padding:1rem;
font-size:14px;
font-family:Poppins,Medium;
font-Weight:600;
  outline: none;
  @media screen and (min-width:320px) and (max-width:1080px){
    width: 15rem;
  }


`;
const RecommendProject=styled.div`

 display:flex;

jutify-content:center;
flex-direction:row;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;

 
  flex-direction:column;
  

  
    
   }
`;


const TitleText=styled.h4`
font-weight:600;
@media screen and (min-width:320px) and (max-width:1080px){

 
  margin-top:2rem;

 
}
`;
const CardContent=styled.div`
margin:0.4rem;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;


border-radius:15px;
font-family:Poppins,Medium;
width:40rem;
height:max-height;
padding: 1rem;
background-color: #F2F4F5;

box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};
}

@media screen and (min-width:320px) and (max-width:1080px){
  width:20rem;
  margin:auto;
 
  
 }
`;


const CardContent1=styled.div`
margin:0.4rem;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;

@media screen and (min-width:320px) and (max-width:1080px){
  width:20rem;
  margin:auto;
 
  
 }


`;




const InputField=styled.input`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
padding-left:20px;
font-weight:600;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;
 
  
 }

`;


const SelectField=styled.select`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
font-weight:600;
color:grey;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;
 
  
 }

`;

const SelectField1=styled.div`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
font-weight:600;
color:grey;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;
 
  
 }
`;


const Button=styled.button`
margin-top:1rem;
border:none;
border-radius: 10px;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
width:20rem;
height:3rem;
font-size:18px;
font-weight:600;
color:white;
margin-top:4rem;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;
 
  
 }
`;




const SideCard=styled.div`
margin-left:5rem;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
border-radius:20px;
font-family:Poppins,Medium;
width:20rem;
height:32rem;

padding: 1rem;
background-color: #F2F4F5;

box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};
}
@media screen and (min-width:320px) and (max-width:1080px){
margin:auto;
margin-top:5rem;
 
  
 }

`;


const SideCardContent=styled.div`
background-color:#90E0EF;
width:17rem;
display:flex;
flex-direction:column;
padding:3px;


border-radius:20px;
`;


const SideCardContent1=styled.div`
background-color:#90E0EF;
width:17rem;
display:flex;
margin-top:1rem;
flex-direction:column;
height:10rem;
justify-content:center;
align-items:center;
border-radius:20px;
`;





const Detail = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const Row = styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
`;
const InfoName = styled.h6`
  font-weight: 600;
`;
const InfoUpdate = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;

const Title = styled.h6`
  font-weight: 600;
`;
const ProjectInfo = styled.p`
padding:5px;
margin:3px;
background-color:white;
border-radius:5px  ;
font-size:13px;
color:black;
font-weight:600;
margin-bottom:1rem
`;

const ViewAll =styled.button`
width:5rem;
font-weight:600;
height:2rem;
margin-left:auto;
margin-top:1rem;
border: 2px solid #1DBF73;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;

const View =styled.button`
width:8rem;
font-weight:600;
height:2rem;
color:black;
background-color:white;
border-radius:5px  ;
margin-bottom:1rem;
margin-top:1rem;
border: none;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;

const H1 = styled.h1`
font-size:22px;
font-weight:600;
margin-bottom:1.2rem;
`;


const InputContainer = styled.div`
display : flex;
border-radius:10px;
justify-content: center;
margin-left:auto;
width:25rem;
margin-top:-2rem;
margin-bottom:1rem;
border:1px solid #4E8FAE;
box-shadow: 3px 6px 9px #00000005;
@media screen and (min-width:320px) and (max-width:1080px){
  margin:auto;
  margin-bottom:2rem;
  margin-top:2rem;
  width: 18rem;
 
}
`;

const Icon = styled.div`
height: 3rem;
width: 2rem;
background-color: #F2F3F4;
display:flex;
flex-direction:row
justify-content: center;
align-items: center;
border-top-left-radius:10px;
border-bottom-left-radius:10px;
svg{
    color:#555555;
}
`;

const Input = styled.input`
justify-content: center;
height: 3rem;
width: 25rem;
border:0px solid #4E8FAE;
background-color:#F2F3F4;
font-family:Poppins,Medium;
font-Weight:600;
border-top-right-radius:10px;
border-bottom-right-radius:10px;
&:focus{
    border:none;
    outline:none;
}
@media screen and (min-width:320px) and (max-width:1080px){
  width: 18rem;
}
`;

const Label =styled.label`
margin-top:1rem;

font-family:Poppins,Medium;
font-weight: bold;
font-size:16px;
color :black;
width:10rem;
`;

export default PodactivityviewalltalentEdit;















