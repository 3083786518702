import React from 'react'
import profile_img from '../assets/profile_img.png'
import { FiSearch } from 'react-icons/fi';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { style } from '@mui/system';
import axios from 'axios';
import '../CandidateAdminDashboard.css';
import logo from '../assets/programmer.png';


const Providerdashboard = () => {


  return (<>
    {/* <DivTag1 >

      <DivTag2  >
          <H5Tag>Pod Provider Dashboard</H5Tag>
          <a href="/PartnerCreatepod"> 
               <button className="homebutton" style={{ boxShadow:'15px 15px 25px #00000029', marginLeft: "22%"}}>
                         create pod
                </button>
                </a> */}
          
          <p>Welcome </p>
      

      {/* </DivTag2>
    
    </DivTag1> */}

    </>)
}



const DivTag1 = styled.div`

        margin:auto;
        justify-content:center;
        `;
const DivTag2 = styled.div`

margin-top:8rem;
        width:1300px;
        overflow: auto;
        background-color:#e6e6ff;
        height:max-height;
        padding-bottom:1.5rem;
        border-radius:20px;
        border:1px solid #e6e6ff;
        @media screen and (min-width:320px) and (max-width:1080px){
          overflow: auto;
         margin:auto;
          width:20rem;
          margin-top:8rem;
}
        `;
const DivTag3 = styled.div`
        display:flex;
        flex-direction:row;
        padding-bottom:1.5rem;
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        flex-direction:column;
}
        `;
const H5Tag = styled.h5`
        font-family:Poppins,Regular;
        font-weight:600;
        padding-top:1rem;
        margin-left:1.5rem;
        @media screen and (min-width:320px) and (max-width:1080px){
          margin - top:5rem;
}
        `;
const TableTag = styled.table`
        background-color:#f8f8f8;
        border:1px solid grey;
        text-decoration:none;
      width:1200px;
      overflow: auto;
        height:max-height;
        justify-content:center;
        align-items:center;
        margin:auto;
        @media screen and (min-width:320px) and (max-width:1080px){
          width:20rem;
          overflow: auto;
}
        `;

        const TableTag1 = styled.table`
        background-color:#f8f8f8;
        border:1px solid grey;
        text-decoration:none;
        width:1200px;
        overflow: auto;
        height:max-height;
        justify-content:center;
        align-items:center;
        margin:auto;
        @media screen and (min-width:320px) and (max-width:1080px){
          width:20rem;
          overflow: auto;
}
        `;

const TdTag1 = styled.td`
        display:flex;
        flex-direction:row;
        width:625px;
        overflow: auto;
        @media screen and (min-width:320px) and (max-width:1080px){
          width:10rem;
          overflow: auto;
}
        `;
const TrTag1 = styled.tr`

        border-bottom:1px solid grey;
        `;
const TdTag2 = styled.td`
        display:flex;
        flex-direction:column;
        padding-left:30px;
   

        `;
const TdTag3 = styled.td`

        
        background-color:#f8f8f8;
        align-items:center;
        width:625px;
        overflow: auto;
        font-family:Poppins,Regular;
        font-weight:600;
        padding-top:1rem;

        @media screen and (min-width:320px) and (max-width:1080px){
          width:10rem;
          overflow: auto;
}
        `;
const TdTag4 = styled.td`
        display:flex;
        flex-direction:row;
        padding-left:20px;
        padding-top:10px;
        `;
const TdTag5 = styled.td`
padding-left:1rem;
        display:flex;
        flex-direction:column;
        `;
const PTag = styled.p`
        font-family:Poppins,Regular;
        font-weight:600;
        padding-top:1rem;
        `;
const PTag1 = styled.p`
        font-family:Poppins,Regular;
        font-weight:600;
        `;
const PTag2 = styled.p`
        margin-top:-1rem;
        font-family:Poppins,Medium;
        font-weight:600;
        font-size:13px;
        `;
const TdTag6 = styled.td`

display:flex;

justify-content:center;
align-items:center;
        `;
const PTag3 = styled.p`
margin-top:-3rem;
     margin-right:5rem;
        margin-left:auto;
        font-family:Poppins,Regular;
        font-weight:600;
        `;


        const PTag4 = styled.p`
       
       

        margin-left:20rem;
        font-family:Poppins,Regular;
        font-weight:600;

        @media screen and (min-width:320px) and (max-width:1080px){
margin:auto;
        }
        `;

const InputContainer = styled.div`
        display:flex;
        flex-direction:row;
        margin-left:auto;
        font-family:"Poppins,Regular";
        font-weight:600;
        padding-top:1rem;
        margin-right:1.5rem;
        // border:1px solid #4E8FAE;
        box-shadow: 3px 6px 9px #00000005;
        @media screen and (min-width:320px) and (max-width:1080px){

        }
        `;
const Icon = styled.div`
        height: 3rem;
        width: 2rem;
        background-color: #F2F3F4;
        display:flex;
        flex-direction:row
        justify-content: center;
        align-items: center;
        border-top-left-radius:10px;
        border-bottom-left-radius:10px;
        svg{
          color:#555555;
}
        `;
const Input = styled.input`
        justify-content: center;
        height: 3rem;
        width: 25rem;
        border:0px solid #4E8FAE;
        background-color:#F2F3F4;
        font-family:Poppins,Medium;
        font-Weight:600;
        border-top-right-radius:10px;
        border-bottom-right-radius:10px;
        &:focus{
          border:none;
        outline:none;
}
        @media screen and (min-width:320px) and (max-width:1080px){
          width: 15rem;
}
        `;
const Numbers = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        align-items:center;
        justify-content:center;
        width:7rem;
}
        `;

        const Row =styled.div`
background-color:white;
height:max-content;
padding-bottom:1rem;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  justify-content:center;
 flex-direction:column;
 align-items:center;
width:100%;
}
`;


const Num2 = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        margin:auto;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        width:100%;
}
        `;

export default Providerdashboard;