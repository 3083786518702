import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { NavHashLink } from "react-router-hash-link";
import { useSelector } from "react-redux";
import { useAuthToken } from "../TokenContext";
import { useIntl } from "react-intl";
import star from "./assets/star.png";
import defaultimg from './assets/defaultimge.png';

// Constants
const ITEMS_PER_PAGE = 3;
const PAGE_NUMBER_LIMIT = 5;

const FILTER_OPTIONS = {
  timezone: [
    { value: "Indian standard time", label: "Indian standard time" },
    { value: "Eastern standard time", label: "Eastern standard time" },
    { value: "Central standard time", label: "Central standard time" },
    { value: "Mountain standard time", label: "Mountain standard time" },
    { value: "Pacific standard time", label: "Pacific standard time" }
  ],
  podSize: ["7", "5", "2"],
  podType: [
    { value: "Saas", label: "Saas" },
    { value: "Web app", label: "Web app" },
    { value: "Java Developer", label: "Java Developer" }
  ],
  location: [
    { value: "usa", label: "Asia" },
    { value: "india", label: "North America" },
    { value: "canada", label: "Latin America" }
  ]
};

// Reusable Filter Select Component
const FilterSelect = ({ value, onChange, options, defaultLabel }) => (
  <td>
    <select
      className="options"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      <option value="default">{defaultLabel}</option>
      {options.map((option, index) => (
        <option 
          key={index} 
          value={typeof option === 'string' ? option : option.value}
          style={{ color: "black" }}
        >
          {typeof option === 'string' ? option : option.label}
        </option>
      ))}
    </select>
  </td>
);

// Pod Card Component
const PodCard = ({ data, user, intl }) => (
  <Div>
    <li>
      <div className="pod-header">
        <div className="name">
          <img
            src={data.picture ? `https://globldataprod.s3.amazonaws.com/${data.picture}` : defaultimg}
            alt=""
            style={{ width: 50, height: 50, borderRadius: "50px" }}
          />
          <p className="firstname">
            <img width="18px" height="18px" src={star} alt="star" />
            {data.avgrating ?? 0}
          </p>
        </div>
        <div>
          <TD1 style={{ color: data.partner_code === user.data[0].partner_code ? "blue" : "red" }}>
            {intl.formatMessage({ id: data.partner_code === user.data[0].partner_code ? "internalPod" : "externalPod" })}
          </TD1>
        </div>
      </div>

      <PodInfoRow label={intl.formatMessage({ id: "podName" })} value={data.podName} />
      <PodInfoRow label={intl.formatMessage({ id: "email" })} value={data.email} />
      <PodInfoRow label={intl.formatMessage({ id: "country" })} value={data.location} />
      <PodInfoRow label={intl.formatMessage({ id: "podSize" })} value={data.podsize} />
      <PodInfoRow 
        label={intl.formatMessage({ id: "podrate" })} 
        value={user.data[0].manager_type !== "PartnerClient" ? data.pototalrate : data.pod_rate} 
      />
      <PodInfoRow label={intl.formatMessage({ id: "podType" })} value={data.podtype} />

      <NavHashLink to={`/FindPodDetailsClient/${data.id}`} smooth>
        <DetailsButton>{intl.formatMessage({ id: "viewPodDetails" })}</DetailsButton>
      </NavHashLink>
    </li>
  </Div>
);

// Pod Info Row Component
const PodInfoRow = ({ label, value }) => (
  <div className="name2">
    <tr className="TR">
      <td className="TD">{label}</td>
      <td>:</td>
      <TD1>{value}</TD1>
    </tr>
  </div>
);

// Pagination Component
const Pagination = ({ currentPage, pages, handlePrevbtn, handleNextbtn, renderPageNumbers, intl }) => (
  <ol className="numbers">
    <Num2>
      <li>
        <button onClick={handlePrevbtn} disabled={currentPage === pages[0]}>
          {intl.formatMessage({ id: "prev" })}
        </button>
      </li>
      <Numbers>{renderPageNumbers}</Numbers>
      <li>
        <button onClick={handleNextbtn} disabled={currentPage === pages[pages.length - 1]}>
          {intl.formatMessage({ id: "next" })}
        </button>
      </li>
    </Num2>
  </ol>
);

const FindPodsPartnerGuest = () => {
  const token = useAuthToken();
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);

  // State Management
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(PAGE_NUMBER_LIMIT);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [getData, setGetData] = useState([]);
  const [filters, setFilters] = useState({
    timezone: "default",
    podSize: "default",
    podType: "default",
    location: "default"
  });

  // Fetch Data
  const fetchPods = async () => {
    const body = {
      partner_code: user.data[0].partner_code,
      podlocation: filters.location !== "default" ? filters.location : "",
      podtype: filters.podType !== "default" ? filters.podType : "",
      podSize: filters.podSize !== "default" ? filters.podSize : "",
      timezone: filters.timezone !== "default" ? filters.timezone : ""
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_PARTNER_FINDPODINPARTNERGUEST,
        {
          method: 'POST',
          headers: {
            Authorization: token,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(body)
        }
      );
      const data = await response.json();
      setGetData(data.data);
    } catch (err) {
      console.error("Error fetching pods:", err);
    }
  };

  useEffect(() => {
    fetchPods();
  }, [token]);

  // Pagination Logic
  const pages = [...Array(Math.ceil(getData.length / ITEMS_PER_PAGE))].map((_, i) => i + 1);
  const currentItems = getData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const handleNextbtn = () => {
    setCurrentPage(curr => curr + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(max => max + PAGE_NUMBER_LIMIT);
      setMinPageNumberLimit(min => min + PAGE_NUMBER_LIMIT);
    }
  };

  const handlePrevbtn = () => {
    setCurrentPage(curr => curr - 1);
    if ((currentPage - 1) % PAGE_NUMBER_LIMIT === 0) {
      setMaxPageNumberLimit(max => max - PAGE_NUMBER_LIMIT);
      setMinPageNumberLimit(min => min - PAGE_NUMBER_LIMIT);
    }
  };

  const renderPageNumbers = pages.map(number => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage === number ? "active" : null}
        >
          {number}
        </li>
      );
    }
    return null;
  });

  return (
    <Return>
      <div>
        <Headingtext1>{intl.formatMessage({ id: "findBestPod" })}</Headingtext1>

        <Pagination
          currentPage={currentPage}
          pages={pages}
          handlePrevbtn={handlePrevbtn}
          handleNextbtn={handleNextbtn}
          renderPageNumbers={renderPageNumbers}
          intl={intl}
        />

        <Main>
          <Table>
            <Row>
              <Row1>
                <FilterSelect
                  value={filters.timezone}
                  onChange={(value) => setFilters(prev => ({ ...prev, timezone: value }))}
                  options={FILTER_OPTIONS.timezone}
                  defaultLabel="Time Zone"
                />
                <FilterSelect
                  value={filters.podSize}
                  onChange={(value) => setFilters(prev => ({ ...prev, podSize: value }))}
                  options={FILTER_OPTIONS.podSize}
                  defaultLabel="Pod Size"
                />
                <FilterSelect
                  value={filters.podType}
                  onChange={(value) => setFilters(prev => ({ ...prev, podType: value }))}
                  options={FILTER_OPTIONS.podType}
                  defaultLabel="Pod Type"
                />
              </Row1>

              <Row2>
                <FilterSelect
                  value={filters.location}
                  onChange={(value) => setFilters(prev => ({ ...prev, location: value }))}
                  options={FILTER_OPTIONS.location}
                  defaultLabel="Location"
                />
              </Row2>

              <tr>
                <td>
                  <button
                    className="optionsaplly"
                    onClick={fetchPods}
                    style={{
                      width: "10rem",
                      height: "2rem",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      backgroundColor: "#1DBF73",
                      boxShadow: "1px 6px 9px #00000029",
                      fontFamily: "Poppins,Regular",
                      fontWeight: "500",
                    }}
                  >
                    Apply Filters
                  </button>
                </td>
              </tr>
            </Row>
          </Table>
        </Main>

        <Cards>
          {currentItems && currentItems.length > 0 ? (
            currentItems.map((data, i) => (
              <OL1 key={i}>
                <PodCard data={data} user={user} intl={intl} />
              </OL1>
            ))
          ) : (
            <p style={{ textAlign: "center" }}>
              {intl.formatMessage({ id: "noPods" })}
            </p>
          )}
        </Cards>
      </div>
    </Return>
  );
};

// Styled Components remain the same...

export default FindPodsPartnerGuest;


const OL1 = styled.ol`
  list-style: none;
  margin: auto;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
  }
`;

const Return = styled.ol`
  margin: auto;

  margin-top: 5rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
  }
`;

const Div = styled.div`
  margin-top: 5rem;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  margin-left: 4rem;
  height: 38rem;
  width: 25rem;
  border-radius: 20px;
  margin-bottom: 5rem;
  background-color: #caf0f8;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    margin: auto;
    width: 20rem;
    height: max-height;
    margin-top: 5rem;
  }
`;

const Cards = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;

    flex-direction: column;
    align-items: center;
    margin: auto;
    justify-content: center;
  }
`;

const TD1 = styled.td`
  margin-left: 2rem;
  width: 12rem;
  overflow-wrap: anywhere;
  font-weight: 600;
  font-family: Poppins, medium;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    margin: auto;
    margin-left: 2rem;
    width: 7rem;
    overflow-wrap: anywhere;
  }
`;

const Num2 = styled.li`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;

    flex-direction: column;

    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
  }
`;

const Numbers = styled.li`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7rem;
  }
`;

const DetailsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 5px;

  color: white;
  border: none;
  border-radius: 5px;
  background-color: #1dbf73;
  box-shadow: 1px 3px 6px #00000029;

  margin-top: 4rem;
  margin-bottom: 2rem;
`;
const CreateButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding: 5px;
  width: 10rem;
  height: 3rem;
  font-size: 18px;
  font-weight: 600;
  color: white;
  border: none;
  border-radius: 5px;
  background-color: #1dbf73;
  box-shadow: 1px 3px 6px #00000029;

  margin-top: 4rem;
  margin-bottom: 2rem;
`;

const Headingtext1 = styled.h3`
  font-family: Poppins, Medium;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 5rem;
  font-weight: 600;
  font-size: 1.5rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    margin: auto;
    width: 20rem;

    padding-bottom: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 7rem;
  }
`;

const Main = styled.tr`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    width: 100%;
  }
`;
const Table = styled.table`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    width: 20rem;
    height: max-content;
  }
`;
const Row = styled.div`
  background-color: white;
  height: max-content;
  padding-bottom: 1rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;
const Row1 = styled.tr`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    // margin:auto;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;
const Row2 = styled.tr`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    // margin:auto;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;
