import React from 'react'
import { Link } from 'react-router-dom';
import '../components/css/footer.css';

export default function Footer() {
  return (
    <footer className="footer">
           <div style={{ "height" : "350px", "backgroundColor" : "#00B4D8", "paddingTop" : "40px"}}>

             <div className='row'>

             <div class="col-xl-4 col-lg-6 col-md-6 mb-30">
                        <div class="footer-widget">
                                                   <ul>
                                <li><a href="#">Search Terms</a></li>
                                <li><a href="#">Privacy and Cookie Policy</a></li>
                                <li><a href="#">Advacned Search</a></li>
                                <li><a href="#">Orders and Returns</a></li>
                                <li><a href="#">Contact Us</a></li>
                           
                            </ul>
                        </div>
                    </div>

                    <div class="col-xl-8 col-lg-6 col-md-6 mb-30">
                        <div class="footer-widget">
                                            
                        <form>
          
          <div className="field" style={{ "display" : "flex" }}>
              <p className="control">
                <i className='fa fa-mail'></i>
                <input 
                  type="text"
                  id="username"
                  aria-describedby="userNameHelp"
                  placeholder="Enter your email"
                
                  style={{ 
                    "width": "408px",
                    "height": "54px",
                   
                    "border":" 1px solid #B7B7B7",
                    "border-radius": "10px",
                    "opacity": 1 }}
                />
              </p>
              <button className="login" style={{ "backgroundColor" : "white", "color" : "grey ", "padding": "15px", marginLeft : "10px" }} >
                  Subscribe
                </button>
            </div>
          
            </form>
                        </div>
                    </div>

                  

             </div>
             
      
      </div>
      <div>
        <p style={{"textAlign": "center", "backgroundColor" : "black", "padding" : "10px", "color" : "white"}}>
        Copyright © 2014-present Digitivy Technology Solutions, Inc. All rights reserved.
        </p>
      </div>
    </footer>
  )
}
