import React, { useState } from 'react';
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import styled from 'styled-components';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import config from '../../config.json';
const ForgotPassword = () => {


  const config = {
  
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
  }

  const { isLoggedIn } = useSelector(state => state.auth);

  const [isValidEmail, setIsValidEmail] = useState(true);
  const [state, setState] = useState({
    email: "",
    errors: {
      cognito: null,
      blankfield: false
    }
  })

  const clearErrorState = () => {
    setState({
      errors: {
        cognito: null,
        blankfield: false
      }
    });
  }

  const history = useHistory();

  const forgotPasswordHandler = async event => {
    event.preventDefault();
  
    // Form validation
    clearErrorState();
    const error = Validate(event, state);
    if (error) {
      setState({
        errors: { ...state.errors, ...error }
      });
      return;
    }
  
    // AWS SDK configuration
    const AWS = require('aws-sdk');

    AWS.config.update({
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESSKEYID,
      secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
    });
  
    // AWS Cognito integration here
    try {
      const cognito = new CognitoIdentityServiceProvider({ region: 'us-east-1' });
  
      // Check if the email exists in the user pool
      const listUsersParams = {
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        Filter: `email = "${state.email}"`
      };
  
      cognito.listUsers(listUsersParams, (error, listUsersResponse) => {
        if (error) {
          console.error(error);
          return;
        }
  
       // console.log('userresponse', listUsersResponse);
        const userExists = listUsersResponse.Users.length > 0;
  
        if (userExists) {
          // If the user exists, proceed with the forgot password flow
          Auth.forgotPassword(state.email)
            .then(forget => {
              //console.log(forget);
              localStorage.setItem("Forgetemail", state.email);
              history.push('/forgotpasswordverification');
            })
            .catch(forgotPasswordError => {
              console.error(forgotPasswordError);
            });
        } else {
          setState({
            errors: {
              ...state.errors,
              invalidEmail: true
            }
          });
        }
      });
    } catch (error) {
      // Handle errors
      setState({
        errors: {
          ...state.errors,
          invalidEmail: true
        }
      });
      console.error(error);
    }
  };
  


  const onInputChange = event => {
    const { id, value } = event.target;

    // Check if the entered email is valid
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setIsValidEmail(isValid);

    setState({
      ...state,
      [id]: value,
      errors: {
        ...state.errors,
        invalidEmail: false // Reset the invalidEmail error when the user enters new input
      }
    });

    document.getElementById(id).classList.remove('is-danger');
  };

  return (
    <div className="container ">
      <MAN2>
        <FormErrors formerrors={state.errors} />
        <br></br>
        <h1 className='register-header' style={{ marginTop: "50px", width: "100%" }}>{isLoggedIn ? 'Reset Password' : 'Forgot Password'}</h1>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <form onSubmit={forgotPasswordHandler}>
          <div className="field">
            <p className="control">
              <InputL
                type="email"
                className={`input2 ${!isValidEmail || state.errors.invalidEmail ? 'is-danger' : ''}`}
                id="email"
                aria-describedby="emailHelp"
                placeholder="Enter E-mail"
                value={state.email}
                onChange={onInputChange}
              />
            </p>
          </div>
          {!isValidEmail && (
            <p className="help is-danger">Please enter a valid email address.</p>
          )}
          {state.errors.invalidEmail && (
            <p className="help is-danger">This email is not registered.</p>
          )}
          <br />
          <br />
          <br />
          <br />
          <div className="field">
            <p className="control">
              <Button disabled={!isValidEmail || state.errors.invalidEmail}>
                Send Code
              </Button>
            </p>
          </div>
        </form>
      </MAN2>
    </div>
  );
}

export default ForgotPassword;

const MAN2 = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 1px 3px 6px #00000029;
  border: 1px solid #d8d8d8;
  border-radius: 16px;
  opacity: 1;
  padding: 30px;
  width: 650px;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    margin: auto;
    width: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    over-flow: hidden;
  }
`;

const InputL = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 420px;
  height: 54px;
  font-family: Poppins, Regular;
  font-weight: 600;
  border: 1px solid #b7b7b7;
  border-radius: 10px;
  opacity: 1;
  margin: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    font-size: 12px;
    width: 215px;
    height: 54px;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 420px;
  height: 50px;
  margin-Top: 40px;
  font-family: Poppins, Medium;
  font-weight: 600;
  top: 869px;
  left: 757px;
  width: 407px;
  height: 54px;
  border-radius: 10px;
  opacity: 1;
  background-color: #1dbf73;
  color: white;
  border: 0px;
  box-shadow: -15px -15px 25px #ffffff;
  border-radius: 10px;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 208px;
    height: 54px;
  }
`;
