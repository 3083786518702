import styled from 'styled-components';
import React  from 'react'
import Badge from './Badge';
import Avatar from "./assets/MaskGroup.png";
import {cardShadow,hoverEffect,themeColor} from '../utils';
import { useState } from 'react';

import ShowMore from 'react-show-more-button/dist/components/ShowMore';
import { NavHashLink } from 'react-router-hash-link';

import ViewPostedProReq from './ViewPostedProReq';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import allergens from './allergens';
import OptionsOutsideSelect from '../OptionsOutsideSelect';
import Select from "react-select";
import { useParams,useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import MultiSelect from 'react-select';
import{FiSearch} from 'react-icons/fi';
import { useAuthToken } from "../TokenContext";
const EditPostRequirementCand = () => {
  const token = useAuthToken();

  const [name, setName] = useState(0);
const [position,setPosition]=useState("");
const [position1,setPosition1]=useState("");
const [budget,setBudget]=useState("");
const [budget1,setBudget1]=useState("");
const [workhours,setWorkhours]=useState("");
const [timezone,setTimezone]=useState("");

  const [selected, setSelected] = useState([]);
  const [skills,setSkills]=useState([]);
  const [edit,setEdit]=useState([]);
const { isLoggedIn, user } = useSelector(state => state.auth);

 







// const [requirementdata,setRequirementData] = useState({
    
//     position : "",
//     skills :  "", 
// budget : " ",
// workhours : "",
// timezone : "",
// id : 50,
    
//    } );


   
   const handleSubmit = (e) => {
    e.preventDefault();

    let selectedvalue = []
    selected.map((item)=>{

  selectedvalue.push({"skill":item.label})

    })
    

    var abc = {
      position : position,
      skills : selectedvalue,
 budget : budget,
  workhours : workhours,
  timezone : timezone,
  id : id
  }


  // https://1r65c761gf.execute-api.us-east-1.amazonaws.com/dev/editpostreq
    fetch(
     process.env.REACT_APP_TALENT_EDITPOSTREQ,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token, 
        },
        body: JSON.stringify(abc),
      }
    ).then(() => {
      
      alert("Project Details Added.")
      window.location.href = "/ViewPostedProReq"
    });
  };

const params =useParams();
const history=useHistory();





const {id}=useParams();


const getDetails=async()=>{
  const headers = {
    'Authorization' : token, // Replace with your actual token
    'Content-Type': 'application/json', // You can add more headers as needed
  };

  // https://7cc7r0t0fi.execute-api.us-east-1.amazonaws.com/dev/getpostreqbyid
axios.post(process.env.REACT_APP_TALENT_GETPOSTREQBYID,{id:id},{headers:headers})

.then((res)=>
{ 
 

  setName(res.data.data[0].id)

var kks = JSON.parse(res.data.data[0].req_skills)
var ab = kks


var collas=[]
ab.map(function(item){

  
  var selectvaluefromallergens = allergens.filter((element) => element.label == item.skill)
  // console.log(selectvaluefromallergens,"--->selectvaluefromallergens")
  if(selectvaluefromallergens.length>0)
   collas.push(selectvaluefromallergens[0])
})
setSelected(collas);







setPosition(res.data.data[0].position);

setBudget(res.data.data[0].budget);

setWorkhours(res.data.data[0].workhours);

setTimezone(res.data.data[0].req_timezone);

}
)
      .catch((err)=>{
        console.log("catch",err);
      })
    }

useEffect(()=>{
  console.warn(params);
  getDetails();
 },[token]);


    


//   const onInputChange = (e:any) => {
// setPosition(e.target.value);





//   };
 

  const onInputChangeworkhours =(e)=>{
    setWorkhours(e.target.value);
  }
  
  const onInputChangetimezone =(e)=>{
    setTimezone(e.target.value);
  }

  const onInputChangebudget = (e) => {
    setBudget(e.target.value);
   
  };

 
  const handleSelectChange = (values) => {

    // console.log("---------->",values)

    setSelected(values);
    var testskill= [];
    
values.map(function(ext) {
  var c= {
    'skill':ext.label
  };
testskill.push(c)
})




setSkills(testskill);

    
  };

  
  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white",height:'45px', marginTop:'1rem',
  
    
    color:'grey',
    fontWeight:'600',
    paddingLeft:"12px"
  })
   
  };


  return (

   
    <Div >

      

<TitleText>Edit Requirement</TitleText>
<InputContainer>
          
          <Icon>
              <FiSearch/>
              </Icon>
              <Input type="text" placeholder="Search for projects" />
              </InputContainer>
<RecommendProject>


<CardContent>
   
<form  onSubmit={handleSubmit}> 
<CardContent1>

<InputField  placeholder='Required Position*'
type='text'
value={position}
onChange={(e) => setPosition(e.target.value)}



required
/>







                     <OptionsOutsideSelect 
                     onChange={handleSelectChange}
                     isMulti
                     options={allergens}
                   placeholder='Skills'
                     value={selected}
                     styles={colourStyles}
                   
                  

                   />
                




    <SelectField 
    
  
id="workhours"
onChange={onInputChangeworkhours}
value={workhours}
required
    >
    <option   value=""   defaultValue>Preferred Work Hours</option>
    <option  value="Full Time" >Full Time</option>
    <option  value="Part Time" >Part Time</option>
    </SelectField>
    <SelectField 



onChange={onInputChangetimezone}
value={timezone}
required
placeholder="timezone"
>
  <option style={{ color: "black" }} value="" defaultValue>
    Time Zone
  </option>
  <option style={{ color: "black" }} value='Indian Standard time' >Indian Standard time</option>
                    <option style={{ color: "black" }} value='Eastern standard time' >Eastern standard time</option>
                    <option style={{ color: "black" }} value='Central standard time'>Central standard time</option>
                    <option style={{ color: "black" }} value='Mountain standard time '>Mountain standard time </option>
                    <option style={{ color: "black" }} value='Pacific standard time'>Pacific standard time</option>


    </SelectField>

    

<Button type='submit'>Post</Button>
</CardContent1>
</form>


</CardContent>


<SideCard>
<H1>Recommended Pods</H1>
  <SideCardContent>
    
<Row>
 
            <Detail>
              <InfoContainer>
                <img
                  src={Avatar}
                  alt=""
                  style={{ width: 50, height: 50 }}
                ></img>
                <Info>
                  <InfoName>Thomas Martin</InfoName>
                  <InfoUpdate>10 days ago</InfoUpdate>
                </Info>
              </InfoContainer>
            </Detail>
          </Row>
          {/* <Badge content="Design/" /> */}

          <Title>
            Need a designer to form branding essentials for my bussiness

            
          </Title>
          <ProjectInfo>
          Required Position: UX/UI Designer
          </ProjectInfo>

          </SideCardContent>
         <ViewAll>View All</ViewAll>

          
  <SideCardContent1>


          <Title>
          Your Posted Pod Requirements
            
          </Title>
          <View>
            
       <NavHashLink to = '/ViewPostedProReq'  smooth>
       
            View</NavHashLink> 
            
             </View>

          </SideCardContent1>
</SideCard>


</RecommendProject>

     </Div>
  )
}


const Div =styled.div`

// display:flex;
display:inline;
jutify-content:center;
flex-direction:column;
margin-top:6rem;
align-items:center;

margin-left:6.5rem;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  overflow:hidden;
justify-content:center;
align-items:center;
margin:auto;
width:24rem;
  }
`;

const RecommendProject=styled.div`

 display:flex;

jutify-content:center;
flex-direction:row;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;

 
  flex-direction:column;
  

  
    
   }
`;


const TitleText=styled.h4`
font-weight:600;
@media screen and (min-width:320px) and (max-width:1080px){

 
  margin-top:2rem;

 
}
`;
const CardContent=styled.div`
margin:0.4rem;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;


border-radius:15px;
font-family:Poppins,Medium;
width:40rem;
height:max-height;
padding: 1rem;
background-color: #F2F4F5;

box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};
}

@media screen and (min-width:320px) and (max-width:1080px){
  width:20rem;
  margin:auto;
 
  
 }
`;


const CardContent1=styled.div`
margin:0.4rem;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;

@media screen and (min-width:320px) and (max-width:1080px){
  width:20rem;
  margin:auto;
 
  
 }


`;




const InputField=styled.input`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
padding-left:20px;
font-weight:600;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;
 
  
 }

`;


const SelectField=styled.select`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
font-weight:600;
color:grey;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;
 
  
 }

`;

const SelectField1=styled.div`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
font-weight:600;
color:grey;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;
 
  
 }
`;


const Button=styled.button`
margin-top:1rem;
border:none;
border-radius: 10px;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
width:20rem;
height:3rem;
font-size:18px;
font-weight:600;
color:white;
margin-top:4rem;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;
 
  
 }
`;




const SideCard=styled.div`
margin-left:5rem;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
border-radius:20px;
font-family:Poppins,Medium;
width:20rem;
height:32rem;

padding: 1rem;
background-color: #F2F4F5;

box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};
}
@media screen and (min-width:320px) and (max-width:1080px){
margin:auto;
margin-top:5rem;
 
  
 }

`;


const SideCardContent=styled.div`
background-color:#90E0EF;
width:17rem;
display:flex;
flex-direction:column;
padding:3px;


border-radius:20px;
`;


const SideCardContent1=styled.div`
background-color:#90E0EF;
width:17rem;
display:flex;
margin-top:1rem;
flex-direction:column;
height:10rem;
justify-content:center;
align-items:center;
border-radius:20px;
`;





const Detail = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const Row = styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
`;
const InfoName = styled.h6`
  font-weight: 600;
`;
const InfoUpdate = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;

const Title = styled.h6`
  font-weight: 600;
`;
const ProjectInfo = styled.p`
padding:5px;
margin:3px;
background-color:white;
border-radius:5px  ;
font-size:13px;
color:black;
font-weight:600;
margin-bottom:1rem
`;

const ViewAll =styled.button`
width:5rem;
font-weight:600;
height:2rem;
margin-left:auto;
margin-top:1rem;
border: 2px solid #1DBF73;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;

const View =styled.button`
width:8rem;
font-weight:600;
height:2rem;
color:black;
background-color:white;
border-radius:5px  ;
margin-bottom:1rem;
margin-top:1rem;
border: none;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;

const H1 = styled.h1`
font-size:22px;
font-weight:600;
margin-bottom:1.2rem;
`;


const InputContainer = styled.div`
display : flex;
border-radius:10px;
justify-content: center;
margin-left:auto;
width:25rem;
margin-top:-2rem;
margin-bottom:1rem;
border:1px solid #4E8FAE;
box-shadow: 3px 6px 9px #00000005;
@media screen and (min-width:320px) and (max-width:1080px){
  margin:auto;
  margin-bottom:2rem;
  margin-top:2rem;
  width: 18rem;
 
}
`;

const Icon = styled.div`
height: 3rem;
width: 2rem;
background-color: #F2F3F4;
display:flex;
flex-direction:row
justify-content: center;
align-items: center;
border-top-left-radius:10px;
border-bottom-left-radius:10px;
svg{
    color:#555555;
}
`;

const Input = styled.input`
justify-content: center;
height: 3rem;
width: 25rem;
border:0px solid #4E8FAE;
background-color:#F2F3F4;
font-family:Poppins,Medium;
font-Weight:600;
border-top-right-radius:10px;
border-bottom-right-radius:10px;
&:focus{
    border:none;
    outline:none;
}
@media screen and (min-width:320px) and (max-width:1080px){
  width: 18rem;
}
`;



export default EditPostRequirementCand;















