// intl.js
import { createIntl } from 'react-intl';
import messagesEn from './locales/en/messages';
import messagesFr from './locales/fr/messages';
import messagesEs from './locales/es/messages';

const locales = {
  en: messagesEn,
  fr: messagesFr,
  es: messagesEs,
};

const defaultLocale = 'en';
const currentLocale = defaultLocale;

const intl = createIntl({ locale: currentLocale, messages: locales[currentLocale] });

export { intl, locales, defaultLocale, currentLocale };
