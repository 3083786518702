import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Paper,
  Tabs,
  Tab,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Avatar,
  IconButton,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
  styled,
  CircularProgress,
  Alert
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ProfileVisibilityMUI from '../ProfileSettings';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LinkIcon from '@mui/icons-material/Link';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "./action/auth";
import { updateProfilecandidate } from "./action/auth";
import S3FileUpload from 'react-s3';
import AWS from 'aws-sdk';
import moment from "moment";
import defaultimg from './assets/defaultimge.png';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import LanguageSelector from '../LanguageSelector';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import axios from 'axios';

// Styled components
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 100,
  height: 100,
  margin: theme.spacing(2),
  border: `2px solid ${theme.palette.primary.main}`,
  cursor: 'pointer'
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  minWidth: 'auto',
  padding: theme.spacing(1, 2),
  '&.Mui-selected': {
    color: theme.palette.primary.main,
  }
}));

const FileUploadButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2)
}));

// Configuration
const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
};

const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
};

// Constants
const area = [
  { value: 'Node', label: 'Node' },
  { value: 'Java', label: 'Java' },
  { value: 'React', label: 'React' },
  { value: 'Python', label: 'Python' },
  { value: 'AWS', label: 'AWS' },
  { value: 'C++', label: 'C++' }
];

const country = [
  { value: 'usa', label: 'USA' },
  { value: 'canada', label: 'Canada' },
  { value: 'india', label: 'India' }
];

const jobTitleOptions = [
  { value: 'UI/UX Designer', label: 'UI/UX Designer' },
  { value: 'Software Engineer', label: 'Software Engineer' },
  { value: 'Full-Stack Developer', label: 'Full-Stack Developer' },
  { value: 'Front-End Developer', label: 'Front-End Developer' },
  { value: 'Back-End Developer', label: 'Back-End Developer' },
  { value: 'Mobile Developer', label: 'Mobile Developer' },
  { value: 'QA Engineer', label: 'QA Engineer' },
  { value: 'Scrum Master', label: 'Scrum Master' },
  { value: 'Product Owner', label: 'Product Owner' },
  { value: 'Product Manager', label: 'Product Manager' },
  { value: 'Project Manager', label: 'Project Manager' }
];

const work_authorization = [
  { value: 'US Citizen', label: 'US Citizen'},
  { value: 'Permanent Resident', label: 'Permanent Resident'},
  { value: 'EAD', label: 'EAD'},
  { value: 'OPT-EAD', label: 'OPT-EAD'},
  { value: 'H4-EAD', label: 'H4-EAD'},
  { value: 'H1B', label: 'H1B'},
  { value: 'TN', label: 'TN'},
  { value: 'Not Applicable (NA)', label: 'Not Applicable (NA)'}
];

const techStack = [
  { value: 'MEAN', label: 'MEAN' },
  { value: 'MERN', label: 'MERN' },
  { value: 'LAMP', label: 'LAMP' },
  { value: 'MEVN', label: 'MEVN' }
];

const industry = [
  { value: 'Healthcare', label: 'Healthcare' },
  { value: 'IT & Software', label: 'IT & Software' },
  { value: 'Banking and Financial', label: 'Banking and Financial' },
  { value: 'Services', label: 'Services' }
];

const Updateyourprofile = ({ currentLocale, changeLocale }) => {
  const token = useAuthToken();
  const intl = useIntl();
  const { isLoggedIn, user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  // Initialize AWS
  AWS.config.update(S3Config);
  const s3 = new AWS.S3();

  // State management
  const [tabValue, setTabValue] = useState(0);
  const [edit, setEdit] = useState(false);
  const [image, setImage] = useState("");
  const [authImage, setAuthImage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [resume, setResume] = useState("");
  const [assesment, setAssesment] = useState("");
  const [pod, setPod] = useState([]);
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Initial arrays
  const inputArr = [{
    degree: "",
    domain: "",
    institution: "",
    startDate: "",
    candidateID: user?.data?.[0]?.c_id || "",
    endDate: "",
    id: ""
  }];

  const expArray = [{
    work_organization: "",
    work_position: "",
    workStartdate: "",
    workEnddate: "",
    present: 0,
    candidateID: user?.data?.[0]?.c_id || "",
    id: ""
  }];

  const certificationArray = [{
    cert_name: "",
    candidateID: user?.data?.[0]?.c_id || "",
    startDate: "",
    endDate: "",
    certUrl: "",
    certificationInstitution: "",
    id: ""
  }];

  // State management for arrays
  const [arrexp1, setArrexp1] = useState(inputArr);
  const [arrCert, setArrCert] = useState(certificationArray);
  const [userexp, setUserexp] = useState(expArray);

  // Main state object with proper initialization
  const [state, setState] = useState({
    fname: "",
    mname: "",
    lname: "",
    candidateemail: "",
    area: "",
    github_repo_name: "",
    github_username: "",
    jira_username: "",
    sonarqube_project_key: "",
    country: "",
    job_title: "",
    primaryskill: "",
    portfolio: "",
    linkedin: "",
    github: "",
    industry: "",
    about: "",
    techStack: "",
    email: "",
    resume: "",
    coding_assessment_url: "",
    work_authorization: "",
    timezone: "",
    availability: "",
    hoursperweek: "",
    currentStatus: "",
    availabledate: "",
    profilePicture: "",
    calendlyurl: "",
    talent_code: "",
    ratePerhour: "",
    ratePermonth: "",
    currency: "",
    workhours: ""
  });

  // Improved input handlers
  const onInputChange = e => {
    const { id, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const onInputChangeselect = e => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Updated array handlers with proper state updates
  const addInput = () => {
    setArrexp1(prev => [...prev, { ...inputArr[0] }]);
  };

  const addExp = () => {
    setUserexp(prev => [...prev, { ...expArray[0] }]);
  };

  const addCert = () => {
    setArrCert(prev => [...prev, { ...certificationArray[0] }]);
  };

  const handleChangeExp = (e, idx, field) => {
    const value = field === 'present' ? (e.target.checked ? 1 : 0) : e.target.value;
    setUserexp(prev => {
      const newExp = [...prev];
      newExp[idx] = {
        ...newExp[idx],
        [field]: value
      };
      return newExp;
    });
  };

  const handleChangeCert = (e, idx) => {
    const { id, value } = e.target;
    setArrCert(prev => {
      const newCert = [...prev];
      newCert[idx] = {
        ...newCert[idx],
        [id]: value
      };
      return newCert;
    });
  };

  const handleChange = (e, idx) => {
    const { id, value } = e.target;
    setArrexp1(prev => {
      const newEdu = [...prev];
      newEdu[idx] = {
        ...newEdu[idx],
        [id]: value
      };
      return newEdu;
    });
  };

  // File upload handlers with proper error handling
  const onFileUpload = async e => {
    try {
      setUploading(true);
      const file = e.target.files[0];
      if (!file) return;
      
      const data = await S3FileUpload.uploadFile(file, config);
      setState(prevState => ({
        ...prevState,
        resume: data.key
      }));
    } catch (err) {
      console.error(err);
      setError('Failed to upload resume');
    } finally {
      setUploading(false);
    }
  };

  const onFileUploadassesment = async e => {
    try {
      setUploading(true);
      const file = e.target.files[0];
      if (!file) return;

      const data = await S3FileUpload.uploadFile(file, config);
      setState(prevState => ({
        ...prevState,
        coding_assessment_url: data.key
      }));
    } catch (err) {
      console.error(err);
      setError('Failed to upload assessment');
    } finally {
      setUploading(false);
    }
  };

  const onImageUpload = async e => {
    try {
      const file = e.target.files[0];
      if (!file) return;

      setImage(URL.createObjectURL(file));
      const data = await S3FileUpload.uploadFile(file, config);
      setState(prevState => ({
        ...prevState,
        profilePicture: data.key
      }));
    } catch (err) {
      console.error(err);
      setError('Failed to upload profile picture');
    }
  };

  // Toggle edit with proper state management
  const toggleEdit = (force = null) => {
    setEdit(prev => force !== null ? force : !prev);
    setError(null);
  };

  // Tab handling
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Portfolio handlers
  const addNewPortfolioItem = () => {
    setPortfolioItems(prev => [
      ...prev,
      {
        project_objective: '',
        project_description: '',
        industry: '',
        project_files: []
      }
    ]);
  };

  const handlePortfolioItemChange = (index, field, value) => {
    setPortfolioItems(prev => {
      const updatedItems = [...prev];
      updatedItems[index] = {
        ...updatedItems[index],
        [field]: value
      };
      return updatedItems;
    });
  };

  // File handlers for portfolio
  const handleImageChange = async (index, files) => {
    try {
      const updatedItems = [...portfolioItems];
      const imageUrls = await Promise.all(
        Array.from(files).map(async (file) => {
          const imageKey = `portfolio-images/${uuidv4()}.png`;
          const uploadParams = {
            Bucket: process.env.REACT_APP_BUCKETNAME,
            Key: imageKey,
            Body: file,
            ContentType: 'image/png',
          };
          const uploadResult = await s3.upload(uploadParams).promise();
          return uploadResult.Location;
        })
      );
      updatedItems[index].project_files = imageUrls;
      setPortfolioItems(updatedItems);
    } catch (error) {
      console.error('Error uploading images:', error);
      setError('Failed to upload portfolio images');
    }
  };

  // Portfolio update handler
  const handleUpdatePortfolio = async () => {
    try {
      const portfolioUserId = user?.data?.[0]?.type === 'Provider' 
        ? user.data[0].id 
        : user.data[0].c_id;
  
      const payload = portfolioItems.map(item => ({
        portfolio_id: item.portfolio_id || '',
        portfolio_user_id: portfolioUserId,
        project_objective: item.project_objective,
        project_description: item.project_description,
        industry: item.industry,
        project_files: item.project_files,
      }));
  
      const response = await axios.put(
        process.env.REACT_APP_TALENT_EDITPORTFOLIO,
        { educationdata: payload, type: "PORT" },
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.status !== 200) {
        throw new Error('Failed to update portfolio');
      }
  
      return response.data;
    } catch (error) {
      console.error('Error updating portfolio:', error);
      throw new Error('Failed to update portfolio');
    }
  };
  
  // Form submission handler
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
  
    try {
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json'
      };
  
      // First, create the profile data payload
      const profileData = {
        id: user.data[0].c_id,
        firstname: state.fname,
        middlename: state.mname,
        lastname: state.lname,
        industry: state.industry,
        techStack: state.techStack,
        primaryskill: state.primaryskill,
        secondaryskill: state.secondaryskill,
        country: state.country,
        job_title: state.job_title,
        linkedinurl: state.linkedin,
        githuburl: state.github,
        workhours: state.workhours,
        portfoliourl: state.portfolio,
        about: state.about,
        profilePicture: state.profilePicture,
        resume: state.resume,
        coding_assessment_url: state.coding_assessment_url,
        work_authorization: state.work_authorization,
        timezone: state.timezone,
        calendlyurl: state.calendlyurl,
        talent_code: state.talent_code,
        availability: state.availability,
        hoursperweek: state.hoursperweek,
        currentStatus: state.currentStatus,
        availabledate: state.availabledate,
        ratePerhour: state.ratePerhour,
        ratePermonth: state.ratePermonth,
        currency: state.currency,
        github_repo_name: state.github_repo_name,
        github_username: state.github_username,
        jira_username: state.jira_username,
        sonarqube_project_key: state.sonarqube_project_key
      };
  
      // Update main profile
      await dispatch(updateProfilecandidate(profileData, headers));
  
      // Update education if there's data
      if (arrexp1.length > 0) {
        await axios.post(
          `${process.env.REACT_APP_TALENT_CANDIDATEPROFILETESTUPDATE}`,
          { educationdata: arrexp1, type: "EDU" },
          { headers }
        );
      }
  
      // Update experience if there's data
      if (userexp.length > 0) {
        await axios.post(
          `${process.env.REACT_APP_TALENT_CANDIDATEPROFILETESTUPDATE}`,
          { educationdata: userexp, type: "EXP" },
          { headers }
        );
      }
  
      // Update certifications if there's data
      if (arrCert.length > 0) {
        await axios.post(
          `${process.env.REACT_APP_TALENT_CANDIDATEPROFILETESTUPDATE}`,
          { educationdata: arrCert, type: "CERT" },
          { headers }
        );
      }
  
      // Update portfolio if there's data
      if (portfolioItems.length > 0) {
        const portfolioUserId = user.data[0].type === 'Provider' ? user.data[0].id : user.data[0].c_id;
        
        const portfolioPayload = portfolioItems.map(item => ({
          portfolio_id: item.portfolio_id || '',
          portfolio_user_id: portfolioUserId,
          project_objective: item.project_objective || '',
          project_description: item.project_description || '',
          industry: item.industry || '',
          project_files: item.project_files || []
        }));
  
        // await axios.put(
        //   'https://fl1lf3m25m.execute-api.us-east-1.amazonaws.com/dev/editPortfolio',
        //   { educationdata: portfolioPayload, type: "PORT" },
        //  { headers: {
        //     Authorization: token,
        //     'Content-Type': 'application/json',
        //   },}
        // );
      }
  
      // Refresh user data
      await dispatch(login(user.data[0].email, "candidate"));
  
      alert("Candidate Details Successfully Updated");
      setEdit(false);
  
    } catch (error) {
      console.error('Error updating profile:', error);
      alert("Failed to update candidate details.");
    } finally {
      setLoading(false);
    }
  };
           
          
            // Initial data fetching effect
            useEffect(() => {
              const fetchData = async () => {
                try {
                  // Set initial edit state based on profile
                  if (user?.data?.[0]?.profile === 0) {
                    setEdit(true);
                  }
          
                  // Initialize state with user data
                  setState(prevState => ({
                    ...prevState,
                    fname: user?.data?.[0]?.firstName || "",
                    mname: user?.data?.[0]?.middleName || "",
                    lname: user?.data?.[0]?.lastName || "",
                    area: user?.data?.[0]?.area || "",
                    github_repo_name: user?.data?.[0]?.github_repo_name || "",
                    github_username: user?.data?.[0]?.github_username || "",
                    jira_username: user?.data?.[0]?.jira_username || "",
                    sonarqube_project_key: user?.data?.[0]?.sonarqube_project_key || "",
                    country: user?.data?.[0]?.country || "",
                    job_title: user?.data?.[0]?.job_title || "",
                    primaryskill: user?.data?.[0]?.primaryskill || "",
                    portfolio: user?.data?.[0]?.portfolioUrl || "",
                    linkedin: user?.data?.[0]?.linkedinUrl || "",
                    github: user?.data?.[0]?.githubUrl || "",
                    industry: user?.data?.[0]?.industry || "",
                    about: user?.data?.[0]?.about || "",
                    techStack: user?.data?.[0]?.techStack || "",
                    email: user?.data?.[0]?.email || "",
                    resume: user?.data?.[0]?.resume || "",
                    coding_assessment_url: user?.data?.[0]?.coding_assessment_url || "",
                    work_authorization: user?.data?.[0]?.work_authorization || "",
                    timezone: user?.data?.[0]?.timezone || "",
                    availability: user?.data?.[0]?.availability || "",
                    hoursperweek: user?.data?.[0]?.hoursperweek || "",
                    currentStatus: user?.data?.[0]?.currentStatus || "",
                    availabledate: user?.data?.[0]?.availabledate || "",
                    profilePicture: user?.data?.[0]?.profilePicture || "",
                    calendlyurl: user?.data?.[0]?.calendlyurl || "",
                    talent_code: user?.data?.[0]?.talent_code || "",
                    ratePerhour: user?.data?.[0]?.ratePerhour || "",
                    ratePermonth: user?.data?.[0]?.ratePermonth || "",
                    currency: user?.data?.[0]?.currency || "",
                    workhours: user?.data?.[0]?.workhours || ""
                  }));
          
                  // Fetch profile picture
                  await generatePreSignedPutUrl11();
                  await generateAndSetSignedUrlassessment();
                  await generateAndSetSignedUrl();
          
                  // Fetch candidate data
                  const headers = {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                  };
          
                  const response = await axios.post(
                    process.env.REACT_APP_CLIENT_GETCANDIDATEDATA,
                    { id: user.data[0].c_id },
                    { headers }
                  );
          
                  // Process response data
                  const { podExperience, education, exp, certification } = response.data.data;
          
                  setPod(podExperience);
          
                  if (education?.length > 0) {
                    const educationData = education.map(data => ({
                      degree: data.degree || "",
                      domain: data.domain || "",
                      institution: data.institution || "",
                      startDate: data.startDate || "",
                      candidateID: data.candidateID || "",
                      endDate: data.endDate || "",
                      id: data.id || ""
                    }));
                    setArrexp1(educationData);
                  }
          
                  if (exp?.length > 0) {
                    const experienceData = exp.map(data => ({
                      work_organization: data.work_organization || "",
                      work_position: data.work_position || "",
                      present: data.present || 0,
                      workStartdate: data.workStartdate || "",
                      workEnddate: data.workEnddate || "",
                      candidateID: data.candidateID || "",
                      id: data.id || ""
                    }));
                    setUserexp(experienceData);
                  }
          
                  if (certification?.length > 0) {
                    const certificationData = certification.map(data => ({
                      cert_name: data.cert_name || "",
                      candidateID: data.candidateID || "",
                      startDate: data.startDate ? moment(data.startDate).format("YYYY-MM-DD") : "",
                      endDate: data.endDate ? moment(data.endDate).format("YYYY-MM-DD") : "",
                      certUrl: data.certUrl || "",
                      certificationInstitution: data.certificationInstitution || "",
                      id: data.id || ""
                    }));
                    setArrCert(certificationData);
                  }
          
                } catch (err) {
                  console.error('Error fetching data:', err);
                  setError('Failed to fetch profile data');
                }
              };
          
              if (token && user?.data?.[0]?.c_id) {
                fetchData();
              }
            }, [token, user]);
          
            // Fetch portfolio data
            useEffect(() => {
              const fetchPortfolioData = async () => {
                if (!token || !user?.data?.[0]) return;
          
                try {
                  setLoading(true);
                  const portfolioUserId = user.data[0].type === 'Provider' ? user.data[0].id : user.data[0].c_id;
                  
                  const response = await axios.post(
                    process.env.REACT_APP_TALENT_LISTPORTFOLIO,
                    { portfolio_user_id: portfolioUserId },
                    {
                      headers: {
                        Authorization: token,
                        'Content-Type': 'application/json',
                      }
                    }
                  );
          
                  if (response.data?.body) {
                    setPortfolioItems(
                      response.data.body.length > 0 
                        ? response.data.body 
                        : [{ project_objective: '', project_description: '', industry: '', project_files: [] }]
                    );
                  }
                } catch (error) {
                  console.error('Error fetching portfolio data:', error);
                  setError('Failed to fetch portfolio data');
                } finally {
                  setLoading(false);
                }
              };
          
              fetchPortfolioData();
            }, [token, user]);
          
            // AWS S3 and CloudFront functions
            const generatePreSignedPutUrl11 = async () => {
              try {
                if (!user?.data?.[0]?.profilePicture) return;
          
                const response = await fetch(
                  process.env.REACT_APP_TALENT_CLOUDFRONTAPI,
                  {
                    method: 'POST',
                    headers: {
                      'Authorization': token,
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      url: process.env.REACT_APP_CLOUDFRONT + user.data[0].profilePicture
                    })
                  }
                );
          
                const data = await response.json();
                const signedUrl = JSON.parse(data.body).signedUrl;
                setAuthImage(signedUrl);
              } catch (error) {
                console.error("Error generating signed URL:", error);
                setError('Failed to load profile picture');
              }
            };
          
            const generateAndSetSignedUrl = async () => {
              try {
                if (!state.resume) return;
          
                const response = await fetch(
                  process.env.REACT_APP_TALENT_CLOUDFRONTAPI,
                  {
                    method: 'POST',
                    headers: {
                      'Authorization': token,
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      url: process.env.REACT_APP_CLOUDFRONT + state.resume
                    })
                  }
                );
          
                const data = await response.json();
                const signedUrl = JSON.parse(data.body).signedUrl;
                setResume(signedUrl);
              } catch (error) {
                console.error("Error generating signed URL:", error);
                setError('Failed to load resume URL');
              }
            };
          
            const generateAndSetSignedUrlassessment = async () => {
              try {
                if (!state.coding_assessment_url) return;
          
                const response = await fetch(
                  process.env.REACT_APP_TALENT_CLOUDFRONTAPI,
                  {
                    method: 'POST',
                    headers: {
                      'Authorization': token,
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      url: process.env.REACT_APP_CLOUDFRONT + state.coding_assessment_url
                    })
                  }
                );
          
                const data = await response.json();
                const signedUrl = JSON.parse(data.body).signedUrl;
                setAssesment(signedUrl);
              } catch (error) {
                console.error("Error generating signed URL:", error);
                setError('Failed to load assessment URL');
              }
            };
          
            // Component return
            return (
              <Container maxWidth="lg" sx={{ mt: 15 }}>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h4" component="h1" gutterBottom>
                    {edit 
                      ? (user?.data?.[0]?.profile === 0 
                        ? intl.formatMessage({ id: 'createProfile' })
                        : intl.formatMessage({ id: 'updateProfile' }))
                      : intl.formatMessage({ id: 'manageProfile' })}
                  </Typography>
          
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button
                      component={Link}
                      to="/candidate-dashboard"
                      variant="text"
                      color="primary"
                    >
                      {intl.formatMessage({ id: 'dashboard' })}
                    </Button>
                    <Typography>{">"}</Typography>
                    <Typography>{intl.formatMessage({ id: 'manageProfile' })}</Typography>
                  </Stack>
                </Box>
        
                <Paper elevation={3}>
                <ProfileVisibilityMUI/>
                  <Box sx={{ position: 'relative' }}>
                    <IconButton
                      onClick={() => toggleEdit()}
                      sx={{ 
                        position: 'absolute', 
                        top: 8, 
                        right: 8,
                        zIndex: 2
                      }}
                    >
                      <EditIcon />
                    </IconButton>
          
                    {edit && (
                      <Box sx={{ 
                        position: 'absolute',
                        top: 8,
                        right: 60,
                        zIndex: 2
                      }}>
                        {/* <Button 
                          variant="outlined" 
                          color="secondary" 
                          onClick={() => toggleEdit(false)}
                          size="small"
                        >
                          Cancel
                        </Button> */}
                      </Box>
                    )}
          
                    <Tabs
                      value={tabValue}
                      onChange={handleTabChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      sx={{ borderBottom: 1, borderColor: 'divider' }}
                    >
                      <Tab label={intl.formatMessage({ id: 'basicInformation' })} />
                      <Tab label={intl.formatMessage({ id: 'skillsAndExpertise' })} />
                      <Tab label={intl.formatMessage({ id: 'education' })} />
                      <Tab label={intl.formatMessage({ id: 'workExperience' })} />
                      <Tab label={intl.formatMessage({ id: 'certifications' })} />
                      <Tab label={intl.formatMessage({ id: 'portfolio' })} />
                      <Tab label={intl.formatMessage({ id: 'otherInformation' })} />
                    </Tabs>
          
                    {error && (
                      <Alert severity="error" sx={{ m: 2 }}>
                        {error}
                      </Alert>
                    )}
          
                    <form onSubmit={handleSubmit}>
                      <Box sx={{ p: 3 }}>
                        {tabValue === 0 && (
                          <Card>
                            <CardContent>
                              <Grid container spacing={3}>
                                <Grid item xs={12} textAlign="center">
                                  <Box position="relative" display="inline-block">
                                  <ProfileAvatar
  src={image || authImage || defaultimg}
  alt={state.fname}
  sx={{
    width: 100,
    height: 100,
    objectFit: 'cover'
  }}
/>
                                    {edit && (
                                      <label htmlFor="profile-upload">
                                        <IconButton
                                          component="span"
                                          sx={{
                                            position: 'absolute',
                                            bottom: 0,
                                            rightright: 0,
                                            bgcolor: 'primary.main',
                                            color: 'white',
                                            '&:hover': { bgcolor: 'primary.dark' }
                                          }}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                        <VisuallyHiddenInput
                                          id="profile-upload"
                                          type="file"
                                          onChange={onImageUpload}
                                          accept="image/*"
                                        />
                                      </label>
                                    )}
                                  </Box>
                                </Grid>
          
                                {/* Basic Information Fields */}
                                <Grid item xs={12} md={4}>
                                  <TextField
                                    fullWidth
                                    label={intl.formatMessage({ id: 'firstName' })}
                                    value={state.fname || ""}
                                    onChange={onInputChange}
                                    id="fname"
                                    disabled={!edit}
                                    required
                                  />
                                </Grid>
          
                                <Grid item xs={12} md={4}>
                                  <TextField
                                    fullWidth
                                    label={intl.formatMessage({ id: 'middleName' })}
                                    value={state.mname || ""}
                                    onChange={onInputChange}
                                    id="mname"
                                    disabled={!edit}
                                  />
                                </Grid>
          
                                <Grid item xs={12} md={4}>
                                  <TextField
                                    fullWidth
                                    label={intl.formatMessage({ id: 'lastName' })}
                                    value={state.lname || ""}
                                    onChange={onInputChange}
                                    id="lname"
                                    disabled={!edit}
                                    required
                                  />
                                </Grid>
          
                                <Grid item xs={12} md={6}>
                                  <FormControl fullWidth>
                                    <InputLabel>{intl.formatMessage({ id: 'country' })}</InputLabel>
                                    <Select
                                      value={state.country || ""}
                                      onChange={onInputChangeselect}
                                      name="country"
                                      disabled={!edit}
                                      required
                                    >
                                      {country.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
          
                                {state.country === 'usa' && (
                                  <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                      <InputLabel>Work Authorization</InputLabel>
                                      <Select
                                        value={state.work_authorization || ""}
                                        onChange={onInputChangeselect}
                                        name="work_authorization"
                                        disabled={!edit}
                                        required
                                      >
                                        {work_authorization.map((option) => (
                                          <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                )}
          
                                <Grid item xs={12}>
                                  <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    label={intl.formatMessage({ id: 'about' })}
                                    value={state.about || ""}
                                    onChange={onInputChange}
                                    id="about"
                                    disabled={!edit}
                                    required
                                  />
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        )}
          
                        {tabValue === 1 && (
                          <Card>
                            <CardContent>
                              <Grid container spacing={3}>
                                {/* Skills Section */}
                                <Grid item xs={12} md={6}>
                                  <FormControl fullWidth>
                                    <InputLabel>{intl.formatMessage({ id: 'primarySkill' })}</InputLabel>
                                    <Select
                                      value={state.primaryskill || ""}
                                      onChange={onInputChangeselect}
                                      name="primaryskill"
                                      disabled={!edit}
                                      required
                                    >
                                      {area.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
          
                                <Grid item xs={12} md={6}>
                                  <FormControl fullWidth>
                                    <InputLabel>{intl.formatMessage({ id: 'techStack' })}</InputLabel>
                                    <Select
                                      value={state.techStack || ""}
                                      onChange={onInputChangeselect}
                                      name="techStack"
                                      disabled={!edit}
                                    >
                                      {techStack.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
          
                                <Grid item xs={12} md={6}>
                                  <FormControl fullWidth>
                                    <InputLabel>{intl.formatMessage({ id: 'jobTitle' })}</InputLabel>
                                    <Select
                                      value={state.job_title || ""}
                                      onChange={onInputChangeselect}
                                      name="job_title"
                                      disabled={!edit}
                                      required
                                    >
                                      {jobTitleOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
          
                                <Grid item xs={12} md={6}>
                                  <FormControl fullWidth>
                                    <InputLabel>{intl.formatMessage({ id: 'industry' })}</InputLabel>
                                    <Select
                                      value={state.industry || ""}
                                      onChange={onInputChangeselect}
                                      name="industry"
                                      disabled={!edit}
                                      required
                                    >
                                      {industry.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        )}
                        {/* Education Section */}
{tabValue === 2 && (
  <Box>
    {edit && (
      <Button
        startIcon={<AddIcon />}
        variant="contained"
        onClick={addInput}
        disabled={arrexp1.length >= 5}
        sx={{ mb: 2 }}
      >
        {intl.formatMessage({ id: 'add' })}
      </Button>
    )}

    {arrexp1.map((edu, index) => (
      <Card key={index} sx={{ mb: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>{intl.formatMessage({ id: 'qualification' })}</InputLabel>
                <Select
                  value={edu.degree || ""}
                  onChange={(e) => handleChange(e, index)}
                  id="degree"
                  disabled={!edit}
                >
                  <MenuItem value="">Select Qualification</MenuItem>
                  <MenuItem value="Bachelors">Bachelors</MenuItem>
                  <MenuItem value="Masters">Masters</MenuItem>
                  <MenuItem value="PHD">PHD</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={intl.formatMessage({ id: 'specialization' })}
                value={edu.domain || ""}
                onChange={(e) => handleChange(e, index)}
                id="domain"
                disabled={!edit}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={intl.formatMessage({ id: 'university' })}
                value={edu.institution || ""}
                onChange={(e) => handleChange(e, index)}
                id="institution"
                disabled={!edit}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={intl.formatMessage({ id: 'startDate' })}
                type="date"
                value={edu.startDate || ""}
                onChange={(e) => handleChange(e, index)}
                id="startDate"
                disabled={!edit}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={intl.formatMessage({ id: 'endDate' })}
                type="date"
                value={edu.endDate || ""}
                onChange={(e) => handleChange(e, index)}
                id="endDate"
                disabled={!edit}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    ))}
  </Box>
)}

{/* Work Experience Section */}
{tabValue === 3 && (
  <Box>
    {edit && (
      <Button
        startIcon={<AddIcon />}
        variant="contained"
        onClick={addExp}
        disabled={userexp.length >= 5}
        sx={{ mb: 2 }}
      >
        {intl.formatMessage({ id: 'add' })}
      </Button>
    )}

    {userexp.map((exp, index) => (
      <Card key={index} sx={{ mb: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={intl.formatMessage({ id: 'companyName' })}
                value={exp.work_organization || ""}
                onChange={(e) => handleChangeExp(e, index, 'work_organization')}
                disabled={!edit}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={intl.formatMessage({ id: 'positionName' })}
                value={exp.work_position || ""}
                onChange={(e) => handleChangeExp(e, index, 'work_position')}
                disabled={!edit}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={intl.formatMessage({ id: 'startDate' })}
                type="date"
                value={exp.workStartdate || ""}
                onChange={(e) => handleChangeExp(e, index, 'workStartdate')}
                disabled={!edit}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            {!exp.present && (
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label={intl.formatMessage({ id: 'endDate' })}
                  type="date"
                  value={exp.workEnddate || ""}
                  onChange={(e) => handleChangeExp(e, index, 'workEnddate')}
                  disabled={!edit}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={exp.present === 1}
                    onChange={(e) => handleChangeExp(e, index, 'present')}
                    disabled={!edit}
                  />
                }
                label={intl.formatMessage({ id: 'present' })}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    ))}
  </Box>
)}

{/* Certifications Section */}
{tabValue === 4 && (
  <Box>
    {edit && (
      <Button
        startIcon={<AddIcon />}
        variant="contained"
        onClick={addCert}
        disabled={arrCert.length >= 5}
        sx={{ mb: 2 }}
      >
        {intl.formatMessage({ id: 'add' })}
      </Button>
    )}

    {arrCert.map((cert, index) => (
      <Card key={index} sx={{ mb: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={intl.formatMessage({ id: 'certifications' })}
                value={cert.cert_name || ""}
                onChange={(e) => handleChangeCert(e, index)}
                id="cert_name"
                disabled={!edit}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={intl.formatMessage({ id: 'institute' })}
                value={cert.certificationInstitution || ""}
                onChange={(e) => handleChangeCert(e, index)}
                id="certificationInstitution"
                disabled={!edit}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label={intl.formatMessage({ id: 'certificationUrl' })}
                value={cert.certUrl || ""}
                onChange={(e) => handleChangeCert(e, index)}
                id="certUrl"
                disabled={!edit}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={intl.formatMessage({ id: 'validFrom' })}
                type="date"
                value={cert.startDate || ""}
                onChange={(e) => handleChangeCert(e, index)}
                id="startDate"
                disabled={!edit}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={intl.formatMessage({ id: 'validTill' })}
                type="date"
                value={cert.endDate || ""}
                onChange={(e) => handleChangeCert(e, index)}
                id="endDate"
                disabled={!edit}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    ))}
  </Box>
)}

{/* Portfolio Section */}
{tabValue === 5 && (
  <Box>
    {edit && (
      <Button
        startIcon={<AddIcon />}
        variant="contained"
        onClick={addNewPortfolioItem}
        sx={{ mb: 2 }}
      >
        {intl.formatMessage({ id: 'add' })}
      </Button>
    )}

    {portfolioItems.map((item, index) => (
      <Card key={index} sx={{ mb: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Project Objective"
                value={item.project_objective || ""}
                onChange={(e) => handlePortfolioItemChange(index, "project_objective", e.target.value)}
                disabled={!edit}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Project Description"
                value={item.project_description || ""}
                onChange={(e) => handlePortfolioItemChange(index, "project_description", e.target.value)}
                disabled={!edit}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Industry</InputLabel>
                <Select
                  value={item.industry || ""}
                  onChange={(e) => handlePortfolioItemChange(index, "industry", e.target.value)}
                  disabled={!edit}
                >
                  {industry.map((ind) => (
                    <MenuItem key={ind.value} value={ind.value}>
                      {ind.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {edit && (
              <Grid item xs={12}>
                <input
                  type="file"
                  multiple
                  onChange={(e) => handleImageChange(index, e.target.files)}
                  accept="image/*"
                  style={{ display: 'none' }}
                  id={`portfolio-images-${index}`}
                />
                <label htmlFor={`portfolio-images-${index}`}>
                  <Button
                    variant="outlined"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Images
                  </Button>
                </label>
              </Grid>
            )}

            {item.project_files?.length > 0 && (
              <Grid item xs={12}>
                <Box display="flex" gap={2} flexWrap="wrap">
                  {item.project_files.map((file, fileIndex) => (
                    <img
                      key={fileIndex}
                      src={file}
                      alt={`Project ${index + 1} image ${fileIndex + 1}`}
                      style={{ width: 100, height: 100, objectFit: 'cover' }}
                    />
                  ))}
                </Box>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    ))}
  </Box>
)}

{/* Other Information Section */}
{tabValue === 6 && (
  <Card>
    <CardContent>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={intl.formatMessage({ id: 'portfolioUrl' })}
            value={state.portfolio || ""}
            onChange={onInputChange}
            id="portfolio"
            disabled={!edit}
            type="url"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={intl.formatMessage({ id: 'linkedInUrl' })}
            value={state.linkedin || ""}
            onChange={onInputChange}
            id="linkedin"
            disabled={!edit}
            type="url"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>{intl.formatMessage({ id: 'prefferedWorkHours' })}</InputLabel>
            <Select
              value={state.workhours || ""}
              onChange={onInputChangeselect}
              name="workhours"
              disabled={!edit}
            >
              <MenuItem value="Morning Shift, 9am - 6pm">Morning Shift, 9am - 6pm</MenuItem>
              <MenuItem value="Evening Shift, 10pm - 7am">Evening Shift, 10pm - 7am</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {user?.data?.[0]?.talent_type !== "PARTNER" && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label={intl.formatMessage({ id: 'ratePerHour' })}
              value={state.ratePerhour || ""}
              onChange={onInputChange}
              id="ratePerhour"
              disabled={!edit}
              type="number"
            />
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={intl.formatMessage({ id: 'calendlyurl' })}
            value={state.calendlyurl || ""}
            onChange={onInputChange}
            id="calendlyurl"
            disabled={!edit}
            type="url"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={intl.formatMessage({ id: 'talentCode' })}
            value={state.talent_code || ""}
            onChange={onInputChange}
            id="talent_code"
            disabled={!edit}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>{intl.formatMessage({ id: 'currency' })}</InputLabel>
            <Select
              value={state.currency || ""}
              onChange={onInputChangeselect}
              name="currency"
              disabled={!edit}
            >
              <MenuItem value="USD">USD</MenuItem>
              <MenuItem value="INR">INR</MenuItem>
              <MenuItem value="CRC">CRC</MenuItem>
              <MenuItem value="Vietnamese Dong">Vietnamese Dong</MenuItem>
              <MenuItem value="Colombian Pesos">Colombian Pesos</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>{intl.formatMessage({ id: 'availability' })}</InputLabel>
            <Select
              value={state.availability || ""}
              onChange={onInputChangeselect}
              name="availability"
              disabled={!edit}
            >
              <MenuItem value="full time">Full Time</MenuItem>
              <MenuItem value="part time">Part Time</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {state.availability === "part time" && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label={intl.formatMessage({ id: 'hoursPerWeek' })}
              value={state.hoursperweek || ""}
              onChange={onInputChange}
              id="hoursperweek"
              disabled={!edit}
              type="number"
            />
          </Grid>
        )}

        <Grid item xs={12} md={6}>
<FormControl fullWidth>
  <InputLabel>{intl.formatMessage({ id: 'timeZone' })}</InputLabel>
  <Select
    value={state.timezone || ""}
    onChange={onInputChangeselect}
    name="timezone"
    disabled={!edit}
  >
    <MenuItem value="Indian standard time">Indian Standard Time</MenuItem>
    <MenuItem value="Eastern standard time">Eastern Standard Time</MenuItem>
    <MenuItem value="Central standard time">Central Standard Time</MenuItem>
    <MenuItem value="Mountain standard time">Mountain Standard Time</MenuItem>
    <MenuItem value="Pacific standard time">Pacific Standard Time</MenuItem>
  </Select>
</FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>{intl.formatMessage({ id: 'currentlyAvailable' })}</InputLabel>
            <Select
              value={state.currentStatus || ""}
              onChange={onInputChangeselect}
              name="currentStatus"
              disabled={!edit}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {state.currentStatus === "No" && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label={intl.formatMessage({ id: 'availableDate' })}
              type="date"
              value={state.availabledate || ""}
              onChange={onInputChange}
              id="availabledate"
              disabled={!edit}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <Box>
            <input
              accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              style={{ display: 'none' }}
              id="coding-assessment-upload"
              type="file"
              onChange={onFileUploadassesment}
              disabled={!edit}
            />
            <label htmlFor="coding-assessment-upload">
              <Button
                variant="outlined"
                component="span"
                startIcon={<CloudUploadIcon />}
                disabled={!edit}
                fullWidth
              >
                {intl.formatMessage({ id: 'uploadAssessment' })}
              </Button>
            </label>
            {state.coding_assessment_url && (
              <Typography variant="caption" display="block">
                {state.coding_assessment_url.split('/').pop()}
              </Typography>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box>
            <input
              accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              style={{ display: 'none' }}
              id="resume-upload"
              type="file"
              onChange={onFileUpload}
              disabled={!edit}
            />
            <label htmlFor="resume-upload">
              <Button
                variant="outlined"
                component="span"
                startIcon={<CloudUploadIcon />}
                disabled={!edit}
                fullWidth
              >
                {intl.formatMessage({ id: 'uploadResume' })}
              </Button>
            </label>
            {state.resume && (
              <Typography variant="caption" display="block">
                {state.resume.split('/').pop()}
              </Typography>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={intl.formatMessage({ id: 'githubUsername' })}
            value={state.github_username || ""}
            onChange={onInputChange}
            id="github_username"
            disabled={!edit}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={intl.formatMessage({ id: 'githubRepoName' })}
            value={state.github_repo_name || ""}
            onChange={onInputChange}
            id="github_repo_name"
            disabled={!edit}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={intl.formatMessage({ id: 'jiraUsername' })}
            value={state.jira_username || ""}
            onChange={onInputChange}
            id="jira_username"
            disabled={!edit}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={intl.formatMessage({ id: 'sonarqubeProjectKey' })}
            value={state.sonarqube_project_key || ""}
            onChange={onInputChange}
            id="sonarqube_project_key"
            disabled={!edit}
          />
        </Grid>

        {/* Resume and Assessment download links */}
        {resume && (
          <Grid item xs={12} md={6}>
            <Button
              variant="text"
              color="primary"
              startIcon={<LinkIcon />}
              href={resume}
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.formatMessage({ id: 'viewResume' })}
            </Button>
          </Grid>
        )}

        {assesment && (
          <Grid item xs={12} md={6}>
            <Button
              variant="text"
              color="primary"
              startIcon={<LinkIcon />}
              href={assesment}
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.formatMessage({ id: 'viewAssessment' })}
            </Button>
          </Grid>
        )}
      </Grid>
    </CardContent>
  </Card>
)}
          
                        {/* Submit Button Section */}
                        {edit && (
                          <Box sx={{ mt: 3, textAlign: 'center' }}>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              disabled={loading}
                              sx={{ mr: 2 }}
                            >
                              {loading ? (
                                <CircularProgress size={24} />
                              ) : (
                                user?.data?.[0]?.profile === 1 
                                  ? intl.formatMessage({ id: 'update' })
                                  : intl.formatMessage({ id: 'createProfile' })
                              )}
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={() => toggleEdit(false)}
                              disabled={loading}
                            >
                              {intl.formatMessage({ id: 'cancel' })}
                            </Button>
                          </Box>
                        )}
                      </Box>
                    </form>
                  </Box>
                </Paper>
              </Container>
            );
          };
          
          export default Updateyourprofile;