import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import PropTypes from "prop-types";

const DetailviewGithublinesofcode = ({ initialPodIds }) => {
  const [data, setData] = useState(null);
  const [selectedCandidateId, setSelectedCandidateId] = useState('');
  const [talentUsers, setTalentUsers] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const token = useAuthToken();
  const intl = useIntl();

  useEffect(() => {
    fetchData();
  }, [token, user.data, initialPodIds]);

  const fetchData = async () => {
    try {
      if (!token) {
        console.log('Token is missing. API requests will not be made.');
        return;
      }
      const payload = {};
      if (user.data[0].type === "stakeholder") {
        if (initialPodIds && initialPodIds.length > 0) {
          payload.pod_id = initialPodIds;
        } else if (user.data[0].client_code) {
          payload.org_code = user.data[0].client_code;
        }
  
        const response = await fetch(process.env.REACT_APP_STAKEHOLDER_CODEBYDAYMETRICSSTAKEHOLDER, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify(payload),
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const responseData = await response.json();
        setData(responseData.body);
        setTalentUsers(JSON.parse(responseData.body).talent_users);
        return;
      }
  
      if (initialPodIds && initialPodIds.length > 0) {
        await fetchDataPod(initialPodIds);
      } else {
        await fetchDataManager();
      }
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };
  

  const fetchDataPod = async (selectedPodIds) => {
    const requestBody = { pod_id: selectedPodIds || null };

    try {
      const response = await fetch(process.env.REACT_APP_CLIENT_GITHUBCODEBYDAYMANAGERPODID, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      setData(responseData.body);
      setTalentUsers(JSON.parse(responseData.body).talent_users);
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  const fetchDataManager = async () => {
    const requestBody = {
      manager_id: user.data[0].emp_id,
      org_code: user.data[0].client_code,
    };

    try {
      const response = await fetch(process.env.REACT_APP_CLIENT_GITHUBCODEBYDAYMANAGER, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      setData(responseData.body);
      setTalentUsers(JSON.parse(responseData.body).talent_users);
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  const formatDataForChart = () => {
    if (!data || data === '{}' || data === '[]') {
      return null;
    }
    try {
      const parsedData = JSON.parse(data);

      if (!parsedData || !Array.isArray(parsedData.talent_users)) {
        return null;
      }
      const labels = [];
      const checkInsData = [];
      const linesAddedData = [];

      const selectedUser = selectedCandidateId || 'all'; // Use 'all' as an identifier for all users

      parsedData.talent_users.forEach((user) => {
        if (
          (selectedUser === 'all' || user.talent_github_username === selectedUser) &&
          Array.isArray(user.stats)
        ) {
          user.stats.forEach((item) => {
            labels.push(item.weekday);
            checkInsData.push(item.checkins_count);
            linesAddedData.push(item.lines_added_count);
          });
        }
      });

      const chartData = {
        labels: labels,
        datasets: [
          {
            label: 'Check-ins',
            data: checkInsData,
            backgroundColor: 'yellow',
            borderWidth: 1,
          },
          {
            label: 'Lines Added',
            data: linesAddedData,
            backgroundColor: 'black',
            borderWidth: 1,
          },
        ],
      };

      return chartData;
    } catch (error) {
      console.log('Error parsing data:', error);
      return null;
    }
  };

  const chartData = formatDataForChart();
  const renderChartContent = () => {
    if (chartData === null) {
      return <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>;
    } else if (chartData?.labels.length > 0) {
      return (
        <div style={{ height: '30rem' }}>
          <Bar
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
            }}
          />
        </div>
      );
    } else {
      return <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>;
    }
  };
  
  const renderUserOptions = () => {
    if (talentUsers?.length > 0) {
      return talentUsers.map((user) => (
        <option
          key={user.talent_github_username}
          value={user.talent_github_username}
        >
          {user.talent_github_username}
        </option>
      ));
    } else {
      return <option value="">{intl.formatMessage({ id: 'noUsers' })}</option>;
    }
  };
  
  return (
    <div className="chart-container">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          
          height: '30rem',
          width: '20rem',
        }}
      >
        <h2 className="chart-heading">{intl.formatMessage({ id: 'linesOfCodeByDay' })}</h2>
        <div>
          <select
            style={{ width: '12rem' }}
            value={selectedCandidateId}
            onChange={(event) => setSelectedCandidateId(event.target.value)}
          >
            <option value="">{intl.formatMessage({ id: 'selectAUser' })}</option>
            {renderUserOptions()}
          </select>
        </div>
        {renderChartContent()}
      </div>
    </div>
  );
  
            }

DetailviewGithublinesofcode.propTypes = {
  initialPodIds: PropTypes.array.isRequired,
};

export default DetailviewGithublinesofcode;
