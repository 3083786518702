import React, { useState, useEffect } from 'react';
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {Menu,MenuItem} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Auth } from "aws-amplify";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "./action/auth";
import { DiAptana } from "react-icons/di";
import { useIntl } from 'react-intl';
import styled from "styled-components";
import NotificationDropdown from "./Notificationtalent";
import Notificationmanagericon from "./Notificationmanagericon";
import globllogo from "./assets/Globlai-Logo-Clear.png";

const NavigationItem = ({ href, label, isActive, marginLeft = "20px" }) => (
  <a
    href={href}
    className="navbar-item"
    style={{
      marginLeft,
      color: isActive ? "#1DBF73" : "black",
      borderBottom: isActive ? "1px solid #1DBF73" : "none",
    }}
  >
    {label}
  </a>
);

const UserNavigation = ({ type, path, intl }) => {
  const navigationConfig = {
    Candidate: [
      { path: "/", label: intl.formatMessage({ id: 'home' }), marginLeft: "550px" },
      { path: "/candidate-dashboard", label: intl.formatMessage({ id: 'dashboard' }) },
      { path: "/FindPods", label: intl.formatMessage({ id: 'findPods' }) },
      { path: "/EditTalentSettingsForm", label: <DiAptana /> }
    ],
    Client: [
      { path: "/", label: intl.formatMessage({ id: 'home' }), marginLeft: "300px" },
      { path: "/client-dashboard", label: intl.formatMessage({ id: 'dashboard' }) },
      { path: "/browse-talent", label: intl.formatMessage({ id: 'findTalent' }) },
      { path: "/Popup", label: intl.formatMessage({ id: 'invite' }) },
      { path: "/FindPodsClient", label: intl.formatMessage({ id: 'findPods' }) },
      { path: "/Team", label: intl.formatMessage({ id: 'myPods' }) },
      { path: "/Setting", label: <DiAptana /> }
    ],
    PartnerGuest: [
      { path: "/", label: intl.formatMessage({ id: 'home' }), marginLeft: "300px" },
      { path: "/FindPodsPartnerGuest", label: intl.formatMessage({ id: 'findPods' }) },
      { path: "/FindTalentPartnerGuest", label: intl.formatMessage({ id: 'findTalent' }) }
    ],
    stakeholder: [
      { path: "/", label: intl.formatMessage({ id: 'home' }), marginLeft: "300px" },
      { path: "/StakeholderDashboard", label: intl.formatMessage({ id: 'dashboard' }) }
    ],
    Provider: [
      { path: "/PartnerSubscription", label: intl.formatMessage({ id: 'subscription' }) },
      { path: "/PartnerInvite", label: intl.formatMessage({ id: 'invite' }) },
      { path: "/PartnerInvoices", label: intl.formatMessage({ id: 'invoices' }) },
      { path: "/PartnerSettings", label: <DiAptana /> }
    ],
    ClientAdmin: [
      { path: "/subscriptions", label: intl.formatMessage({ id: 'subscription' }) },
      { path: "/Clientinvite", label: intl.formatMessage({ id: 'invite' }) },
      { path: "/InvoicesClient", label: intl.formatMessage({ id: 'invoices' }) }
    ]
  };

  const items = navigationConfig[type] || [];
  return items.map((item, index) => (
    <NavigationItem
      key={index}
      href={item.path}
      label={item.label}
      isActive={path === item.path}
      marginLeft={item.marginLeft}
    />
  ));
};

const UserDropdown = ({ username, handleLogOut, userType, intl }) => {
  const getProfileUrl = () => {
    const profileUrls = {
      Candidate: "/Updateyourprofile",
      Client: "/client-profile",
      stakeholder: "/stakeholder-profile",
      PartnerGuest: "/partnerguest-profile",
      Provider: "/provider-profile",
      ClientAdmin: "/costomer-profile"
    };
    return profileUrls[userType] || "/profile";
  };

  return (
    <Dropdown style={{ marginLeft: "20px", borderColor: "transparent" }}>
      <Dropdown.Toggle variant="Secondary">
        <strong>Hi, {username}</strong>
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ padding: "20px" }}>
        <Dropdown.Item href={getProfileUrl()} style={{ color: "black", fontWeight: "bold" }}>
          {intl.formatMessage({ id: 'manageProfile' })}
        </Dropdown.Item>
        <Dropdown.Item href="/forgotpassword" style={{ color: "black", fontWeight: "bold" }}>
          {intl.formatMessage({ id: 'resetPassword' })}
        </Dropdown.Item>
        <Dropdown.Item onClick={handleLogOut} style={{ color: "black", fontWeight: "bold" }}>
          {intl.formatMessage({ id: 'logOut' })}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const Header = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [username, setUsername] = useState("");
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const currentPath = window.location.pathname;
  const userType = user?.data?.[0]?.type;

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setUsername(user.username);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };
    
    if (isLoggedIn) {
      fetchUser();
    }
  }, [isLoggedIn]);

  const handleLogOut = async (event) => {
    event.preventDefault();
    try {
      await Auth.signOut();
      await dispatch(logout());
      sessionStorage.setItem("cprofile", "");
      history.push(userType === "ClientAdmin" ? "/admin-login" : "/login");
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);
  const handleCloseNavMenu = () => setAnchorElNav(null);

  const renderNotifications = () => {
    if (!isLoggedIn) return null;
    
    return userType === "Client" ? (
      <Notificationmanagericon style={{ color: "black" }} />
    ) : userType === "Candidate" ? (
      <NotificationDropdown />
    ) : null;
  };

  const renderPublicNavigation = () => (
    <>
      <NavigationItem 
        href="/" 
        label={intl.formatMessage({ id: 'home' })} 
        isActive={currentPath === "/"} 
        marginLeft="950px"
      />
      <NavigationItem 
        href="/login" 
        label={intl.formatMessage({ id: 'login' })} 
        isActive={currentPath === "/login"}
      />
      <NavigationItem 
        href="/register" 
        label={intl.formatMessage({ id: 'register' })} 
        isActive={currentPath === "/register"}
      />
    </>
  );

  return (
    <AppBar style={{ backgroundColor: "#f3f3f3", boxShadow: "none" }}>
      <Toolbar>
        {/* Logo for desktop */}
        <Typography
          variant="h6"
          component="a"
          href="/"
          sx={{
            mr: 2,
            display: { xs: "none", md: "flex" },
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "black",
            textDecoration: "none",
            marginLeft: "15px"
          }}
        >
          <Logo src={globllogo} alt="Globl.ai logo" />
        </Typography>

        {/* Mobile menu */}
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            onClick={handleOpenNavMenu}
            color="inherit"
            style={{ color: "black" }}
          >
            <MenuIcon />
          </IconButton>
          <Logo
            src={globllogo}
            alt="Globl.ai logo"
            style={{
              flex: 1,
              marginLeft: "30px"
            }}
          />
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            <MenuItem onClick={handleCloseNavMenu} style={{
              width: "300px",
              display: "flex",
              flexDirection: "column",
              margin: "auto"
            }}>
              {isLoggedIn ? (
                <UserNavigation type={userType} path={currentPath} intl={intl} />
              ) : (
                renderPublicNavigation()
              )}
            </MenuItem>
          </Menu>
        </Box>

        {/* Desktop navigation */}
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {isLoggedIn ? (
            <>
              <UserNavigation type={userType} path={currentPath} intl={intl} />
              <div style={{ marginTop: "10px" }}>
                {renderNotifications()}
              </div>
              <UserDropdown 
                username={username}
                handleLogOut={handleLogOut}
                userType={userType}
                intl={intl}
              />
            </>
          ) : (
            renderPublicNavigation()
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const Logo = styled.img`
  flex: 1;
  float: left;
  margin-left: 30px;
  width: 13rem;
  height: 3.5rem;
  
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 8rem;
    height: 3rem;
  }
`;

export default Header;