import { fontSize } from '@mui/system'
import React from 'react'
import styled from '@emotion/styled';
import MailIcon from '@mui/icons-material/Mail';
import { NavLink } from 'react-router-dom';
import Home from './Home';
// import FindPodsCan from './FindPodsCan';

const ProfileSuccessScreen = props => {
  return (
    <div>

{/* <Navbar/> */}
        



<Divprofile className='posted' ><p> Your profile is pending approval. Please check back once it’s approved.
 </p></Divprofile>



   
    </div>
  )
}

const InputContainer = styled.div`
display : flex;
// border:2px solid #90E0EF;
// border-radius:1rem;
// justify-content: center;
 margin-left:33rem;


`;


const Divprofile = styled.div`
text-align : left;
margin-left: 200px;
@media screen and (min-width:320px) and (max-width:1080px){
margin:auto;
overflow-wrap: anywhere;
}
`;

const Input = styled.input`
border:0px solid black;
justify-content: center;
height: 2.5rem;
width: 18rem;

background-color:white;
border-top-right-radius:10px;
border-bottom-right-radius:10px;
&:focus{
    border:none;
    outline:none;
}

`;
const Icon = styled.div`
height: 2.5rem;
width: 2rem;
background-color: white;
// border:1px solid black;
margin-Left:20rem;
display:flex;
flex-direction:row
justify-content: center;
align-items: center;
border-top-left-radius:10px;
border-bottom-left-radius:10px;
svg{
    color:#555555;
}
`;


export default ProfileSuccessScreen;