import React from 'react'
import styled from 'styled-components';
import Badge from './Badge';
import Avatar from "./assets/profile_img.png";
import { cardShadow, hoverEffect, themeColor } from '../utils';


function Invoices() {
    return (
        <InvoicesContainer>
            {/* <br></br> */}

            {/* <AllProjects>View All</AllProjects> */}
            <div >
                <TitleText style={{padding:10}}>Recent Invoices</TitleText>
                <br></br>
                <h5 style={{ textAlign : "center" ,fontFamily:'Poppins,Medium' }}>Coming Soon..</h5>

            </div>
            {/* <CardContent>
                <Invoice>
                    <Info>

                        <img src={Avatar} alt='' style={{ width: 50 }}></img>
                        <TextContainer>
                            <Title>Alaxander Williams</Title>
                           
                        </TextContainer>
                    </Info>
                    <Container>
                        <Badge content="Paid" paid />
                        <Price>$1,200.87</Price>
                    </Container>
</Invoice>
                
            
               <Invoice>
            
               <Info>

                <img src={Avatar} alt='' style={{ width: 50 }}></img>

                <TextContainer>
                    <Title>John Phillips</Title>
                    
                </TextContainer>
                </Info>
            
            
               <Container>
                <Badge content="Cancelled" cancelled />
                <Price>$2,200.87</Price>
              </Container>
              </Invoice>
        </CardContent> */}

        </InvoicesContainer>



    );
}

const AllProjects=styled.div` 
text-align:center;
color: black;
float:right;
cursor:pointer;
border-radius:0.3rem;
border:2px solid green;
width:75px;
margin-top: 10; 
margin-right: 15px;
font-size:15px;
align-self:center;
@media screen and (min-width:320px) and (max-width:1080px){
    // margin-top:-1.5rem;
    font-size:15px;
    width:65px;
 
    display:flex;
   }
`;

const InvoicesContainer =styled.div`  
 width:42rem;
border-radius:1rem;

background-color:#F2F4F5;
height:190%;
box-shadow: 0px 4px 6px #00000029;
transition:0.45 ease-in-out;
&hover:{
box-shadow:${hoverEffect};
}

@media screen and (min-width:320px) and (max-width:1080px){
       width:20rem;
       flex-direction:column;
       height:15rem;
       display:flex;
       justify-content:center;
       align-items:center;
     margin-right:auto;
      } 
      `;
      
   const TitleText=styled.h5` 
   height:20%;
   margin-left:1rem;
   margin-bottom:1rem;
   font-weight:600;
   font-family:Poppins,Medium;
   @media screen and (min-width:320px) and (max-width:1080px){
    flex-direction:column;
    height:max-content;
    display:flex;
 //    justify-content:center;
text-align:center;
margin:auto;
     }
   `;
  
   const CardContent=styled.div`
   
   @media screen and (min-width:320px) and (max-width:1080px){
    margin:2rem 1;
    width:100%;
     }
     
     `;
   
   
   
   const Invoice =styled.div` 
    display:flex;
   align-items:center;
   justify-content: space-around;
   margin:0.4rem;
   padding-top:0.6rem;
   
  
   @media screen and (min-width:320px) and (max-width:1080px){
    flex-direction:column;
    gap:1rem;
    }

   `;
   const Info =styled.div`
   display:flex;
   align-items:center;
   width:50%;

   
   @media screen and (min-width:320px) and (max-width:1080px){
    flex-direction:column;
    width:100%;
    text-align:center;
   }

   `;
   
   
   
   const TextContainer=styled.div` 
   
   margin-left:1rem;
   
   `;
 
   
   
   const Title=styled.h6`
   font-weight:600;
   font-size:18px;
   `;
   const SubTitle =styled.div`font-weight:400;`;
   
   const Container=styled.div`  
   display:flex;
   justify-content: space-between;
   width: 40%;
   align-items:center;
 
   
 @media screen and (min-width:320px) and (max-width:1080px){
       width:100%;
       flex-direction:column;
       gap:0.6rem;
      }
   `;
  const Price =styled.div``;
   
   
   
   
   
export default Invoices