import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { updateProfilecandidate } from "./action/auth";
import { useAuthToken } from "../TokenContext";
import '../components/css/addcandidate.css';
const Createprofiletest = () => {
    const token = useAuthToken();
  const [formData, setFormData] = useState({
    fname: '',
    mname: '',
    lname: '',
    candidateemail: '',
    area: '',
    github_repo_name: '',
    github_username: '',
    jira_username: '',
    sonarqube_project_key: '',
    country: '',
    primaryskill: '',
    portfolio: '',
    linkedin: '',
    github: '',
    industry: '',
    degree: '',
    institution: '',
    domain: '',
    startingDate: '',
    endingDate: '',
    certification: '',
    certificationIntitution: '',
    certificationUrl: '',
    certification_startDate: '',
    certification_endDate: '',
    work_organization: '',
    work_position: '',
    present: false,
    workStartdate: '',
    workEnddate: '',
    secondaryskill: '',
    ratePerhour: '',
    ratePermonth: '',
    currency: '',
    workhours: '',
    about: '',
    techStack: '',
    email: '',
    resume: '',
    coding_assessment_url: '',
    work_authorization: '',
    timezone: '',
    availability: '',
    hoursperweek: '',
    currentStatus: '',
    availabledate: '',
    profilePicture: '',
    calendlyurl: '',
    talent_code: '',
    profileImg: '',
    errors: {},
  });

  const [educationData, setEducationData] = useState([]);
  const [certificationsData, setCertificationsData] = useState([]);
  const [experienceData, setExperienceData] = useState([]);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const headers = {
        'Authorization': token,
        'Content-Type': 'application/json',
      };
    try {
      // Main profile update
      await dispatch(updateProfilecandidate(formData, headers));
      
      // Education, experience, and certifications updates
      const educationPayload = { educationData, type: "EDU" };
      const expPayload = { educationData: experienceData, type: "EXP" };
      const certificationsPayload = { educationData: certificationsData, type: "CERT" };

      await Promise.all([
        axios.post(process.env.REACT_APP_TALENT_CANDIDATEPROFILETESTUPDATE, educationPayload, { headers }),
        axios.post(process.env.REACT_APP_TALENT_CANDIDATEPROFILETESTUPDATE, expPayload, { headers }),
        axios.post(process.env.REACT_APP_TALENT_CANDIDATEPROFILETESTUPDATE, certificationsPayload, { headers })
      ]);

      // Handle successful submission (e.g., show a success message)
    } catch (error) {
      console.error('Error submitting profile data:', error);
    }
  };
  const handleChangeExperience = (id, field, value) => {
    setExperienceData(prevData =>
      prevData.map(item =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };
  const handleAdd = (section) => {
    switch (section) {
      case 'education':
        setEducationData(prev => [...prev, {
          degree: '',
          domain: '',
          institution: '',
          startDate: '',
          endDate: '',
          candidateID: 901,
          id: Date.now()
        }]);
        break;

      case 'certifications':
        setCertificationsData(prev => [...prev, {
          cert_name: '',
          certificationInstitution: '',
          certUrl: '',
          startDate: '',
          endDate: '',
          candidateID: 901,
          id: Date.now()
        }]);
        break;

      case 'experience':
        setExperienceData(prev => [...prev, {
          work_organization: '',
          work_position: '',
          workStartdate: '',
          workEnddate: '',
          present: false,
          candidateID: 901,
          id: Date.now()
        }]);
        break;

      default:
        break;
    }
  };

  const handleRemove = (section, id) => {
    switch (section) {
      case 'education':
        setEducationData(prev => prev.filter(item => item.id !== id));
        break;

      case 'certifications':
        setCertificationsData(prev => prev.filter(item => item.id !== id));
        break;

      case 'experience':
        setExperienceData(prev => prev.filter(item => item.id !== id));
        break;

      default:
        break;
    }
  };

  return (
    <div className="container" style={{ "margin-top": "140px" }} id="main">
  <div className="row">
    
    <form onSubmit={handleSubmit}>
      <h2>Create Profile</h2>

      {/* Personal Information */}
      <div>
        <label>First Name:</label>
        <input type="text" name="fname" value={formData.fname} onChange={handleChange} placeholder="First Name" />
      </div>
      <div>
        <label>Middle Name:</label>
        <input type="text" name="mname" value={formData.mname} onChange={handleChange} placeholder="Middle Name" />
      </div>
      <div>
        <label>Last Name:</label>
        <input type="text" name="lname" value={formData.lname} onChange={handleChange} placeholder="Last Name" />
      </div>
      <div>
        <label>Email:</label>
        <input type="email" name="candidateemail" value={formData.candidateemail} onChange={handleChange} placeholder="Email" />
      </div>

      {/* GitHub and Jira */}
      <div>
        <label>GitHub Repo Name:</label>
        <input type="text" name="github_repo_name" value={formData.github_repo_name} onChange={handleChange} placeholder="GitHub Repo Name" />
      </div>
      <div>
        <label>GitHub Username:</label>
        <input type="text" name="github_username" value={formData.github_username} onChange={handleChange} placeholder="GitHub Username" />
      </div>
      <div>
        <label>Jira Username:</label>
        <input type="text" name="jira_username" value={formData.jira_username} onChange={handleChange} placeholder="Jira Username" />
      </div>

      {/* Project Key and Area */}
      <div>
        <label>SonarQube Project Key:</label>
        <input type="text" name="sonarqube_project_key" value={formData.sonarqube_project_key} onChange={handleChange} placeholder="SonarQube Project Key" />
      </div>
      <div>
        <label>Area:</label>
        <input type="text" name="area" value={formData.area} onChange={handleChange} placeholder="Area" />
      </div>

      {/* Dropdowns */}
      <div>
        <label>Country:</label>
        <select name="country" value={formData.country} onChange={handleChange}>
          <option value="">Select Country</option>
          {/* Add country options here */}
        </select>
      </div>
      <div>
        <label>Primary Skill:</label>
        <select name="primaryskill" value={formData.primaryskill} onChange={handleChange}>
          <option value="">Select Primary Skill</option>
          {/* Add primary skills options here */}
        </select>
      </div>
      <div>
        <label>Industry:</label>
        <select name="industry" value={formData.industry} onChange={handleChange}>
          <option value="">Select Industry</option>
          {/* Add industry options here */}
        </select>
      </div>
      <div>
        <label>Secondary Skill:</label>
        <select name="secondaryskill" value={formData.secondaryskill} onChange={handleChange}>
          <option value="">Select Secondary Skill</option>
          {/* Add secondary skills options here */}
        </select>
      </div>
      <div>
        <label>Currency:</label>
        <select name="currency" value={formData.currency} onChange={handleChange}>
          <option value="">Select Currency</option>
          {/* Add currency options here */}
        </select>
      </div>
      <div>
        <label>Work Hours:</label>
        <select name="workhours" value={formData.workhours} onChange={handleChange}>
          <option value="">Select Work Hours</option>
          {/* Add work hours options here */}
        </select>
      </div>
      <div>
        <label>Tech Stack:</label>
        <select name="techStack" value={formData.techStack} onChange={handleChange}>
          <option value="">Select Tech Stack</option>
          {/* Add tech stack options here */}
        </select>
      </div>
      <div>
        <label>Timezone:</label>
        <select name="timezone" value={formData.timezone} onChange={handleChange}>
          <option value="">Select Timezone</option>
          {/* Add timezone options here */}
        </select>
      </div>
      <div>
        <label>Availability:</label>
        <select name="availability" value={formData.availability} onChange={handleChange}>
          <option value="">Select Availability</option>
          {/* Add availability options here */}
        </select>
      </div>
      <div>
        <label>Current Status:</label>
        <select name="currentStatus" value={formData.currentStatus} onChange={handleChange}>
          <option value="">Select Current Status</option>
          {/* Add current status options here */}
        </select>
      </div>

      {/* Text Area */}
      <div>
        <label>About:</label>
        <textarea name="about" value={formData.about} onChange={handleChange} placeholder="About"></textarea>
      </div>

      {/* Additional Fields */}
      <div>
        <label>Portfolio:</label>
        <input type="text" name="portfolio" value={formData.portfolio} onChange={handleChange} placeholder="Portfolio" />
      </div>
      <div>
        <label>LinkedIn:</label>
        <input type="text" name="linkedin" value={formData.linkedin} onChange={handleChange} placeholder="LinkedIn" />
      </div>
      <div>
        <label>GitHub:</label>
        <input type="text" name="github" value={formData.github} onChange={handleChange} placeholder="GitHub" />
      </div>
      <div>
        <label>Degree:</label>
        <input type="text" name="degree" value={formData.degree} onChange={handleChange} placeholder="Degree" />
      </div>
      <div>
        <label>Institution:</label>
        <input type="text" name="institution" value={formData.institution} onChange={handleChange} placeholder="Institution" />
      </div>
      <div>
        <label>Domain:</label>
        <input type="text" name="domain" value={formData.domain} onChange={handleChange} placeholder="Domain" />
      </div>
      <div>
        <label>Starting Date:</label>
        <input type="date" name="startingDate" value={formData.startingDate} onChange={handleChange} />
      </div>
      <div>
        <label>Ending Date:</label>
        <input type="date" name="endingDate" value={formData.endingDate} onChange={handleChange} />
      </div>
      <div>
        <label>Certification:</label>
        <input type="text" name="certification" value={formData.certification} onChange={handleChange} placeholder="Certification" />
      </div>
      <div>
        <label>Certification Institution:</label>
        <input type="text" name="certificationIntitution" value={formData.certificationIntitution} onChange={handleChange} placeholder="Certification Institution" />
      </div>
      <div>
        <label>Certification URL:</label>
        <input type="text" name="certificationUrl" value={formData.certificationUrl} onChange={handleChange} placeholder="Certification URL" />
      </div>
      <div>
        <label>Certification Start Date:</label>
        <input type="date" name="certification_startDate" value={formData.certification_startDate} onChange={handleChange} />
      </div>
      <div>
        <label>Certification End Date:</label>
        <input type="date" name="certification_endDate" value={formData.certification_endDate} onChange={handleChange} />
      </div>
      <div>
        <label>Work Organization:</label>
        <input type="text" name="work_organization" value={formData.work_organization} onChange={handleChange} placeholder="Work Organization" />
      </div>
      <div>
        <label>Work Position:</label>
        <input type="text" name="work_position" value={formData.work_position} onChange={handleChange} placeholder="Work Position" />
      </div>
      <div>
        <label>Present:</label>
        <input type="checkbox" name="present" checked={formData.present} onChange={handleChange} />
      </div>
      <div>
        <label>Work Start Date:</label>
        <input type="date" name="workStartdate" value={formData.workStartdate} onChange={handleChange} />
      </div>
      <div>
        <label>Work End Date:</label>
        <input type="date" name="workEnddate" value={formData.workEnddate} onChange={handleChange} />
      </div>

      {/* Additional Fields */}
      <div>
        <label>Rate Per Hour:</label>
        <input type="text" name="ratePerhour" value={formData.ratePerhour} onChange={handleChange} placeholder="Rate Per Hour" />
      </div>
      <div>
        <label>Rate Per Month:</label>
        <input type="text" name="ratePermonth" value={formData.ratePermonth} onChange={handleChange} placeholder="Rate Per Month" />
      </div>
      <div>
        <label>Hours Per Week:</label>
        <input type="text" name="hoursperweek" value={formData.hoursperweek} onChange={handleChange} placeholder="Hours Per Week" />
      </div>
      <div>
        <label>Profile Picture URL:</label>
        <input type="text" name="profilePicture" value={formData.profilePicture} onChange={handleChange} placeholder="Profile Picture URL" />
      </div>
      <div>
        <label>Calendly URL:</label>
        <input type="text" name="calendlyurl" value={formData.calendlyurl} onChange={handleChange} placeholder="Calendly URL" />
      </div>
      <div>
        <label>Talent Code:</label>
        <input type="text" name="talent_code" value={formData.talent_code} onChange={handleChange} placeholder="Talent Code" />
      </div>
      <div>
        <label>Profile Image URL:</label>
        <input type="text" name="profileImg" value={formData.profileImg} onChange={handleChange} placeholder="Profile Image URL" />
      </div>
      <div>
        <label>Email:</label>
        <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email" />
      </div>
      <div>
        <label>Resume URL:</label>
        <input type="text" name="resume" value={formData.resume} onChange={handleChange} placeholder="Resume URL" />
      </div>
      <div>
        <label>Coding Assessment URL:</label>
        <input type="text" name="coding_assessment_url" value={formData.coding_assessment_url} onChange={handleChange} placeholder="Coding Assessment URL" />
      </div>
      <div>
        <label>Work Authorization:</label>
        <input type="text" name="work_authorization" value={formData.work_authorization} onChange={handleChange} placeholder="Work Authorization" />
      </div>

      {/* Submit Button */}
      <button type="submit">Submit</button>

      {/* Add Sections for Education, Certifications, and Experience */}
      <div>
        <h3>Education</h3>
        {educationData.map(item => (
          <div key={item.id}>
            <input type="text" value={item.degree} placeholder="Degree" />
            <input type="text" value={item.domain} placeholder="Domain" />
            <input type="text" value={item.institution} placeholder="Institution" />
            <input type="date" value={item.startDate} />
            <input type="date" value={item.endDate} />
            <button type="button" onClick={() => handleRemove('education', item.id)}>Remove</button>
          </div>
        ))}
        <button type="button" onClick={() => handleAdd('education')}>Add Education</button>
      </div>

      <div>
        <h3>Certifications</h3>
        {certificationsData.map(item => (
          <div key={item.id}>
            <input type="text" value={item.cert_name} placeholder="Certification Name" />
            <input type="text" value={item.certificationInstitution} placeholder="Certification Institution" />
            <input type="text" value={item.certUrl} placeholder="Certification URL" />
            <input type="date" value={item.startDate} />
            <input type="date" value={item.endDate} />
            <button type="button" onClick={() => handleRemove('certifications', item.id)}>Remove</button>
          </div>
        ))}
        <button type="button" onClick={() => handleAdd('certifications')}>Add Certification</button>
      </div>

      <div>
        <h3>Experience</h3>
        {experienceData.map(item => (
          <div key={item.id}>
            <input type="text" value={item.work_organization} placeholder="Work Organization" />
            <input type="text" value={item.work_position} placeholder="Work Position" />
            <input type="date" value={item.workStartdate} />
            <input type="date" value={item.workEnddate} />
            <label>
              Present:
              <input type="checkbox" checked={item.present} onChange={(e) => handleChangeExperience(item.id, 'present', e.target.checked)} />
            </label>
            <button type="button" onClick={() => handleRemove('experience', item.id)}>Remove</button>
          </div>
        ))}
        <button type="button" onClick={() => handleAdd('experience')}>Add Experience</button>
      </div>
    </form>  </div></div>
  );
}

export default Createprofiletest;
