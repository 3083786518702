// es/messages.js
const messagesEs = {
  welcome: '¡Bienvenido a Globl.ai!',
  globalWorkPlatform: 'Plataforma de trabajo global',
  manageProfile: 'Gestionar perfil',
  getStarted: 'Empezar',
  createGig: 'Crear proyecto',
  createArt: 'Crear ART',
  createPod: 'Crear POD',
  findYourBestTalentPod: 'Encuentra tu mejor POD de talento',
  signUpToGetStarted: '¡"Regístrate" a continuación para comenzar!',
  Register: 'Registrarse',
  privacyPolicy: 'Política de privacidad',
  termsOfService: 'Términos del servicio',
  copyright: 'Copyright © 2023-presente Brilli Global, Inc. Todos los derechos reservados.',
  home: 'Inicio',
  dashboard: 'Tablero',
  findPods: 'Buscar PODs',
  resetPassword: 'Restablecer Contraseña',
  logOut: 'Cerrar Sesión',
  latestSprintVelocity: 'Última Velocidad del Sprint',
  noData: 'Sin Datos',
  podrate:'Tasa de Reproducción',
  stories: 'Historias',
  noDataAvailable: 'No hay datos disponibles',
  defects: 'Defectos',
  code: 'Líneas de Código',
  jiraTimeDetails: 'Registro de Tiempo',
  notifications: 'Notificaciones',
  noNotifications: 'No hay notificaciones',
  assignedItems: 'Elementos asignados',
  taskName: 'Nombre de la tarea',
  podActivity: 'Actividad del POD',
  noPodActivity: 'No tienes ninguna actividad de POD',
  done: 'Hecho',
  selectPodTeamMember: 'Seleccionar miembro del equipo del POD',
  PodTeamMember:'miembro del equipo del POD',
  submittedDate: 'Fecha de envío',
  teamMember: 'Miembro del equipo',
  accomplishments: 'Logros',
  enterStoryDescription: 'Ingrese una Descripción de la Historia',
  pleaseEnterStoryDescription: 'Por favor, ingrese una descripción de la historia y haga clic en enviar',
  submit: 'Enviar',
  storyDescription: 'Descripción de la Historia',
  acceptanceCriteria: 'Criterios de Aceptación',
  testCases: 'Casos de Prueba',
  loadingStoryDescription: 'Cargando Descripción de la Historia',
  cancel: 'Cancelar',
  edit: 'Editar',
  loadingAcceptanceCriteria: 'Cargando Criterios de Aceptación',
  loadingTestCases: 'Cargando Casos de Prueba',
  loading: 'Cargando',
  noSubscription: 'No tienes ningún plan de suscripción',
  prev: 'Anterior',
  next: 'Siguiente',
  podName: 'Nombre del Pod',
  email: 'Correo Electrónico',
  portfolio: 'Portafolio',
  podSize: 'Tamaño del Pod',
  spotsLeft: 'Lugares Disponibles',
  joinPod: 'Unirse al Pod',
  noMatchingPods: 'No hay pods disponibles que coincidan',
  yourPods: 'Tus Pods',
  noPods: 'No tienes ningún Pod',
  status: 'Estado',
  podRetro: 'Retro del Pod',
  podTask: 'Tarea del Pod',
  timeSubmit: 'Enviar Hora',
  podBoard: 'Tablero del Pod',
  toDo: 'Por hacer',
  inProgress: 'En progreso',
  Done: 'Completado',
  selectPod: 'Seleccione un Pod',
  taskManager: 'Administrador de tareas', 
  description: 'Descripción', 
  dueDate: 'Fecha de vencimiento', 
  all: 'Todo', 
  markComplete: 'Marcar como completado', 
  noPodTasks: 'No tienes ninguna tarea de Pod',
  podAnalytics: 'Análisis de POD', // Spanish
  selectPods: 'Seleccionar Pods', // Spanish
  doraMetrics: 'Métricas Dora',
  allReporters: 'Todos los reporteros', // Spanish
  reporter: 'Reportero', // Spanish
  issueSummary: 'Resumen del problema', // Spanish
  issueKey: 'Clave del problema', // Spanish
  issueStatus: 'Estado del problema', // Spanish
  githubSummary: 'Resumen de GitHub', // Spanish
  githubUser: 'Usuario de GitHub', // Spanish
  totalLinesChangedLastWeek: 'Total de líneas modificadas la semana pasada', // Spanish
  totalLinesChangedThisWeek: 'Total de líneas modificadas esta semana', // Spanish
  allRepoCheckins: 'Todos los check-ins del repositorio', // Spanish
  currentWeek: 'Semana actual', // Spanish
  previousWeek: 'Semana anterior', // Spanish
  prCount: 'Conteo de PR', // Spanish
  prSummary: 'Resumen de PR', // Spanish
  submittedPRCount: 'Número de PR enviados', // Spanish
  approvedPRCount: 'Número de PR aprobados', // Spanish
  totalPRCount: 'Número total de PR', // Spanish
  ciCdPipeline: 'Pipeline CI/CD', // Spanish
  pipelineExecutionDetails: 'Detalles de ejecución del pipeline', // Spanish
  pipelineName: 'Nombre del pipeline', // Spanish
  pipelineVersion: 'Versión del pipeline', // Spanish
  executionID: 'ID de ejecución', // Spanish
  revisionSummary: 'Resumen de la revisión', // Spanish
  revisionURL: 'URL de la revisión', // Spanish
  executionDate: 'Fecha de ejecución', // Spanish
  sonarqubeStats: "Estadísticas de Sonarqube",
  sonarqubeprojectkey:"Clave del Proyecto Sonarqube",
  detailsForGitHubRepo: "Detalles del repositorio en GitHub",
  projectKey: "Clave del proyecto",
  ncloc: "Ncloc",
  bugs: "Bugs",
  vulnerabilities: "Vulnerabilidades",
  codeSmells: "Olores de código",
  duplicatedLinesDensity: "Densidad de líneas duplicadas",
  cloudCost: "Costo en la Nube",
  services: "Servicios",
  cost: "Costo",
  costDetails: "Detalles del costo",
  timeLog: "Registro de tiempo",
  selectFilter: "Seleccionar filtro",
  candidates: "Candidatos",
  linesOfCodeByDay: "Líneas de código por día",
  selectAUser: "Seleccionar un usuario",
  sprintVelocity: "Velocidad de sprint",
  reviewAndRating: "Revisión y Calificación",
  pod: "Pod",
  client: "Cliente",
  candidate: "Candidato",
  noCandidateReviews: "No hay revisiones de candidatos.",
  individual: "Individual",
    manageProfileHere: "Administra tu perfil aquí",
    updateProfileHere: "Actualiza tu perfil aquí",
    addPortfolio: "Agregar portafolio",
    basicInformation: "Información básica",
    skillsAndExpertise: "Habilidades y experiencia",
    skills: "Habilidades",
    industry: "Industria",
    techStack: "TechStack",
    educationAndCertifications: "Educación y Certificaciones",
    education: "Educación",
    certifications: "Certificaciones",
    workExperience: "Experiencia laboral",
    podExperience: "Experiencia de Pod",
    noExperience: "Sin experiencia",
    otherInformation: "Otra información",
    github: "Github",
    linkedIn: "LinkedIn",
    preferredWorkHours: "Horas de trabajo preferidas",
    ratePerHour: "Tarifa /hora",
    calendlyUrl: "URL Calendly",
    talentCode: "Código de talento",
    currency: "Moneda",
    resume: "Currículum",
    resumeLink: "Ver Currículum",
    firstName: "Nombre",
    middleName: "Segundo nombre",
    lastName: "Apellido",
    country: "País",
    about: "Acerca de",
    primarySkill: "Habilidad principal",
    secondarySkill: "Habilidad secundaria",
    qualification: "Calificación",
    specialization: "Especialización",
    university: "Universidad",
    startDate: "Fecha de inicio",
    endDate: "Fecha de fin",
    add: "Agregar",
    institute: "Instituto",
    certificationUrl: "URL de certificación",
    validFrom: "Válido desde",
    validTill: "Válido hasta",
    companyName: "Nombre de la empresa",
    positionName: "Nombre del puesto",
    portfolioUrl: "URL del portafolio",
    linkedInUrl: "URL de LinkedIn",
    githubUrl: "URL de Github",
    preferredWorkHoursMonthly: "Horas de trabajo preferidas /mes",
    ratePerMonth: "Tar",
    postActivity: "Publicar actividad",
      viewActivity: "Ver actividad",
      postPodActivity: "Publicar actividad de Pod",
      languageSpoken: "Idioma hablado",
      categories: "Categorías",
      fileUpload: "Carga de archivos",
      fileNameNoSpaces: "El nombre del archivo no debe incluir espacios entre todos los elementos o caracteres",
      upload: "Subir",
      userStory: "Historia de usuario",
      clickHereUploadVideoClip: "Haga clic aquí para subir un clip de video !",
      post: "Publicar",
      yourPostedPodActivity: "Su actividad de Pod publicada",
      postedDate: "Fecha de publicación",
      jiraIssue: "Problema de Jira",
      videoUrl: "URL del video",
      todaysFocus: "Enfoque del día",
      blocker: "Bloqueador",
  podAssist: "Asistente de Pod",
  board: "Tablero",
  myPods: "Mis Pods",
  myTasks: "Mis tareas",
  podMetrics: "Métricas de Pod",
  podFeedback: "Feedback de Pod",
  blockers:"bloqueadores",
  noBlockers: "No hay bloqueadores",
  internalPod: "Pod interno",
  externalPod: "Pod externo",
  podType: "Tipo de Pod",
  viewPodDetails: "Ver detalles del Pod",
  findBestPod: "Encuentra grupos que coincidan con tu experiencia/conjunto de habilidades",
  createYourOwnPod: "Crea tu propio Pod y busca proyectos",
  enterPodName: "Ingresar el nombre del Pod",
  selectArtName: "Seleccionar el nombre del ART",
  noArtOptionsAvailable: "No hay opciones de ART disponibles",
  enterPodMission: "Ingresar la misión del Pod",
  podTechStack: "Tech Stack del Pod",
  create: "Crear",
  privatePods: "Pods privados",
  activePods: "Pods activos",
  previousPods: "Pods anteriores",
  pendingPods: "Pods pendientes",
  podDescription: "Descripción del Pod",
  date: "Fecha",
  viewDetails: "Ver detalles",
  noCandidatesAddedToPod: "¡No hay candidatos añadidos al Pod!",
  viewPodActivities: "Ver actividades del Pod",
  viewTimeEntries: "Ver entradas de tiempo",
  select: "Seleccionar",
  viewVideo: "Ver video",
  approved: "Aprobado",
  pending: "Pendiente",
  rowsPerPage: "Filas por página",
  selectItemsPerPage: "Seleccionar elementos por página",
  noReviews: "No hay revisiones",
  approve: "Aprobar",
  noVideoAvailable: "No hay video disponible",
  dateRangeFilter: "Filtro de rango de fechas",
  podTasks: "Tareas de Pod",
  createTask: "Crear tarea",
  viewTasks: "Ver tareas",
  podRequests: "SOLICITUDES DE POD",
  sprint: "Sprint",
  startDoing: "Empezar a hacer",
  stopDoing: "Dejar de hacer",
  keepDoing: "Seguir haciendo",
  save: "Guardar",
  phoneNumber: "Número de teléfono",
  createProfile: "Crear perfil",
  createProfileOpportunity: "Crea tu perfil en nuestra base de talentos para ser incluido en diversas oportunidades de trabajo remoto.",
  region: "Región",
  creatingProfile: "Creando perfil",
  addStakeholder: "Agregar Stakeholder",
  manage: "Gestionar",
  deactivatePod: "Desactivar Pod",
  delete: "Eliminar",
  invite: "Invitar",
  setting: "Configuración",
  noStakeholders: "No hay stakeholders para este pod",
  editPod: "Edite su pod y navegue por proyectos",
  deactivateToUpdate: "¡Desactive el pod para actualizar el pod!",
  managePod: "Gestionar Pod",
  taskForm: "Formulario de tarea",
  podTaskName: "Nombre de la tarea de Pod",
  podTaskDescription: "Descripción de la tarea de Pod",
  podTaskDueDate: "Fecha de vencimiento de la tarea de Pod",
  podTaskOwner: "Propietario de la tarea de Pod",
  podTaskStatus: "Estado de la tarea de Pod",
  podCategories: "Categorías de Pod",
  jiraItem: "Elemento Jira",
  notificationRecipient: "Destinatario de la notificación",
  checkBoxBlocker: "Marque esta casilla si es un bloqueador",
  visitFindTalent: "Por favor, visite la página 'Buscar talento' y agregue individuos talentosos a su pod.",
  name: "Nombre",
  actions: "Acciones",
  gitHubRepo: "Repositorio de GitHub",
  gitHubUsername: "Nombre de usuario de GitHub",
  jiraUsername: "Nombre de usuario de Jira",
  browseTalent: "Buscar talento",
  findBestFreelancers: "¡Encuentra a los mejores freelancers o pods de talento para obtener la entrega de tus proyectos más rápido!",
  addingCandidateWait: "Añadiendo Candidato Espere...",
  clear: "Limpiar",
  search: "Buscar",
  keyword: "Palabra clave",
  applyFilters: "Aplicar Filtros",
  enterEmailToInvite: "Ingrese un correo electrónico para invitar",
  talentApprovalRequests: "Solicitudes de aprobación de talentos",
  approvalRequests: "Solicitudes de aprobación",
  listOfTalents: "Lista de talentos",
  viewProfile: "Ver perfil",
  findTalent: 'Encontrar talento',
  subscription: 'Suscripción',
  invoices: 'Facturas',
  login: 'Iniciar sesión',
};

export default messagesEs;
