import styled from 'styled-components';
import React, { useEffect }  from 'react'
import Badge from './Badge';
import Avatar from "./assets/MaskGroup.png";
import {cardShadow,hoverEffect,themeColor} from '../utils';
import { useState } from 'react';
import ShowMore from 'react-show-more-button/dist/components/ShowMore';
import { NavHashLink } from 'react-router-hash-link';

import { useParams,useHistory, Link } from 'react-router-dom';

import Select from "react-select";

import{FiSearch} from 'react-icons/fi';
import axios from 'axios';
import {FiImage} from "react-icons/fi";
import {BsEmojiSmile} from "react-icons/bs";

import { NavLink } from 'react-router-dom';
import { data } from 'jquery';
import { useDispatch, useSelector } from "react-redux";

import {VscClose} from "react-icons/vsc";

import Pods from './Pods';
import Updatedailystatus from './Updatedailystatus';
import { InsertInvitationRounded } from '@mui/icons-material';
import { useAuthToken } from "../TokenContext";
const Statusupdate = () => {

  const token = useAuthToken();


 const[removebutton,setRemovebutton]=useState(0);
    const { isLoggedIn, user } = useSelector(state => state.auth);
    
    const[getcandlistdata,setGetcandlistdata]=useState([]);
       
    const[Clistdata,setClistdata]=useState([]);
    const[candidateid,setCandidateid]=useState( {});
    
   
    const [poddata, setinputList] = useState([{ categories:'',pod_candidates:'', today_update:'',blockers:'', lead_id: user.data[0].c_id}]);
    




const[value,setvalue] = useState('fruit');

const handleChange = (event) =>{
  setvalue(event.target.value);
 
};
  const params =useParams();

  const handleinputchange=(e, index)=>{
    const {name, value}= e.target;
    const list= [...poddata];
    list[index][name]= value;
    setinputList(list);
    


  }
  
  const handleChangetest=(e, index)=>{
    const {name, value}= e.target;
    const list= [...poddata];
    list[index][name]= value;
    setinputList(list);
    
  }

  
const handleremove= index=>{
  if(poddata.length==1){
    alert('there should be atleast one update field')
    setRemovebutton(1)


  }else{
    const list=[...poddata];
    list.splice(index,1);
    setinputList(list);
  }

}

 const handleaddclick=()=>{
  // setinputList([...inputList,{PodCandidate:'', update:'',Categories:''}]);
  if(poddata.length>=10){
    alert("out of limit");

  }else{
   
    setinputList([...poddata,{categories:'',pod_candidates:'',today_update:'',blockers:'',lead_id: user.data[0].c_id}]);
  }
 }


// const[savedraftdata,setSavedraftdata]=useState({
//   c_id : user.data[0].c_id
// })


const handleSubmit = (e) => {




  e.preventDefault();


  const requestOptions = {
    method: 'POST',
    headers: { 'Authorization' : token, 'Content-Type': 'application/json' },
    body: JSON.stringify({poddata , status: "no_draft",lead_id: user.data[0].c_id })
  
};

var arr = poddata

var clean = arr.filter((arr, index, self) =>
    index === self.findIndex((t) => (t.pod_candidates === arr.pod_candidates)))

if (poddata.length !== clean.length) {
 alert ('pod candidate name should be unique');
}else{
  
  // https://2msq0kl26i.execute-api.us-east-1.amazonaws.com/dev/pod_activity
fetch(process.env.REACT_APP_TALENT_PODACTIVITY,requestOptions)
.then((response) => response.json())


.then((json) => {
if(json.status == 200){
       alert("Added Successfully.")
       window.location.href = "/PodActivityLead"
      //  setTimeout(() => {
      //   window.location.reload();            
      // }, 2000);
     }else if(json.status == 400){
      alert(json.data)
      window.location.href = "/PodActivityLead"
    //   setTimeout(() => {
    //    window.location.reload();            
    //  }, 2000);
    }
  
  });


 }


 

  //  fetch(
  //   "https://f29wk634a8.execute-api.us-east-1.amazonaws.com/dev/pod_activity",
  //    {
  //      method: "POST",
     
  //      body: JSON.stringify(abc),
  //    }
  //  ).then((data) => {
  //   console.log('message',data)
  //    if(data.status == 200){
  //      alert("Posted Successfully.")
  //      setTimeout(() => {
  //       window.location.reload();            
  //     }, 2000);
  //    }else if(data.status == 400){
  //     alert(data.data)
  //     setTimeout(() => {
  //      window.location.reload();            
  //    }, 2000);
  //   }
  //  });
 };
 
 useEffect(() => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Authorization' : token, 'Content-Type': 'application/json' },
    body: JSON.stringify({
      lead_id: user.data[0].c_id
      
  })
  
  };
  // https://r9421gdng7.execute-api.us-east-1.amazonaws.com/dev/getcandidatelist
  fetch(process.env.REACT_APP_TALENT_GETCANDIDATELIST,requestOptions)
  .then((response) => response.json())
  
  
  .then((json) => { 


    var selectvaluefromallergens = []
    {json.data.data.map((clist) => {
      
       selectvaluefromallergens.push({value:clist.c_id,pod_candidates:clist.firstName});
  

    }) } 
    setGetcandlistdata(json.data.data);

    setClistdata(selectvaluefromallergens);
  
  
  });
  
  

}, [token])



 const handleSubmitsavedraft = (e) => {
  e.preventDefault();
  const requestOptions = {
    method: 'POST',
    headers: { 'Authorization' : token, 'Content-Type': 'application/json' },
    body: JSON.stringify({poddata , status: "draft",lead_id: user.data[0].c_id })
  
};


var arr = poddata

var clean = arr.filter((arr, index, self) =>
    index === self.findIndex((t) => (t.pod_candidates === arr.pod_candidates)))

console.log('consoledata2',clean);
if (poddata.length !== clean.length) {
 alert ('pod candidate name should be unique');
}else{


  // https://2msq0kl26i.execute-api.us-east-1.amazonaws.com/dev/pod_activity
fetch(process.env.REACT_APP_TALENT_PODACTIVITY,requestOptions)
.then((response) => response.json())


.then((json) => {
   
if(json.status == 200){
       alert("Posted Successfully.")
       setTimeout(() => {
        window.location.reload();            
      }, 2000);
     }else if(json.status == 400){
      alert(json.data)
      setTimeout(() => {
       window.location.reload();            
     }, 2000);
    }
  
  });
 };
}

 const Candname = user.data[0].firstName
 const profilePic = user.data[0].profilePicture
 


   
    
  
   const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 }
  ];
  function timeSince(date) {
    const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
    const interval = intervals.find(i => i.seconds < seconds);
    const count = Math.floor(seconds / interval.seconds);
    return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
  

  }

 


 

  return (

   
    <Div >

      

<TitleText>Post Pod Activity</TitleText>

<RecommendProject>



<CardContent>




<form  onSubmit={handleSubmit}>




        




   
 

{
  poddata.map( (x,i)=>{
  return<div>
    
    <Row1 >
        <label style={{fontWeight:'600',color:'grey'}} required>  Pod team member   <span>:</span>
        </label>

<SelectField 
    
    id="pod_candidates"
    name='pod_candidates' onChange={ e=>handleinputchange(e,i)}
    value= {x.pod_candidates} required>
        <option  pod_candidates="pod_candidates" defaultValue >Pod team member</option>
        {
  Clistdata.map( (data,i)=>{
    return <option   key={i} pod_candidates= {data.pod_candidates}>{data.pod_candidates}</option>
  })}
        </SelectField>
        

    
        
        
        </Row1>
        <Row1>
        <label style={{fontWeight:'600',color:'grey'}}>  Categories   <span>:</span>    </label>

  <SelectField 
    
      id="categories"
      name='categories'    onChange={ e=>handleinputchange(e,i)}
      value={x.categories} required >

          <option  Categories="Categories" defaultValue >Categories</option>
          <option Categories="Design" >Design</option>
          <option  Categories="Coding">Coding</option>
          <option  Categories="Testing">Testing</option>
          <option  Categories="Resolving Bugs">Resolving Bugs</option>
          <option  Categories="Deployment">Deployment</option>
          </SelectField>
          
      
        
        
          </Row1>


  
 
  <Textarea 
name='today_update'
value={x.today_update}
onChange={ e=>handleinputchange(e,i)}
required
placeholder="Today's Focus . . ."/>
   
   <Textarea 
name='blockers'
value={x.blockers}
onChange={ e=>handleinputchange(e,i)}

placeholder="Blocker . . ."/>
        

 <TextDiv>
  
{ poddata.length-1===i &&
<Buttonadd onClick={ handleaddclick}>Add +</Buttonadd>
} 

 
{
poddata.length!==0 &&
      
      <ButtonRemove onClick={()=> handleremove(i)}>Remove</ButtonRemove>
          }
        
</TextDiv>     

  
  </div>
  

})}



{/* <Row1>
<FiImage     style={{fontSize:'1.5rem'}}/>


<BsEmojiSmile  style={{marginLeft:"20px",fontSize:'1.5rem'}}/>

</Row1> */}


{/* <Button1
onClick={handleSubmitsavedraft}

>


Save As Draft

</Button1>  */}
<div  style={{marginLeft:'auto*'}}>
{/* <NavLink  to ='/VIewDailyPostedUpdates' > */}
<Button type='submit'>
Add 
</Button>

{/* </NavLink> */}
</div>

             
</form>
</CardContent>
{/* <SideCard>
 <H1>Recommended Pods</H1>
  <SideCardContent>
    
<Row>
 
            <Detail>
              <InfoContainer>
                <img
                  src={Avatar}
                  alt=""
                  style={{ width: 50, height: 50 }}
                ></img>
                <Info>
                  <InfoName>Thomas Martin</InfoName>
                  <InfoUpdate>10 days ago</InfoUpdate>
                </Info>
              </InfoContainer>
            </Detail>
          </Row>
      

          <Title>
            Need a designer to form branding essentials for my bussiness

            
          </Title>
          <ProjectInfo>
          Required Position: UX/UI Designer
          </ProjectInfo>

          </SideCardContent>
         <ViewAll>View All</ViewAll> 
         <SideCardContent1>

<Title>
         View Saved Draft
            
          </Title>
          <View>
            
       <NavHashLink to = '/Updatedailystatus'  smooth>
       
            View</NavHashLink> 
            
             </View>
             </SideCardContent1>
  
          
</SideCard> */}


</RecommendProject>

     </Div>
  )
}


const Div =styled.div`

// display:flex;
display:inline;
jutify-content:center;
flex-direction:column;
margin-top:6rem;
align-items:center;

margin-left:6.5rem;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;

  overflow:hidden;
justify-content:center;
align-items:center;
margin:auto;
width:25rem;
  }
`;

const RecommendProject=styled.div`

 display:flex;

jutify-content:center;
flex-direction:row;
@media screen and (min-width:320px) and (max-width:1080px){
  flex-direction:column;
    width:80%;
    height:max-content;
    margin:0.4rem;
    
   }
`;


const TitleText=styled.h4`
font-weight:600;

@media screen and (min-width:320px) and (max-width:1080px){

  margin-top:2rem;
 
}
`;
const CardContent=styled.div`
margin:0.4rem;
margin-top:2rem;
display:flex;
flex-direction:column;
// justify-content:center;
// align-items:center;


border-radius:20px;
font-family:Poppins,Medium;
width:40rem;
height:max-height;
padding: 1rem;
background-color: #F2F4F5;

box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};
}

@media screen and (min-width:320px) and (max-width:1080px){
  width:20rem;
  margin-top:2rem;
 
  
 }
`;





const InputField=styled.input`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
padding-left:1.5rem;
font-weight:600;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;

  
 }
`;


const SelectField=styled.select`
margin-top:10px;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
font-weight:600;

color:grey;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;
margin:auto;
  
 }
`;
const InputFieldCandidates=styled.input`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
font-weight:600;
color:grey;
margin-left:10px;
padding-left:1.5rem;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;

  margin-left:-2px;
 }
`;


const SelectField1=styled.div`
margin-top:1rem;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
font-weight:600;
color:grey;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;

  
 }
`;


const Button=styled.button`
margin-left:auto;
border:none;
border-radius: 10px;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
width:8rem;
height:2rem;
font-size:18px;
font-weight:600;
color:white;
margin-top:4rem;
@media screen and (min-width:320px) and (max-width:1080px){

  width:7rem;
}
`;


const Button1=styled.button`
margin-right:auto;
border:none;
border-radius: 10px;
background-color:#00B4D8
;
box-shadow: 1px 3px 6px #00000029;
width:8rem;
height:max-height;
font-size:16px;
padding:8px;
font-weight:600;
color:white;
margin-top:1rem;
@media screen and (min-width:320px) and (max-width:1080px){

  width:7rem;
}
`;




const SideCard=styled.div`
margin-left:5rem;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
border-radius:20px;
font-family:Poppins,Medium;
width:20rem;
height:32rem;

padding: 1rem;
background-color: #F2F4F5;

box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};
}

@media screen and (min-width:320px) and (max-width:1080px){

margin:auto;
margin-top:3rem;
  width:20rem;
}
`;


const SideCardContent=styled.div`
background-color:#90E0EF;
width:17rem;
display:flex;
flex-direction:column;
padding:3px;


border-radius:20px;
`;


const SideCardContent1=styled.div`
background-color:#90E0EF;
width:17rem;
display:flex;
margin-top:1rem;
flex-direction:column;
height:10rem;
justify-content:center;
align-items:center;
border-radius:20px;

`;





const Detail = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer = styled.div`
  display: flex;
margin:auto;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const Row = styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
`;
const Row1 = styled.div`
  display: flex;
  flex-direction:column;
margin-top:2rem;

  align-item: center;
  margin-bottom: 0.8rem;
  @media screen and (min-width:320px) and (max-width:1080px){
    display: flex;
    flex-direction:column;
  }
`;
const InfoName = styled.h6`
  font-weight: 600;
`;
const InfoUpdate = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;

const Title = styled.h6`
  font-weight: 600;
`;
const ProjectInfo = styled.p`
padding:5px;
margin:3px;
background-color:white;
border-radius:5px  ;
font-size:13px;
color:black;
font-weight:600;
margin-bottom:1rem
`;

const ViewAll =styled.button`
width:5rem;
font-weight:600;
height:2rem;
margin-left:auto;
margin-top:1rem;
border: 2px solid #1DBF73;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;

const View =styled.button`
width:8rem;
font-weight:600;
height:2rem;
color:black;
background-color:white;
border-radius:5px  ;
margin-bottom:1rem;
margin-top:1rem;
border: none;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;

const H1 = styled.h1`
font-size:22px;
font-weight:600;
margin-bottom:1.2rem;
`;

const InputContainer = styled.div`
display : flex;
border-radius:10px;
justify-content: center;
margin-left:auto;
width:25rem;
margin-bottom:1rem;
border:1px solid #4E8FAE;
box-shadow: 3px 6px 9px #00000005;
@media screen and (min-width:320px) and (max-width:1080px){
  margin:auto;
  margin-top:2rem;

    width: 20rem;
  
 
`;

const Icon = styled.div`
height: 3rem;
width: 2rem;
background-color: #F2F3F4;
display:flex;
flex-direction:row
justify-content: center;
align-items: center;
border-top-left-radius:10px;
border-bottom-left-radius:10px;
svg{
    color:#555555;
}
`;

const Input = styled.input`
justify-content: center;
height: 3rem;
width: 25rem;
border:0px solid #4E8FAE;
background-color:#F2F3F4;
font-family:Poppins,Medium;
font-Weight:600;
border-top-right-radius:10px;
border-bottom-right-radius:10px;
&:focus{
    border:none;
    outline:none;
}
@media screen and (min-width:320px) and (max-width:1080px){
  width: 20rem;
}
`;

const InfoPod = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-left: 15px;
`;


const Textarea = styled.textarea`
width:25rem;
margin-top:1rem;

tex-decoration:none;
border:1px solid #cccccc;
border-radius:5px;

padding:1rem;
font-size:14px;
font-family:Poppins,Medium;
font-Weight:600;
  outline: none;
  @media screen and (min-width:320px) and (max-width:1080px){
    width: 15rem;
  }


`;

const TextDiv = styled.div`
display:flex;
flex-direction:row;




`;

const Buttonadd = styled.button`
tex-decoration:none;
border:none;
display:flex;
// margin-right:auto;
border-radius:5px;
font-size:14px;
font-family:Poppins,Medium;
font-Weight:600;
  outline: none;
width:4rem;
justify-content:center;
align-items:center;
height:2.5rem;
margin-top:0.5rem;
color:white;
background-color:#00B4D8;

`;

const ButtonRemove = styled.button`
text-decoration:none;
border:none;
border-radius:5px;
font-size:14px;
font-family:Poppins,Medium;
font-Weight:600;
  outline: none;
width:4.5rem;
height:2.5rem;
margin-top:0.5rem;
margin-left:10px;
color:white;
background-color:#00B4D8;

`;




export default Statusupdate



