import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from "react-redux";
import { useAuthToken } from '../TokenContext';
import { FaImage } from 'react-icons/fa';

// AWS Configuration
const configureAWS = () => {
  AWS.config.update({
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
  });
  return new AWS.S3();
};

// Reusable image upload function
const uploadImageToS3 = async (imageFile, s3) => {
  const imageKey = `portfolio-images/${uuidv4()}.png`;
  const uploadParams = {
    Bucket: process.env.REACT_APP_BUCKETNAME,
    Key: imageKey,
    Body: imageFile,
    ContentType: 'image/png',
  };

  try {
    const uploadResult = await s3.upload(uploadParams).promise();
    return uploadResult.Location;
  } catch (error) {
    console.error('Error uploading image to S3:', error);
    throw error;
  }
};

const ImageGalleryItem = ({ image, onImageClick }) => (
  <Card>
    {image ? (
      <img
        src={image}
        alt="Portfolio item"
        style={{ width: '100%', height: '100%', borderRadius: '8px', cursor: 'pointer' }}
        onClick={() => onImageClick(image)}
      />
    ) : (
      <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <FaImage style={{ fontSize: '50px', color: '#ccc' }} />
      </div>
    )}
  </Card>
);

const PortfolioSlide = ({ item, onImageClick }) => (
  <Slide>
    <div>
      <h3>{item.project_objective}</h3>
      <p>{item.project_description}</p>
      <p>Industry: {item.industry}</p>
    </div>
    <ImageGallery>
      {item.project_files.map((image, imageIndex) => (
        <ImageGalleryItem 
          key={imageIndex} 
          image={image} 
          onImageClick={onImageClick}
        />
      ))}
    </ImageGallery>
  </Slide>
);

const NavigationButtons = ({ currentIndex, totalItems, onPrev, onNext }) => (
  <>
    <Button prev onClick={onPrev} disabled={currentIndex === 0}>
      &#10094;
    </Button>
    <Button next onClick={onNext} disabled={currentIndex === totalItems - 1}>
      &#10095;
    </Button>
  </>
);

const PortfolioList = ({ candidateid }) => {
  const { user } = useSelector(state => state.auth);
  const token = useAuthToken();
  const s3 = configureAWS();

  const [portfolioItems, setPortfolioItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalImageUrl, setModalImageUrl] = useState(null);

  const fetchPortfolioData = async () => {
    try {
      setLoading(true);
      const response = await fetch(process.env.REACT_APP_TALENT_LISTPORTFOLIO, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          portfolio_user_id: candidateid,
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        setPortfolioItems(responseData.body);
      }
    } catch (error) {
      console.error('Error fetching portfolio data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPortfolioData();
  }, [token, candidateid]);

  const handleImageClick = (imageUrl) => setModalImageUrl(imageUrl);
  const nextSlide = () => {
    setCurrentIndex(prev => prev === portfolioItems.length - 1 ? 0 : prev + 1);
  };
  const prevSlide = () => {
    setCurrentIndex(prev => prev === 0 ? portfolioItems.length - 1 : prev - 1);
  };

  const renderContent = () => {
    if (loading) {
      return <p>Loading...</p>;
    }

    if (!Array.isArray(portfolioItems) || portfolioItems.length === 0) {
      return <p>No portfolio records found.</p>;
    }

    return (
      <SlideshowContainer>
        <SlideshowImageContainer>
          {portfolioItems.map((item, index) => (
            <div key={index} style={{ display: index === currentIndex ? 'block' : 'none' }}>
              <PortfolioSlide 
                item={item}
                onImageClick={handleImageClick}
              />
            </div>
          ))}
        </SlideshowImageContainer>
        <NavigationButtons 
          currentIndex={currentIndex}
          totalItems={portfolioItems.length}
          onPrev={prevSlide}
          onNext={nextSlide}
        />
      </SlideshowContainer>
    );
  };

  return (
    <Container>
      <Portfolio>
        {renderContent()}
      </Portfolio>
    </Container>
  );
};

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 80%;
`;

const Card = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 10px;
  margin: 5px;
  width: 45%;
  height: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
`;

const SlideshowContainer = styled.div`
  position: relative;
  width: 100%;
`;

const SlideshowImageContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

const Slide = styled.div`
  text-align: center;
  padding: 20px;
`;

const ImageGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Button = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: black;
  border: none;
  outline: none;
  font-size: 18px;
  padding: 15px;
  cursor: pointer;
  z-index: 1;
  ${props => props.prev ? 'left: 0;' : 'right: 0;'}
`;

const Portfolio = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);

  h2 {
    margin-bottom: 1rem;
  }
`;

export default PortfolioList;