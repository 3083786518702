import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Paper,
  Avatar,
  Typography,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Divider,
  Breadcrumbs,
  Card,
  CardContent,
  styled,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import {
  Edit as EditIcon,
  ArrowBack as ArrowBackIcon,
  Person as PersonIcon,
  PhotoCamera as PhotoCameraIcon,
  Save as SaveIcon,
  Close as CloseIcon,
  Phone as PhoneIcon,
  Public as PublicIcon,
  Info as InfoIcon
} from '@mui/icons-material';
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import S3FileUpload from 'react-s3';
import AWS from 'aws-sdk';
import { CostomerupdateProfile } from "./action/auth";
import { useAuthToken } from "../TokenContext";
import defaultimg from './assets/defaultimge.png';

// AWS Configuration
const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
};

const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
};

const countries = [
  { value: 'USA', label: 'USA' },
  { value: 'Canada', label: 'Canada' },
  { value: 'India', label: 'India' }
];

const countryCodes = ['+1', '+44', '+91', '+81', '+86'];

// Styled Components
const ProfileContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(10),
  marginBottom: theme.spacing(8)
}));

const ProfileCard = styled(Card)(({ theme }) => ({
  borderRadius: 16,
  boxShadow: '0 4px 24px 0 rgba(34, 41, 47, 0.1)',
  overflow: 'visible',
  position: 'relative'
}));

const ProfileHeader = styled(Box)(({ theme }) => ({
  height: 200,
  background: 'linear-gradient(135deg, #03045e 0%, #0077b6 100%)',
  borderRadius: '16px 16px 0 0',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  padding: '16px',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '40%',
    background: 'linear-gradient(to top, rgba(0,0,0,0.4), transparent)'
  }
}));

const ProfileAvatarWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  bottom: -60,
  transform: 'translateX(-50%)',
  zIndex: 2
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 120,
  height: 120,
  border: `4px solid ${theme.palette.background.paper}`,
  boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    backgroundColor: theme.palette.background.paper,
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#f8fafc'
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
      boxShadow: '0 0 0 2px rgba(0, 180, 216, 0.2)'
    }
  }
}));

const PhoneInputWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(3)
}));

const InfoSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.grey[50],
  borderRadius: 12,
  marginBottom: theme.spacing(3)
}));

const CustomerProfile = () => {
  const token = useAuthToken();
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector(state => state.auth);

  const [state, setState] = useState({
    fname: user.data[0].customer_firstname || '',
    mname: user.data[0].customer_middlename || '',
    lname: user.data[0].customer_lastname || '',
    country: user.data[0].customer_country || '',
    about: user.data[0].about || '',
    email: user.data[0].email || '',
    phone_number: user.data[0].phone_number || '',
    profilePicture: user.data[0].profilePicture || ''
  });

  const [phoneState, setPhoneState] = useState({
    countryCode: state.phone_number ? state.phone_number.slice(0, 3) : '+1',
    number: state.phone_number ? state.phone_number.slice(3) : ''
  });

  const [edit, setEdit] = useState(user.data[0].profile === 0);
  const [image, setImage] = useState('');
  const [uploading, setUploading] = useState(false);
  const [authImage, setAuthImage] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    generatePreSignedUrl();
  }, []);

  const generatePreSignedUrl = async () => {
    if (!state.profilePicture) return;

    try {
      const response = await fetch(process.env.REACT_APP_TALENT_CLOUDFRONTAPI, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          url: process.env.REACT_APP_CLOUDFRONT + state.profilePicture
        })
      });
      
      const data = await response.json();
      if (data.body) {
        const signedUrl = JSON.parse(data.body).signedUrl;
        setAuthImage(signedUrl);
      }
    } catch (error) {
      console.error("Error generating signed URL:", error);
      setSnackbar({
        open: true,
        message: 'Failed to load profile picture',
        severity: 'error'
      });
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (file.size > 5 * 1024 * 1024) {
      setSnackbar({
        open: true,
        message: "File size should be less than 5MB",
        severity: "error"
      });
      return;
    }

    setUploading(true);
    try {
      const preview = URL.createObjectURL(file);
      setImage(preview);

      const uploadResult = await S3FileUpload.uploadFile(file, config);
      setState(prev => ({
        ...prev,
        profilePicture: uploadResult.key
      }));

      setSnackbar({
        open: true,
        message: "Profile picture updated successfully",
        severity: "success"
      });
    } catch (error) {
      console.error("Upload error:", error);
      setSnackbar({
        open: true,
        message: "Failed to upload profile picture",
        severity: "error"
      });
      setImage("");
    } finally {
      setUploading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    try {
      const data = {
        customer_id: user.data[0].customer_id,
        customer_firstname: state.fname,
        phone_number: `${phoneState.countryCode}${phoneState.number}`,
        customer_middlename: state.mname,
        customer_lastname: state.lname,
        customer_country: state.country,
        about: state.about,
        profilePicture: state.profilePicture
      };

      await dispatch(CostomerupdateProfile(data, {
        'Authorization': token,
        'Content-Type': 'application/json'
      }));

      setSnackbar({
        open: true,
        message: "Profile updated successfully",
        severity: "success"
      });

      setTimeout(() => {
        window.location.href = "/subscriptions";
      }, 1500);
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to update profile",
        severity: "error"
      });
    }
  };

  const handleInputChange = (e) => {
    setState(prev => ({
      ...prev,
      [e.target.id]: e.target.value
    }));
  };

  return (
    <ProfileContainer maxWidth="lg">
      <Box sx={{ mb: 4 }}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link 
            to="/subscriptions"
            style={{
              color: '#03045e',
              textDecoration: 'none',
              fontSize: '16px',
              fontWeight: 600
            }}
          >
            Subscription
          </Link>
          <Typography color="textSecondary" fontWeight={600}>
            Manage Profile
          </Typography>
        </Breadcrumbs>
      </Box>

      <ProfileCard>
        <ProfileHeader>
          <ProfileAvatarWrapper>
            <ProfileAvatar
              src={image || authImage || defaultimg}
              alt={state.fname}
            />
            {edit && (
              <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  opacity: 0,
                  transition: '0.3s',
                  borderRadius: '50%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  '&:hover': { opacity: 1 }
                }}
              >
                <label htmlFor="profile-image">
                  <input
                    accept="image/*"
                    type="file"
                    hidden
                    id="profile-image"
                    onChange={handleImageUpload}
                  />
                  <IconButton 
                    component="span"
                    sx={{ 
                      color: 'white',
                      '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' }
                    }}
                  >
                    <PhotoCameraIcon />
                  </IconButton>
                </label>
              </Box>
            )}
          </ProfileAvatarWrapper>

          {!edit && (
            <IconButton
              sx={{
                position: 'absolute',
                right: 16,
                top: 16,
                backgroundColor: 'white',
                '&:hover': { backgroundColor: '#f0f0f0' }
              }}
              onClick={() => setEdit(true)}
            >
              <EditIcon />
            </IconButton>
          )}
        </ProfileHeader>

        <CardContent sx={{ pt: 10 }}>
          {edit ? (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <StyledTextField
                    fullWidth
                    label="First Name"
                    id="fname"
                    value={state.fname}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <StyledTextField
                    fullWidth
                    label="Middle Name"
                    id="mname"
                    value={state.mname}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <StyledTextField
                    fullWidth
                    label="Last Name"
                    id="lname"
                    value={state.lname}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <PhoneInputWrapper>
                    <FormControl sx={{ width: '30%' }}>
                      <InputLabel>Code</InputLabel>
                      <Select
                        value={phoneState.countryCode}
                        onChange={(e) => setPhoneState(prev => ({
                          ...prev,
                          countryCode: e.target.value
                        }))}
                        label="Code"
                      >
                        {countryCodes.map(code => (
                          <MenuItem key={code} value={code}>{code}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <StyledTextField
                      sx={{ width: '70%' }}
                      label="Phone Number"
                      value={phoneState.number}
                      onChange={(e) => setPhoneState(prev => ({
                        ...prev,
                        number: e.target.value
                      }))}
                      required
                    />
                  </PhoneInputWrapper>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth sx={{ mb: 3 }}>
                    <InputLabel>Country</InputLabel>
                    <Select
                      value={state.country}
                      onChange={(e) => setState(prev => ({
                        ...prev,
                        country: e.target.value
                      }))}
                      label="Country"
                      required
                    >
                      {countries.map(country => (
                        <MenuItem key={country.value} value={country.value}>
                          {country.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <StyledTextField
                    fullWidth
                    multiline
                    rows={4}
                    label="About"
                    id="about"
                    value={state.about}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <StyledTextField
                    fullWidth
                    label="Email"
                    value={state.email}
                    disabled
                    type="email"
                  />
                </Grid>
              </Grid>

              <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
                <Button
                  variant="outlined"
                  onClick={() => setEdit(false)}
                  startIcon={<CloseIcon />}
                >Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  disabled={uploading}
                  sx={{
                    backgroundColor: '#03045e',
                    '&:hover': { backgroundColor: '#0077b6' }
                  }}
                >
                  {uploading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    user.data[0].profile === 0 ? 'Create Profile' : 'Save Changes'
                  )}
                </Button>
              </Box>
            </form>
          ) : (
            <Box>
              <Typography variant="h5" align="center" gutterBottom>
                {`${state.fname} ${state.lname}`}
              </Typography>
              <Typography 
                variant="body1" 
                color="textSecondary" 
                align="center" 
                sx={{ mb: 4 }}
              >
                {state.email}
              </Typography>
              
              <InfoSection>
                <Typography 
                  variant="h6" 
                  color="primary" 
                  gutterBottom
                  sx={{ fontWeight: 600 }}
                >
                  Basic Information
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <PhoneIcon color="action" />
                      <Box>
                        <Typography variant="subtitle2" color="textSecondary">
                          Phone Number
                        </Typography>
                        <Typography variant="body1">
                          {state.phone_number}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <PublicIcon color="action" />
                      <Box>
                        <Typography variant="subtitle2" color="textSecondary">
                          Country
                        </Typography>
                        <Typography variant="body1">
                          {state.country}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                      <InfoIcon color="action" sx={{ mt: 0.5 }} />
                      <Box>
                        <Typography variant="subtitle2" color="textSecondary">
                          About
                        </Typography>
                        <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                          {state.about}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </InfoSection>
            </Box>
          )}
        </CardContent>
      </ProfileCard>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
          severity={snackbar.severity}
          elevation={6}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {uploading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999,
            backdropFilter: 'blur(4px)'
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <CircularProgress size={40} sx={{ color: '#03045e' }} />
            <Typography sx={{ mt: 2, color: '#03045e' }}>
              Uploading your profile picture...
            </Typography>
          </Box>
        </Box>
      )}
    </ProfileContainer>
  );
};

export default CustomerProfile;