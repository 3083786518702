import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { useAuthToken } from '../TokenContext';
import { useIntl } from 'react-intl';

const SprintVelocitiesCharttalent = ({ initialPodIds }) => {
  const [sprintVelocities, setSprintVelocities] = useState([]);
  const[data,setData]=useState([])
  const { user } = useSelector((state) => state.auth);
  const token = useAuthToken();
  const intl = useIntl();

  const fetchDataPod = async () => {
    if (!token) {
      return; // Exit early if there is no token
    }
    const headers = {
      Authorization: token,
      'Content-Type': 'application/json',
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_TALENT_SPRINTVELOCITYMETRISTALENT,
        { c_id: user.data[0].c_id, pod_id: initialPodIds || [] },
        { headers: headers }
      );
      const responseData = response.data.body;
      const transformedData = responseData.replace(/NaN/g, 'null');
      const newData = JSON.parse(transformedData);
  
      const velocities = newData.map((item) => {
        const velocity = parseFloat(item.sprint_velocities);
        return isNaN(velocity) ? null : velocity;
      });
  
      // Filter out null or NaN values from sprintVelocities
      const filteredSprintVelocities = velocities.filter((velocity) => velocity !== null);
  
      setSprintVelocities(filteredSprintVelocities);
      setData(newData); // Update data state with the new data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  

  useEffect(() => {
    fetchDataPod();
  }, [initialPodIds, token]);
  
  

  // Filter out null or NaN values from sprintVelocities
  const filteredSprintVelocities = sprintVelocities.filter((velocity) => velocity !== null);

  // Extracting relevant information for chart
  const sprintNames = data.map((item) => item.sprint_name);

  // Example colors, you can customize these
  const colors = [
    'rgba(75, 192, 192, 0.5)',
    'rgba(255, 99, 132, 0.5)',
    'rgba(54, 162, 235, 0.5)',
    // Add more colors as needed
  ];

  // Calculate the number of datasets based on the actual data
  const numberOfDatasets = sprintNames.length;

  const datasets = Array.from({ length: numberOfDatasets }, (_, index) => ({
    label: sprintNames[index],
    data: sprintVelocities.map((velocity, velocityIndex) => (velocityIndex === index ? velocity : null)),
    backgroundColor: colors[index % colors.length],
    borderColor: colors[index % colors.length],
    borderWidth: 1,
  }));
  

  const chartData = {
    labels: sprintNames,
    datasets: datasets,
  };

  const chartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Sprint',
        },
      },
      y: {
        type: 'linear',
        beginAtZero: true,
        display: true,
        title: {
          display: true,
          text: 'Velocity',
        },
      },
    },
  };

  return (
    <div className="chart-container">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '20px',
          height: '30rem',
          width: '20rem',
        }}
      >
        <div className="chart-wrapper" style={{ height: '25rem',
          width: '25rem',}}>
          <h2 className="chart-heading">{intl.formatMessage({ id: 'sprintVelocity' })}</h2>
          <br/>
          <br/>
          {filteredSprintVelocities.length > 0 ? (
            <Bar data={chartData} options={chartOptions} />
          ) : (
            <p>{intl.formatMessage({ id: 'noDataAvailable' })}...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SprintVelocitiesCharttalent;
