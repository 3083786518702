import Select, { components as Components } from "react-select";
import styled from "styled-components";
const ValuesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width:20rem;
font-weight:600;
font-family:Poppins,Medium;
  @media screen and (min-width:320px) and (max-width:1080px){
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
   
    width:15rem;
    
  
    }
 
`;
const Div = styled.div`

`;
const Value = styled.div`
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  margin: 10px 10px;
  font-size: 0.75rem;
  color: black;
  background-color: rgba(247, 173, 46, 0.6);
  user-select: none;

`;
const XButton = styled.button`
  all: unset;
  margin-left: 0.3rem;
  color: black;
  transition: fill 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    color: #bb392d;
  }
  &:focus {
    color: #c82f21;
  }
`;


let addedskills =[]
const OptionsOutsideSelect = (props) => {
  const { isMulti, value, onChange } = props;
  const handleRemoveValue = (e) => {
    if (!onChange) return;
    const { name: buttonName } = e.currentTarget;
    const removedValue = value.find((val) => val.value === buttonName);
    if (!removedValue) return;
    onChange(
      value.filter((val) => val.value !== buttonName),
      { name: buttonName, action: "remove-value", removedValue }
    );
 
addedskills.push(value);

  };
  return (
    <Div  >
        <Select  {...props} controlShouldRenderValue={!isMulti} />
      <ValuesContainer>
        {isMulti
          ? value.map((val) => (
              <Value key={val.value}>
                {val.label}
                <XButton name={val.value} onClick={handleRemoveValue}>
                  ✕
                </XButton>
              </Value>
            ))
          : null}
      </ValuesContainer>
    </Div>
  );
};
export default OptionsOutsideSelect;