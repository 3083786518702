import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components';
import {cardShadow,hoverEffect,themeColor} from '../utils';
import { useAuthToken } from "../TokenContext";
const CreateGigForm = () => {
  const [gigName, setGigName] = useState('');
  const [gigDescription, setGigDescription] = useState('');
  const { isLoggedIn, user } = useSelector(state => state.auth);
  const token = useAuthToken();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare the data to send to the API
    const gigData = {
      Gig_Name: gigName,
      Gig_Description: gigDescription,
      Organization_Code:user.data[0].client_code
    };

    try {
      // Send a POST request to the API
      const response = await fetch(process.env.REACT_APP_CLIENT_CREATEAGIG, {
        method: 'POST',
        headers: {
          'Authorization' : token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(gigData),
      });

      if (response.ok) {
        // Handle success, e.g., show a success message
        console.log('Gig created successfully!');
        alert('GIG has been submitted Succesfully.');
        window.location.reload();
        window.history.push('/client-dashboard');

      } else {
        // Handle errors, e.g., show an error message
        console.error('Failed to create gig.');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  return (
    <Div style={{marginTop:'12rem'}}>
        
         
       
        
              
    
         <h2 style={{ textAlign: "center" }} >
    Create GIG</h2>
    <CardContent>
    <CardContent1>
      <form onSubmit={handleSubmit}>
      <Postfield>
          <Label htmlFor="gigName">Gig Name:</Label>
          <InputField
            type="text"
            id="gigName"
            maxLength="50"
            value={gigName}
            onChange={(e) => setGigName(e.target.value)}
            required
          />
         </Postfield>
         <Postfield>
          <Label htmlFor="gigDescription">Gig Description:</Label>
          <InputField
            id="gigDescription"
            value={gigDescription}
            maxLength="200"
            onChange={(e) => setGigDescription(e.target.value)}
            required
          />
        </Postfield>
    
          <Button type="submit">Create Gig</Button>
        
      </form>
      </CardContent1>
      </CardContent>
    </Div>
   
  );
};

export default CreateGigForm;
const Div =styled.div`

 display:flex;
margin:auto;
jutify-content:center;
flex-direction:column;
align-items:center;
margin-top:6rem;


@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  overflow:auto;
justify-content:center;
align-items:center;
margin:auto;
width:24rem;

  }
`;

const Button=styled.button`
display:flex;
justify-content:center;
align-items:center;
margin:auto;
margin-top:1rem;
border:none;
border-radius: 10px;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
width:10rem;
height:3rem;
font-size:18px;
font-weight:600;
color:white;
margin-top:4rem;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;
 
  
 }
`;
const CardContent=styled.div`
margin:0.4rem;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;


border-radius:15px;
font-family:Poppins,Medium;
width:40rem;
height:max-height;
padding: 1rem;
background-color: #F2F4F5;

box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};
}

@media screen and (min-width:320px) and (max-width:1080px){
  width:20rem;
  margin:auto;
 

  
 }
`;

const Postfield=styled.div`
font-family:Poppins,Medium;
display:flex;
justify-content:center;
align-items:center;
margin:auto;
padding:10px

@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  flex-direction:column;
`;
const CardContent1=styled.div`
margin:0.4rem;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;

@media screen and (min-width:320px) and (max-width:1080px){
  width:20rem;
  margin:auto;
 
  
 }


`;
const Label =styled.label`
font-family:Poppins,Medium;
font-weight: 600;
font-size:16px;
color :grey;
width:14rem;
`;



const InputField=styled.input`

color:grey;
border: 1px solid #cccccc;
border-radius: 5px;
width:20rem;
height:3.1rem;
margin-left:1rem;
padding-left:20px;
font-weight:600;
font-family:Poppins,Medium;
@media screen and (min-width:320px) and (max-width:1080px){
  width:15rem;
 
  
 }

`;