import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuthToken  } from '../TokenContext';
import {  useSelector } from "react-redux";
import styled from 'styled-components';
import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';
const PortfolioComponent = () => {
  const [portfolioItems, setPortfolioItems] = useState([{ project_objective: '', project_description: '', industry: '', project_files: [] }]);
  const [loading, setLoading] = useState(false);
  const [portfolioDataFetched, setPortfolioDataFetched] = useState(false);
  const { isLoggedIn, user } = useSelector(state => state.auth);
  const token = useAuthToken();



  AWS.config.update({
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
  });

  const s3 = new AWS.S3();
  const uploadImageToS3 = async (imageFile) => {
    const imageKey = `portfolio-images/${uuidv4()}.png`;
    const uploadParams = {
      Bucket: process.env.REACT_APP_BUCKETNAME,
      Key: imageKey,
      Body: imageFile,
      ContentType: 'image/png',
    };

    try {
      const uploadResult = await s3.upload(uploadParams).promise();
      return uploadResult.Location;
    } catch (error) {
      console.error('Error uploading image to S3:', error);
      throw error;
    }
  };

  useEffect(() => {
    fetchPortfolioData();
  }, []);

  const fetchPortfolioData = async () => {
    try {
      setLoading(true);
      const portfolioUserId =
        user.data[0].type === 'Provider' ? user.data[0].id : user.data[0].c_id;
      const response = await fetch(process.env.REACT_APP_TALENT_LISTPORTFOLIO, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          portfolio_user_id: portfolioUserId,
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.body.length > 0) {
          setPortfolioItems(responseData.body);
        }
        setPortfolioDataFetched(true);
      } else {
        console.error('Error fetching portfolio data:', response.statusText);
        setPortfolioDataFetched(false);
      }
    } catch (error) {
      console.error('Error fetching portfolio data:', error);
      setPortfolioDataFetched(false);
    } finally {
      setLoading(false);
    }
  };

  const handlePortfolioItemChange = (index, field, value) => {
    const updatedItems = [...portfolioItems];
    updatedItems[index][field] = value;
    setPortfolioItems(updatedItems);
  };

  const handleImageChange = async (index, files) => {
    const updatedItems = [...portfolioItems];
    const imageUrls = await Promise.all([...files].map(uploadImageToS3)); // Assume uploadImageToS3 is a function that handles image upload
    updatedItems[index].project_files = imageUrls;
    setPortfolioItems(updatedItems);
  };

  const addNewPortfolioItem = () => {
    setPortfolioItems([...portfolioItems, { project_objective: '', project_description: '', industry: '', project_files: [] }]);
  };

  const handleUpdatePortfolio = async (event) => {
    event.preventDefault();
    try {
      const portfolioUserId =
        user.data[0].type === 'Provider' ? user.data[0].id : user.data[0].c_id;
      const payload = portfolioItems.map((item) => ({
        portfolio_user_id: portfolioUserId,
        project_objective: item.project_objective,
        project_description: item.project_description,
        industry: item.industry,
        project_files: item.project_files,
      }));

      const response = await axios.put(
        'https://fl1lf3m25m.execute-api.us-east-1.amazonaws.com/dev/editPortfolio',
        payload,
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        alert('Portfolio updated successfully!');
        fetchPortfolioData(); // Optionally refetch data
      } else {
        alert('Failed to update portfolio.');
      }
    } catch (error) {
      console.error('Error updating portfolio:', error);
      alert('Error updating portfolio. Please try again.');
    }
  };

  return (
    <div style={{border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px",}}>

      {loading && <p>Loading portfolio data...</p>}
      {!loading && portfolioDataFetched && (
        <AddPortfolioItem>
    <h5>Add Portfolio Item</h5>
        <form onSubmit={handleUpdatePortfolio}>
          {portfolioItems.map((item, index) => (
            <div key={index}>
              <label>Project Objective:</label>
              <Input
                type="text"
                value={item.project_objective || ''}
                onChange={(e) =>
                  handlePortfolioItemChange(index, 'project_objective', e.target.value)
                }
              />
              <label>Project Description:</label>
              <Textarea
                type="text"
                value={item.project_description || ''}
                onChange={(e) =>
                  handlePortfolioItemChange(index, 'project_description', e.target.value)
                }
              />
              <label>Industry:</label>
              <Selectfield
                type="text"
                value={item.industry || ''}
                onChange={(e) =>
                  handlePortfolioItemChange(index, 'industry', e.target.value)
                }
              />
              <label>Project Files:</label>
              <ImageInput
                type="file"
                multiple
                onChange={(e) => handleImageChange(index, e.target.files)}
              />
              {item.project_files && (
                <div>
                  {item.project_files.map((file, i) => (
                    <img
                      key={i}
                      src={file}
                      alt={`Project file ${i}`}
                      style={{ width: '100px', height: '100px' }}
                    />
                  ))}
                </div>
              )}
            </div>
          ))}
          <Button1 type="button" onClick={addNewPortfolioItem}>
            Add New Portfolio Item
          </Button1>
          <button type="submit">Update Portfolio</button>
        </form>
        </AddPortfolioItem>


      )}
    </div>
  );
};

export default PortfolioComponent;

const RemoveButton = styled.button`
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e60000;
  }

  &:focus {
    outline: none;
  }
`;
const Input = styled.input`
  width: 28rem;
  height: 3rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 18rem;
  }
`;

const Selectfield = styled.select`
  width: 28rem;
  height: 3rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 18rem;
  }
`;

const Textarea = styled.textarea`
  width: 28rem;
  height: 5rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 18rem;
  }
`;

const ImageInput = styled.input`
  margin-bottom: 1rem;
`;

const ResumeUploadPageWrapper = styled.div`
  padding: 20px;
  position: relative;
`;

const UploadButton = styled.button`
  margin-left: 10px;
`;

const UploadStatus = styled.p`
  margin-top: 10px;
`;
const Button1 = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;
const AddPortfolioItem = styled.div`
  background-color: #f2f2f2;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  width: 30rem;

  h2 {
    margin-bottom: 1rem;
  }
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3), 0 1px 3px rgba(0, 0, 0, 0.010);
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 20rem;
 
  }
`;