import React from 'react'
import styled from '@emotion/styled';
import { NavLink } from "react-router-dom";
import MailIcon from '@mui/icons-material/Mail';
import { Button } from '@mui/material';
import  {useState, useEffect} from 'react'
import reactnative from './assets/reactnative.png';
import angular from './assets/angular.png';
import apple from './assets/apple.png';
import cloud from './assets/cloud.png';
import java from './assets/java.png';
import php from './assets/php.png';
import python from './assets/python.png';
import android from './assets/android.png';
import react from './assets/react.png';
import nodejs from './assets/nodejs.png';
import mean1 from './assets/mean1.png';
import lamp from './assets/lamp.png';
import mern from './assets/mern.png';
import homeimage from './assets/Group94.png';
import createprofile from './assets/Group917.png';
import mevn from './assets/mevn.png';


function Projectrequirementdata ()  {
    const [toggleMenu, setToggleMenu] = useState(true)
   return (
<div>
<center>
                          <div  className="conatiner" style={{marginTop:'15rem', marginLeft: "100px" }}>
                          {/* <h1 style={{textAlign:'center',fontSize:'35px',fontWeight:'600',height:'120px',width:'1092px'}}>Check out the options below for on-demand talent<h1 style={{textAlign:'center',fontSize:'35px',fontWeight:'600',height:'120px',width:'1092px'}}> here to gain access to our Developer Talent Database</h1></h1> */}
                          <h1 className='containerheader'>Check out the options below for on-demand talent<br></br> here to gain access to our Developer Talent Database</h1>
    <div className='items' style={{ marginRight: "140px" }}>
                         <div className='card1' >
                         <center>
                          <img  className='img' src= {reactnative}></img>
                          <p className='names'>React Native Developer</p>
                          </center>
                         </div>
                         <div className='card1' >
                         <center>
                          <img  className='img' src= {nodejs}></img>
                          <p className='names'>NodeJS Developer</p>
                          </center>
                         </div>
                         <div className='card1' >
                         <center>
                          <img  className='img' src= {php}></img>
                          <p className='names'>PHP Developer</p>
                          </center>
                         </div>
                         <div className='card1' >
                         <center>
                          <img  className='img' src= {java}></img>
                          <p className='names'>Java Developer</p>
                          </center>
                         </div>
                         <div className='card1' >
                         <center>
                          <img  className='img' src= {python}></img>
                          <p className='names'>Python Developer</p>
                          </center>
                         </div>
                         <div className='nextitems'   >
                         <div className='card1' >
                         <center>
                          <img  className='img' src= {apple}></img>
                          <p className='names'>IOS Developer</p>
                          </center>
                         </div>
                         <div className='card1' >
                         <center>
                          <img  className='img' src= {android}></img>
                          <p className='names'>Android Developer</p>
                          </center>
                         </div>
                         <div className='card1' >
                         <center>
                          <img  className='img' src= {angular}></img>
                          <p className='names'>AngularJS Developer</p>
                          </center>
                         </div>
                         <div className='card1' >
                         <center>
                          <img  className='img' src= {react}></img>
                          <p className='names'>ReactJS Developer</p>
                          </center>
                         </div>
                         <div className='card1' >
                         <center>
                          <img  className='img' src= {cloud}></img>
                          <p className='names'>Cloud Engineer</p>
                          </center>
                         </div>
                         </div>
                         </div>
                          </div>
                          <a href='/project-requirment' style={{ textDecoration: 'none' }} onClick={()=>setToggleMenu(false)}  >
<button       style={{backgroundColor:'#1DBF73',color:'white',width:'15rem',height:'4rem',borderRadius:'10px', marginTop: "100px", border: "none" }} >Post Requirements</button>
</a>
                          </center>
                          <div style={{height:'21rem',width:'100vw',marginLeft:'-1rem',marginTop:'12rem',border:'2px solid #00B4D8',backgroundColor:'#00B4D8'}}>
     <div style={{marginLeft:'5rem',marginTop:'2.5rem',color:'white'}}>
     <InputContainer  >
              <Input type="text" placeholder="Enter Your E-mail Address" />
              <span style={{marginLeft:'-19.8rem',width:'30px',backgroundColor:'white',height:'40px',color:'grey',paddingTop:'6px',
borderTopLeftRadius:'10px',
borderBottomLeftRadius:'10px'}}>
              <MailIcon/>
              </span>
              </InputContainer>
              <p style={{marginLeft:'75rem',borderRadius:'10px',marginTop:'-2.4rem',cursor:'pointer',height:'2.3rem',width:'10rem' ,backgroundColor:'white',color:'gray',textAlign:'center',padding:'6px'}}>  <a>Subscribe</a></p>
            <p style={{paddingTop:'0.5rem'}} >Search Terms</p>
      <p style={{paddingTop:'0.1rem'}}>Privacy and Cookie Policy</p>
      <p style={{paddingTop:'0.1rem'}}>Advanced Search</p>
      <p style={{paddingTop:'0.1rem'}}>Orders and Returns</p>
      <p style={{paddingTop:'0.1rem',paddingBottom:'1rem'}}>Contact Us</p>
      </div>
<div style={{height:'3rem', backgroundColor:'black'}}><p style={{color:'white',fontWeight:'200', paddingTop:'10px',textAlign:'center' }}>Copyright © 2014-present Digitivy Technology Solutions, Inc. All rights reserved.</p></div>
    </div>
   </div>
   )
}
const InputContainer = styled.div`
display : flex;
// border:2px solid #90E0EF;
// border-radius:1rem;
// justify-content: center;
 margin-left:55rem;
`;
const Input = styled.input`
border:0px solid black;
justify-content: center;
height: 2.5rem;
width: 18rem;
background-color:white;
border-top-right-radius:10px;
border-bottom-right-radius:10px;
&:focus{
    border:none;
    outline:none;
}
`;
const Icon = styled.div`
height: 3rem;
width: 2rem;
background-color: white;
// border:1px solid black;
display:flex;
flex-direction:row
justify-content: center;
align-items: center;
border-top-left-radius:10px;
border-bottom-left-radius:10px;
svg{
    color:#555555;
}
`;
export default Projectrequirementdata;