export default [
    { value: "0", label: "html" },
    { value: "1", label: "JavaScript" },
    { value: "3", label: "Adobe Illustrator"},
    { value: "4", label: "ReactJS"},
    { value: "5", label: "AngularJS"},
    { value: "6", label: "Vue"},
    { value: "7", label: "Next"},
    { value: "8", label: "Typescript"},
    { value: "9", label: "Gatsby"},
    { value: "10", label: "ThreeJs"},
    { value: "11", label: "NodeJs"},
    { value: "12", label: "Git"},
    { value: "13", label: "c++"},
    { value: "14", label: "Perl"},
    { value: "15", label: "Kotlin"},
    { value: "16", label: "AWS"},
    { value: "17", label: "Azure"},
    { value: "18", label: "Adobe Illustrator"},
    { value: "19", label: "CSS"},
    { value: "20", label: "PHP"},
    { value: "21", label: "Python"},
    { value: "22", label: "SQL"},
    { value: "23", label: "Java"},
];





