import styled from "styled-components";
import React from "react";
import Badge from "./Badge";
import Avatar from "./assets/MaskGroup.png";
import { cardShadow, hoverEffect, themeColor } from "../utils";
import { useState } from "react";
import ShowMore from "react-show-more-button/dist/components/ShowMore";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Rsvp } from "@mui/icons-material";
import axios from "axios";
import { data, error, getJSON } from "jquery";
import { Route } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';
import EditPostRequirementCand from './EditPostRequirementCand';
import { useParams,useHistory } from 'react-router-dom';
import{FiSearch} from 'react-icons/fi';
import moment from "moment";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useAuthToken } from "../TokenContext";
const Podactivitydashboardviewall = () => {


 
    const { isLoggedIn, user } = useSelector(state => state.auth);
    
    const[getdata,setGetData]=useState([]);

  const params =useParams();


  const token = useAuthToken();






     

   useEffect(() =>{


    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };


const body={ lead_id: user.data[0].c_id, status: "view_all",} ;

// https://qoe6ubwhig.execute-api.us-east-1.amazonaws.com/dev/pod_activity_dashboard
    axios.post(process.env.REACT_APP_TALENTLEAD_PODACTIVITYDASHBOARD,body,{headers:headers})

      .then((res)=>
      { 
       
      setGetData(res.data.data.data);
      
    }
    
  

   
      )
      .catch((err)=>{
        console.log("catch",err);
      })

    
   },[token]);
  

   
   
    
  
   const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 }
  ];
  function timeSince(date) {
    const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
    const interval = intervals.find(i => i.seconds < seconds);
    const count = Math.floor(seconds / interval.seconds);
    return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
  

  }


    
//   console.log(rep);

 

  return (
    <Div>
      <TitleText>Your Posted Pod Activity</TitleText>
      
      <RecommendProject>
      <TableContainer component={Paper} >
      <Table component={Paper} sx={{ maxWidth: 900 ,fontFamily:'Poppins,Medium', borderRadius: '10px'}} aria-label="simple table">
    
      
    
    <TableHead sx={{ backgroundColor:'#00B4D8','& td, & th': {
                fontFamily: 'Poppins,Medium',
                fontSize: '15px',
                color: 'white',
                fontWeight:'600'
              },}}>
   
      <TableRow>
      <TableCell align="right" sx={{fontWeight:'bold'}}>Posted Date</TableCell>
        <TableCell align="right" sx={{fontWeight:'bold'}}>Pod Member</TableCell>
        <TableCell align="right"sx={{fontWeight:'bold'}}>Category</TableCell>
        <TableCell align="right"sx={{fontWeight:'bold'}}>Today's Update</TableCell>
        <TableCell align="right"sx={{fontWeight:'bold'}}>Blockers</TableCell>
       
      </TableRow>
    </TableHead>
    {
  getdata.map((item,index)=> 
        <TableBody>
        
       
            <TableRow
            
              key={""}
              sx={{
                '&:last-child td, &:last-child th': { border: 0, color: 'black', fontFamily: 'Poppins,Medium', fontSize: '15px' },
                backgroundColor: index % 2 === 0 ? '#caf0f8' : '#90E0EF', // Set even and odd row colors
              }}
           >
 <TableCell align="right" > {moment(item.createdAt ).format("YYYY-MM-DD") }</TableCell>
              
              <TableCell   align="right" >{item.pod_candidates}</TableCell>
              <TableCell align="right">{item.categories}</TableCell>
              <TableCell align="right">{item.today_update}</TableCell>
              <TableCell align="right">{item.blockers}</TableCell>
          
              
            </TableRow>
             
        </TableBody>)}
    </Table>

    </TableContainer  >

      
        


      </RecommendProject>
    </Div>
  );
};

const Div = styled.div`
  // display:flex;
  display: inline;
  jutify-content: center;
  flex-direction: column;
  margin-top: 6rem;
  align-items: center;

  margin-left: 6.5rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 25rem;
  }
`;
const Div1 = styled.div`
  display: flex;

  jutify-content: center;
  flex-direction: row;

  align-items: center;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
  }
`;

const ButtonEdit =styled.button`

  text-decoration: none;
  border: none;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top:2rem;
  }

`;
const RecommendProject=styled.div`

 display:flex;

jutify-content:center;
flex-direction:row;
@media screen and (min-width:320px) and (max-width:1080px){
    width:100%;
    height:max-content;
    margin:auto;
    
 display:flex;


 flex-direction:column;
    
   }
`;

const Row = styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
`;
const TitleText = styled.h4`
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
   margin-top:2rem;
  }

`;

const Position = styled.p`
  font-weight: 600;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:4rem;
  }
`;


const Position1 = styled.p`
  font-weight: 600;
  width:15rem;
  overflow-wrap: anywhere;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:4rem;
  }
`;



const Answer = styled.p`
  font-weight: 600;
  width:15rem;
  overflow-wrap: anywhere;
  border-radius:10px;
  
  font-family:Poppins,Medium;
//   background-color:#1DBF73;
//   padding:1rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:7rem;
    overflow-wrap: anywhere;
  }
`;



const CardContent = styled.div`
  margin: 0.4rem;
  width: 45rem;
  padding:1rem;
  height:34rem;
  overflow-y:scroll;

  border-radius:10px;
  font-family:Poppins,Medium;
  background-color:#F2F4F5;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width:20rem;
    margin:auto;
  
  }
  
`;
const Divseperator = styled.div`
border-bottom:1px solid #CECECE;
padding:1rem;
`;
const Detail = styled.div`
  display: flex;
  justify-content: space-around;
  aligh-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const InfoName = styled.h6`
  font-weight: 600;
`;
const InfoUpdate = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;
const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  padding: 0.3rem;

  border-radius: 7px;
  height: 100%;
  width: max-width;
  background-color: ${themeColor};

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    // gap:0.4rem;
   width:100%;
    height: 100%;
    margin: auto;
  }
`;
const Price = styled.div`
  color: white;
  width: max-width;
  padding-right: 10px;
`;
const Title = styled.h6`
  font-weight: 600;
  display:flex;
  flex-direction:row;
`;
const ProjectInfo = styled.h6`
font-weight: 600;
  display:flex;
  flex-direction:row;
`;

const SideCard=styled.div`
margin-left:5rem;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
border-radius:20px;
font-family:Poppins,Medium;
width:20rem;
height:34rem;

padding: 1rem;
background-color: #F2F4F5;

box-shadow:${cardShadow};
transition:0.45 ease-in-out;
&:hover {
box-shadow:${hoverEffect};

}
@media screen and (min-width: 320px) and (max-width: 1080px) {
  width:20rem;
  height:38rem;
  margin: auto;
  margin-top:2rem;
}
`;


const SideCardContent=styled.div`
background-color:#90E0EF;
width:17rem;

display:flex;
flex-direction:column;
padding:3px;
margin:1rem;

border-radius:20px;
`;


const SideCardContent1=styled.div`
background-color:#90E0EF;
width:17rem;
display:flex;
margin-top:1rem;
flex-direction:column;
height:10rem;
justify-content:center;
align-items:center;
border-radius:20px;
`;





const Detail1 = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }
`;
const InfoContainer1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Info1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;
const Row1= styled.div`
  display: flex;

  align-item: center;
  margin-bottom: 0.8rem;
`;
const InfoName1 = styled.h6`
  font-weight: 600;
`;
const InfoUpdate1 = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: auto;
`;

const Title1 = styled.h6`
  font-weight: 600;
`;
const ProjectInfo1 = styled.p`
padding:5px;
margin:3px;
background-color:white;
border-radius:5px  ;
font-size:13px;
color:black;
font-weight:600;
margin-bottom:1rem
`;

const ViewAll =styled.button`
width:5rem;
font-weight:600;
height:2rem;
margin-left:auto;
margin-top:1rem;
border: 2px solid #1DBF73;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;

const View =styled.button`
width:8rem;
font-weight:600;
height:2rem;
background-color:white;
border-radius:5px  ;
margin-bottom:1rem;
margin-top:1rem;
border: none;
border-radius:5px  ;
box-shadow: 1px 3px 6px #00000029;
`;
const Button=styled.button`
margin-top:1rem;
border:none;
border-radius: 10px;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
width:12rem;
height:2rem;
font-size:15px;
font-weight:600;
color:white;
@media screen and (min-width:320px) and (max-width:1080px){
  
  margin-bottom:1rem;

}

`;

const InputContainer = styled.div`
display : flex;
border-radius:10px;
justify-content: center;
margin-left:auto;
width:25rem;
margin-top:-2rem;
margin-bottom:1rem;
border:1px solid #4E8FAE;
box-shadow: 3px 6px 9px #00000005;
@media screen and (min-width:320px) and (max-width:1080px){
  margin:auto;
  margin-top:2rem;
  margin-bottom:2rem;
  width:20rem;
}
`;

const Icon = styled.div`
height: 3rem;
width: 2rem;
background-color: #F2F3F4;
display:flex;
flex-direction:row
justify-content: center;
align-items: center;
border-top-left-radius:10px;
border-bottom-left-radius:10px;
svg{
    color:#555555;
}
`;

const Input = styled.input`
justify-content: center;
height: 3rem;
width: 25rem;
border:0px solid #4E8FAE;
background-color:#F2F3F4;
font-family:Poppins,Medium;
font-Weight:600;
border-top-right-radius:10px;
border-bottom-right-radius:10px;
&:focus{
    border:none;
    outline:none;
}
@media screen and (min-width:320px) and (max-width:1080px){
  width:20rem;
}
`;



export default Podactivitydashboardviewall;
