import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
  Button,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Stack,
  Breadcrumbs,
  Alert,
  Fade,
  Paper,
  alpha,
  IconButton,
  Tooltip
} from '@mui/material';
import axios from "axios";

import {
  Assignment as AssignmentIcon,
  CalendarToday as CalendarIcon,
  Category as CategoryIcon,
  Description as DescriptionIcon,
  BugReport as BugReportIcon,
  ArrowBack as ArrowBackIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { useAuthToken } from "../TokenContext";

export default function TaskForm() {
  const location = useLocation();
  const { podname, podid } = location.state;
  const { user } = useSelector(state => state.auth);
  const token = useAuthToken();

  // Form state
  const [formData, setFormData] = useState({
    podTaskName: '',
    description: '',
    dueDate: '',
    category: '',
    jiraItem: '',
    isBlocker: false
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const categories = [
    { value: 'Design', icon: '🎨' },
    { value: 'Coding', icon: '💻' },
    { value: 'Testing', icon: '🔍' },
    { value: 'Documentation', icon: '📝' }
  ];

  const handleChange = (field) => (event) => {
    setFormData(prev => ({
      ...prev,
      [field]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
    }));
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.podTaskName || !formData.description || !formData.dueDate || !formData.category) {
      setError('Please fill in all required fields');
      return;
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_TALENT_TALENTCREATEPODTASK,
        {
          project_key: podname,
          pod_task_name: formData.podTaskName,
          description: formData.description,
          due_date: formData.dueDate,
          candidate_id: user.data[0].c_id,
          Candidate_Name: user.data[0].firstName,
          issuetype: "story",
          summary: '',
          category: formData.category,
          pod_id: podid,
          jira_item: formData.jiraItem,
          blockercheck: formData.isBlocker ? 1 : 0
        },
        {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        }
      );

      setSuccess(true);
      setFormData({
        podTaskName: '',
        description: '',
        dueDate: '',
        category: '',
        jiraItem: '',
        isBlocker: false
      });

      setTimeout(() => setSuccess(false), 3000);
    } catch (error) {
      setError('Failed to create task. Please try again.');
    }
  };

  return (
    <Box
      sx={{
        margin:"auto",
        minHeight: '100vh',
        p: 3,
        background: 'linear-gradient(145deg, #f6f8fb 30%, #f0f4f8 90%)'
      }}
    >
      <Paper
        elevation={0}
        sx={{
          maxWidth: 800,
          mx: 'auto',
          mt: 8,
          borderRadius: '20px',
          overflow: 'hidden',
          backgroundColor: alpha('#fff', 0.9),
          backdropFilter: 'blur(20px)'
        }}
      >
        <Stack spacing={3}>
          {/* Header */}
          <Box
            sx={{
              p: 3,
              background: 'linear-gradient(145deg, #2196f3 30%, #1976d2 90%)',
              color: 'white'
            }}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Tooltip title="Back to Pods">
                <IconButton
                  component={Link}
                  to="/Pods"
                  sx={{ color: 'white' }}
                >
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
              <Stack spacing={1}>
                <Typography variant="h5" fontWeight="600">
                  Create New Task
                </Typography>
                <Breadcrumbs
                  sx={{
                    '& .MuiTypography-root': { color: 'white' },
                    '& .MuiBreadcrumbs-separator': { color: 'white' }
                  }}
                >
                  <Link to="/Pods" style={{ color: 'white', textDecoration: 'none' }}>
                    My Pods
                  </Link>
                  <Typography>Post Task</Typography>
                </Breadcrumbs>
              </Stack>
            </Stack>
          </Box>

          {/* Form */}
          <form onSubmit={handleSubmit}>
            <Stack spacing={3} sx={{ p: 3 }}>
              {error && (
                <Alert severity="error" sx={{ borderRadius: 2 }}>
                  {error}
                </Alert>
              )}
              
              {success && (
                <Alert severity="success" sx={{ borderRadius: 2 }}>
                  Task created successfully!
                </Alert>
              )}

              <TextField
                label="Task Name"
                required
                value={formData.podTaskName}
                onChange={handleChange('podTaskName')}
                InputProps={{
                  startAdornment: <AssignmentIcon sx={{ mr: 1, color: 'primary.main' }} />
                }}
                sx={{ '& .MuiInputBase-root': { borderRadius: 2 } }}
              />

              <TextField
                label="Description"
                required
                multiline
                rows={4}
                value={formData.description}
                onChange={handleChange('description')}
                InputProps={{
                  startAdornment: <DescriptionIcon sx={{ mr: 1, color: 'primary.main' }} />
                }}
                sx={{ '& .MuiInputBase-root': { borderRadius: 2 } }}
              />

              <TextField
                label="Due Date"
                type="date"
                required
                value={formData.dueDate}
                onChange={handleChange('dueDate')}
                InputProps={{
                  startAdornment: <CalendarIcon sx={{ mr: 1, color: 'primary.main' }} />,
                }}
                InputLabelProps={{ shrink: true }}
                sx={{ '& .MuiInputBase-root': { borderRadius: 2 } }}
              />

              <TextField
                select
                label="Category"
                required
                value={formData.category}
                onChange={handleChange('category')}
                InputProps={{
                  startAdornment: <CategoryIcon sx={{ mr: 1, color: 'primary.main' }} />
                }}
                sx={{ '& .MuiInputBase-root': { borderRadius: 2 } }}
              >
                {categories.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <span>{option.icon}</span>
                      <span>{option.value}</span>
                    </Stack>
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                label="Jira Item"
                value={formData.jiraItem}
                onChange={handleChange('jiraItem')}
                InputProps={{
                  startAdornment: <BugReportIcon sx={{ mr: 1, color: 'primary.main' }} />
                }}
                sx={{ '& .MuiInputBase-root': { borderRadius: 2 } }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.isBlocker}
                    onChange={handleChange('isBlocker')}
                    color="error"
                  />
                }
                label={
                  <Typography color="error" fontWeight={500}>
                    This task is a blocker
                  </Typography>
                }
              />

              <Button
                type="submit"
                variant="contained"
                size="large"
                startIcon={<SaveIcon />}
                sx={{
                  mt: 2,
                  py: 1.5,
                  borderRadius: 2,
                  background: 'linear-gradient(45deg, #2196f3 30%, #1976d2 90%)',
                  boxShadow: '0 3px 5px 2px rgba(33, 150, 243, .3)',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #1976d2 30%, #1565c0 90%)',
                  }
                }}
              >
                Create Task
              </Button>
            </Stack>
          </form>
        </Stack>
      </Paper>
    </Box>
  );
}
