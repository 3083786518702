import React ,{Component}from "react";
import '../components/css/addcandidate.css';
import axios from 'axios';
// import FormikControl from './formikcontrol';
import Validate from "./utility/FormValidation";
import FormErrors from "./FormErrors";
// import Select from "react-select/src/Select";
import Select from 'react-select';
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
const area = [
   { 
     value: 'Node', label: 'Node'
    },
  { 
    value: 'Java', label: 'Java'
   },
  { 
    value: 'React', label: 'React'
   },
  { 
    value: 'Python', label: 'Python' 
  },
  { 
    value: 'AWS', label: 'AWS' 
  },
  { 
    value: 'C++', label: 'C++' 
  }
]

//for country
const country = [
  { 
    value: 'USA', label: 'USA'
   },
 { 
   value: 'Canada', label: 'Canada'
  },
 { 
   value: 'India', label: 'India'
  }
]
//new
const industry = [
  { 
    value: ' Healthcare', label: ' Healthcare'
   },
 { 
   value: ' Banking and Financia', label: ' Banking and Financia'
  },
 { 
   value: 'Services', label: 'Services'
  }
]

class Updatecandidate extends Component {

  imageHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () =>{
      if(reader.readyState === 2){
        this.setState({profileImg: reader.result})
      }
    }
    reader.readAsDataURL(e.target.files[0])
  };
  state = {
  fname: "",
  mname:"",
  lname:"",
  candidateemail: "",
  area: "", 
  country:"",
  oexpertise: "",
  portfolio: "",
  linkedin:"",
  github:"",
  resetForm: "",
  profileImg:
	'/https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
    errors: {
      blankfield: false,
      passwordmatch: false
    }
  }

  handleChangeindustry = (event) => {
   
    this.setState({ industry : event.value });
  };

  handleChangearea = (event) => {
  
    this.setState({ area : event.value });
  };

  handleChangeexpertise = (event) => {
   
    this.setState({ oexpertise : event.value });
  };

    handleChangecountry = (event) => {
    
    this.setState({ country : event.value });
  };

  clearErrorState = () => {
    this.setState({
      errors: {
        blankfield: false,
        passwordmatch: false
      }
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    // Form validation
 
    const { fname, mname, lname, candidateemail, area, country, linkedin, github, oexpertise, portfolio  } = this.state;

    console.log("buviviyviyv")
    // Database insert here
    if (fname && mname) {
      // https://zfnddk64nb.execute-api.us-east-1.amazonaws.com/dev/Edit_Candidate
      axios.put(process.env.REACT_APP_TALENT_EDITCANDIDATE, {
        id : this.props.auth.user.c_id,
        firstName: fname, middleName : mname, lastName : lname, primaryskill : area, country : country, linkedinUrl : linkedin, githubUrl : github,
        secondaryskill: oexpertise
      
      }).then(res => {
          // console.log(res);
          // console.log(res.data);
          window.alert("Profile is updated successfully!");
          this.props.history.push("/");
      }, (error) => {
          console.log(error);
      });
  }
  };
  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }
render() {
  const { profileImg} = this.state
    return (

      <div>
        <h1 className="head">Create your profile to our talent database to be included<br></br> for various on-demand staffing or project opportunities</h1>
<br></br>
     <div className="container" style={{   "box-shadow": "1px 3px 6px #00000029",
  "border": "1px solid #D8D8D8", "paddingBottom" : "40px" }}>
      <br></br>
      <br></br>

      <div className="row">


        <div className="col-lg-3" ></div>
 
          <div className="col-lg-6" >
            
          <h1 className="headsmall">Update profile</h1>

          <br></br>
<form onSubmit={this.handleSubmit}>
            {/* For First Name */}


            <div className="email">
              <input
                className="input" 
                placeholder="Enter First Name"
                type="text"
                id="fname"
                value={this.state.fname}
                onChange={this.onInputChange}
                required
              />
            </div>

            {/* For Middle Name */}
            <div className="email">
              <input
                className="input" 
                placeholder="Enter Middle Name"
                type="text"
                id="mname"
                value={this.state.mname}
                onChange={this.onInputChange}
                required
              />
            </div>

             {/* For Last Name */}
             <div className="email">
              <input
                className="input" 
                placeholder="Enter Last Name"
                type="text"
                id="lname"
                value={this.state.lname}
                onChange={this.onInputChange}
                required
              />
            </div>

            {/* For Email */}
           

            {/* For Primary Expertise */}
            <div className="email">
              <Select
                onChange={this.handleChangearea}
                options={area}
                className="input2"
                id="area"
                placeholder="Primary Expertise"
                isMulti="true"
                value={this.state.area} 
                required
                />
            </div>

            {/* Other Expertise */}
            <div className="password">
              <input             
                className="input"
                placeholder="Any other expertise you would like to list?"
                type="text"
                value={this.state.oexpertise}
                onChange={this.onInputChange}
                id="oexpertise"
              />
            </div>

             {/* For Country */}
             <div className="email">
              <Select
                  //value={area}
                  options={country}
                  className="input2"
                  id="country"
                  placeholder="What's your country"
                  onChange={this.handleChangecountry}

                  />
            </div>

            {/* Portfolio */}
            <div className="password">
              <input
                className="input"
                placeholder="url of your portfolio work"
                type="url"
                value={this.state.portfolio}
                onChange={this.onInputChange}
                id="portfolio"
              />
            </div>

            {/* LinkedIn */}
            <div className="password">
              <input
                className="input"
                placeholder="paste your LinkedIn profile url"
                type="url"
                value={this.state.linkedin}
                onChange={this.onInputChange}
                id="linkedin"
              />
            </div>

            {/* GitHub */}
            <div className="password">
              <input
                className="input"
                placeholder="paste your GitHub url"
                type="url"
                value={this.state.github}
                onChange={this.onInputChange}
                id="github"
              />
            </div>
            
             <div className="email">
              <Select
                  //value={area}
                  onChange={this.handleChangeindustry}
                  options={industry}
                  className="input2"
                  id="industry"
                  placeholder="Industry"
                  />
            </div> 

            
            <div className="createAccount">
              <button className="button is-success" style={{ width : "50%"}} type="submit">Save</button>
            </div>
              <div className="col-sm-6">

           </div>
           </form>

        </div>
        <div className="col-lg-3"></div>

        
        </div>
      </div>
<br></br>
<Footer></Footer>

     </div>

    );
  }
}
export default Updatecandidate;
